import { colors } from 'enums'
import { FC, useEffect, useState } from 'react'
import { Label } from '../Styled'
import {
   Container,
   StyledError,
   StyledInput,
   SwitchPasswordVisiableButton
} from './styled'
import { TInputProps } from './types'

const Input: FC<TInputProps> = ({
   label = '',
   value = '',
   width = '318px',
   height = '45px',
   placeholder,
   type = 'text',
   onChange,
   name,
   isValid = true,
   required = false,
   readonly = false,
   disabled = false,
   align = 'flex-start',
   maxLength,
   error,
   cref,
   defaultValue,
   min,
   max,
   currency,
   changedProduct
}) => {
   const [visiable, setVisiable] = useState(false)
   const [inputValue, setInputValue] = useState(value)

   const Events = {
      switchVisiability: () => {
         setVisiable(!visiable)
      },
      onKeyPressFormatedField: (e: any) => {
         const key = e.key // const {key} = event; ES6+
         if (key === 'Backspace' || key === 'Delete') {
            setInputValue(inputValue.replace(/[^0-9]/g, '').slice(0, -1))
         }
      }
   }

   useEffect(() => {
      if (type === 'percentage') {
         setInputValue(value.replace(/[^0-9]/g, ''))
         if (inputValue.substring(0, 1) === '0') {
            setInputValue(inputValue.substring(1))
         }
      }
   }, [value])

   return (
      <Container align={align} width={width}>
         {(type == 'text' || type == 'number') && (
            <>
               {label && <Label>{label}</Label>}

               <StyledInput
                  name={name}
                  type={type}
                  defaultValue={defaultValue}
                  value={value}
                  placeholder={placeholder}
                  onChange={(e) => onChange && onChange(e, e.target.value)}
                  error={!isValid}
                  required
                  readOnly={readonly}
                  height={height}
                  maxLength={maxLength}
                  ref={cref}
                  disabled={disabled}
                  min={min}
                  max={max}
                  style={{ backgroundColor: changedProduct ? '#EEF4FA' : '' }}
               />
               <StyledError className={isValid ? 'error' : ''}>{error}</StyledError>
            </>
         )}

         {type === 'password' && (
            <>
               {label && <Label>{label}</Label>}
               <StyledInput
                  name={name}
                  type={visiable ? 'text' : 'password'}
                  defaultValue={value}
                  placeholder={placeholder}
                  onChange={(e) => onChange && onChange(e, e.target.value)}
                  error={!isValid}
                  height={height}
                  required
               />

               <SwitchPasswordVisiableButton
                  visiable={visiable}
                  onClick={Events.switchVisiability}
               />
            </>
         )}

         {type === 'percentage' && (
            <>
               {label && <Label>{label}</Label>}
               <StyledInput
                  name={name}
                  type="text"
                  defaultValue={inputValue ? inputValue + '%' : '0%'}
                  placeholder={placeholder}
                  onChange={(e) =>
                     onChange &&
                     onChange(e, parseInt(e.target.value.replace(/[^0-9]/g, '')))
                  }
                  error={!isValid}
                  height={height}
                  required
                  onKeyDown={Events.onKeyPressFormatedField}
               />
            </>
         )}
      </Container>
   )
}

export default Input
