import { FC } from 'react'
import {
   Container,
   HoverAbsoluteContainer,
   HoverContainer,
   Item,
   RelativeContainer
} from './styled'
import { THoverList } from './types'

const HoverList: FC<THoverList> = ({ items }) => {
   return (
      <Container>
         +{items.length}
         <HoverAbsoluteContainer>
            <RelativeContainer>
               <HoverContainer>
                  {items.map((item, index) => (
                     <Item key={index}>{item}</Item>
                  ))}
               </HoverContainer>
            </RelativeContainer>
         </HoverAbsoluteContainer>
      </Container>
   )
}

export default HoverList
