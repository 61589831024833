import styled from 'styled-components'

export const Container = styled.div`
   display: grid;
   width: 100%;
   height: 100%;
   grid-template-columns: 3.7fr 1fr;
   grid-column-gap: 30px;

   padding: 32px;
`
