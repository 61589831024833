import { all } from 'redux-saga/effects'
import { adminWatcher } from './admin'
import { bannerWatcher } from './banner'
import { bonusWatcher } from './bonus'
import { brandWatcher } from './brand'
import { categoryWatcher } from './category'
import { clientWatcher } from './client'
import { gridBannerWatcher } from './grid'
import { moderationWatcher } from './moderation'
import { orderWatcher } from './order'
import { productWatcher } from './product'
import { roleWatcher } from './role'
import { sectionWatcher } from './section'
import { seoWatcher } from './seo'
import { specialistWatcher } from './specialist/saga'
import { supportWatcher } from './support'
import { userWatcher } from './user'

function* rootSaga() {
   yield all([
      userWatcher(),
      adminWatcher(),
      bannerWatcher(),
      sectionWatcher(),
      productWatcher(),
      orderWatcher(),
      clientWatcher(),
      bonusWatcher(),
      supportWatcher(),
      roleWatcher(),
      categoryWatcher(),
      brandWatcher(),
      gridBannerWatcher(),
      seoWatcher(),
      specialistWatcher(),
      moderationWatcher()
   ])
}

export default rootSaga
