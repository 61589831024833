import { FC } from 'react'
import { Container, SearchBarTemplate, SearchIcon } from './styled'
import { TSearchBar } from './types'

const SearchBar: FC<TSearchBar> = ({
   name,
   value = '',
   placeholder = '',
   onChange,
   onSubmit
}) => {
   return (
      <form onSubmit={onSubmit}>
         <Container>
            <SearchBarTemplate
               name={name}
               value={value}
               placeholder={placeholder}
               onChange={onChange}
               onSubmit={onSubmit}
            />
            <SearchIcon />
         </Container>
      </form>
   )
}

export default SearchBar
