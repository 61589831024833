import { createSlice } from '@reduxjs/toolkit'
import { TInitialState } from './types'

const initialState: TInitialState = {
   brands: {
      items: [],
      error: false,
      discountBrandId: '',
      meta: null
   }
}

export const slice = createSlice({
   name: 'brand',
   initialState,
   reducers: {
      setBrands: (state, { payload }) => {
         state.brands = { error: false, items: [], ...payload }
      },
      setDiscountBrandId: (state, { payload }) => {
         state.brands.discountBrandId = payload
      }
   }
})

export const sliceActions = slice.actions

export default slice.reducer
