import { FC, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
   AdminPanelContainer,
   AlignContainer,
   Button,
   FlexContainer,
   Header,
   Input,
   PlusIcon,
   RemoveButton
} from '../../components'
import { AddButtonContainer } from '../Bonuses/styled'
import { Container } from './styled'

const IndexPage: FC = () => {
   const { t } = useTranslation()

   const [form, setForm] = useState([
      { name: 'Доставка по городу до 20 км ', cost: ' 25 ₼' },
      { name: 'Доставка по городу до 40 км ', cost: ' 50 ₼' }
   ])

   const Events = {
      saveButtonHandler: () => {},
      removeButtonClickHandler: () => {},
      onAddClickHandler: () => {
         setForm(form.concat([{ name: '', cost: '' }] as typeof form))
      },
      inputHanler: (e: SyntheticEvent, value: string | number) => {
         const input = e.target as HTMLInputElement
         const deliveryContainerIndex = Utils.getDeliveryIndex(e)

         setForm((form) =>
            form.map((delivery, index) => {
               if (index != deliveryContainerIndex) return delivery
               return { ...delivery, [input.name]: value }
            })
         )
      }
   }

   const Utils = {
      getDeliveryIndex: (e: SyntheticEvent): number => {
         const target = e.target as HTMLElement
         const bonusesContainer = target.closest('.deliveries_form_container')
         const bonusContainer = target.closest('.delivery_form_container')
         const bonusContainerIndex = Array.prototype.indexOf.call(
            bonusesContainer?.children,
            bonusContainer
         )

         return bonusContainerIndex
      }
   }

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('delivery')}
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.saveButtonHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }>
         <Container>
            <FlexContainer
               gap="50px"
               direction="column"
               className="deliveries_form_container">
               {form.map((delivery, index) => {
                  return (
                     <FlexContainer
                        gap="30px"
                        key={index}
                        className="delivery_form_container">
                        <Input
                           name="name"
                           label={t('label')}
                           placeholder={t('enter.label')}
                           value={delivery.name}
                           onChange={Events.inputHanler}
                        />
                        <Input
                           name="cost"
                           type="percentage"
                           label={t('discount')}
                           placeholder={t('enter.discount')}
                           value={delivery.cost}
                           onChange={Events.inputHanler}
                        />
                        <AlignContainer justify="flex-end" align="flex-end">
                           <RemoveButton
                              width={45}
                              onClick={Events.removeButtonClickHandler}
                           />
                        </AlignContainer>
                     </FlexContainer>
                  )
               })}
            </FlexContainer>
         </Container>

         <AddButtonContainer>
            <Button height={40} width={139} onClick={Events.onAddClickHandler}>
               {
                  <>
                     <PlusIcon />
                     {t('add')}
                  </>
               }
            </Button>
         </AddButtonContainer>
      </AdminPanelContainer>
   )
}

export default IndexPage
