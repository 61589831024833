import { colors } from 'enums'
import styled from 'styled-components'

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 30px;
   padding: 24px 30px;
   border-right: 1px solid ${colors.table_grey};
`
