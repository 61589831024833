export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'
export const SET_CATEGORY = 'SET_CATEGORY'
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORY_STATE = 'SET_CATEGORY_STATE'
export const SET_SECTIONS = 'SET_SECTIONS'
export const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES'
export const REMOVE_CATEGORIES = 'REMOVE_CATEGORIES'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const GET_CATEGORY_PARENT = 'GET_CATEGORY_PARENT'
export const GET_CATEGORIES_BY_PARENT = 'GET_CATEGORIES_BY_PARENT'
export const SET_EDIT_BACKID = 'SET_EDIT_BACKID'
export const SET_SECONDLEVEL_EDIT_BACKID = 'SET_SECONDLEVEL_EDIT_BACKID'
export const GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY'
export const SET_PRODUCTS_BY_CATEGORY = 'SET_PRODUCTS_BY_CATEGORY'
export const SET_IDS = 'SET_IDS'
export const EDIT_CATEGORY_ORDER = 'EDIT_CATEGORY_ORDER'

export const CHANGE_PRODUCT_ORDER = 'CHANGE_PRODUCT_ORDER'

export enum CATEGORY_RESPONSE {
   CREATED = 'CREATED',
   EDITED = 'EDITED',
   REMOVED = 'REMOVED',
   DONE = 'DONE',
   GET_SECTIONS = 'GET_SECTIONS'
}
