import { api } from 'config'

export const ORDER_URL = {
   getOrders: `${api.public}v1/order`,
   getOrder: `${api.public}v1/order`,
   editOrder: `${api.public}v1/order`,
   getArchive: `${api.public}v1/order/archive`,
   getAllOrdersByCustomer: `${api.public}v1/order/getAllOrders`,
   refundMoney: (id: string) => `${api.public}v1/payment/${id}/refund`
}
