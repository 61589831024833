import { FC, SyntheticEvent, useRef } from 'react'
import { FlexContainer } from '../Styled'
import { BackgroundContainer, PopupContainer } from './styled'
import { TPopup } from './types'

const Popup: FC<TPopup> = ({
   backgroundClickListener,
   children,
   height = '818px',
   width = '627px',
   backgroundColor = 'rgba(99, 123, 150, 0.4)'
}) => {
   const backgroundRef = useRef(null)

   const Events = {
      backgroundClickHandler: (e: SyntheticEvent) => {
         e.target == backgroundRef.current &&
            backgroundClickListener &&
            backgroundClickListener(e)
      }
   }

   return (
      <BackgroundContainer
         ref={backgroundRef}
         onClick={Events.backgroundClickHandler}
         style={{ backgroundColor }}>
         <PopupContainer width={width} height={height}>
            <FlexContainer style={{ height: '100%', overflow: 'auto' }}>
               {children}
            </FlexContainer>
         </PopupContainer>
      </BackgroundContainer>
   )
}

export default Popup
