import { useTranslation } from 'react-i18next'
import { SwitcherWrap } from './styled'

export const Switcher = ({
   value,
   onChange
}: {
   value: boolean
   onChange: (value: boolean) => void
}) => {
   const { t } = useTranslation()

   return (
      <SwitcherWrap>
         <div className={value ? 'active' : ''} onClick={() => onChange(true)}>
            {t('closedQuestion')}
         </div>
         <div className={!value ? 'active' : ''} onClick={() => onChange(false)}>
            {t('openQuestion')}
         </div>
      </SwitcherWrap>
   )
}
