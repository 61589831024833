import styled from 'styled-components'
import { colors } from '../../enums'
import { FONT } from '../../utils'

export const Container = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;

   width: 100%;
   height: 100%;
   row-gap: 5px;

   @media (max-width: 1100px) {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
   }
`

export const TableContainer = styled.div`
   overflow-y: auto;
   flex: 1;
   border-right: 1px solid ${colors.light_grey};
`

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 30px;
   flex: 1;
`

export const DateField = styled.span`
   ${FONT({ size: '14px' })}
`

export const Message = styled.div`
   text-overflow: ellipsis;
   overflow: hidden;
   height: 1.2em;
   white-space: nowrap;

   width: 100%;
   max-width: 356px;
   margin: 0 auto;
`

export const PreloaderContainer = styled.div`
   width: 100%;
   height: 200px;
`
