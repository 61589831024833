import { FC } from 'react'
import { Button, buttonTheme } from '../Button'
import { FlexContainer } from '../Styled'
import {
   ActionButtonContainer,
   BackButton,
   BackButtonIcon,
   ButtonContainer,
   Container,
   DateContainer,
   DateField,
   Title
} from './styled'
import { THeader } from './types'

const Header: FC<THeader> = ({
   title,
   backButtonClickHandler,
   buttonsList,
   dates,
   clickbleText
}) => {
   return (
      <Container>
         <ActionButtonContainer>
            {backButtonClickHandler ? (
               <BackButton
                  onClick={() => backButtonClickHandler && backButtonClickHandler()}>
                  <BackButtonIcon />
                  <Title>{title}</Title>
               </BackButton>
            ) : (
               <Title>{title}</Title>
            )}

            <DateContainer>
               {dates?.map((date, index) => (
                  <DateField key={index}>
                     <span>
                        {`${date.info}`} {date.date}
                     </span>
                  </DateField>
               ))}
            </DateContainer>
         </ActionButtonContainer>

         <ButtonContainer style={{ width: clickbleText ? '100%' : '' }}>
            {buttonsList}
         </ButtonContainer>
      </Container>
   )
}

export default Header
