import { TSection } from 'components'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigationHook = () => {
   const navigate = useNavigate()

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('current'),
            onClickHandler: () => {
               navigate('/products')
            }
         },
         {
            title: t('new.products'),
            onClickHandler: () => {
               navigate('/products/newProducts')
            }
         },
         {
            title: t('promotion.products'),
            onClickHandler: () => {
               navigate('/products/promotionProducts')
            }
         },
         {
            title: t('statistic'),
            onClickHandler: () => {
               navigate('/statistic')
            }
         },
         {
            title: t('changelog'),
            active: true
         }
      ],
      []
   )

   return { sections }
}
