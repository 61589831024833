import { Alert, Snackbar } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Moment from 'react-moment'

import { useDispatch } from 'react-redux'

import { useNavigate, useParams } from 'react-router-dom'

import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   ItemsPerRowGridContainer,
   PhotoPicker
} from '../../../../components'

import { api } from '../../../../config'

import { useTypedSelector } from '../../../../hooks'

import {
   adminActions,
   ADMIN_RESPONSE,
   getMainAdminSelector,
   getMainRoleSelector,
   roleActions
} from '../../../../store'

import { TRole } from '../../../../store/role/types'

import { TForm } from './types'

const EditPage: FC = () => {
   const { t } = useTranslation()
   const navigate = useNavigate()

   const dispatch = useDispatch()
   const { roles, loading: roleLoading } = useTypedSelector(getMainRoleSelector)

   const {
      loading: adminLoading,
      admin,
      response
   } = useTypedSelector(getMainAdminSelector)
   const { id } = useParams()

   const rolesOptions = useMemo(
      () => roles.data.map((role) => ({ name: role.title, value: role._id })),
      [roles]
   )
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const [form, setForm] = useState<TForm>({
      name: admin?.name || '',
      surname: admin?.secondName || '',
      login: admin?.login || '',
      role: (admin?.role as TRole)?._id || '',
      profile_photo: null
   })

   const Schema = useMemo(
      () => ({
         login: {
            condition: form.login?.length >= 4
         },

         name: {
            condition: form.name.length >= 2
         },
         surname: {
            condition: form.surname.length >= 2
         }
      }),
      [form]
   )

   const Events = {
      backButtonClickHandler: () => {
         navigate('../roles')
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      onProfilePhotoChange: (file: File | null) => {
         setForm({ ...form, profile_photo: file })
      },
      removeButtonClickHandler: () => {
         setAlertText('role.successfuly.removed')
         Requests.removeAdmin()
         toogleIsAlertOpen(true)
         // navigate('../roles')
      },
      saveClickHandler: () => {
         setAlertText('role.successfuly.edited')
         Requests.editAdmin()
         toogleIsAlertOpen(true)
      }
   }

   const Requests = {
      getAdmim: () => {
         dispatch(adminActions.getAdmin({ _id: id as string }))
      },
      getRoles: () => {
         dispatch(roleActions.getRoles())
      },
      editAdmin: () => {
         const data = new FormData()
         data.append('name', form.name)
         data.append('secondName', form.surname)
         data.append('login', form.login)
         form.role && data.append('role', form.role)
         form.profile_photo && data.append('img', form.profile_photo as File)

         dispatch(adminActions.editAdmin({ data, _id: id as string }))
      },
      removeAdmin: () => {
         dispatch(adminActions.removeAdmin({ _id: id as string }))
      }
   }

   useEffect(() => {
      admin &&
         setForm({
            ...form,
            name: admin?.name || '',
            surname: admin?.secondName || '',
            login: admin?.login || '',
            role: (admin?.role as TRole)?._id || ''
         })
   }, [admin])

   useEffect(() => {
      Requests.getAdmim()
      Requests.getRoles()
   }, [])

   // Redirect if item doesnt exist
   useEffect(() => {
      !adminLoading && response == ADMIN_RESPONSE.NOT_FOUNDED && navigate('../roles')
   }, [adminLoading])

   useEffect(() => {
      if (admin) {
         response != ADMIN_RESPONSE.EDITED &&
            setForm({
               ...form,
               name: admin.name,
               surname: admin.secondName,
               login: admin.login,
               role: (admin.role as TRole)._id
            })

         if (response == ADMIN_RESPONSE.EDITED) {
            Requests.getAdmim()
            Requests.getRoles()
         }
      }
   }, [response])

   return (
      <AdminPanelContainer
         loading={adminLoading || roleLoading}
         Header={
            <Header
               title={t('edit')}
               backButtonClickHandler={Events.backButtonClickHandler}
               buttonsList={
                  <>
                     <Button theme="red" onClick={Events.removeButtonClickHandler}>
                        {t('delete')}
                     </Button>
                     <Button theme="green" onClick={Events.saveClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(admin?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(admin?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }>
         <FlexContainer
            width="100%"
            columnGap="62px"
            rowGap="30px"
            style={{ padding: '30px' }}>
            <Input
               name="name"
               label={t('name')}
               placeholder={t('enter.name')}
               onChange={Events.inputChangeHandler}
               value={form.name}
               isValid={Schema.name.condition}
            />
            <Input
               name="surname"
               label={t('surname')}
               placeholder={t('enter.surname')}
               onChange={Events.inputChangeHandler}
               value={form.surname}
               isValid={Schema.surname.condition}
            />
            <Input
               name="login"
               label={t('login')}
               placeholder={t('enter.login.limit')}
               onChange={Events.inputChangeHandler}
               value={form.login}
               isValid={Schema.login.condition}
            />
            <DropDownList
               name="role"
               label={t('position')}
               options={rolesOptions}
               onChange={Events.inputChangeHandler}
               value={form.role}
            />
            <PhotoPicker
               label={t('profile.photo')}
               onChange={Events.onProfilePhotoChange}
               value={`${api.img}admin/${admin?.image}`}
            />
         </FlexContainer>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}

export default EditPage
