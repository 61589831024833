import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { i18n } from '../../config'
import { ELocales } from '../../enums'
import { TResponse } from '../types'
import { getAccessToken } from '../user'
import { specialistActions } from './action'
import { ApiSpecialistService } from './api.service'
import { SPECIALIST_RESPONSE } from './consts'
import {
   TCreateSpecialistPayload,
   TDeleteSpecialistPayload,
   TEditSpecialistPayload,
   TEditSpecialistReviewPayload,
   TGetReviewBySpecialistPayload,
   TGetSpecialistPayload,
   TGetSpecialistsPayload
} from './types'

function* getSpecialistsWorker({ payload }: PayloadAction<TGetSpecialistsPayload>) {
   yield put(specialistActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiSpecialistService, ApiSpecialistService.getSpecialists],
         {
            ...payload,
            token,
            lang: i18n.language as ELocales
         }
      )

      if (response.data) yield put(specialistActions.SET_SPECIALISTS(response.data))
   } catch (e) {}

   yield put(specialistActions.SET_STATE({ loading: false, response: 'DONE' }))
}

function* getSpecialistWorker({ payload }: PayloadAction<TGetSpecialistPayload>) {
   yield put(specialistActions.SET_STATE({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiSpecialistService, ApiSpecialistService.getSpecialist],
         {
            ...payload,
            token
         }
      )

      if (response.data) yield put(specialistActions.SET_SPECIALIST(response.data.data))
   } catch (e) {}

   yield put(specialistActions.SET_STATE({ loading: false, response: 'DONE' }))
}

function* createSpecialistWorker({ payload }: PayloadAction<TCreateSpecialistPayload>) {
   yield put(specialistActions.SET_STATE({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiSpecialistService, ApiSpecialistService.createSpecialist], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(specialistActions.SET_STATE({ loading: false, response: 'CREATED' }))
}

function* editSpecialistWorker({ payload }: PayloadAction<TEditSpecialistPayload>) {
   yield put(specialistActions.SET_STATE({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiSpecialistService, ApiSpecialistService.editSpecialist], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(
      specialistActions.SET_STATE({
         loading: false,
         response: SPECIALIST_RESPONSE.EDITED
      })
   )
}

function* deleteSpecialistWorker({ payload }: PayloadAction<TDeleteSpecialistPayload>) {
   yield put(specialistActions.SET_STATE({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiSpecialistService, ApiSpecialistService.deleteSpecialist], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(
      specialistActions.SET_STATE({
         loading: false,
         response: SPECIALIST_RESPONSE.REMOVED
      })
   )
}

function* getSpecialistsReviewsWorker() {
   yield put(specialistActions.SET_STATE({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiSpecialistService, ApiSpecialistService.getSpecialistsReviews],
         {
            token
         }
      )

      if (response.data)
         yield put(specialistActions.SET_SPECIALISTS_REVIEWS(response.data))
   } catch (e) {}

   yield put(specialistActions.SET_STATE({ loading: false }))
}

function* getReviewBySpecialistWorker({
   payload
}: PayloadAction<TGetReviewBySpecialistPayload>) {
   yield put(specialistActions.SET_STATE({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiSpecialistService, ApiSpecialistService.getReviewBySpecialist],
         {
            ...payload,
            token
         }
      )

      if (response.data) yield put(specialistActions.SET_SPECIALIST_REVIEW(response.data))
      if (response.data)
         yield put(specialistActions.SET_STATE({ meta: response.data.meta }))
   } catch (e) {}

   yield put(specialistActions.SET_STATE({ loading: false }))
}

function* editSpecialistReviewWorker({
   payload
}: PayloadAction<TEditSpecialistReviewPayload>) {
   yield put(specialistActions.SET_STATE({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiSpecialistService, ApiSpecialistService.editSpecialistReview], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(
      specialistActions.SET_STATE({
         loading: false,
         response: SPECIALIST_RESPONSE.EDITED
      })
   )
}

export function* specialistWatcher() {
   yield takeLatest(specialistActions.GET_SPECIALISTS, getSpecialistsWorker)
   yield takeLatest(specialistActions.CREATE_SPECIALIST, createSpecialistWorker)
   yield takeLatest(specialistActions.GET_SPECIALIST, getSpecialistWorker)
   yield takeLatest(specialistActions.EDIT_SPECIALIST, editSpecialistWorker)
   yield takeLatest(specialistActions.DELETE_SPECIALIST, deleteSpecialistWorker)

   yield takeLatest(
      specialistActions.GET_REVIEW_BY_SPECIALIST,
      getReviewBySpecialistWorker
   )
   yield takeLatest(
      specialistActions.GET_SPECIALISTS_REVIEWS,
      getSpecialistsReviewsWorker
   )
   yield takeLatest(specialistActions.EDIT_SPECIALIST_REVIEW, editSpecialistReviewWorker)
}
