import { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { ORDER_URL } from './config'
import {
   TEditOrderRequestPayload,
   TGetAllOrdersByCustomerRequestPayload,
   TGetArchivePayload,
   TGetArchiveRequestPayload,
   TGetOrderRequestPayload,
   TGetOrdersRequestPayload,
   TRefundMoneyRequestPayload
} from './types'

export class ApiOrderService extends HttpService {
   static getOrders({
      token,
      limit = 10,
      page = 0,
      lang = ELocales.ru,
      value = '',
      field = '',
      start = '',
      end = '',
      regex = '',
      sortBy = '',
      order = '',
      paymentStatus
   }: TGetOrdersRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ORDER_URL.getOrders}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            lang,
            regex,
            value,
            field,
            start,
            end,
            sortBy,
            order,
            paymentStatus
         }
      })

      return response
   }

   static getArchive({
      token,
      limit = 10,
      page = 0,
      lang = ELocales.ru,
      value = '',
      field = '',
      start = '',
      end = '',
      regex = '',
      sortBy = '',
      order = ''
   }: TGetArchiveRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ORDER_URL.getArchive}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: limit * page,
            lang,
            regex,
            value,
            field,
            start,
            end,
            sortBy,
            order
         }
      })

      return response
   }

   static getOrder({
      token,
      _id,
      lang
   }: TGetOrderRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ORDER_URL.getOrder}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang
         }
      })

      return response
   }

   static editOrder({
      token,
      _id,
      data
   }: TEditOrderRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ORDER_URL.editOrder}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getAllOrdersByCustomer({
      token,
      _id,
      page = 0,
      limit = 0,
      ...params
   }: TGetAllOrdersByCustomerRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ORDER_URL.getAllOrdersByCustomer}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            customer: _id,
            skip: limit * page,
            ...params
         }
      })

      return response
   }

   static refundMoney({
      id,
      token,
      amount
   }: TRefundMoneyRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ORDER_URL.refundMoney(id)}`,
         method: 'POST',
         data: { amount },
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
