import { assets } from '../../assets'
import { TMenuItems } from './types'

export const menuItemsSchema: TMenuItems = {
   orders: {
      name: 'orders',
      link: '#/orders',
      img: assets.ORDERS,
      access: 'Order'
   },
   products: {
      name: 'manage.products',
      link: '#/products',
      img: assets.MANAGE_PRODUCT,
      access: 'Product'
   },
   clients: {
      name: 'manage.clients',
      link: '#/clients',
      img: assets.MANAGE_CLIENTS,
      access: 'Customer'
   },
   catalog: {
      name: 'categories.and.subcategories',
      link: '#/catalog',
      img: assets.CATEGORIES,
      access: 'Category'
   },
   support: {
      name: 'support',
      link: '#/support',
      img: assets.SUPPORT,
      access: 'Support'
   },
   // bonuses: {
   //    name: 'bonus.system',
   //    link: '#/bonuses',
   //    img: assets.BONUS_SYSTEM,
   //    access: 'BonusSystem'
   // },
   banners: {
      name: 'banners',
      link: '#/banners',
      img: assets.BANNERS,
      access: 'Banner'
   },
   // delivery: {
   //    name: 'delivery',
   //    link: '#/delivery',
   //    img: assets.DELIVERY
   // },
   roles: {
      name: 'roles',
      link: '#/roles',
      img: assets.ROLES,
      access: 'Role'
   },
   settings: {
      name: 'settings',
      link: '#/settings',
      img: assets.SETTINGS,
      access: ''
   },
   seo: {
      name: 'seo',
      link: '#/seo',
      img: assets.SEO_ICON,
      access: 'SEO'
   },
   workers: {
      name: 'manage.workers',
      link: '#/workers',
      img: assets.WORKER_ICON,
      access: 'Specialist'
   },
   brands: {
      name: 'brands',
      link: '#/brands',
      img: assets.BRANDS,
      access: 'Specialist'
   },
   calculator: {
      name: 'calculator',
      link: '#/calculator',
      img: assets.Calculator,
      access: 'Specialist'
   }

   // categoriesForTheCalculator: {
   //    name: 'categoriesForTheCalculator',
   //    link: '#/categoriesForTheCalculator',
   //    img: assets.CATEGORIES,
   //    access: 'Specialist'
   // }
   // subscribers: {
   //    name: 'subscribers',
   //    link: '#/subscribers',
   //    img: assets.SUBSCRIBERS_ICON,
   //    access: 'Mail'
   // }
}
