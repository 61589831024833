import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   HoverList,
   Pagination,
   SearchBar,
   SubPagesBar,
   Table,
   TColumnTable,
   TDataTable,
   TSection,
   TSort
} from '../../../../components'
import { useLocalization, useTypedSelector } from '../../../../hooks'
import { getMainOrderSelector, orderActions } from '../../../../store'
import { PAGE_LIMIT } from '../../consts'
import { useNavigate } from 'react-router-dom'
import { ELocales } from '../../../../enums'
import { TDescription } from 'store/product/types'
import { ApiOrderService } from 'store/order/api.service'

const IndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const { orders, loading, filters } = useTypedSelector(getMainOrderSelector)
   const { accessToken } = useTypedSelector((state) => state.user)
   const navigate = useNavigate()

   const [page, setPage] = useState<number>(0)
   const [locale] = useLocalization()

   const [searchForm, setSearchForm] = useState({
      searchValue: filters.searchValue ? filters.searchValue : '',
      startDateValue: filters.startDateValue ? filters.startDateValue : '',
      endDateValue: filters.endDateValue ? filters.endDateValue : ''
   })

   const [searchParams, setSearchParams] = useSearchParams({})

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })
   // update count order's in sidebar
   const getNewOrders = async () => {
      const newOrders =
         accessToken &&
         (await ApiOrderService.getOrders({
            token: `Bearer ${accessToken}`,
            limit: 1,
            sortBy: 'createdAt',
            order: 1
         }).then((res) => res?.data?.meta?.new))

      dispatch(orderActions.setNewOrder(newOrders))
   }

   const paginationSections: TSection[] = useMemo(
      () => [
         {
            title: t('orderUnpaid'),
            active: true
         },
         {
            title: t('orderPaid'),
            active: false,
            onClickHandler: async () => {
               await getNewOrders()
               navigate('../orders/current')
            }
         },

         {
            title: t('archive'),
            onClickHandler: async () => {
               await getNewOrders()
               navigate('../orders/archive')
            }
         }
      ],
      []
   )

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const Requests = {
      getOrders: () => {
         dispatch(
            orderActions.getOrders({
               limit: PAGE_LIMIT,
               page,
               regex: searchForm.searchValue,
               start: searchForm.startDateValue,
               end: searchForm.endDateValue,
               ...sortParamsForm,
               lang: locale as ELocales,
               paymentStatus: 'unpaid'
            })
         )
      }
   }

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         Requests.getOrders()
      },
      editClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(orderActions.setFilters({ ...searchForm }))
         navigate(`../orders/${orders.data[index]._id}`)
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      resetFilters: () => {
         dispatch(
            orderActions.setFilters({
               searchValue: '',
               startDateValue: '',
               endDateValue: ''
            })
         )
         window.location.reload()
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('order.code'),
            accessor: 'id',
            width: 100,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('date'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('order'),
            accessor: 'order',
            width: 250
         },
         {
            Header: t('client'),
            accessor: 'customer',
            width: 300,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('receiver'),
            accessor: 'receiver',
            width: 300,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('payment'),
            accessor: 'paymentType',
            width: 300,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('payment.status'),
            accessor: 'paymentStatus',
            width: 300,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('order.status'),
            accessor: 'orderStatus',
            width: 300,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const data: TDataTable[] = useMemo(
      () =>
         orders?.data?.map((order) => {
            const created_at = new Date(order.createdAt)
            return {
               id: <>{order.id}</>,
               createdAt: <Moment format="DD.MM.YYYY HH:mm">{created_at}</Moment>,
               order: (
                  <FlexContainer align="center" justify="center">
                     <FlexContainer
                        direction="column"
                        align="flex-start"
                        justify="flex-start"
                        gap="4px"
                        width="170px"
                        style={{ padding: 20 }}>
                        <p style={{ textAlign: 'start' }}>
                           {(order.items[0].product?.description as TDescription)?.title}
                        </p>
                        {!!order.items.slice(1).length && (
                           <HoverList
                              items={order.items
                                 .slice(1)
                                 .map(
                                    (item) =>
                                       (item.product?.description as TDescription)?.title
                                 )}
                           />
                        )}
                     </FlexContainer>
                  </FlexContainer>
               ),
               customer: (
                  <>
                     <p>
                        {order.customer?.name} {order.customer?.secondName}
                     </p>
                     <p>{order.customer?.email}</p>
                     <p>{order.customer?.phone}</p>
                  </>
               ),
               receiver: (
                  <>
                     {order?.receiver?.anotherReceiver ? (
                        <>
                           <p>{order.receiver?.name}</p>
                           <p>{order.receiver?.phone}</p>
                        </>
                     ) : (
                        <>
                           <p>
                              {order.customer?.name} {order.customer?.secondName}
                           </p>
                           <p>{order.customer?.email}</p>
                           <p>{order.customer?.phone}</p>
                        </>
                     )}
                  </>
               ),
               paymentType: <>{t(order.paymentType.split(' ').join('.'))}</>,
               paymentStatus: <>{t(order.paymentStatus.split(' ').join('.'))}</>,
               orderStatus: <>{t(order.orderStatus.split(' ').join('.'))}</>
            }
         }),
      [orders]
   )

   useEffect(() => {
      Requests.getOrders()
      setSearchParams({ ...searchParams, page: '' + (page + 1) })
   }, [page, sortParamsForm, searchForm])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string) - 1
            : 0
      )

      dispatch(
         orderActions.setFilters({
            searchValue: '',
            startDateValue: '',
            endDateValue: ''
         })
      )
   }, [])

   return (
      <AdminPanelContainer loading={loading} Header={<Header title={t('orders')} />}>
         <SubPagesBar
            newCountPaid={orders.meta?.newPaid}
            newCountUnpaid={orders.meta?.newUnpaid}
            sections={paginationSections}
         />
         <FlexContainer direction="column" gap="30px">
            <HeaderWrapper>
               <FiltersWrapper>
                  <SearchBar
                     name="searchValue"
                     placeholder={t('search')}
                     value={searchForm.searchValue}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
                  <DateInput
                     name="startDateValue"
                     value={searchForm.startDateValue}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="endDateValue"
                     value={searchForm.endDateValue}
                     onChange={Events.onChangeHandler}
                  />
                  <div style={{ paddingRight: '180px' }} onClick={Events.resetFilters}>
                     <Button style="transparant">{t('reset.filters')}</Button>
                  </div>
               </FiltersWrapper>

               <DataTotal>
                  {t('total.orders')}: {orders.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               columns={columns}
               data={data}
               editClickHandler={Events.editClickHandler}
               sortBy={tableSortBy}
               editable
            />
         </FlexContainer>

         <Pagination
            page={page}
            pageCount={orders.meta ? Math.ceil(orders.meta.totalCount / PAGE_LIMIT) : 1}
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}

export default IndexPage
