import { colors } from 'enums'
import styled from 'styled-components'
import { FONT } from 'utils'

export const Container = styled.div`
   display: grid;
   width: 900px;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   grid-column-gap: 10px;
   grid-row-gap: 10px;
`

export const HeaderText = styled.p`
   ${FONT({ weight: '500', size: '16px' })}
   padding-bottom: 22.5px;
`

export const AddText = styled.p`
   ${FONT({ weight: '600', size: '16px', color: '#637B96' })}
   cursor: pointer;
   border-bottom: 2px solid #fff;
   &:hover {
      transition: 0.3s;
      border-bottom: 2px solid #637b96;
   }
`

export const DeleteText = styled.p`
   ${FONT({ weight: '600', size: '16px', color: '#BF5E5E' })}
   cursor: pointer;
   border-bottom: 2px solid #fff;
   &:hover {
      transition: 0.3s;
      border-bottom: 2px solid #bf5e5e;
   }
`

export const CheckboxText = styled.p`
   ${FONT({ weight: '400', size: '14px' })}
`

export const Line = styled.div`
   border-bottom: 1px solid #f4f4f4;
   margin: 32px 0;
`

export const ClickbleText = styled.p`
   border-bottom: 2px solid ${colors.solid_white};

   margin-top: 12px;

   ${FONT({ weight: '600', size: '16px', color: '#637B96' })}

   cursor: pointer;

   &:hover {
      transition: 0.3s;
      border-bottom: 2px solid #637b96;
   }
`
