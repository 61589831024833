import { colors } from 'enums'
import styled from 'styled-components'

export const CreateButton = styled.button`
   width: 195px;
   height: 46px;
   display: flex;
   background-color: ${colors.green};
   outline: none;
   border: none;
   border-radius: 8px;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   font-weight: 500;
   color: ${colors.solid_white};
   cursor: pointer;

   transition: background-color 0.3s;
   svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
   }

   &:hover {
      background-color: #4caa55;
   }
`
export const QuestionsList = styled.ul`
   list-style: none;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   li {
      padding: 0 20px;
      height: 60px;
      width: 100%;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
         border-bottom: 1px solid ${colors.border_grey_color};
      }
   }
`
export const DeleteBtn = styled.button`
   background-color: ${colors.red};
   width: 36px;
   height: 36px;
   color: #ffffff;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 8px;
   transition: background-color 0.3s;
   cursor: pointer;
   border: none;

   &:hover {
      background-color: #d67878;
   }
   svg {
      width: 24px;
      height: 24px;
   }
`
