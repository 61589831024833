import { useTranslation } from 'react-i18next'
import { Button, HedingWrap, ModalWrap } from './styled'
import { MdOutlineClose } from 'react-icons/md'
import { CustomInput } from 'pages/Calculator/components'
import { useState } from 'react'
import { QuestionCategoriesService } from 'store'
import { i18n } from 'config'
import { useTypedSelector } from 'hooks'
import { toast } from 'react-toastify'
import { KeyedMutator, mutate } from 'swr'
import { AxiosResponse } from 'axios'
import { TQuestionsCatogoriesResponse } from 'store/questionCategory/types'

export const ModalWindow = ({
   close,
   mutate,
   id
}: {
   close: () => void
   mutate: KeyedMutator<AxiosResponse<TQuestionsCatogoriesResponse, any>>
   id?: string
}) => {
   const { t } = useTranslation()
   const { accessToken } = useTypedSelector((state) => state.user)
   const lang = i18n.language
   const [value, setValue] = useState('')
   const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
   }
   const headingText = id ? t('changeCategoryName') : t('createCategoryName')
   const bttnText = id ? t('edit') : t('create')

   const ation = async () => {
      try {
         await QuestionCategoriesService.createQuestionCategory(
            { title: [{ lang, value }] },
            lang,
            accessToken || ''
         )
         close()
         mutate()
         toast.success(t('categoryNameCreated'))
      } catch (error) {
         toast.error(t('categoryNameCreated'))
      }
   }

   return (
      <ModalWrap>
         <HedingWrap>
            <h2>{headingText}</h2>
            <MdOutlineClose onClick={close} />
         </HedingWrap>
         <CustomInput
            label={t('enter.label')}
            width="100%"
            value={value}
            onChange={onChange}
         />
         <Button onClick={ation} disabled={value.length < 3}>
            {bttnText}
         </Button>
      </ModalWrap>
   )
}
