import { assets } from 'assets'
import { Input } from 'components'
import { api } from 'config'
import { useLocalization, useTypedSelector } from 'hooks'
import { t } from 'i18next'
import { FC, SyntheticEvent, useState, Dispatch, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
   brandsActions,
   clientActions,
   getMainProductSelector,
   productActions
} from 'store'
import { TBrand } from 'store/brand/types'
import { TProduct } from 'store/product/types'
import { RelatedProductWrapper } from './styled'
import { TGetCustomerByIdResponse } from 'store/client/types'

type DragAndDropProps = {
   productItem?: TProduct
   brandItem?: TBrand
   path?: string
   discountValue?: string
   defaultDiscountValue?: string
   index?: number
   setProductState?: Dispatch<TProduct[]>
   selectedProducts?: (TProduct & { discount?: number })[]
   setSelectedProducts?: Dispatch<(TProduct & { discount?: number })[]>
   selectedBrands?: (TBrand & { discount?: number })[]
   setSelectedBrands?: Dispatch<(TBrand & { discount?: number })[]>
   customerDiscount: TGetCustomerByIdResponse
}

export const Card: FC<DragAndDropProps> = ({
   productItem,
   brandItem,
   discountValue,
   selectedBrands,
   setSelectedBrands,
   setSelectedProducts,
   selectedProducts,
   defaultDiscountValue,
   customerDiscount
}) => {
   const dispatch = useDispatch()

   const [form, setForm] = useState({
      discountPrice: defaultDiscountValue
   })

   const Events = {
      onDeleteProduct: () => {
         const deleteId = customerDiscount.data.find(
            (item) => item?.product?._id == productItem?._id
         )?._id

         dispatch(clientActions.deleteCustomerDiscountBulk({ _id: [deleteId] }))
      },
      onDeleteBrand: () => {
         const deleteId = customerDiscount.data.find(
            (item) => item?.brand?._id == brandItem?._id
         )?._id

         dispatch(clientActions.deleteCustomerDiscountBulk({ _id: [deleteId] }))
      },
      onClickHandler: () => {},
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement

         if ((+value < 0.1 || +value > 99.9) && value != '') {
            return
         }

         if (productItem) {
            setSelectedProducts &&
               selectedProducts &&
               setSelectedProducts(
                  selectedProducts?.map((item) => {
                     if (item._id == productItem?._id) {
                        return {
                           ...item,
                           discount: discountValue ? +discountValue : +value
                        }
                     }
                     return item
                  })
               )
         }
         if (brandItem) {
            setSelectedBrands &&
               selectedBrands &&
               setSelectedBrands(
                  selectedBrands?.map((item) => {
                     if (item._id == brandItem?._id) {
                        return {
                           ...item,
                           discount: discountValue ? +discountValue : +value
                        }
                     }
                     return item
                  })
               )
         }

         setForm((props) => ({ ...props, [name]: value }))
      }
   }

   return (
      <>
         {productItem ? (
            <RelatedProductWrapper>
               <>
                  <img
                     src={assets.CROSS}
                     alt="delete"
                     className="product-delete"
                     onClick={Events.onDeleteProduct}
                  />
                  <img
                     src={`${api.preview}${productItem.preview}`}
                     alt="product"
                     className="product-img"
                     style={{ width: '120px' }}
                  />

                  <div>
                     <div
                        className="product-barcode"
                        onClick={Events.onClickHandler}
                        style={{
                           paddingTop: '5px'
                        }}>
                        {productItem?.barcode}
                     </div>
                     <div
                        className="product-name"
                        onClick={Events.onClickHandler}
                        style={{
                           paddingTop: '6px'
                        }}>
                        {productItem?.description.title}
                     </div>
                  </div>

                  <div className="product-price">
                     {productItem?.discountPrice && (
                        <div className="product-price__old">
                           {`${productItem?.price} ₼/шт`}
                        </div>
                     )}
                     <div className="product-price__new">
                        {productItem?.discountPrice
                           ? productItem?.discountPrice
                           : productItem?.price}
                        ₼/шт
                     </div>
                  </div>
                  <Input
                     name="discountPrice"
                     placeholder={t('enter.discount.in.percent.or.num')}
                     value={discountValue ? discountValue : form.discountPrice}
                     disabled={discountValue ? true : false}
                     onChange={Events.inputChangeHandler}
                  />
               </>
            </RelatedProductWrapper>
         ) : (
            <RelatedProductWrapper brandHeight="233px" brandWidth="210px">
               <>
                  <img
                     src={assets.CROSS}
                     alt="delete"
                     className="product-delete"
                     onClick={Events.onDeleteBrand}
                  />
                  <img
                     src={`${api.preview}${brandItem?.image}`}
                     alt="product"
                     className="product-img"
                     style={{ width: '120px', height: '120px' }}
                  />
                  <div className="brand-name" onClick={Events.onClickHandler}>
                     {brandItem?.title}
                  </div>
                  <Input
                     name="discountPrice"
                     placeholder={t('enter.discount.in.percent.or.num')}
                     value={discountValue ? discountValue : form.discountPrice}
                     disabled={discountValue ? true : false}
                     onChange={Events.inputChangeHandler}
                  />
               </>
            </RelatedProductWrapper>
         )}
      </>
   )
}
