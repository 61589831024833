import { Button, Header } from 'components'
import { t } from 'i18next'
import { FC } from 'react'
import Moment from 'react-moment'
import { TProduct } from 'store/product/types'

type ProductHeaderProps = {
   backButtonClickHandler: () => void
   deleteButtonClickHandler: () => void
   saveButtonClickHandler: () => void
   product: TProduct
}

export const ProductHeader: FC<ProductHeaderProps> = ({
   backButtonClickHandler,
   saveButtonClickHandler,
   deleteButtonClickHandler,
   product
}) => {
   return (
      <Header
         backButtonClickHandler={backButtonClickHandler}
         title={t('edit')}
         buttonsList={
            <>
               <Button theme="red" onClick={deleteButtonClickHandler}>
                  {t('delete')}
               </Button>
               <Button theme="green" onClick={saveButtonClickHandler}>
                  {t('save')}
               </Button>
            </>
         }
         dates={[
            {
               info: t('createdAt.date'),
               date: (
                  <Moment format="DD.MM.YYYY HH:mm">
                     {new Date(product?.createdAt as string)}
                  </Moment>
               )
            },
            {
               info: t('updatedAt.date'),
               date: (
                  <Moment format="DD.MM.YYYY HH:mm">
                     {new Date(product?.updatedAt as string)}
                  </Moment>
               )
            }
         ]}
      />
   )
}
