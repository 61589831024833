import {
   FieldArrayWithId,
   UseFieldArrayAppend,
   UseFormSetValue,
   UseFormWatch
} from 'react-hook-form'

import { TCalculatorAction } from 'store/calculator/types'
import {
   createId,
   setDefaultQuestion
} from 'pages/Calculator/Pages/CreateCalculator/helpers'
import { TEditCalculatorForm } from '../../types'

export const removeQuestionsAndSubquestions = (
   questionIdToRemove: string,
   watch: UseFormWatch<TEditCalculatorForm>,
   setValue: UseFormSetValue<TEditCalculatorForm>
) => {
   const questions = watch('questions')

   // Створюємо мапу для швидкого доступу
   const questionsMap = new Map(questions.map((q) => [q.id, q]))

   const collectIdsToRemove = (questionId: string, collectedIds = new Set<string>()) => {
      if (collectedIds.has(questionId)) return collectedIds // Уникнення циклічних залежностей

      collectedIds.add(questionId)
      const question = questionsMap.get(questionId)
      if (question) {
         question.answers.forEach((answer) => {
            if (answer.question && !collectedIds.has(answer.question)) {
               collectIdsToRemove(answer.question, collectedIds)
            }
         })
      }
      return collectedIds
   }

   const idsToRemove = collectIdsToRemove(questionIdToRemove)

   // Фільтруємо питання, виключаючи ті, що потрібно видалити
   const updatedQuestions = questions.filter((q) => !idsToRemove.has(q.id))

   // Оновлюємо стан форми один раз
   setValue('questions', updatedQuestions)
}

export const onSelectChange = (
   value: unknown,
   subIndex: number,
   nextQuestionId: string,
   watch: UseFormWatch<TEditCalculatorForm>,
   setValue: UseFormSetValue<TEditCalculatorForm>,
   index: number,
   append: UseFieldArrayAppend<TEditCalculatorForm, 'questions'>
) => {
   const newValue = value as { label: string; value: TCalculatorAction }
   const newId = createId()
   const actionValue = watch(`questions.${index}.answers.${subIndex}.action.value`)
   if (newValue.value === actionValue) {
      return
   }

   // if (newValue.value === 'products') {
   //    setValue(`questions.${index}.answers.${subIndex}.answer`, '')
   // }

   if (newValue.value === 'question') {
      setValue(`questions.${index}.answers.${subIndex}.question`, newId)
      const parrentData = {
         isParent: false,
         parentId: watch(`questions.${index}.id`)
      }
      setValue(`questions.${index}.answers.${subIndex}.answerProductsData`, undefined)
      append(setDefaultQuestion(parrentData, newId))
   } else if (newValue.value === 'question&products') {
      removeQuestionsAndSubquestions(nextQuestionId, watch, setValue)
      setValue(`questions.${index}.answers.${subIndex}.question`, newId)
      const parrentData = {
         isParent: false,
         parentId: watch(`questions.${index}.id`)
      }
      setValue(`questions.${index}.answers.${subIndex}.answerProductsData`, undefined)
      append(setDefaultQuestion(parrentData, newId))
   } else if (newValue.value === 'nothing') {
      setValue(`questions.${index}.answers.${subIndex}.question`, undefined)
      setValue(`questions.${index}.answers.${subIndex}.answerProductsData`, undefined)
      removeQuestionsAndSubquestions(nextQuestionId, watch, setValue)
   } else if (
      newValue.value === 'products'
      // || newValue.value === 'question&products'
   ) {
      removeQuestionsAndSubquestions(nextQuestionId, watch, setValue)
      setValue(`questions.${index}.answers.${subIndex}.answerProductsData`, undefined)
   }
}
