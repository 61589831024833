import { createAction } from '@reduxjs/toolkit'
import { ActionTypes } from './actionTypes'
import { sliceActions } from './reducer'
import {
   TApproveChangesBulkPayload,
   TModerationApprovePayload,
   TModerationDeclinePayload,
   TModerationGetChangedProductPayload,
   TModerationPatchChangesPayload,
   TModerationPayload
} from './types'

export const moderationActions = {
   ...sliceActions,
   getChangedProducts: createAction(
      ActionTypes.getChangedProducts,
      (payload: TModerationPayload) => ({ payload })
   ),
   approveChanges: createAction(
      ActionTypes.approveChanges,
      (payload: TModerationApprovePayload) => ({ payload })
   ),
   declineChanges: createAction(
      ActionTypes.declineChanges,
      (payload: TModerationDeclinePayload) => ({ payload })
   ),
   getChangedProduct: createAction(
      ActionTypes.getChangedProduct,
      (payload: TModerationGetChangedProductPayload) => ({ payload })
   ),
   patchChanges: createAction(
      ActionTypes.patchChanges,
      (payload: TModerationPatchChangesPayload) => ({ payload })
   ),
   approveChangesBulk: createAction(
      ActionTypes.approveChangesBulk,
      (payload: TApproveChangesBulkPayload) => ({ payload })
   )
}
