import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { orderActions } from './actions'
import { ApiOrderService } from './api.service'
import { ORDER_RESPONSE } from './consts'
import {
   TEditOrderPayload,
   TGetAllOrdersByCustomerPayload,
   TGetArchivePayload,
   TGetOrderPayload,
   TGetOrdersPayload,
   TRefundMoneyPayload
} from './types'

function* getOrdersWorker({ payload }: TDataWrapper<TGetOrdersPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiOrderService, ApiOrderService.getOrders],
         { ...payload, token }
      )
      if (response.data) yield put(orderActions.setOrders(response.data))
   } catch (e) {
      yield put(orderActions.setOrders({ data: [], meta: null }))
   }

   yield put(orderActions.setOrderState({ loading: false }))
}

export function* getOrderWorker({ payload }: TDataWrapper<TGetOrderPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)
   const { _id, lang } = payload
   try {
      const response: TResponse = yield call(
         [ApiOrderService, ApiOrderService.getOrder],
         { token, _id, lang }
      )
      if (response.data.data) yield put(orderActions.setOrder(response.data.data))
      yield put(
         orderActions.setOrderState({
            loading: false,
            response: ORDER_RESPONSE.GET_ORDER_SUCCESS
         })
      )
   } catch (e) {
      yield put(
         orderActions.setOrderState({
            loading: false,
            response: ORDER_RESPONSE.GET_ORDER_ERROR
         })
      )
   }
}

function* getArchiveWorker({ payload }: TDataWrapper<TGetArchivePayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)
   try {
      const response: TResponse = yield call(
         [ApiOrderService, ApiOrderService.getArchive],
         { ...payload, token }
      )
      if (response.data) yield put(orderActions.setArchive(response.data))
   } catch (e) {
      yield put(orderActions.setOrders({ data: [], meta: null }))
   }

   yield put(orderActions.setOrderState({ loading: false }))
}

function* editOrderWorker({ payload }: TDataWrapper<TEditOrderPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)
   const { data, _id } = payload
   try {
      yield call([ApiOrderService, ApiOrderService.editOrder], { token, _id, data })
      yield put(
         orderActions.setOrderState({
            loading: false,
            response: ORDER_RESPONSE.EDIT_SUCCESS
         })
      )
   } catch (e) {
      yield put(
         orderActions.setOrderState({
            loading: false,
            response: ORDER_RESPONSE.EDIT_ERROR
         })
      )
   }
}

function* getAllOrdersByCustomerWorker({
   payload
}: PayloadAction<TGetAllOrdersByCustomerPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiOrderService, ApiOrderService.getAllOrdersByCustomer],
         { token, ...payload }
      )

      if (response.data) yield put(orderActions.setOrders(response.data))
   } catch (e) {}

   yield put(orderActions.setOrderState({ loading: false }))
}

function* refundMoneyWorker({ payload }: PayloadAction<TRefundMoneyPayload>) {
   yield put(orderActions.setOrderState({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiOrderService, ApiOrderService.refundMoney],
         { token, ...payload }
      )
   } catch (e) {}

   yield put(orderActions.setOrderState({ loading: false }))
}

export function* orderWatcher() {
   yield takeLatest(orderActions.getOrders, getOrdersWorker)
   yield takeLatest(orderActions.getOrder, getOrderWorker)
   yield takeLatest(orderActions.getArchive, getArchiveWorker)
   yield takeLatest(orderActions.editOrder, editOrderWorker)
   yield takeLatest(orderActions.getAllOrdersByCustomer, getAllOrdersByCustomerWorker)
   yield takeLatest(orderActions.refundMoney, refundMoneyWorker)
}
