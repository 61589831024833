import { createReducer } from '@reduxjs/toolkit'
import { roleActions } from './actions'
import { TInitialState, TSetRoleStatePayload } from './types'

const InitialState: TInitialState = {
   roles: {
      data: [],
      meta: null
   },
   filters: {
      searchValue: '',
      startDateValue: '',
      endDateValue: ''
   },
   loading: false
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(roleActions.setRoleState, (state, { payload }) => ({
         ...state,
         ...payload
      }))
      .addCase(roleActions.setRoles, (state, { payload }) => ({
         ...state,
         roles: payload
      }))
      .addCase(roleActions.setFilters, (state, { payload }) => ({
         ...state,
         filters: payload
      }))
})

export default reducer
