export const GET_CLIENTS = 'GET_CLIENTS'
export const SET_CLIENTS = 'SET_CLIENTS'

export const SET_CLIENT_STATE = 'SET_CLIENT_STATE'

export const GET_CLIENT = 'GET_CLIENT'
export const SET_CLIENT = 'SET_CLIENT'

export const EDIT_CLIENT = 'EDIT_CLIENT'

export const SET_FILTERS = 'SET_FILTERS'

export const GET_BUSINESS_CLIENTS = 'GET_BUSINESS_CLIENTS'
export const SET_BUSINESS_CLIENTS = 'SET_BUSINESS_CLIENTS'

export const CREATE_CUSTOMER_DISCOUNT = 'CREATE_CUSTOMER_DISCOUNT'
export const CREATE_CUSTOMER_DISCOUNT_BULK = 'CREATE_CUSTOMER_DISCOUNT_BULK'

export const GET_CUSTOMER_DISCOUNT_BRANDS = 'GET_CUSTOMER_DISCOUNT_BRANDS'
export const GET_CUSTOMER_DISCOUNT_PRODUCTS = 'GET_CUSTOMER_DISCOUNT_PRODUCTS'
export const SET_CUSTOMER_DISCOUNT_BRANDS = 'SET_CUSTOMER_DISCOUNT_BRANDS'
export const SET_CUSTOMER_DISCOUNT_PRODUCTS = 'SET_CUSTOMER_DISCOUNT_PRODUCTS'

export const UPDATE_CUSTOMER_DISCOUNT_BULK = 'UPDATE_CUSTOMER_DISCOUNT_BULK'

export const DELETE_CUSTOMER_DISCOUNT_BULK = 'DELETE_CUSTOMER_DISCOUNT_BULK'
export const CUSTOMER_DISCOUNT_BULK = 'CUSTOMER_DISCOUNT_BULK'

export const GET_BUSINESS_CUSTOMER_REQUEST = 'GET_BUSINESS_CUSTOMER_REQUEST'
export const SET_BUSINESS_CUSTOMER_REQUEST = 'SET_BUSINESS_CUSTOMER_REQUEST'

export const RESPONSE = {
   NOT_FOUND: 'NOT_FOUND'
}
