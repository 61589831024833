import { FC } from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'

import routes from './routes'

const NotAuthRoutes: FC = () => {
   return (
      <Routes>
         <Route path={routes.login.path} element={<routes.login.component />} />

         <Route path={routes.notFounded.path} element={<routes.notFounded.component />} />
      </Routes>
   )
}

export default NotAuthRoutes
