import { color } from '@mui/system'
import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { FLEX, FONT } from '../../utils'

export const Container = styled.div`
   width: 252px;
   flex-grow: 0; /* do not grow   - initial value: 0 */
   flex-shrink: 0;
   height: 100vh;
   border-right: 1px solid ${colors.lighter_grey};
   background-color: ${colors.solid_white};
   /* ${FLEX({ direction: 'column', justify: 'flex-start', align: 'flex-start' })} */

   display: flex;
   flex-direction: column;
   position: relative;
   overflow-y: auto;
`

export const Logo = styled.img.attrs({
   src: `${assets.LOGO}`
})`
   width: 131px;
   height: 54px;
   margin-top: 34px;
   margin-left: 30px;
`

export const ProfileContainer = styled.div`
   width: 223px;
   height: 55px;
   background-color: ${colors.darker_grey};
   border-radius: 12px;
   align-self: center;
   margin-top: 18px;

   padding: 0px 16px;

   display: flex;
   flex-direction: row;
`

export const ProfileName = styled.span`
   ${FONT({})}
   margin-left: 10px;
   line-height: 14px;
`

export const MenuContainer = styled.div`
   ${FLEX({ direction: 'column' })}
   width:100%;
   margin-top: 35px;
`

export const MenuIcon = styled.img`
   height: 20px;
   width: 20px;
   filter: invert(54%) sepia(3%) saturate(22%) hue-rotate(1deg) brightness(93%)
      contrast(88%);
`

export const MenuItem = styled.a`
   svg {
      height: 20px;
      width: 20px;
      color: ${colors.grey};
   }
   width: 100%;
   height: 42px;
   cursor: pointer;
   border-right: 1px solid ${colors.solid_white};

   &:hover,
   &.active {
      background-color: ${colors.blue};
      border-color: ${colors.dark_blue};
      color: ${colors.solid_white};
      svg {
         color: ${colors.solid_white};
      }
   }

   &:hover ${MenuIcon}, &.active ${MenuIcon} {
      filter: invert(0);
   }

   ${FONT({ size: '14px' })}
   line-height: 16px;

   display: flex;
   align-items: center;

   gap: 13px;
   padding-left: 30px;

   text-decoration: none;
`

export const ExitButton = styled.button`
   background-color: ${colors.solid_white};
   border: none;

   ${FONT({ color: colors.blue, size: '14px' })}
   line-height: 16px;
   cursor: pointer;

   ${FLEX({})}
   gap:10px;

   align-self: start;
   justify-self: center;
`

export const ExitButtonIcon = styled.img.attrs({
   src: `${assets.EXIT}`
})`
   height: 18px;
`

export const SpaceBetweenContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
   width: 100%;
`

export const AlignContainer = styled.div`
   display: flex;
   justify-content: center;
   flex-direction: column;
`

export const ExitButtonContainer = styled.div`
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;
   padding: 33px;
`
export const NewMessageCount = styled.span`
   width: 24px;
   height: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${colors.red};
   border-radius: 50%;
   color: ${colors.solid_white};
`
