import styled from 'styled-components'
import { colors } from '../../enums'

export const Container = styled.div`
   background-color: ${colors.background_panel_grey};
   display: flex;
   max-width: 100%;
`

export const MainContentContainer = styled.div`
   flex: 1;

   height: 100vh;
   display: flex;
   flex-direction: column;
   padding: 30px;
   padding-top: 0;
   overflow-x: auto;
`
