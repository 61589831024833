import { api } from 'config'
import { FC } from 'react'
import { assets } from '../../../../../../../assets'
import { RelatedProductWrapper } from '../styled'

type RelatedProductProps = {
   similarProduct: any
   rowIndex: number
   deleteProducthandler: (id: string, index: number) => void
}

export const RelatedProduct: FC<RelatedProductProps> = ({
   similarProduct,
   rowIndex,
   deleteProducthandler
}) => {
   const Events = {
      onDeleteProduct: () => {
         deleteProducthandler(similarProduct?._id?._id, rowIndex)
      }
   }
   return (
      <RelatedProductWrapper>
         <img
            src={assets.CROSS_GRAY}
            alt="delete"
            className="product-delete"
            onClick={Events.onDeleteProduct}
         />
         <img
            src={`${api.preview}${similarProduct?._id?.preview}`}
            alt="product"
            className="product-img"
         />
         <div className="product-code">
            {similarProduct?._id?.barcode ? similarProduct?._id?.barcode : ''}
         </div>
         <div className="product-name">{similarProduct?._id?.description?.title}</div>
         <div className="product-price">
            {similarProduct?._id?.discountPrice && (
               <div className="product-price__old">
                  {`${similarProduct?._id?.price} ₼/шт`}{' '}
               </div>
            )}
            <div className="product-price__new">
               {similarProduct?._id?.discountPrice
                  ? similarProduct?._id?.discountPrice
                  : similarProduct?._id?.price}{' '}
               ₼/шт
            </div>
         </div>
      </RelatedProductWrapper>
   )
}
