import { createReducer } from '@reduxjs/toolkit'
import { TDataWrapper } from '../types'
import { productActions } from './actions'
import { TInitialState, TSetProductPayload } from './types'

const InitialState: TInitialState = {
   products: {
      data: [],
      meta: null
   },
   product: null,
   photoProduct: {
      data: { gallery: [] },
      meta: null
   },
   similar: [],
   variations: {
      data: [],
      meta: null
   },
   parameters: null,
   promotionProducts: [],
   promotionProductId: '',
   discountPriceProductId: '',
   variationProducts: [],
   filters: {
      query: '',
      dateEnd: '',
      dateStart: '',
      categoryId: ''
   },
   subcategories: { data: [], meta: null },
   statFileName: '',
   variationsByCategory: {
      data: null,
      meta: null
   },
   loading: false,
   response: null
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(productActions.setProducts, (state, { payload }) => ({
         ...state,
         products: payload
      }))
      .addCase(productActions.setProductState, (state, { payload }) => ({
         ...state,
         response: null,
         ...payload
      }))
      .addCase(
         productActions.setProduct,
         (state, { payload }: TDataWrapper<TSetProductPayload>) => ({
            ...state,
            product: payload.product
         })
      )
      .addCase(productActions.setPhotoProduct, (state, { payload }) => ({
         ...state,
         photoProduct: payload
      }))
      .addCase(productActions.setSimilarProducts, (state, { payload }) => ({
         ...state,
         similar: payload?.similar
      }))
      .addCase(productActions.setVariations, (state, { payload }) => ({
         ...state,
         variations: payload
      }))
      .addCase(productActions.setVariationParameter, (state, { payload }) => ({
         ...state,
         parameters: payload
      }))
      .addCase(productActions.setSubCategories, (state, { payload }) => ({
         ...state,
         subcategories: payload
      }))
      .addCase(productActions.setPromotionProductId, (state, { payload }) => ({
         ...state,
         promotionProductId: payload
      }))
      .addCase(productActions.setPromotionProducts, (state, { payload }) => ({
         ...state,
         promotionProducts: payload
      }))
      .addCase(productActions.setVariationProducts, (state, { payload }) => ({
         ...state,
         variationProducts: payload
      }))
      .addCase(productActions.setFilters, (state, { payload }) => ({
         ...state,
         filters: payload
      }))
      .addCase(productActions.setDiscountProductId, (state, { payload }) => ({
         ...state,
         discountPriceProductId: payload
      }))
      .addCase(productActions.setStatFileName, (state, { payload }) => ({
         ...state,
         statFileName: payload
      }))
      .addCase(productActions.setVariationByCategory, (state, { payload }) => ({
         ...state,
         variationsByCategory: payload
      }))
})

export default reducer
