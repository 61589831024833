import { colors } from 'enums'
import styled from 'styled-components'
import { FONT } from 'utils'

export const RelatedProductWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 12px;
   border: 1px solid ${colors.table_grey};
   border-radius: 6px;
   margin-bottom: 8px;
   height: 190px;

   .product-delete {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
   }
   .product-img {
      width: 62px;
      height: 62px;
      padding-right: 8px;
      object-fit: contain;
      align-self: center;
   }
   .product-code {
      font-weight: 500;
      padding: 12px 0 6px;
   }
   .product-name {
      /* line-height: 14px; */
      /* overflow: hidden; */
      /* text-overflow: ellipsis; */
      /* display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; */
      width: 235px;
      ${FONT({ size: '16px', align: 'center' })}
   }
`
