import { api } from 'config'

export const SPECIALIST_URL = {
   getSpecialists: `${api.public}v1/specialist`,
   getSpecialist: `${api.public}v1/specialist`,
   createSpecialist: `${api.public}v1/specialist`,
   editSpecialist: `${api.public}v1/specialist`,
   deleteSpecialist: `${api.public}v1/specialist`,
   getSpecialistsReviews: `${api.public}v1/specialistReview`,
   getReviewBySpecialist: (id: string) => `${api.public}v1/specialistReview`,
   editSpecialistReview: `${api.public}v1/specialistReview`
}
