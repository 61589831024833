import { FC } from 'react'
import {
   Button,
   Container,
   NewReviewEditStyle,
   PaidStatus,
   UnpaidStatusCount
} from './styled'
import { TSubPagesBar } from './types'

const SubPagesBar: FC<TSubPagesBar> = ({
   sections,
   sumRating,
   revievPage,
   newCountPaid,
   newCountUnpaid
}) => {
   return (
      <Container>
         {sumRating ? (
            <NewReviewEditStyle revievPage={revievPage}>{sumRating}</NewReviewEditStyle>
         ) : null}
         {newCountUnpaid ? (
            <UnpaidStatusCount revievPage={revievPage}>
               {newCountUnpaid}
            </UnpaidStatusCount>
         ) : null}
         {newCountPaid ? (
            <PaidStatus revievPage={revievPage}>{newCountPaid}</PaidStatus>
         ) : null}
         {sections.map((section, key) => (
            <Button
               key={key}
               onClick={section.onClickHandler}
               active={section.active || false}>
               {section.title}
            </Button>
         ))}
      </Container>
   )
}

export default SubPagesBar
