import { call, put, takeLatest } from 'redux-saga/effects'

import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { bonusActions } from './actions'
import { ApiBonusService } from './api.service'
import { TBonus, TDeleteBonusPayload } from './types'

function* getBonusesWorker() {
   yield put(bonusActions.setBonusesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiBonusService, ApiBonusService.getBonuses],
         { token }
      )
      if (response.data) yield put(bonusActions.setBonuses(response.data))
   } catch (e) {
      yield put(bonusActions.setBonuses({ data: [], meta: null }))
   }

   yield put(bonusActions.setBonusesState({ loading: false }))
}

function* saveWorker({ payload }: TDataWrapper<TBonus[]>) {
   yield put(bonusActions.setBonusesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   const newBonuses = payload.filter((bonus) => {
      return !bonus._id
   })

   for (let bonus of newBonuses) {
      try {
         yield call([ApiBonusService, ApiBonusService.save], { token, data: bonus })
      } catch (e) {}
   }

   const existingBonuses = payload.filter((bonus) => {
      return !!bonus._id
   })

   for (let bonus of existingBonuses) {
      try {
         yield call([ApiBonusService, ApiBonusService.change], { token, data: bonus })
      } catch (e) {}
   }

   yield put(bonusActions.setBonusesState({ loading: false }))
}

function* deleteWorker({ payload }: TDataWrapper<TDeleteBonusPayload>) {
   yield put(bonusActions.setBonusesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiBonusService, ApiBonusService.delete], { token, id: payload.id })
   } catch (e) {}

   yield put(bonusActions.setBonusesState({ loading: false }))
   yield put(bonusActions.getBonusesRequest())
}

export function* bonusWatcher() {
   yield takeLatest(bonusActions.getBonusesRequest, getBonusesWorker)
   yield takeLatest(bonusActions.saveBonusesRequest, saveWorker)
   yield takeLatest(bonusActions.deleteBonusRequest, deleteWorker)
}
