import { createAction } from '@reduxjs/toolkit'
import {
   EDIT_ORDER,
   GET_ALL_ORDERS_BY_CUSTOMER,
   GET_ARCHIVE,
   GET_ORDER,
   GET_ORDERS,
   REFUND_MONEY,
   SET_ARCHIVE,
   SET_FILTERS,
   SET_ORDER,
   SET_ORDERS,
   SET_ORDER_STATE
} from './consts'
import {
   TEditOrderPayload,
   TGetAllOrdersByCustomerPayload,
   TGetArchivePayload,
   TGetOrderPayload,
   TGetOrdersPayload,
   TSetArchivePayload,
   TSetOrderPayload,
   TSetOrderState
} from './types'

export const orderActions = {
   getOrders: createAction(GET_ORDERS, (payload: TGetOrdersPayload) => ({ payload })),
   setOrders: createAction(SET_ORDERS, (payload) => ({ payload })),
   setOrderState: createAction(SET_ORDER_STATE, (payload: TSetOrderState) => ({
      payload
   })),
   getOrder: createAction(GET_ORDER, (payload: TGetOrderPayload) => ({ payload })),
   setOrder: createAction(SET_ORDER, (payload: TSetOrderPayload) => ({ payload })),
   getArchive: createAction(GET_ARCHIVE, (payload: TGetArchivePayload) => ({ payload })),
   setArchive: createAction(SET_ARCHIVE, (payload) => ({ payload })),
   editOrder: createAction(EDIT_ORDER, (payload: TEditOrderPayload) => ({ payload })),
   getAllOrdersByCustomer: createAction(
      GET_ALL_ORDERS_BY_CUSTOMER,
      (payload: TGetAllOrdersByCustomerPayload) => ({ payload })
   ),
   setFilters: createAction(SET_FILTERS, (payload) => ({ payload })),

   refundMoney: createAction(REFUND_MONEY, (payload) => ({ payload })),
   setNewOrder: createAction('SET_NEW_ORDERS', (payload) => ({ payload }))
}
