import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { supportActions } from './actions'
import { ApiSupportService } from './api.service'
import { TRequestModifyPayload, TSupportRequestsPayload } from './types'

function* getRequestsWorker({ payload }: TDataWrapper<TSupportRequestsPayload>) {
   const token: string = yield call(getAccessToken)
   try {
      const response: TResponse = yield call(
         [ApiSupportService, ApiSupportService.getRequests],
         { token, ...payload }
      )

      if (response.data) yield put(supportActions.setRequests(response.data))
   } catch (e) {
      yield put(supportActions.setRequests({ data: [], meta: null }))
   }
}

function* modifyRequstWorker({ payload }: TDataWrapper<TRequestModifyPayload>) {
   yield put(supportActions.setSupportState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiSupportService, ApiSupportService.modifyRequest], {
         token,
         ...payload
      })
   } catch (e) {}

   yield put(supportActions.setSupportState({ loading: false }))
}

export function* supportWatcher() {
   yield takeLatest(supportActions.getRequests, getRequestsWorker)
   yield takeLatest(supportActions.modifyRequest, modifyRequstWorker)
}
