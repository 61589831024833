import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './config'
import { GlobalStyles } from './assets'

ReactDOM.render(
   <React.StrictMode>
      <GlobalStyles />
      <App />
   </React.StrictMode>,
   document.getElementById('root')
)
