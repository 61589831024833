import i18next from 'i18next'
import { Dispatch, SetStateAction, SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
   Button,
   DateInput,
   DropDownList,
   FlexContainer,
   Input,
   PhotoPicker,
   SelectProductPopup,
   TableUtils,
   TColumnTable,
   TDataTable
} from '../../../../components'
import { api } from '../../../../config'
import { ELocales } from '../../../../enums'
import { useTypedSelector } from '../../../../hooks'
import {
   ApiProductService,
   getBrandSelector,
   getMainCategorySelector,
   getUserSelector,
   TBanner
} from '../../../../store'
import { TProduct } from '../../../../store/product/types'
import { P } from '../../styled'
import { TBanner as TFormBanner } from '../../types'

type TUseTableParams = {
   banners: TBanner[]
   form: TFormBanner[]
   setForm: Dispatch<SetStateAction<TFormBanner[]>>
   type?: 'default' | 'grid'
   isClicked?: boolean
}

export const useTableParams = ({
   banners,
   form,
   setForm,
   type = 'default',
   isClicked
}: TUseTableParams) => {
   const { t } = useTranslation()

   const { brands } = useTypedSelector(getBrandSelector)
   const { categories } = useTypedSelector(getMainCategorySelector)
   const { accessToken } = useTypedSelector(getUserSelector)

   const Events = {
      onChangeDateHandler: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         const id = TableUtils.getRowIndex(input)
         setForm((form) =>
            form.map((item, index) => {
               if (index == id) {
                  return { ...item, [input.name]: new Date(input.value).toISOString() }
               }
               return item
            })
         )
      },
      onChangeHandler: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         const id = TableUtils.getRowIndex(input)

         setForm((form) =>
            form.map((item, index) => {
               if (index == id) {
                  return { ...item, [input.name]: input.value }
               }
               return item
            })
         )
      },
      onImageChange: (file: File | null, id: number) => {
         setForm((form) =>
            form.map((item, index) => {
               if (index == id) {
                  return { ...item, image: file }
               }
               return item
            })
         )
      },

      dropDownListChange: (e: SyntheticEvent, id: number) => {
         const select = e.currentTarget as HTMLSelectElement

         setForm((form) =>
            form.map((item, index) => {
               if (index == id) {
                  return {
                     ...item,
                     [select.name]: select.value
                  }
               }
               return item
            })
         )
      },
      setPopupVisiablity: (id: number, visible: boolean) => {
         setForm((form) =>
            form.map((item, index) => {
               if (index == id) {
                  return {
                     ...item,
                     isProductPopupOpen: visible
                  }
               }
               return item
            })
         )
      },
      productPopupAddHandler: async (id: number, product: string) => {
         const productTitle = await Requests.getProductById(product)

         setForm((form) =>
            form.map((item, index) => {
               if (index == id) {
                  return {
                     ...item,
                     link: product,
                     productTitle
                  }
               }
               return item
            })
         )
         Events.setPopupVisiablity(id, false)
      }
   }

   const Requests = {
      getProductById: async (_id: string): Promise<string> => {
         const response = await ApiProductService.getProduct({
            token: accessToken as string,
            _id,
            lang: i18next.language as ELocales
         })

         return (response.data.data as TProduct)?.description?.title
      }
   }

   const data: TDataTable[] = useMemo(
      () =>
         form.map((banner, index) => {
            const start =
               form[index].start &&
               new Date(form[index].start).toISOString().substr(0, 10)

            const min =
               form[index].start &&
               new Date(
                  new Date(form[index].start).setDate(
                     new Date(form[index].start).getDate() + 1
                  )
               )
                  .toISOString()
                  .substr(0, 10)

            const end =
               form[index].end && new Date(form[index].end).toISOString().substr(0, 10)

            let photo = null

            banners.forEach((element) => {
               if (element._id == banner._id) {
                  photo = element.image
               }
            })

            const max =
               form[index].end &&
               new Date(
                  new Date(form[index].end).setDate(
                     new Date(form[index].end).getDate() - 1
                  )
               )
                  .toISOString()
                  .substr(0, 10)

            const arr = form.map((item) => item.order)

            return {
               number: (
                  <>
                     <Input
                        type="number"
                        name="order"
                        width="100px"
                        value={form[index].order}
                        isValid={
                           arr.filter((item, index) => arr.indexOf(item) != index)[0] !=
                              banner.order && +banner.order > 0
                        }
                        error={
                           +banner.order > 0
                              ? arr.filter(
                                   (item, index) => arr.indexOf(item) != index
                                )[0] != banner.order
                                 ? ''
                                 : t('same.ids')
                              : t('value.should.be.higher.than.0')
                        }
                        onChange={Events.onChangeHandler}
                     />
                  </>
               ),
               image: (
                  <FlexContainer
                     style={{ margin: '10px 0' }}
                     align="center"
                     justify="center">
                     <PhotoPicker
                        name="image"
                        index={index}
                        value={photo && `${api.img}banner/${photo}`}
                        onChange={Events.onImageChange}
                        isClicked={isClicked}
                     />
                  </FlexContainer>
               ),
               date: (
                  <FlexContainer gap="10px" width="212px">
                     <FlexContainer align="center" justify="space-between">
                        <span>{t('from')}</span>
                        <DateInput
                           name="start"
                           onChange={Events.onChangeDateHandler}
                           max={max}
                           value={start}
                        />
                     </FlexContainer>
                     <FlexContainer align="center" justify="space-between">
                        <span>{t('to')}</span>
                        <DateInput
                           name="end"
                           onChange={Events.onChangeDateHandler}
                           min={min}
                           value={end}
                        />
                     </FlexContainer>
                  </FlexContainer>
               ),
               destination: (
                  <FlexContainer justify="center">
                     <DropDownList
                        name="destination"
                        width="200px"
                        options={[
                           { name: t('site'), value: 'site' },
                           { name: t('app'), value: 'app' }
                        ]}
                        value={banner.destination as string}
                        placeholder={t('choose.platform')}
                        onChange={(e) => Events.dropDownListChange(e, index)}
                     />
                  </FlexContainer>
               ),
               link: (
                  <FlexContainer justify="flex-start" gap="64px" wrap="nowrap">
                     <DropDownList
                        name="linkType"
                        width="200px"
                        options={[
                           // { name: '', value: '' },
                           // { name: t('brand'), value: 'brand' },
                           { name: t('category'), value: 'category' },
                           { name: t('product'), value: 'product' }
                        ]}
                        value={banner.linkType as string}
                        placeholder={t('choose.type')}
                        onChange={(e) => Events.dropDownListChange(e, index)}
                        isValid={
                           isClicked ? !((banner.link as string).length > 3) : false
                        }
                     />

                     {banner.linkType == 'brand' && (
                        <>
                           <DropDownList
                              name="link"
                              width="200px"
                              options={brands.items.map((brand) => ({
                                 name: brand.title,
                                 value: brand._id
                              }))}
                              value={banner.link as string}
                              placeholder={t('choose.brand')}
                              onChange={(e) => Events.dropDownListChange(e, index)}
                              isValid={
                                 isClicked ? !((banner.link as string).length > 0) : false
                              }
                           />
                        </>
                     )}
                     {banner.linkType == 'category' && (
                        <>
                           <DropDownList
                              name="link"
                              width="200px"
                              options={categories.data.map((category) => ({
                                 name: category.title,
                                 value: category._id
                              }))}
                              value={banner.link as string}
                              placeholder={t('choose.category')}
                              onChange={(e) => Events.dropDownListChange(e, index)}
                              isValid={
                                 isClicked ? !((banner.link as string).length > 0) : false
                              }
                           />
                        </>
                     )}

                     {banner.linkType == 'product' && (
                        <>
                           {banner.productTitle && <P>{banner.productTitle}</P>}

                           {!banner.productTitle && (
                              <Button
                                 width={180}
                                 height={45}
                                 onClick={() => Events.setPopupVisiablity(index, true)}>
                                 {t('attach.product')}
                              </Button>
                           )}

                           {banner.productTitle && (
                              <Button
                                 width={180}
                                 height={45}
                                 onClick={() => Events.setPopupVisiablity(index, true)}>
                                 {t('change.product')}
                              </Button>
                           )}

                           {banner.isProductPopupOpen && (
                              <SelectProductPopup
                                 closeClickHandler={() =>
                                    Events.setPopupVisiablity(index, false)
                                 }
                                 addProductClickHandler={(products) => {
                                    Events.productPopupAddHandler(index, products[0])
                                 }}
                              />
                           )}
                        </>
                     )}
                  </FlexContainer>
               )
            }
         }),
      [form, isClicked, categories]
   )

   const columns: TColumnTable[] = useMemo(() => {
      if (type == 'grid') {
         return [
            {
               Header: t('number'),
               accessor: 'number', // accessor is the "key" in the data
               width: 100
            },
            {
               Header: t('image'),
               accessor: 'image',
               width: 300
            },

            {
               Header: t('link'),
               accessor: 'link',
               width: 700
            }
         ]
      }

      return [
         {
            Header: t('number'),
            accessor: 'number', // accessor is the "key" in the data
            width: 100
         },
         {
            Header: t('image'),
            accessor: 'image',
            width: 300
         },
         {
            Header: t('date'),
            accessor: 'date',
            width: 220
         },

         {
            Header: t('destination'),
            accessor: 'destination',
            width: 300
         },
         {
            Header: t('link'),
            accessor: 'link',
            width: 700
         }
      ]
   }, [])

   // console.log(data)

   return { columns, data }
}
