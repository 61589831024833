import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { FONT } from '../../utils'
import { FlexContainer } from '../Styled'

export const CardContainer = styled.div`
   display: flex;
   border-top: 1px solid ${colors.table_grey};
   border-bottom: 1px solid ${colors.table_grey};
   width: 100%;
   height: 100px;
   position: relative;
   margin: -1px 0 0 0;
   align-items: center;
   justify-content: space-between;

   @media (max-width: 1200px) {
      flex-direction: column;
      gap: 40px;
      height: auto;
      padding: 10px;
   }
`

export const ProductImg = styled.img`
   width: 72px;
   height: 72px;
   margin-left: 30px;
`

export const ProductName = styled.p`
   ${FONT({ color: colors.lighter_black, size: '14px' })}
   width:268px;
   max-width: 100%;
`

export const ProductNumber = styled.span`
   ${FONT({ color: colors.grey })}
`

export const RemoveButton = styled.button`
   border: none;
   background-color: transparent;
   background-image: ${`url(${assets.CROSS})`};
   background-repeat: no-repeat;
   background-position: center;
   cursor: pointer;
   width: 14px;
   height: 14px;
`

export const PromotionalPrice = styled.div`
   ${FONT({ color: colors.grey, size: '14px' })}
   text-decoration-line: line-through;
`

export const Price = styled.div`
   ${FONT({ size: '16px', weight: '500' })}
`
export const RemoveButtonWrapper = styled(FlexContainer)`
   width: auto;
   padding: 35px;

   @media (max-width: 1200px) {
      padding: 0;
      position: absolute;
      top: 20px;
      right: 20px;
   }
`
