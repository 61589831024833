import styled from 'styled-components'
import { colors } from '../../enums'
import { TStyles } from './types'

export const Styles = styled.div<TStyles>`
   position: relative;

   input[type='checkbox'] {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
   }

   .checkbox {
      display: inline-block;
      height: ${({ size }) => size};
      width: ${({ size }) => size};

      border-radius: 4px;
      background: transparent;
      border: 1px solid ${colors.grey};
      cursor: pointer;
   }

   .checkbox--active {
      border-color: ${colors.blue};
      background: ${colors.blue};
   }

   path {
      transform-origin: center;
      transform: scale(0.8);
   }
`
