import { ProductImage } from 'pages/Products/pages/CurrentProducts/pages/Edit/pages/Main/components/Photos/types'
import { TChangesProduct, TModeration, TProduct } from 'store/moderation/types'
import { TDescription } from 'store/product/types'

type useChangesHookProps = {
   changedProduct: TModeration
   beforeChanges: boolean
   form: TProduct
   changes: TChangesProduct
   productImages: ProductImage[]
   description: TDescription
}

export const useChangesHook = ({
   changedProduct,
   beforeChanges,
   form,
   changes,
   productImages,
   description
}: useChangesHookProps) => {
   const utils = {
      type: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved) return changedProduct.product.type

         if (beforeChanges) return changedProduct.product.type

         if (!beforeChanges && changes?.type != undefined) return changes?.type

         if (
            changes?.type &&
            changedProduct.changes?.type &&
            changedProduct.changes?.type == changes?.type
         )
            return changedProduct.changes?.type

         return changedProduct?.product.type
      },
      visability: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved) return changedProduct.product.show

         if (beforeChanges) return changedProduct.product.show

         if (!beforeChanges && changes?.show != undefined) return changes?.show

         return changedProduct?.product.show
      },
      barcode: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved) return changedProduct.product.barcode

         if (beforeChanges) return changedProduct.product.barcode

         if (!beforeChanges && changes?.barcode != undefined) return changes.barcode

         if (
            changes?.barcode &&
            changedProduct.changes.barcode &&
            changedProduct.changes.barcode == changes?.barcode
         )
            return changedProduct.changes.barcode

         return changedProduct?.product?.barcode
      },
      title: () => {
         if (!changedProduct && !form && !changes) return

         if (beforeChanges) return changedProduct?.product?.description?.title

         if (changedProduct?.approved) {
            return changedProduct?.changes?.description?.map((item) => item)[0]?.title
               ? changedProduct.changes.description[0].title
               : changedProduct.product.description.title
         }

         if (changes?.description?.map((item) => item?.title)?.length)
            return changes?.description[0]?.title

         if (description?.title || description?.title?.length == 0)
            return description?.title

         return changedProduct?.product?.description?.title
      },
      brand: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved) return changedProduct.product.brand

         if (beforeChanges) return changedProduct?.product?.brand

         if (!beforeChanges && changes?.brand != undefined) return changes.brand

         if (
            changes?.brand &&
            changedProduct.changes.brand &&
            changedProduct.changes.brand == changes?.brand
         )
            return changedProduct.changes.brand

         return changedProduct?.product?.brand
      },
      description: () => {
         if (!changedProduct && !form && !changes) return

         if (beforeChanges) return changedProduct?.product?.description?.description

         if (changedProduct?.approved)
            return changedProduct?.changes?.description?.map((item) => item)[0]
               ?.description
               ? changedProduct.changes.description[0].description
               : changedProduct.product.description.description

         if (changes?.description?.map((item) => item?.description)?.length)
            return changes?.description[0]?.description

         if (description?.description || description?.description?.length == 0)
            return description?.description

         return changedProduct?.product?.description?.description
      },
      price: () => {
         if (!changedProduct && !form) return

         if (changedProduct?.approved) return changedProduct.product.price

         if (beforeChanges) return changedProduct?.product?.price

         if (!beforeChanges && changes?.price != undefined) return changes.price

         if (
            changes?.price &&
            changedProduct.changes.price &&
            changedProduct.changes.price == changes?.price
         )
            return changedProduct.changes.price

         return changedProduct?.product?.price
      },
      discountPrice: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved)
            return changedProduct.product.discountPrice == null
               ? 0
               : changedProduct.product.discountPrice

         if (beforeChanges) return changedProduct?.product?.discountPrice

         if (!beforeChanges && changes?.discountPrice != undefined)
            return Number(changes.discountPrice)

         if (
            changes?.discountPrice &&
            changedProduct.changes.discountPrice &&
            changedProduct.changes.discountPrice == changes?.discountPrice
         )
            return changedProduct.changes.discountPrice

         return changedProduct?.product?.discountPrice
      },
      amount: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved) return changedProduct.product.amount

         if (beforeChanges) return changedProduct?.product?.amount

         if (!beforeChanges && changes?.amount != undefined) return changes.amount

         if (
            changes?.amount &&
            changedProduct.changes.amount &&
            changedProduct.changes.amount == changes?.amount
         )
            return changedProduct.changes.amount

         return changedProduct?.product?.amount
      },
      status: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved) return changedProduct.product.sellStatus

         if (beforeChanges) return changedProduct?.product?.sellStatus

         if (!beforeChanges && changes?.sellStatus != undefined)
            return changes?.sellStatus

         if (
            changes?.sellStatus &&
            changedProduct.changes.sellStatus &&
            changedProduct.changes.sellStatus === changes?.sellStatus
         )
            return changedProduct.changes.sellStatus

         return changedProduct?.product?.sellStatus
      },
      metaDescription: () => {
         if (!changedProduct && !form && !changes) return

         if (beforeChanges)
            return changedProduct?.product?.description?.metaDescription ?? ''

         if (changedProduct?.approved)
            return changedProduct?.changes?.description?.map((item) => item)[0]
               ?.metaDescription
               ? changedProduct.changes.description[0].metaDescription
               : changedProduct.product.description.metaDescription

         if (changes?.description?.map((item) => item?.metaDescription)?.length)
            return changes?.description[0]?.metaDescription

         if (description?.metaDescription || description?.metaDescription?.length == 0)
            return description?.metaDescription

         return changedProduct?.product?.description?.metaDescription
      },
      category: () => {
         if (!changedProduct && !form && !changes) return

         if (changedProduct?.approved)
            return changedProduct?.changes?.category
               ? changedProduct?.changes?.category
               : changedProduct?.product?.category

         if (beforeChanges) return changedProduct?.product?.category

         if (!beforeChanges && changes?.category != undefined) return changes?.category

         if (
            changes?.category &&
            changedProduct.changes.category &&
            changedProduct.changes.category === changes?.category
         )
            return changedProduct?.changes?.category

         return changedProduct?.product?.category
      },
      gallery: () => {
         if (!changedProduct && !form && !changes && !productImages) return

         if (changedProduct?.approved)
            return changedProduct?.changes?.gallery
               ? changedProduct?.changes?.gallery
               : changedProduct?.product?.gallery

         if (beforeChanges) return changedProduct?.product?.gallery

         if (!beforeChanges && productImages?.length) return productImages

         if (productImages?.length == 0) return []

         return changedProduct?.product?.gallery
      },
      metaKeywords: () => {
         if (!changedProduct && !form && !changes) return

         if (beforeChanges)
            return changedProduct?.product?.description?.metaKeywords ?? []

         if (changedProduct?.approved)
            return description?.metaKeywords
               ? description?.metaKeywords
               : changedProduct.product.description.metaKeywords

         if (changes?.description?.map((item) => item?.metaKeywords)?.length)
            return changes?.description[0]?.metaKeywords

         if (description?.metaKeywords || description?.metaKeywords?.length == 0)
            return description?.metaKeywords

         return changedProduct?.product?.description?.metaKeywords
      }
   }

   return {
      type: utils.type(),
      visability: utils.visability(),
      barcode: utils.barcode(),
      title: utils.title(),
      brand: utils.brand(),
      description: utils.description(),
      price: utils.price(),
      discountPrice: utils.discountPrice(),
      amount: utils.amount(),
      status: utils.status(),
      metaDescription: utils.metaDescription(),
      category: utils.category(),
      gallery: utils.gallery(),
      metaKeywords: utils.metaKeywords()
   }
}
