import { Checkbox, FlexContainer, Input } from 'components'
import { FC, useEffect, useMemo, useState } from 'react'
import {
   AddText,
   CheckboxText,
   ClickbleText,
   Container,
   DeleteText,
   HeaderText
} from '../../styled'
import { assets } from 'assets'
import { Card } from '../Card'
import { t } from 'i18next'
import { TBrandsTableProps } from './types'
import { PAGE_LIMIT } from '../../consts'
import { useDispatch } from 'react-redux'
import { CUSTOMER_DISCOUNT } from 'store/client/types'
import { clientActions } from 'store'
import { useLocalization } from 'hooks'

export const BrandsTable: FC<TBrandsTableProps> = ({
   selectedBrands,
   isCheckedBrands,
   customerDiscount,
   isChecked,
   form,
   Events,
   setSelectedBrands,
   customerId
}) => {
   const dispatch = useDispatch()
   const [locale] = useLocalization()
   const [page, setPage] = useState<number>(0)

   const brandsWithDiscountValue = useMemo(() => {
      const filteredCustomerDiscountByBrands = customerDiscount?.data?.filter((item) =>
         selectedBrands.map((item) => item?._id).includes(item?.brand?._id)
      )
      const res = selectedBrands.map((brand) => ({
         ...brand,
         discount: filteredCustomerDiscountByBrands.find(
            (item) => item?.brand?._id == brand._id
         )?.discount
      }))
      return res
   }, [selectedBrands])

   const pagination = useMemo(
      () => Math.ceil(customerDiscount?.meta?.totalCount / PAGE_LIMIT),
      [customerDiscount?.meta]
   )

   const getBrands = () => {
      dispatch(
         clientActions.getCustomerDiscountBrands({
            _id: customerId as string,
            lang: locale,
            type: CUSTOMER_DISCOUNT.brand,
            page: page,
            limit: PAGE_LIMIT
         })
      )
   }

   useEffect(() => {
      getBrands()
   }, [page])

   return (
      <>
         {selectedBrands.length > 0 && (
            <FlexContainer margin="0 0 0 30px" gap="30px">
               <FlexContainer width="210px" direction="column" gap="15px">
                  <HeaderText>{t('discount.for.brands')}</HeaderText>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.PLUS_ICON_BLUE} />

                     <AddText onClick={Events.setBrandsPopupVisiablity}>
                        {t('add.brands')}
                     </AddText>
                  </FlexContainer>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.DELETE_ICON} />
                     <DeleteText onClick={Events.clearBrands}>
                        {t('delete.brands')}
                     </DeleteText>
                  </FlexContainer>

                  <FlexContainer
                     gap="8px"
                     style={{ paddingTop: isCheckedBrands ? '60px' : '105px' }}
                     align="center">
                     <Checkbox
                        checked={isCheckedBrands}
                        onChange={Events.checkedBrandsStateHandler}
                     />

                     <CheckboxText style={{ width: '163px' }}>
                        {t('common.discount.for.brands')}
                     </CheckboxText>

                     {isCheckedBrands && (
                        <Input
                           name="discountPriceBrands"
                           onChange={Events.inputChangeHandler}
                           value={form.discountPriceBrands}
                           placeholder={t('for.all.brands')}
                        />
                     )}
                  </FlexContainer>
               </FlexContainer>

               <Container>
                  {brandsWithDiscountValue.map((brand, index) => (
                     <>
                        <Card
                           brandItem={brand}
                           selectedBrands={selectedBrands}
                           setSelectedBrands={setSelectedBrands}
                           discountValue={isChecked ? form.discountPriceProducts : ''}
                           defaultDiscountValue={
                              brand?.discount ? brand?.discount + '' : ''
                           }
                           customerDiscount={customerDiscount}
                        />

                        {index == 6 * (page + 1) + page && pagination > 1 ? (
                           <div
                              style={{
                                 alignSelf: 'center',
                                 justifySelf: 'center',
                                 width: '200px'
                              }}>
                              <p>
                                 {t('checked') +
                                    ` ${index + 1} ` +
                                    t('from.all') +
                                    ` ${customerDiscount?.meta?.totalCount}`}
                              </p>
                              <ClickbleText onClick={() => setPage(page + 1)}>
                                 {t('show.more')}
                              </ClickbleText>
                           </div>
                        ) : (
                           ''
                        )}
                     </>
                  ))}
               </Container>
            </FlexContainer>
         )}
      </>
   )
}
