export const titles = [
   'Order',
   'Product',
   'Customer',
   'Category',
   'Support',
   'BonusSystem',
   'Banner',
   'Brand',
   'Role',
   'SEO',
   'Mail',
   'Specialist'
]
export const checkboxNames = [
   'Order',
   'Product',
   'Customer',
   'Category',
   'Support',
   'BonusSystem',
   'Banner',
   'Brand',
   'Role',
   'SEO',
   'Mail',
   'Specialist'
]
