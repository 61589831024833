import { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { CATEGORY_URL } from './config'
import {
   TCategories,
   TCategory,
   TChangeProductOrderRequestPayload,
   TCreateCategoryRequestPayload,
   TEditCategoryOrderPayloadRequest,
   TEditCategoryRequestPaylaod,
   TGetCategoriesPayload,
   TGetCategoriesRequestPayload,
   TGetCategoryRequestPayload,
   TGetProductsByCategoryRequestPayload,
   TMeta,
   TRemoveCategoryRequestPayload
} from './types'
import useSWRInfinite from 'swr/infinite'

export class ApiCategoryService extends HttpService {
   static getCategories({
      token,
      limit = 10,
      page = 0,
      order = '',
      sortBy = '',
      lang = ELocales.ru,
      value,
      field,
      ...params
   }: TGetCategoriesRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: CATEGORY_URL.getCategories,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            limit,
            skip: limit * page,
            order,
            sortBy,
            lang,
            value,
            field,
            ...params
         }
      })

      return response
   }

   static removeCategory({
      token,
      _id
   }: TRemoveCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.removeCategory}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static editCategory({
      token,
      _id,
      data
   }: TEditCategoryRequestPaylaod): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.editCategory}/${_id}`,
         method: 'PATCH',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         data
      })

      return response
   }

   static createCategory({
      token,
      data
   }: TCreateCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: CATEGORY_URL.createCategory,
         method: 'POST',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         data
      })

      return response
   }

   static getCategory({
      token,
      _id,
      lang = ELocales.ru,
      ...params
   }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getCategory}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            ...params,
            lang
         }
      })

      return response
   }

   static getCategoryParent({
      token,
      _id,
      lang = ELocales.ru
   }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getCategoryParent}/${_id}/branch`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            lang
         }
      })

      return response
   }

   static getCategoriesByParent({
      token,
      _id,
      lang = ELocales.ru,
      limit,
      page,
      sortBy,
      order
   }: TGetCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getCategoryParent}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            field: 'parent',
            value: _id,
            lang,
            skip: (page as number) * (limit as number),
            sortBy,
            order,
            limit: limit
         }
      })

      return response
   }

   static getProductsByCategory({
      token,
      _id,
      lang = ELocales.ru
   }: TGetProductsByCategoryRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.getProductsByCategory(_id)}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            lang
         }
      })

      return response
   }

   static editCategoryBulk({
      token,
      data
   }: TEditCategoryOrderPayloadRequest): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CATEGORY_URL.editCategoryOrder}`,
         method: 'PATCH',
         data: data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })

      return response
   }

   static changeProductOrder({ token, data }: TChangeProductOrderRequestPayload) {
      return this.request({
         url: `${CATEGORY_URL.changeProductOrder}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         }
      })
   }
}

export const useGetCategories = (params: TGetCategoriesRequestPayload) => {
   const fetcher = async (params: TGetCategoriesRequestPayload) => {
      const res = (await ApiCategoryService.getCategories(
         params
      )) as AxiosResponse<TCategories>
      return res.data
   }

   const getKey = (
      pageIndex: number,
      previousPageData: any
   ): [string, TGetCategoriesRequestPayload] | null => {
      if (previousPageData && !previousPageData?.data?.length) return null
      return [
         'getCategoriesInfinite',
         {
            ...params,
            page: pageIndex
         }
      ]
   }
   const { data, isLoading, setSize, mutate } = useSWRInfinite(
      getKey,
      ([, getParams]) => {
         if (typeof getParams === 'object') {
            return fetcher(getParams)
         }
      },
      {
         revalidateFirstPage: false,
         revalidateOnFocus: false
      }
   )
   const categoriesData = data?.reduce<{ categories: TCategory[]; meta?: TMeta | null }>(
      (acc, item) => {
         return {
            categories: [...(acc.categories || []), ...(item?.data || [])],
            meta: item?.meta
         }
      },
      { categories: [], meta: null }
   )
   const hasMore =
      (categoriesData?.categories?.length || 0) < (categoriesData?.meta?.totalCount || 0)

   return { categoriesData, isLoading, setSize, mutate, hasMore }
}
