import { FC } from 'react'
import { colors } from '../../enums'
import { AbsoluteContainer, LoadingBar } from './styled'
import { TPreloader } from './types'

const Preloader: FC<TPreloader> = ({
   loading,
   size = '80px',
   primaryColor = colors.blue,
   secondaryColor = colors.solid_white
}) => {
   return (
      <>
         {loading && (
            <AbsoluteContainer>
               <LoadingBar size={size} primaryColor={primaryColor} secondaryColor={secondaryColor} />
            </AbsoluteContainer>
         )}
      </>
   )
}

export default Preloader
