import { createReducer } from '@reduxjs/toolkit'
import { clientActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   clients: {
      data: [],
      meta: null
   },
   businessClients: {
      data: [],
      meta: null
   },
   client: null,
   filters: {
      searchValue: '',
      dateStart: '',
      dateEnd: ''
   },
   customerDiscountBrands: null,
   customerDiscountProducts: null,
   businessCustomerRequest: null,
   loading: false,
   response: null
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(clientActions.setClients, (state, { payload }) => ({
         ...state,
         clients: { ...payload }
      }))
      .addCase(clientActions.setBusinessClients, (state, { payload }) => ({
         ...state,
         businessClients: { ...payload }
      }))
      .addCase(clientActions.setClientState, (state, { payload }) => ({
         ...state,
         response: null,
         ...payload
      }))
      .addCase(clientActions.setClient, (state, { payload }) => ({
         ...state,
         client: payload
      }))
      .addCase(clientActions.setCustomerDiscountBrands, (state, { payload }) => ({
         ...state,
         customerDiscountBrands: payload
      }))
      .addCase(clientActions.setCustomerDiscountProducts, (state, { payload }) => ({
         ...state,
         customerDiscountProducts: payload
      }))
      .addCase(clientActions.setBusinessCustomerRequest, (state, { payload }) => ({
         ...state,
         businessCustomerRequest: payload
      }))
})

export default reducer
