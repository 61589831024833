import { ELocales } from 'enums'
import useSWR from 'swr'
import { getProducts } from './helpers'
import { TLocales } from 'locales'
import { TSort } from 'components'

type UseGetFirstStageProps = {
   accessToken: string | null
   locale: TLocales
   page: number
}

export const useGetFirstStage = ({
   locale,
   accessToken,
   page
}: UseGetFirstStageProps) => {
   const { data: productsEconom, mutate: mutateEconom } = useSWR(
      [
         'get-products-v3-econom',
         {
            token: accessToken as string,
            params: {
               limit: 10,
               skip: page * 10,
               hidden: 'false',
               lang: locale as ELocales,
               firstStageEconom: true,
               order: 1 as TSort,
               sortBy: 'firstStageEconomOrder'
            }
         }
      ],
      ([key, params]) => getProducts(params)
   )
   const { data: productsStandart, mutate: mutateStandart } = useSWR(
      [
         'get-products-v3-standart',
         {
            token: accessToken as string,
            params: {
               limit: 10,
               skip: page * 10,
               hidden: 'false',
               lang: locale as ELocales,
               firstStageStandart: true,
               order: 1 as TSort,
               sortBy: 'firstStageStandartOrder'
            }
         }
      ],
      ([key, params]) => getProducts(params)
   )
   const { data: productsPremium, mutate: mutatePremium } = useSWR(
      [
         'get-products-v3-premium',
         {
            token: accessToken as string,
            params: {
               limit: 10,
               skip: page * 10,
               hidden: 'false',
               lang: locale as ELocales,
               firstStagePremium: true,
               order: 1 as TSort,
               sortBy: 'firstStagePremiumOrder'
            }
         }
      ],
      ([key, params]) => getProducts(params)
   )

   return {
      econom: productsEconom,
      standart: productsStandart,
      premium: productsPremium,
      mutateEconom,
      mutateStandart,
      mutatePremium
   }
}
