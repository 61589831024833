import { TSort } from '../../components'
import { ELocales } from '../../enums'
import { TFirebase, TLang } from '../../types'
import {
   TCategory,
   TDescription,
   TGallery,
   TGroup,
   TSellStatus,
   TVariations
} from '../product/types'
import { ORDER_RESPONSE } from './consts'

export type TInitialState = {
   orders: TOrders
   order: TOrder | null
   archive: TOrders
   filters: {
      searchValue: string
      startDateValue: string
      endDateValue: string
   }
   loading: boolean
   response: TOrderResponse
   newOrders: number
}

export type TOrders = {
   data: TOrder[]
   meta: TOrdersMeta | null
}

export type TOrder = {
   _id: string
   id: string
   customer: TCustomer
   receiver: TReceiver
   items: TItem[]
   orderStatus: TOrderStatus | string
   paymentStatus: TPaymentStatus
   delivery: TDelivery
   totalPrice: string
   createdAt: string
   updatedAt: string
   paymentCode: number
   call: true
   comment: string
   paymentType: string
   deliveryPrice: number
   paidCount: number
}

export type TReceiver = {
   anotherReceiver: boolean
   name?: string
   phone?: string
   _id: string
}

export type TOrderStatus =
   | 'new'
   | 'pending payment'
   | 'processing'
   | 'delivering'
   | 'completed'
   | 'canceled'

export enum OrderStatus {
   new = 'new',
   delivering = 'delivering',
   completed = 'completed',
   canceled = 'canceled'
}

export enum PaymentStatus {
   paid = 'paid',
   processing = 'processing',
   failure = 'failure',
   unpaid = 'unpaid',
   refunded = 'refunded',
   expired = 'expired'
}

export type TPaymentStatus =
   | 'paid'
   | 'processing'
   | 'failure'
   | 'unpaid'
   | 'refunded'
   | 'expired'

export type TItem = {
   count: number
   price: number
   product: TProduct
}

export type TProduct = {
   _id: string
   variations: TVariations[]
   group: TGroup
   type: 'variated' | 'simple'
   gallery: TGallery[]
   preview: string
   category: TCategory
   subCategory: TCategory
   show: boolean
   price: number
   sellStatus: TSellStatus
   sku: number
   createdAt: string
   updatedAt: string
   description: TDescription[] | TDescription
   count: number
   barcode: string
   amount: number
   categoryOrder?: number
   discountPrice?: number
   discountOrder?: number
   metaPrice?: number
   brand?: string | undefined
   metaKeywords?: string[]
   metaDescription?: string
   popularity?: number
   views?: number
}

export type TOrdersMeta = {
   lang: string
   limit: number
   message: string
   new: number
   newPaid: number
   newUnpaid: number
   order: number
   skip: number
   sortBy: string
   statusCode: number
   totalCount: number
}

export type TDelivery = {
   form: 'pickup' | 'movers'
   address?: string
   apartment: string
   lon?: number
   lat?: number
}

export type TGetOrdersPayload = {
   limit?: number
   page?: number
   lang?: TLang
   field?: string
   value?: string
   start?: string
   end?: string
   regex?: string
   sortBy?: string
   order?: TSort
   paymentStatus?: 'paid' | 'unpaid'
   orderStatus?: 'new' | 'canceled' | 'delivering' | 'completed'
}

export type TGetOrdersRequestPayload = {
   token: string
} & TGetOrdersPayload

export type TCustomer = {
   createdAt: string
   email: string
   phone: string
   email_verified: boolean
   firebase: TFirebase
   name: string
   secondName: string
   picture: string
   updatedAt: string
   _id: string
}

export type TSetOrderState = {
   loading?: boolean
   response?: TOrderResponse
}

export type TOrderResponse = ORDER_RESPONSE | null

export type TGetOrderPayload = {
   _id: string
   lang?: ELocales
   paymentStatus?: string
}

export type TGetOrderRequestPayload = TGetOrderPayload & {
   token: string
}

export type TSetOrderPayload = TOrder | null

export type TGetArchivePayload = TGetOrdersPayload

export type TGetArchiveRequestPayload = TGetArchivePayload & {
   token: string
}

export type TSetArchivePayload = TSetOrderPayload

export type TEditOrderPayload = {
   _id: string
   data: TEditOrderData
}

export type TEditOrderData = {
   items?: {
      count: number
      product: string
   }[]
   orderStatus?: TOrderStatus
   paymentType?: TPaymentType
   paymentStatus?: TPaymentStatus
}

export type TPaymentType = 'online' | 'cash'

export type TEditOrderRequestPayload = TEditOrderPayload & {
   token: string
}

export type TGetAllOrdersByCustomerPayload = {
   _id: string
   limit?: number
   page?: number
   lang?: TLang
   field?: string
   value?: string
   start?: string
   end?: string
   regex?: string
   sortBy?: string
   order?: TSort
}

export type TGetAllOrdersByCustomerRequestPayload = TGetAllOrdersByCustomerPayload & {
   token: string
}

export type TRefundMoneyPayload = {
   id: string
   amount: number
}

export type TRefundMoneyRequestPayload = TRefundMoneyPayload & {
   token: string
}
