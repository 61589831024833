import { Modal } from '@mui/material'
import { TModalMaterialProps } from './types'

export const ModalMaterial = ({ isOpen, close, children }: TModalMaterialProps) => {
   return (
      <Modal
         componentsProps={{
            backdrop: {
               style: {
                  backgroundColor: 'rgba(99, 123, 150, 0.4)'
               }
            }
         }}
         onClose={close}
         open={isOpen}>
         <div>{children}</div>
      </Modal>
   )
}
