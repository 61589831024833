import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ELocales } from '../enums'
import { getLocale } from '../utils'

// local imports
import { az, ru } from '../locales'

const language = getLocale()

i18n.use(initReactI18next).init({
   resources: {
      az: { translations: az },
      ru: { translations: ru }
   },
   fallbackLng: ELocales.default,
   lng: language,
   ns: ['translations'],
   defaultNS: 'translations',
   keySeparator: false,
   interpolation: {
      escapeValue: false,
      formatSeparator: ','
   }
})

export default i18n
