import { AxiosResponse } from 'axios'
import { HttpService } from 'services'
import { TResponse } from 'store/types'
import { MODERATION_URL } from './config'
import {
   TApproveChangesBulkRequestPayload,
   TModerationApproveRequestPayload,
   TModerationDeclineRequestPayload,
   TModerationGetChangedProductRequestPayload,
   TModerationPatchChangesRequestPayload,
   TModerationRequestPayload
} from './types'

export class ApiModerationService extends HttpService {
   static getChangedProducts({
      token,
      limit,
      page,
      ...params
   }: TModerationRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${MODERATION_URL.getChangedProducts}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache',
            'Cache-Control': 'no-cache'
         },
         params: {
            skip: limit * page,
            limit,
            ...params
         }
      })

      return response
   }

   static approveChanges({
      token,
      _id,
      changes
   }: TModerationApproveRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${MODERATION_URL.approveChages(_id)}`,
         method: 'PATCH',
         data: changes,
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache',
            'Cache-Control': 'no-cache'
         }
      })

      return response
   }

   static declineChanges({
      token,
      _id
   }: TModerationDeclineRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${MODERATION_URL.declineChages(_id)}`,
         method: 'PATCH',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache',
            'Cache-Control': 'no-cache'
         }
      })

      return response
   }

   static getChangedProduct({
      token,
      _id,
      lang
   }: TModerationGetChangedProductRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${MODERATION_URL.getChangedProduct}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache',
            'Cache-Control': 'no-cache'
         },
         params: {
            lang
         }
      })

      return response
   }

   static patchChanges({
      token,
      _id,
      data
   }: TModerationPatchChangesRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${MODERATION_URL.patchChages(_id)}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static approveChangesBulk({
      token,
      data
   }: TApproveChangesBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${MODERATION_URL.approveChagesBulk}`,
         method: 'PATCH',
         data: { moderation: data },
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache',
            'Cache-Control': 'no-cache'
         }
      })

      return response
   }
}
