import { useTranslation } from 'react-i18next'
import {
   Container,
   FilterWrap,
   LabelSearch,
   SortBarList,
   SortItem,
   TimeInpustWrap,
   selectStyles
} from './styled'
import { TFilterBarProps } from './types'
import { CiSearch } from 'react-icons/ci'
import { ChangeEvent, SyntheticEvent, memo, useEffect, useState } from 'react'
import Select from 'react-select'
import { IoMdArrowDropdown } from 'react-icons/io'
import { DateInput } from 'components'

export const ProductFilterBar = memo(({ params, setParams }: TFilterBarProps) => {
   const { t } = useTranslation()

   const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
      setParams((prev) => ({ ...prev, query: e.target.value }))
   }
   const onDataChenge = (e: SyntheticEvent<Element, Event>, type: 'start' | 'end') => {
      const input = e.target as HTMLInputElement
      if (type === 'start') {
         setParams((prev) => ({ ...prev, date_start: input.value }))
      } else {
         setParams((prev) => ({ ...prev, date_end: input.value }))
      }
   }

   const onSelectChange = (value: unknown) => {
      const newValue = value as { label: string; value: string } | undefined
      setParams((prev) => ({ ...prev, category: newValue }))
   }

   const onSortClick = (sortBy: string) => {
      setParams((prev) => ({ ...prev, sortBy, order: prev.order === 1 ? -1 : 1 }))
   }

   return (
      <Container>
         <FilterWrap>
            <LabelSearch>
               <CiSearch />
               <input
                  onChange={onSearch}
                  value={params.query}
                  placeholder={t('search')}
               />
            </LabelSearch>
            <TimeInpustWrap>
               <DateInput
                  onChange={(e) => onDataChenge(e, 'start')}
                  value={params.date_start}
               />
               <DateInput
                  onChange={(e) => onDataChenge(e, 'end')}
                  value={params.date_end}
               />
            </TimeInpustWrap>
            <Select
               isDisabled
               isClearable
               value={params.category}
               placeholder={t('select.category')}
               styles={selectStyles}
               onChange={onSelectChange}
               // onMenuScrollToBottom={() => setPage((prev) => prev + 1)}
               // options={categories?.data?.map((item) => ({
               //    label: item?.title,
               //    value: item?._id
               // }))}
            />
         </FilterWrap>
         <SortBarList>
            <SortItem>{t('barcode')}</SortItem>
            <SortItem>{t('photo')}</SortItem>
            <SortItem>{t('label')}</SortItem>
            <SortItem
               $growth={params.order === -1}
               onClick={() => onSortClick('createdAt')}
               $active={params.sortBy === 'createdAt'}>
               {t('publish.date')}
               <IoMdArrowDropdown />
            </SortItem>
            <SortItem>{t('category')}</SortItem>
            <SortItem
               $growth={params.order === -1}
               onClick={() => onSortClick('price')}
               $active={params.sortBy === 'price'}>
               {t('price')}
               <IoMdArrowDropdown />
            </SortItem>
         </SortBarList>
      </Container>
   )
})
