import styled from 'styled-components'
import { colors } from '../../../../../../enums'
import { FONT } from '../../../../../../utils'

export const UserContainer = styled.div`
   background-color: ${colors.gray_light};
   border: 1px solid ${colors.lighter_grey};
   width: 318px;
   max-width: 100%;
   height: auto;
   min-height: 66px;
   ${FONT({ color: colors.lighter_black, size: '16px' })}
   padding: 14px;
   display: flex;
   flex-direction: column;
   border-radius: 8px;
`

export const TotalSum = styled.div`
   ${FONT({ size: '16px' })}
`
