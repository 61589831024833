import { UseFormWatch } from 'react-hook-form'
import { t } from 'i18next'
import { createId } from 'pages/Calculator/Pages/CreateCalculator/helpers'
import { TCreateCalculatorForm } from '../../types'
import { TItemDragTypes } from './types'
import _ from 'lodash'

export const setDefaultElement = (watch: UseFormWatch<TCreateCalculatorForm>) => {
   const generalParams = Object.entries(watch('typeofrooms')).filter(
      ([_, value]) => !!value
   ).length
      ? [
           {
              label: t(`totalArea`),
              value: `totalArea`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`wallArea`),
              value: `wallArea`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`maxHeight`),
              value: `maxHeight`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`totalLength`),
              value: `totalLength`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`totalWidth`),
              value: `totalWidth`,
              type: 'block',
              id: createId()
           }
        ]
      : []

   const activeRoomsArray = Object.entries(watch('typeofrooms'))
      .filter(([key, value]) => value && key !== 'other')
      .flatMap(([key]) => [
         {
            label: t(`${key}Area`),
            value: `${key}Area`,
            type: 'block',
            id: createId()
         },
         {
            label: t(`${key}Walls`),
            value: `${key}Walls`,
            type: 'block',
            id: createId()
         },
         {
            label: t(`${key}Length`),
            value: `${key}Length`,
            type: 'block',
            id: createId()
         },
         {
            label: t(`${key}Width`),
            value: `${key}Width`,
            type: 'block',
            id: createId()
         }
      ])

   return [...activeRoomsArray, ...generalParams]
}

export const signs = [
   { label: '+', value: '+', type: 'signs', id: createId() },
   { label: '/', value: '/', type: 'signs', id: createId() },
   { label: '-', value: '-', type: 'signs', id: createId() },
   {
      label: 'x',
      value: '*',
      type: 'signs',
      id: createId()
   },
   {
      label: t('inputField'),
      value: 'inputField',
      type: 'signs',
      id: createId(),
      inputValue: ''
   }
]

export const formatFormula = (formula: TItemDragTypes[]) => {
   return formula
      ?.map((exp) => {
         if (exp.value === 'inputField') {
            return `{${exp.inputValue}}`
         } else if (exp.type === 'signs' && exp.value !== 'inputField') {
            return `{${exp.value}}`
         } else if (exp.type === 'block' && exp.value !== 'question') {
            return `{${exp.value}}`
         } else if (exp.value === 'question' && exp.type === 'block') {
            return `{${exp.id}}`
         }
      })
      ?.join(',')
      ?.replace(/,/g, '')
}

export function parseFormula(formulaStr: string): TItemDragTypes[] {
   const result: TItemDragTypes[] = []
   let buffer = ''
   let isInsideBraces = false

   // Known sign values
   const signs = ['+', '-', '*', '/']

   // Helper function to process tokens
   const processToken = (token: string) => {
      if (signs.includes(token)) {
         // Check if token is a known sign
         result.push({
            label: token == '*' ? 'x' : token, // Assuming t() is a translation function
            value: token,
            type: 'signs', // Correctly label as 'signs'
            id: createId()
         })
      } else if (token.match(/^\d+$/)) {
         // Handling 'inputField' block
         result.push({
            label: t('inputField'),
            value: 'inputField',
            type: 'signs',
            id: createId(),
            inputValue: token

            // label: '', // The label for 'question' might need special handling
            // value: 'question',
            // type: 'block',
            // id: token // Assuming IDs are unique and correctly formatted
         })
      } else {
         // It's a block
         result.push({
            label: t(token),
            value: token,
            type: 'block',
            id: createId()
         })
      }
   }

   for (const char of formulaStr) {
      if (char === '{') {
         isInsideBraces = true
      } else if (char === '}') {
         isInsideBraces = false
         processToken(buffer)
         buffer = '' // Reset the buffer after processing
      } else {
         buffer += char
      }
   }

   // If there's any remaining buffer content, process it
   if (buffer) {
      processToken(buffer)
   }

   return result
}

export function filterDuplicates(
   firstArray: TItemDragTypes[],
   secondArray: TItemDragTypes[]
) {
   // Use _.differenceWith to compare the two arrays.
   // The comparator function checks if the labels are equal.
   const filteredArray = _.differenceWith(secondArray, firstArray, (arrVal, othVal) => {
      return arrVal.label === othVal.label
   })

   return filteredArray
}

export function validateBlocksSpacing(items: TItemDragTypes[]): boolean {
   if (items?.length == 0) {
      return true
   }

   const res = items?.map((item) => {
      if (item?.type == 'block' || item?.type == 'inputField') {
         return false
      }

      return true
   })

   return res?.slice(-1)![0]
}
