import { colors } from 'enums'
import styled from 'styled-components'

export const FilterBarWrap = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
   min-height: 73px;
   padding: 0 20px;
   font-size: 14px;
   justify-content: space-between;
`

export const FilterItem = styled.div<{ $active?: boolean; $up?: boolean }>`
   display: flex;
   align-items: center;
   gap: 6px;

   font-weight: ${({ $active }) => ($active ? '600' : '500')};
   color: ${({ $active }) => ($active ? colors.blue : colors.grey)};
   padding: 0 10px;
   width: 100%;
   &:nth-child(1) {
      /* padding: 0 10px 0 0; */
      max-width: 40px;
   }
   &:nth-child(2) {
      max-width: 200px;
   }
   &:nth-child(3) {
      cursor: pointer;
      max-width: 300px;
   }
   &:nth-child(4) {
      cursor: pointer;
      max-width: 430px;
   }
   &:nth-child(5) {
      max-width: 120px;
   }

   svg {
      transition: rotate 0.3s;
      width: 20px;
      height: 20px;
      rotate: ${({ $active, $up }) => {
         if ($active && $up) {
            return '0'
         } else if ($active && !$up) {
            return '180deg'
         } else {
            return '0'
         }
      }};
   }
`
