import styled from 'styled-components'
import { colors } from 'enums'
import { CSSObjectWithLabel, ControlProps } from 'react-select'
export const TopInputsWrap = styled.div`
   padding: 20px;
   display: flex;
   align-items: center;
`
export const InputWrap = styled.div`
   max-width: 880px;
   width: 100%;
   display: flex;
   position: relative;
   flex-direction: column;
   gap: 8px;
   p {
      font-weight: 600;
      font-size: 14px;
   }
   span {
      position: absolute;
      color: ${colors.red};
      font-size: 12px;
      bottom: -14px;
   }
`
export const CheckBoxWrap = styled.div`
   margin-left: 20px;
`
export const CheckBoxTitle = styled.p`
   font-size: 14px;
   font-weight: 600;
   display: block;
   margin: 0 0 4px 10px;
`
export const CheckBoxList = styled.ul`
   list-style: none;
   display: flex;
   align-items: center;
   gap: 10px;
   li {
      display: flex;
      align-items: center;
   }
`
export const selectStyles = {
   control: (
      provided: CSSObjectWithLabel,
      state: ControlProps<{ label: string; value: string }>
   ) => ({
      ...provided,
      backgroundColor: 'transparent',
      width: '100%',
      height: '44px',
      borderRadius: '8px',
      boxShadow: 'none',
      border:
         state.isFocused || state.menuIsOpen
            ? `1px solid ${colors.black}`
            : `1px solid ${colors.lighter_grey}`,
      '&:hover': {
         border: state.isFocused || state.menuIsOpen ? '1px solid #222222' : undefined
      }
   }),
   dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided
      // display: 'none'
   }),
   indicatorSeparator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none'
   })
}
