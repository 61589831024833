import styled from 'styled-components'
import { FONT } from '../../utils'

export const HeaderText = styled.p`
   ${FONT({ weight: '700', size: '24px', align: 'center' })}
   padding-top: 24px;
`

export const Text = styled.p`
   ${FONT({ weight: '400', size: '16px', align: 'center' })}
`
