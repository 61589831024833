import { call, put, takeLatest } from 'redux-saga/effects'
import { getAccessToken } from 'store/user'
import { i18n } from '../../config'
import { ELocales } from '../../enums'
import { TDataWrapper, TResponse } from '../types'
import { seoActions } from './actions'
import { ApiSeoService } from './api.service'
import { TEditPagesPayload, TGetSeoPagesPayload } from './types'

function* getPagesWorker({ payload }: TDataWrapper<TGetSeoPagesPayload>) {
   yield put(seoActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiSeoService, ApiSeoService.getPages], {
         ...payload,
         token,
         lang: i18n.language as ELocales
      })

      if (response.data.data) {
         yield put(seoActions.SET_PAGES({ pages: response.data.data }))
      }
   } catch (e) {
      console.log(e)
   }

   yield put(seoActions.SET_STATE({ loading: false }))
}

function* editPagesWorker({ payload }: TDataWrapper<TEditPagesPayload>) {
   yield put(seoActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const { pages } = payload

      for (const page of pages) {
         yield call([ApiSeoService, ApiSeoService.editPage], {
            token,
            page,
            lang: i18n.language as ELocales
         })
      }
   } catch (e) {
      console.log(e)
   }
   yield put(seoActions.SET_STATE({ loading: false }))
}

export function* seoWatcher() {
   yield takeLatest(seoActions.GET_PAGES, getPagesWorker)
   yield takeLatest(seoActions.EDIT_PAGES, editPagesWorker)
}
