import { AdminPanelContainer, Pagination } from 'components'
import { Header } from 'pages/Calculator/components'
import { useTranslation } from 'react-i18next'
import { CreateButton, DeleteBtn, QuestionsList } from './styled'
import { FiPlus } from 'react-icons/fi'
import { QuestionCategoriesService, useGetQuestionCategories } from 'store'
import { i18n } from 'config'
import { MdDeleteForever } from 'react-icons/md'
import { useState } from 'react'
import { ModalMaterial } from 'components'
import { ModalWindow } from './components'
import { useTypedSelector } from 'hooks'
import { toast } from 'react-toastify'

const LIMIT = 20

export const CalculatorCategory = () => {
   const [page, setPage] = useState(1)
   const { t } = useTranslation()
   const { questionsCategories, mutate } = useGetQuestionCategories({
      limit: LIMIT,
      skip: (page - 1) * LIMIT
   })
   const lang = i18n.language
   const [visibleModal, setVisiblemodal] = useState(false)
   const [id, setId] = useState('')
   const { accessToken } = useTypedSelector((state) => state.user)
   const closeModal = () => {
      setId('')
      setVisiblemodal(false)
   }

   const openModal = (id?: string) => {
      setId(id || '')
      setVisiblemodal(true)
   }
   const onDeleteClick = async (id: string) => {
      try {
         await QuestionCategoriesService.deleteQuestionCategory(id, accessToken || '')
         mutate()
         toast.info(t('successfullyDeleted'))
      } catch (error) {
         toast.error(t('anErrorHasOccurred'))
      }
   }
   return (
      <AdminPanelContainer
         Header={
            <Header
               title={<h2>{t('categoriesForTheCalculator')}</h2>}
               button={
                  <CreateButton onClick={() => openModal()}>
                     <FiPlus /> {t('create')}
                  </CreateButton>
               }
            />
         }>
         <ModalMaterial close={closeModal} isOpen={visibleModal}>
            <ModalWindow id={id || undefined} close={closeModal} mutate={mutate} />
         </ModalMaterial>
         <QuestionsList>
            {questionsCategories?.data?.data?.map((item) => (
               <li key={item._id}>
                  {item.title.find((el) => el.lang === lang)?.value}
                  <DeleteBtn onClick={() => onDeleteClick(item._id)}>
                     <MdDeleteForever />
                  </DeleteBtn>
               </li>
            ))}
         </QuestionsList>
         {questionsCategories?.data?.meta &&
            questionsCategories?.data?.meta.totalCount / LIMIT > 1 && (
               <Pagination
                  onPageChange={(page) => setPage(page.selected + 1)}
                  page={page - 1}
                  pageCount={
                     questionsCategories?.data.meta
                        ? Number(
                             (
                                (+questionsCategories?.data?.meta?.totalCount as number) /
                                LIMIT
                             ).toFixed()
                          )
                        : 1
                  }
               />
            )}
      </AdminPanelContainer>
   )
}
