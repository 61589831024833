import styled from 'styled-components'
import { colors } from '../../enums'
import { FONT } from '../../utils'

export const HoverAbsoluteContainer = styled.div`
   position: absolute;
   width: 323px;
   bottom: 15px;
   right: -305px;
`

export const RelativeContainer = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
`

export const HoverContainer = styled.div`
   position: absolute;
   display: flex;
   flex-direction: column;
   height: auto;
   padding: 18px;
   ${FONT({ color: colors.lighter_black })}
   background-color: ${colors.solid_white};
   box-shadow: 0px 10px 20px rgba(135, 135, 135, 0.16);
   z-index: 2;
`

export const Item = styled.p``

export const Container = styled.div`
   width: 27px;
   height: 27px;
   border-radius: 4px;

   background-color: ${colors.black};
   ${FONT({ color: colors.solid_white, size: '14px' })}
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   position: relative;

   & ${HoverContainer} {
      display: none;
   }

   &:hover ${HoverContainer} {
      display: block;
   }
`
