import { t } from 'i18next'
import { FC, useMemo, useEffect } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   SubPagesBar,
   TSection
} from '../../../../../../components'
import { Container } from '../../../../../../components/ContentBlock/styled'
import { useTypedSelector } from '../../../../../../hooks'
import { getMainProductSelector, productActions } from '../../../../../../store'
import { Dots, FlexWrapper, Text } from './styled'

export const EditCategoryProductCharacteristicIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { product } = useTypedSelector(getMainProductSelector)

   const { id } = useParams()

   const Events = {
      backButtonClickHandler: () => {
         navigate(`/catalog/edit/${id as string}`)
      },
      deleteProduct: () => {},
      saveButtonClickHandler: () => {}
   }

   const Requests = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: id as string }))
      }
   }

   const Sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/catalog/edit/${id as string}`)
            }
         },
         {
            title: t('characteristics'),
            active: true
         },
         {
            title: t('related.orders'),
            onClickHandler: () => {
               navigate(`/catalog/related/${id as string}`)
            }
         },
         {
            title: t('variations'),
            onClickHandler: () => {
               navigate(`/catalog/variations/${id as string}`)
            }
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getProduct()
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('edit.products')}
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
               buttonsList={
                  <>
                     {true && (
                        <Button theme="red" height={46} onClick={Events.deleteProduct}>
                           {t('delete.product')}
                        </Button>
                     )}
                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }>
         <SubPagesBar sections={Sections} />
         <Container>
            <FlexWrapper>
               {product?.description.attributes.map((item, index) => (
                  <FlexContainer
                     width="335px"
                     justify="space-between"
                     align="flex-end"
                     key={index}>
                     <Text>{item.key}</Text>
                     <Dots />
                     <Text>{item.value}</Text>
                  </FlexContainer>
               ))}
            </FlexWrapper>
         </Container>
      </AdminPanelContainer>
   )
}
