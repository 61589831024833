import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, KeywordsInput, Textarea } from '../../../../../../components'
import { TSeoPage } from '../../../../../../store'
import { Container } from './styled'

type TPageCard = {
   page: TSeoPage
   onChange?: (page: TSeoPage) => void
}

const PageCard: FC<TPageCard> = ({ page, onChange }) => {
   const { t } = useTranslation()

   const [form, setForm] = useState<TSeoPage>(page)

   const Events = {
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setForm({ ...form, [input.name]: input.value })
      },
      onKeywordsChangeHandler: (metaKeywords: string[]) => {
         setForm({ ...form, metaKeywords })
      }
   }

   useEffect(() => {
      onChange && onChange(form)
   }, [form])

   return (
      <Container>
         <Input
            name="metaTitle"
            width="100%"
            label={t(form.page)}
            value={form.metaTitle}
            onChange={Events.onChange}
            placeholder={t('title')}
         />
         <KeywordsInput
            label={t('meta.keywords')}
            value={form.metaKeywords}
            onChange={Events.onKeywordsChangeHandler}
         />
         <Textarea
            name="metaDescription"
            width="100%"
            height="140px"
            label={t('meta.description')}
            onChange={Events.onChange}
            placeholder={t('description')}>
            {form.metaDescription}
         </Textarea>
      </Container>
   )
}

export default PageCard
