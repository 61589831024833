import { PayloadAction } from '@reduxjs/toolkit'
import { i18n } from 'config'
import { ELocales } from 'enums'
import { call, put, takeLatest } from 'redux-saga/effects'
import { TResponse } from 'store/types'
import { getAccessToken } from 'store/user'
import { moderationActions } from './action'
import { ApiModerationService } from './api.service'
import { MODERATION_RESPONSE } from './consts'
import {
   TApproveChangesBulkPayload,
   TModerationApprovePayload,
   TModerationDeclinePayload,
   TModerationGetChangedProductPayload,
   TModerationPatchChangesPayload,
   TModerationPayload
} from './types'

function* getChangedProductsWorker({ payload }: PayloadAction<TModerationPayload>) {
   yield put(moderationActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiModerationService, ApiModerationService.getChangedProducts],
         {
            ...payload,
            token,
            lang: i18n.language as ELocales
         }
      )

      if (response.data) yield put(moderationActions.SET_CHANGED_PRODUCTS(response.data))
   } catch (e) {}

   yield put(
      moderationActions.SET_STATE({ loading: false, response: MODERATION_RESPONSE.DONE })
   )
}

function* approveChangesWorker({ payload }: PayloadAction<TModerationApprovePayload>) {
   yield put(moderationActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiModerationService, ApiModerationService.approveChanges], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(
      moderationActions.SET_STATE({
         loading: false,
         response: MODERATION_RESPONSE.EDITED
      })
   )
}

function* declineChangesWorker({ payload }: PayloadAction<TModerationDeclinePayload>) {
   yield put(moderationActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiModerationService, ApiModerationService.declineChanges], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(
      moderationActions.SET_STATE({
         loading: false,
         response: MODERATION_RESPONSE.EDITED
      })
   )
}

function* getChangedProductWorker({
   payload
}: PayloadAction<TModerationGetChangedProductPayload>) {
   yield put(moderationActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiModerationService, ApiModerationService.getChangedProduct],
         {
            ...payload,
            token,
            lang: i18n.language as ELocales
         }
      )

      if (response.data)
         yield put(moderationActions.SET_CHANGED_PRODUCT(response.data.data))
   } catch (e) {}

   yield put(
      moderationActions.SET_STATE({ loading: false, response: MODERATION_RESPONSE.DONE })
   )
}

function* patchChangesWorker({ payload }: PayloadAction<TModerationPatchChangesPayload>) {
   yield put(moderationActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiModerationService, ApiModerationService.patchChanges], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(
      moderationActions.SET_STATE({
         loading: false,
         response: MODERATION_RESPONSE.EDITED
      })
   )
}

function* approveChangesBulkWorker({
   payload
}: PayloadAction<TApproveChangesBulkPayload>) {
   yield put(moderationActions.SET_STATE({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiModerationService, ApiModerationService.approveChangesBulk], {
         ...payload,
         token
      })

      const response: TResponse = yield call(
         [ApiModerationService, ApiModerationService.getChangedProducts],
         {
            limit: payload.limit,
            page: payload.page,
            token,
            lang: i18n.language as ELocales
         }
      )

      if (response.data) yield put(moderationActions.SET_CHANGED_PRODUCTS(response.data))

      yield put(
         moderationActions.SET_STATE({
            loading: false,
            response: MODERATION_RESPONSE.EDITED
         })
      )
   } catch (e) {
      console.log(e)
      yield put(
         moderationActions.SET_STATE({
            loading: false,
            response: MODERATION_RESPONSE.ERROR
         })
      )
   }
}

export function* moderationWatcher() {
   yield takeLatest(moderationActions.getChangedProducts, getChangedProductsWorker)
   yield takeLatest(moderationActions.approveChanges, approveChangesWorker)
   yield takeLatest(moderationActions.declineChanges, declineChangesWorker)
   yield takeLatest(moderationActions.getChangedProduct, getChangedProductWorker)
   yield takeLatest(moderationActions.patchChanges, patchChangesWorker)
   yield takeLatest(moderationActions.approveChangesBulk, approveChangesBulkWorker)
}
