import { api } from 'config'
import { InfoWrap, PriceWrap, ProductCardWrap, ToppWrap } from './styled'
import { TProductCardsProps } from './types'
import { CiImageOn } from 'react-icons/ci'
import { AiOutlineClose } from 'react-icons/ai'
import { memo } from 'react'

export const ProductCard = memo(({ type, product, setProducts }: TProductCardsProps) => {
   const onDeleteClick = () => {
      setProducts((prev) => prev.filter((item) => item.type !== type))
   }
   return (
      <ProductCardWrap>
         <ToppWrap>
            {product?.preview ? (
               <img alt="product_image" src={`${api.preview}/${product?.preview}`} />
            ) : (
               <CiImageOn />
            )}
            <button onClick={onDeleteClick}>
               <AiOutlineClose />
            </button>
         </ToppWrap>
         <InfoWrap>
            <span>{product?.barcode}</span>
            <span>{product?.description.title}</span>
         </InfoWrap>
         <PriceWrap>
            {product?.discountPrice && (
               <span>
                  {product?.discountPrice} ₼ /{product?.unit}
               </span>
            )}

            <span>
               {product?.price} ₼ /{product?.unit}
            </span>
         </PriceWrap>
      </ProductCardWrap>
   )
})
