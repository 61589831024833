import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { assets } from '../../../../../../../../assets'
import {
   FlexContainer,
   Label,
   PhotoLoaderButton,
   ProductPhoto
} from '../../../../../../../../components'
import { api } from '../../../../../../../../config'
import { colors } from '../../../../../../../../enums'
import { PhotosProps, ProductImage } from './types'

export const Photos: FC<PhotosProps> = ({
   gallery,
   preview,
   uploadPhoto,
   deletePhoto
}) => {
   const { t } = useTranslation()

   useEffect(() => {}, [])

   const Events = {
      onChangeImage: (e: any) => {
         uploadPhoto(e)
      },
      onDeleteImage: (order: number) => {
         deletePhoto(order)
      }
   }

   return (
      <FlexContainer
         direction="column"
         style={{
            borderTop: `1px solid ${colors.table_grey}`,
            marginTop: '8px',
            padding: '24px 30px'
         }}>
         <Label>{t('photo')}</Label>
         <FlexContainer gap="28px">
            <ProductPhoto>
               <img
                  src={`${api.preview}product/preview/${preview}`}
                  alt="product preview"
                  className="product-photo"
               />
            </ProductPhoto>
            {gallery?.map((photo) => (
               <ProductPhoto key={photo.order}>
                  <img
                     src={
                        photo?.preview
                           ? photo.preview
                           : `${api.img}product/gallery/${photo?.image}`
                     }
                     alt="product"
                     className="product-photo"
                  />
                  <div
                     className="product-photo-delete"
                     onClick={() => Events.onDeleteImage(photo.order)}></div>
               </ProductPhoto>
            ))}
            <PhotoLoaderButton htmlFor="productImage">
               <img src={assets.PLUS_BLACK} alt="product preview" className="plus" />
            </PhotoLoaderButton>
            <input
               style={{ margin: '10px 0', display: 'none' }}
               id="productImage"
               onChange={Events.onChangeImage}
               type="file"
               accept="image/*"
            />
         </FlexContainer>
      </FlexContainer>
   )
}
