import moment from 'moment'
import { ProductItemStyled } from './styled'
import { api } from 'config'
import { EditButton } from 'pages/Calculator/components'
import { TProductItemProps } from './types'
import { Checkbox } from '@mui/material'
import { memo } from 'react'
import { CiImageOn } from 'react-icons/ci'
import { useTypedSelector } from 'hooks'
import { getMainCategorySelector } from 'store'

export const ProductItem = memo(
   ({ onSelectedProductClick, item, selectedProduct }: TProductItemProps) => {
      const { categories } = useTypedSelector(getMainCategorySelector)

      return (
         <ProductItemStyled>
            <div>
               <Checkbox
                  // disabled={
                  //    !!selectedProduct && selectedProduct?._id !== item._id
                  // }
                  checked={selectedProduct?._id === item?._id}
                  style={{ width: '25px' }}
                  onChange={() => onSelectedProductClick(item)}
               />
            </div>
            <div>{item?.barcode}</div>
            <div>
               {!item.preview ? (
                  <CiImageOn />
               ) : (
                  <img alt="preview" src={`${api.preview}/${item?.preview}`} />
               )}
            </div>
            <div>
               <p>{item?.description?.title}</p>
            </div>
            <div>{moment(item?.createdAt).format('DD.MM.YYYY HH:mm')}</div>
            <div>
               {categories.data?.find((el) => el._id === item?.category._id)?.title}
            </div>
            <div>
               {item?.price} ₼ /{item?.unit}
            </div>
            <div>
               <EditButton link={`/products/page=0/${item?._id}`} />
            </div>
         </ProductItemStyled>
      )
   }
)
