import { FC, SyntheticEvent, useEffect, useState } from 'react'
import {
   ButtonsContainer,
   CheckBoxHeaderContentContainer,
   HeaderContentContainer,
   NewReviewCount,
   StyledText,
   Styles
} from './styled'
import { TTableProps } from './types'
import { useTable } from 'react-table'
import { ApproveButton, DeclineButton, EditButton, RemoveButton } from '../Button'
import { TableUtils } from '.'
import { Checkbox } from '../Checkbox'
import { t } from 'i18next'
import { FlexContainer } from 'components/Styled'

const Table: FC<TTableProps> = ({
   columns,
   data,
   editable = false,
   removeable = false,
   approvable = false,
   declineble = false,
   visability = true,
   approveButtonTheme,
   headerText = 'visability',
   checkedHeader,
   setCheckedHeader,
   checkboxClickHandler,
   headerCheckbox,
   rowClickHandler,
   editClickHandler,
   approveClickHandler,
   removeClickHandler,
   declineClickHandler,
   primaryClickHandler,
   sortBy = {
      id: null,
      desk: false
   }
}) => {
   if (!data) {
      return <></>
   }

   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable<any>({
         columns,
         data
      })

   const Events = {
      onSelectCheckboxClickHandler: (e: SyntheticEvent, index: number) => {
         const checkboxes = document.querySelectorAll('.selectColumnCheckbox')
         let hasCheckboxesActiveState = false
         const checkedItemsIndex: number[] = []

         Array.from(checkboxes).forEach((checkbox) => {
            if ((checkbox as HTMLInputElement).checked) {
               hasCheckboxesActiveState = true
               checkedItemsIndex.push(TableUtils.getRowIndex(checkbox as HTMLElement))
            }
         })

         checkboxClickHandler &&
            checkboxClickHandler(e, hasCheckboxesActiveState, checkedItemsIndex, index)
      },
      onPrimarySelectCheckboxClickHandler: (e: SyntheticEvent, index: number) => {
         const checkboxes = document.querySelectorAll('.primaryColumnCheckbox')
         let hasCheckboxesActiveState = false
         const checkedItemsIndex: number[] = []
         const res: { checked: boolean; index: number }[] = []

         Array.from(checkboxes).forEach((checkbox) => {
            res.push({
               checked: (checkbox as HTMLInputElement).checked,
               index: TableUtils.getRowIndex(checkbox as HTMLElement)
            })
            if ((checkbox as HTMLInputElement).checked) {
               hasCheckboxesActiveState = true
               checkedItemsIndex.push(TableUtils.getRowIndex(checkbox as HTMLElement))
            }
         })

         primaryClickHandler &&
            primaryClickHandler(e, hasCheckboxesActiveState, res, index)
      },

      rowClickHandler: (e: SyntheticEvent) => {
         rowClickHandler &&
            rowClickHandler(TableUtils.getRowIndex(e.target as HTMLElement))
      },

      removeClickListener: (e: SyntheticEvent) => {
         removeClickHandler &&
            removeClickHandler(e, TableUtils.getRowIndex(e.target as HTMLElement))
      },

      declineClickListener: (e: SyntheticEvent) => {
         declineClickHandler &&
            declineClickHandler(e, TableUtils.getRowIndex(e.target as HTMLElement))
      },

      editClickListener: (e: SyntheticEvent) => {
         editClickHandler &&
            editClickHandler(e, TableUtils.getRowIndex(e.target as HTMLElement))
      },
      approveClickListener: (e: SyntheticEvent) => {
         approveClickHandler &&
            approveClickHandler(e, TableUtils.getRowIndex(e.target as HTMLElement))
      },

      headerCheckbox: (e: SyntheticEvent, index?: number) => {
         const checkboxes = document.querySelectorAll('.selectColumnCheckbox')
         let hasCheckboxesActiveState = false
         const checkedItemsIndex: number[] = []

         Array.from(checkboxes).forEach((checkbox) => {
            hasCheckboxesActiveState = true
            setCheckedHeader && setCheckedHeader(true)
            checkedItemsIndex.push(TableUtils.getRowIndex(checkbox as HTMLElement))
         })

         rows
            .filter((item) => !item.original.disabled)
            .map((item) => (item.original.isChecked = (e.target as any).checked))

         headerCheckbox &&
            headerCheckbox(
               e,
               hasCheckboxesActiveState,
               checkedItemsIndex,
               index as number
            )
      }
   }

   useEffect(() => {
      !setCheckedHeader &&
         rows
            .filter((item) => !item.original.disabled)
            .map((item) => (item.original.isChecked = false))
   }, [])

   useEffect(() => {
      setCheckedHeader && rows.map((item) => (item.original.isChecked = false))
   }, [])

   useEffect(() => {
      setCheckedHeader && rows.map((item) => (item.original.isChecked = !checkedHeader))
   }, [checkedHeader])

   return (
      <Styles>
         <table {...getTableProps()}>
            <thead>
               {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                     {checkboxClickHandler && !headerCheckbox && <th></th>}
                     {headerCheckbox && (
                        <th
                           className="checkboxHeader visability"
                           style={{
                              paddingTop: '30px',
                              paddingLeft: '10px'
                           }}>
                           {visability && <StyledText>{t(headerText)}</StyledText>}
                           <Checkbox
                              checked={checkedHeader}
                              onChange={Events.headerCheckbox}
                           />
                        </th>
                     )}

                     {headerGroup.headers.map((column, index) => (
                        <th
                           {...column.getHeaderProps({
                              style: { width: column.width }
                           })}
                           onClick={(e: SyntheticEvent) => {
                              if (column.sortToggleHandler) {
                                 column.sortToggleHandler(
                                    !(sortBy.id && sortBy.desc)
                                       ? (column.id as string)
                                       : '',
                                    sortBy.id ? (sortBy.desc ? '' : 1) : -1
                                 )
                              }
                           }}>
                           {headerCheckbox && (
                              <CheckBoxHeaderContentContainer>
                                 {column.render('Header')}
                              </CheckBoxHeaderContentContainer>
                           )}
                           {!headerCheckbox && (
                              <HeaderContentContainer>
                                 {column.render('Header')}
                              </HeaderContentContainer>
                           )}
                        </th>
                     ))}

                     {(editable || removeable || approvable || declineble) && (
                        <th className="buttonHeader"></th>
                     )}
                  </tr>
               ))}
            </thead>

            <tbody {...getTableBodyProps()}>
               {rows.map((row, index) => {
                  prepareRow(row)

                  if (
                     row.cells.map(
                        (item) =>
                           item.value && item.value.props && item.value.props.children
                     )[2] == 'Скрыт'
                  ) {
                     return (
                        <tr
                           {...row.getRowProps()}
                           className="row"
                           onClick={Events.rowClickHandler}
                           style={{
                              cursor: rowClickHandler && 'pointer'
                           }}>
                           {checkboxClickHandler && (
                              <td
                                 style={{
                                    backgroundColor: '#FFF5F5'
                                 }}>
                                 <Checkbox
                                    className="selectColumnCheckbox"
                                    onChange={(e) =>
                                       Events.onSelectCheckboxClickHandler(e, index)
                                    }
                                    key={index}
                                    checked={row.original.isChecked}
                                 />
                              </td>
                           )}
                           {row.cells.map((cell) => {
                              return (
                                 <td
                                    style={{
                                       backgroundColor: '#FFF5F5'
                                    }}
                                    {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                 </td>
                              )
                           })}

                           {(editable || removeable || approvable || declineble) && (
                              <td style={{ backgroundColor: '#FFF5F5' }}>
                                 <ButtonsContainer>
                                    {approvable && declineble && (
                                       <FlexContainer
                                          justify="flex-end"
                                          gap="18px"
                                          direction="row">
                                          {approvable && (
                                             <ApproveButton
                                                theme={approveButtonTheme}
                                                onClick={Events.approveClickListener}
                                             />
                                          )}
                                       </FlexContainer>
                                    )}

                                    {editable && (
                                       <EditButton onClick={Events.editClickListener} />
                                    )}

                                    {removeable && (
                                       <RemoveButton
                                          onClick={Events.removeClickListener}
                                       />
                                    )}
                                 </ButtonsContainer>
                              </td>
                           )}
                        </tr>
                     )
                  } else if (
                     row.cells.map(
                        (item) =>
                           item.value && item.value.props && item.value.props.children
                     )[2] == 'Опубликован'
                  ) {
                     return (
                        <tr
                           {...row.getRowProps()}
                           className="row"
                           onClick={Events.rowClickHandler}
                           style={{
                              cursor: rowClickHandler && 'pointer'
                           }}>
                           {checkboxClickHandler && (
                              <td>
                                 <Checkbox
                                    className="selectColumnCheckbox"
                                    onChange={(e) =>
                                       Events.onSelectCheckboxClickHandler(e, index)
                                    }
                                    key={index}
                                    checked={row.original.isChecked}
                                 />
                              </td>
                           )}
                           {row.cells.map((cell) => {
                              return (
                                 <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              )
                           })}

                           {(editable || removeable || approvable || declineble) && (
                              <td>
                                 <ButtonsContainer>
                                    {approvable && declineble && (
                                       <FlexContainer
                                          justify="flex-end"
                                          gap="18px"
                                          direction="row">
                                          {declineble && (
                                             <DeclineButton
                                                onClick={Events.declineClickListener}
                                             />
                                          )}
                                       </FlexContainer>
                                    )}

                                    {editable && (
                                       <EditButton onClick={Events.editClickListener} />
                                    )}

                                    {removeable && (
                                       <RemoveButton
                                          onClick={Events.removeClickListener}
                                       />
                                    )}
                                 </ButtonsContainer>
                              </td>
                           )}
                        </tr>
                     )
                  } else if (row.original.approved) {
                     const approved = row.original.approved.props.children
                     return (
                        <tr
                           {...row.getRowProps()}
                           className="row"
                           onClick={Events.rowClickHandler}
                           style={{ cursor: rowClickHandler && 'pointer' }}>
                           {checkboxClickHandler && (
                              <td style={{ paddingLeft: '0px' }}>
                                 <Checkbox
                                    className="selectColumnCheckbox"
                                    onChange={(e) =>
                                       Events.onSelectCheckboxClickHandler(e, index)
                                    }
                                    key={index}
                                    checked={row.original.isChecked}
                                 />
                              </td>
                           )}
                           {row.cells.map((cell) => {
                              return (
                                 <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              )
                           })}

                           {(editable || removeable || approvable || declineble) && (
                              <td>
                                 <ButtonsContainer>
                                    {approved == null && approvable && declineble && (
                                       <FlexContainer
                                          padding="0 60px 0 0"
                                          gap="18px"
                                          width="150px"
                                          direction="row">
                                          {(!approved || approved == null) &&
                                             approvable && (
                                                <ApproveButton
                                                   theme={approveButtonTheme}
                                                   onClick={Events.approveClickListener}
                                                />
                                             )}

                                          {(approved || approved == null) &&
                                             declineble && (
                                                <DeclineButton
                                                   onClick={Events.declineClickListener}
                                                />
                                             )}
                                       </FlexContainer>
                                    )}

                                    {editable && (
                                       <EditButton onClick={Events.editClickListener} />
                                    )}

                                    {removeable && (
                                       <RemoveButton
                                          onClick={Events.removeClickListener}
                                       />
                                    )}
                                 </ButtonsContainer>
                              </td>
                           )}
                        </tr>
                     )
                  } else if (
                     row.original.disabled == true ||
                     row.original.disabled == false
                  ) {
                     const disabled = row.original.disabled
                     return (
                        <tr
                           {...row.getRowProps()}
                           className="row"
                           onClick={Events.rowClickHandler}
                           style={{ cursor: rowClickHandler && 'pointer' }}>
                           {checkboxClickHandler && (
                              <td style={{ paddingLeft: '0px' }}>
                                 {!disabled && (
                                    <Checkbox
                                       className="selectColumnCheckbox"
                                       onChange={(e) =>
                                          Events.onSelectCheckboxClickHandler(e, index)
                                       }
                                       key={index}
                                       checked={row.original.isChecked}
                                    />
                                 )}
                              </td>
                           )}
                           {row.cells.map((cell) => {
                              return (
                                 <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              )
                           })}

                           {(editable || removeable || approvable || declineble) && (
                              <td>
                                 <ButtonsContainer>
                                    {approvable && declineble && (
                                       <FlexContainer
                                          justify="flex-end"
                                          gap="18px"
                                          direction="row">
                                          {approvable && (
                                             <ApproveButton
                                                theme={approveButtonTheme}
                                                onClick={Events.approveClickListener}
                                             />
                                          )}

                                          {declineble && (
                                             <DeclineButton
                                                onClick={Events.declineClickListener}
                                             />
                                          )}
                                       </FlexContainer>
                                    )}

                                    {editable && (
                                       <EditButton onClick={Events.editClickListener} />
                                    )}

                                    {removeable && (
                                       <RemoveButton
                                          onClick={Events.removeClickListener}
                                       />
                                    )}
                                 </ButtonsContainer>
                              </td>
                           )}
                        </tr>
                     )
                  } else {
                     const hasNewReviewsRow = row.original.newReviews

                     const isStatusNew = row.original.isNewStatus
                     return (
                        <tr
                           className={`row ${
                              hasNewReviewsRow || isStatusNew ? 'has-new-reviews' : ''
                           }`}
                           {...row.getRowProps()}
                           onClick={Events.rowClickHandler}
                           style={{ cursor: rowClickHandler && 'pointer' }}>
                           {checkboxClickHandler && (
                              <td style={{ paddingLeft: '0px' }}>
                                 <Checkbox
                                    className="selectColumnCheckbox"
                                    onChange={(e) =>
                                       Events.onSelectCheckboxClickHandler(e, index)
                                    }
                                    key={index}
                                    checked={row.original.isChecked}
                                 />
                              </td>
                           )}

                           {row.cells.map((cell, index) => {
                              if (cell.column.Header?.toString() == 'Главный') {
                                 return (
                                    <td>
                                       <Checkbox
                                          className="primaryColumnCheckbox"
                                          onChange={(e) =>
                                             Events.onPrimarySelectCheckboxClickHandler(
                                                e,
                                                index
                                             )
                                          }
                                          key={index}
                                          checked={cell.value.props.children}
                                       />
                                    </td>
                                 )
                              }

                              return (
                                 <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              )
                           })}

                           {(editable || removeable || approvable || declineble) && (
                              <td>
                                 <ButtonsContainer>
                                    {approvable && declineble && (
                                       <FlexContainer
                                          // padding="0 0px 0 0"
                                          justify="flex-end"
                                          gap="18px"
                                          // width="150px"
                                          direction="row">
                                          {approvable && (
                                             <ApproveButton
                                                theme={approveButtonTheme}
                                                onClick={Events.approveClickListener}
                                             />
                                          )}

                                          {declineble && (
                                             <DeclineButton
                                                onClick={Events.declineClickListener}
                                             />
                                          )}
                                       </FlexContainer>
                                    )}
                                    {hasNewReviewsRow ? (
                                       <NewReviewCount>{hasNewReviewsRow}</NewReviewCount>
                                    ) : null}
                                    {editable && (
                                       <EditButton onClick={Events.editClickListener} />
                                    )}

                                    {removeable && (
                                       <RemoveButton
                                          onClick={Events.removeClickListener}
                                       />
                                    )}
                                 </ButtonsContainer>
                              </td>
                           )}
                        </tr>
                     )
                  }
               })}
            </tbody>
         </table>
      </Styles>
   )
}

export default Table
