import { colors } from 'enums'
import styled from 'styled-components'

export const Container = styled.div`
   margin: 0 auto;

   display: flex;
   flex-direction: column;
   position: relative;
   font-size: 12px;
   border: 1px solid ${colors.table_grey};
   border-radius: 6px;
   padding: 12px 8px;
   min-width: 138px;
   width: 260px;
   height: 190px;
   @media (min-width: 1920px) {
      width: 430px;
   }
   .product-delete {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
   }
   .product-img {
      width: 46px;
      height: 46px;
      object-fit: contain;
      align-self: center;
   }
   .product-code {
      font-weight: 500;
      padding: 12px 0 6px;
   }
   .product-name {
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
   }
   .product-price {
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      &__old {
         color: ${colors.grey};
         text-decoration: line-through;
      }
      &__new {
      }
   }
`
