import { useTranslation } from 'react-i18next'
import { BottomWrap, CloseBtn, ModalproductsWrap, ProductsList } from './styled'
import { TFilterParams, TModalproductsProps } from './types'
import { memo, useEffect, useState } from 'react'
import { useTypedSelector } from 'hooks'
import { ApiProductService, categoryActions } from 'store'
import { ProductFilterBar, ProductItem } from './components'
import {
   TGetProductsRequestPayload,
   TGetRecommendedRequest,
   TProduct
} from 'store/product/types'
import { i18n } from 'config'
import locales from 'enums/locales'
import useSWRInfinite from 'swr/infinite'
import { RelativePreloader } from 'components'
import { PreloaderContainer } from 'components/SelectProductPopup/styled'
import { useInView } from 'react-intersection-observer'
import { ButtonSave } from 'pages/Calculator/Pages/CreateCalculator/styled'
import { ELocales } from 'enums'
import { defaultParams } from './helpers'
import useSWR from 'swr'

export const Modalproducts = memo(
   ({ products, productType, setProducts, close }: TModalproductsProps) => {
      const { t, i18n } = useTranslation()
      const lang = i18n.language

      const { accessToken } = useTypedSelector((state) => state.user)
      const [selectedProduct, setSelectedProduct] = useState<TProduct | null>(null)
      const [params, setParams] = useState<TFilterParams>(defaultParams)

      const { categories } = useTypedSelector((state) => state.category)

      const { ref, inView } = useInView({
         threshold: 0.1
      })

      useEffect(() => {
         const firstCategory = categories.data.find(
            (cat) => cat._id === '64c1195c64a8f3ff537b893d'
         )
         firstCategory &&
            setParams((prev) => ({
               ...prev,
               category: { label: firstCategory?.title, value: firstCategory?._id }
            }))
      }, [categories])

      const action = async (params: TGetProductsRequestPayload) =>
         (await ApiProductService.getProducts(params)).data

      const recommendedReq = async (params: TGetRecommendedRequest['payload']) =>
         (await ApiProductService.getRecommended(params)).data

      const getKey = (pageIndex: number, previousPageData: any) => {
         const parentCategory = products[0]?.product?.category?._id ?? ''

         if (previousPageData && !previousPageData?.data?.length) return null
         return !products.length
            ? [
                 'getProducts',
                 {
                    limit: 20,
                    lang: i18n.language as locales,
                    order: params.order,
                    sortBy: params.sortBy,
                    query: params.query || undefined,
                    date_start: params.date_start,
                    date_end: params.date_end,
                    token: accessToken || '',
                    category: [
                       parentCategory ? parentCategory : params?.category?.value ?? ''
                    ],
                    page: pageIndex
                 }
              ]
            : null
      }

      const parentKey = () => {
         const product = products[0]?.product ?? {}

         return products.length
            ? [
                 'get-recommended-products',
                 {
                    _id: product?._id ?? '',
                    token: accessToken || '',
                    lang: lang as ELocales
                 }
              ]
            : null
      }

      // get products
      const { data, isLoading, setSize } = useSWRInfinite(
         getKey,
         //@ts-ignore
         ([, getParams]) => {
            if (typeof getParams === 'object') {
               return action(getParams)
            }
         },
         { revalidateOnFocus: false, parallel: true }
      )
      // get recommended products
      const { data: recommended, isLoading: recommendedLoading } = useSWR(
         parentKey,
         ([, getParams]) => {
            if (typeof getParams === 'object') {
               return recommendedReq(getParams)
            }
         },
         { revalidateOnFocus: false, parallel: true }
      )

      useEffect(() => {
         if (inView) {
            setSize((prevSize) => prevSize + 1)
         }
      }, [inView])

      // clear params before exit
      useEffect(() => {
         return () => {
            setSize(0)
            setSelectedProduct(null)
            setParams(defaultParams)
         }
      }, [])

      const onSelectedProductClick = (product: TProduct) => {
         setSelectedProduct(product)
      }

      const onAddClick = () => {
         if (productType && selectedProduct) {
            productType &&
               setProducts((prev) => [
                  ...prev,
                  { type: productType, product: selectedProduct }
               ])
            close()
         }
      }

      return (
         <ModalproductsWrap>
            <h2>{t('addProduct')}</h2>
            <ProductFilterBar params={params} setParams={setParams} />

            <ProductsList>
               {recommended?.length
                  ? recommended?.map((item) => (
                       <ProductItem
                          selectedProduct={selectedProduct}
                          item={item}
                          key={item._id}
                          onSelectedProductClick={onSelectedProductClick}
                       />
                    ))
                  : data?.flatMap((pageData) =>
                       pageData?.data?.map((item: TProduct) => (
                          <ProductItem
                             selectedProduct={selectedProduct}
                             item={item}
                             key={item._id}
                             onSelectedProductClick={onSelectedProductClick}
                          />
                       ))
                    )}
               {products[0]?.product && (
                  <ProductItem
                     selectedProduct={selectedProduct}
                     item={products[0]?.product}
                     key={products[0]?.product?._id}
                     onSelectedProductClick={onSelectedProductClick}
                  />
               )}
               <li
                  ref={ref}
                  style={{
                     visibility: 'hidden',
                     display: 'flex',
                     height: '100px',
                     width: '100%'
                  }}>
                  <div>Loading</div>
               </li>
            </ProductsList>

            {isLoading && (
               <PreloaderContainer>
                  <RelativePreloader size="50px" loading />
               </PreloaderContainer>
            )}
            <BottomWrap>
               <CloseBtn onClick={close}>{t('cancel')}</CloseBtn>
               <ButtonSave disabled={!selectedProduct} onClick={onAddClick}>
                  {t('add')}
               </ButtonSave>
            </BottomWrap>
         </ModalproductsWrap>
      )
   }
)
