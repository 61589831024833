import styled from 'styled-components'

import { TImage } from './types'

export const Image = styled.div<TImage>`
   display: inline-block;
   background-image: ${({ src }) => `url(${src})`};
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   height: 46px;
   width: 46px;
`
