import { ELocales } from 'enums'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { assets } from '../../../../../assets'
import { api } from '../../../../../config'
import { useLocalization, useTypedSelector } from '../../../../../hooks'
import { getMainProductSelector, productActions } from '../../../../../store'
import { TProduct } from '../../../../../store/product/types'
import { PAGE_LIMIT } from '../consts'
import { RelatedProductWrapper } from '../styled'

type DragAndDropProps = {
   productItem: TProduct
   page: number
}

export const DragAndDropProduct: FC<DragAndDropProps> = ({ productItem, page }) => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { product, promotionProducts } = useTypedSelector(getMainProductSelector)
   const [locale] = useLocalization()

   const Events = {
      onDeleteProduct: () => {
         Requests.editProduct()
         // Requests.getProducts()
      },
      onClickHandler: () => {
         navigate(`/products/page=${0}/${productItem._id}`)
      }
   }
   const Requests = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: productItem._id as string }))
      },
      getProducts: () => {
         dispatch(
            productActions.getProducts({
               limit: PAGE_LIMIT,
               page: 0,
               lang: locale as ELocales,
               hidden: 'false',
               discount: 1
            })
         )
      },
      editProduct: () => {
         dispatch(
            productActions.updateProduct({
               _id: productItem._id as string,
               data: {
                  discountPrice: 0
               }
            })
         )
      }
   }

   return (
      <RelatedProductWrapper>
         <img
            src={assets.CROSS}
            alt="delete"
            className="product-delete"
            onClick={Events.onDeleteProduct}
         />
         <img
            src={`${api.preview}${productItem?.preview}`}
            alt="product"
            className="product-img"
         />
         <div className="product-code">
            {productItem?.barcode ? productItem?.barcode : ''}
         </div>
         <div
            className="product-name"
            onClick={Events.onClickHandler}
            style={{ cursor: 'pointer' }}>
            {productItem?.description?.title}
         </div>
         <div className="product-price">
            {productItem?.discountPrice && (
               <div className="product-price__old">{`${productItem?.price} ₼/шт`} </div>
            )}
            <div className="product-price__new">
               {productItem?.discountPrice
                  ? productItem?.discountPrice
                  : productItem?.price}{' '}
               ₼/шт
            </div>
         </div>
      </RelatedProductWrapper>
   )
}
