import { LinkWrapper } from './styled'
import { MdEdit } from 'react-icons/md'

export const EditButton = ({ link }: { link: string }) => {
   return (
      <LinkWrapper to={link}>
         <MdEdit />
      </LinkWrapper>
   )
}
