import { api } from 'config'

export const MODERATION_URL = {
   getChangedProducts: `${api.public}v1/catalog/moderation`,
   getChangedProduct: `${api.public}v1/catalog/moderation`,
   approveChages: (id: string) => `${api.public}v1/catalog/moderation/${id}/approve`,
   declineChages: (id: string) => `${api.public}v1/catalog/moderation/${id}/reject`,
   patchChages: (id: string) => `${api.public}v1/catalog/moderation/${id}`,
   approveChagesBulk: `${api.public}v1/catalog/moderation/bulk/approve`
}
