import { colors } from 'enums'
import styled from 'styled-components'
import { FONT } from 'utils'

export const Container = styled.div`
   display: grid;
   width: 100%;
   height: 100%;
   grid-template-columns: 3fr 1fr;
`

export const ClickbleText = styled.p`
   width: 110px;
   padding-top: 20px;
   border-bottom: 2px solid ${colors.background_panel_grey};

   ${FONT({ weight: '600', size: '16px', color: '#637B96' })}

   cursor: pointer;

   &:hover {
      transition: 0.3s;
      border-bottom: 2px solid #637b96;
   }
`
