import { Alert, Snackbar } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AdminPanelContainer, Button, Header, SubPagesBar } from '../../../../components'
import { ELocales } from '../../../../enums'
import { useLocalization, useTypedSelector } from '../../../../hooks'
import { getSeoSelector, seoActions, TSeoPage } from '../../../../store'
import { useSubPagesPagination } from '../../hooks'
import { Container } from '../../styled'
import { PageCard } from './components'

const MetadataView: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()

   const [locale] = useLocalization()

   const { paginationSections } = useSubPagesPagination()

   const { pages, loading } = useTypedSelector(getSeoSelector)

   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const [form, setForm] = useState<TSeoPage[]>([
      {
         metaTitle: '',
         metaKeywords: [],
         metaDescription: '',
         page: 'Главная',
         lang: locale as ELocales
      },
      {
         metaTitle: '',
         metaKeywords: [],
         metaDescription: '',
         page: 'Категории',
         lang: locale as ELocales
      },
      {
         metaTitle: '',
         metaKeywords: [],
         metaDescription: '',
         page: 'Подкатегории',
         lang: locale as ELocales
      }
   ])

   const Events = {
      saveButtonClickHandler: () => {
         setAlertText('metadata.successfuly.saved')
         Requests.editPages()
         toogleIsAlertOpen(true)
      },
      onChangePageCartHandler: (page: TSeoPage) => {
         setForm((form) =>
            form.map((formPage) => {
               if (formPage._id == page._id) {
                  return page
               }
               return formPage
            })
         )
      }
   }

   const Requests = {
      getPages: () => {
         dispatch(seoActions.GET_PAGES({}))
      },
      editPages: () => {
         dispatch(seoActions.EDIT_PAGES({ pages: form }))
      }
   }

   useEffect(() => {
      setForm(pages)
   }, [pages])

   useEffect(() => {
      Requests.getPages()
   }, [])

   return (
      <>
         <AdminPanelContainer
            loading={loading}
            Header={
               <Header
                  title={t('SEO')}
                  buttonsList={
                     <>
                        <Button theme="green" onClick={Events.saveButtonClickHandler}>
                           {t('save')}
                        </Button>
                     </>
                  }
               />
            }>
            <SubPagesBar sections={paginationSections} />
            <Container gap="89px" direction="row">
               {pages
                  .filter((page) => page.page != 'seotext')
                  .map((page) => (
                     <PageCard
                        key={page._id}
                        page={page}
                        onChange={Events.onChangePageCartHandler}
                     />
                  ))}
            </Container>

            <Snackbar
               open={isAlertOpen}
               autoHideDuration={6000}
               onClose={() => toogleIsAlertOpen(false)}
               anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
               <Alert severity="success" sx={{ width: '100%' }}>
                  {t(`${alertText ? alertText : 'added'}`)}
               </Alert>
            </Snackbar>
         </AdminPanelContainer>
      </>
   )
}

export default MetadataView
