export const ActionTypes = {
   getSpecialists: 'specialist/GET_SPECIALISTS',
   getSpecialist: 'specialist/GET_SPECIALIST',
   createSpecialist: 'specialist/CREATE_SPECIALIST',
   editSpecialist: 'specialist/EDIT_SPECIALIST',
   deleteSpecialist: 'specialist/DELETE_SPECIALIST',
   getSpecialistsReviews: 'specialist/GET_SPECIALIST_REVIEWS',
   getReviewBySpecialist: `specialist/GET_REVIEW_SPECIALIST`,
   editSpecialistReview: `specialist/EDIT_SPECIALIST_REVIEW`
}
