import { t } from 'i18next'
import { FC, useMemo, SyntheticEvent, useState, useEffect } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   KeywordsInput,
   Popup,
   SubPagesBar,
   Textarea,
   TSection
} from '../../../../../../components'
import { useTypedSelector } from '../../../../../../hooks'
import { getMainWorkerSelector } from '../../../../../../store/specialist'
import { specialistActions } from '../../../../../../store/specialist/action'
import { TSpecialist } from '../../../../../../store/specialist/types'
import { Container } from '../../../../styled'
import { HeaderText, Text } from '../../styled'
import { PHONE_NUMBER_LIMIT } from './consts'
import { Controller, useForm } from 'react-hook-form'
import { usePlacesWidget } from 'react-google-autocomplete'
import { i18n } from '../../../../../../config'
import { SPECIALIST_RESPONSE } from '../../../../../../store/specialist/consts'
import { Alert, Snackbar } from '@mui/material'

export const EditWorkerIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const {
      specialist,
      loading,
      response: specialistResponse
   } = useTypedSelector(getMainWorkerSelector)

   const [isClicked, setClick] = useState(false)

   const { id } = useParams()

   const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false)
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const [form, setForm] = useState<TSpecialist>({
      name: '',
      secondName: '',
      mainSpecialty: '',
      specialties: [],
      languages: [],
      metaDescription: '',
      metaKeywords: [],
      salary: 0,
      salaryType: '',
      phone: '',
      serviceArea: '',
      newReviews: 0
   })

   const [coordinates, setCoordinates] = useState<{
      lat: number | null
      lon: number | null
   }>({
      lat: null,
      lon: null
   })

   const Events = {
      saveButtonClickHandler: () => {
         setClick(true)
         setAlertText('worker.successfuly.edited')
         Utils.isSchemaValid() && Requests.editSpecialist()
         toogleIsAlertOpen(true)
      },
      backButtonClickHanlder: () => {
         navigate('/workers')
      },
      onKeywordsChangeHandler: (metaKeywords: string[]) => {
         setForm({ ...form, metaKeywords: metaKeywords })
      },
      onKeywordsSpecialitiesChangeHandler: (specialties: string[]) => {
         setForm({
            ...form,
            specialties: specialties
         })
      },
      onKeywordsLanguageChangeHandler: (languages: string[]) => {
         setForm({ ...form, languages: languages })
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement

         if (name == 'salary') {
            setForm((props) => ({ ...props, [name]: +value.replaceAll('₼', '') }))
         } else {
            setForm((props) => ({ ...props, [name]: value }))
         }
      },
      deleteButtonClickHandler: () => {
         setPopupVisiablity(true)
      },
      closePopupClickHandler: () => {
         setPopupVisiablity(false)
      },
      cancelButtonClickHandler: () => {
         setPopupVisiablity(false)
      },
      removeButtonClickHandler: () => {
         Requests.deleteSpecialist()
         navigate('/workers')
      },
      onFormChange: () => {
         setForm((props) => ({
            ...props,
            serviceArea: getValues().address
         }))
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement

         setForm({ ...form, [input.name]: input.value })
      }
   }

   const Requests = {
      getSpecialist: () => {
         dispatch(specialistActions.GET_SPECIALIST({ _id: id as string }))
      },
      editSpecialist: () => {
         Utils.isSchemaValid() &&
            getValues().address.length > 0 &&
            dispatch(
               specialistActions.EDIT_SPECIALIST({
                  _id: id as string,
                  data: {
                     name: form.name,
                     secondName: form.secondName,
                     mainSpecialty: form.mainSpecialty,
                     specialties: form.specialties,
                     languages: form.languages,
                     salary: Number(form.salary),
                     salaryType: form.salaryType,
                     serviceArea: getValues().address,
                     phone: Utils.toPhoneNumber(form.phone),
                     metaDescription: form.metaDescription,
                     metaKeywords: form.metaKeywords
                  }
               })
            )
      },
      deleteSpecialist: () => {
         dispatch(specialistActions.DELETE_SPECIALIST({ _id: id as string }))
      }
   }

   const Utils = {
      removeNonNumber: (phone: string): string => {
         if (!phone) return ''
         return phone.replace(/\D/g, '').substring(0, PHONE_NUMBER_LIMIT - 1)
      },
      toPhoneNumber: function (phone: string): string {
         const value = this.removeNonNumber(phone)

         if (!value.length) return ''
         if (value.length == 1) return `${value}`
         if (value.length <= 4) {
            return `${value.substring(0, 1)}${value.substring(1)}`
         }

         return `${value.substring(0, 1)}${value.substring(1, 4)}${value.substring(
            4,
            7
         )}${value.substring(7, 9)}${value.substring(9)}`.trim()
      },
      isSchemaValid: () => {
         let isValid = true
         Object.keys(Schema).forEach((prop) => {
            if (!Schema[prop as keyof typeof Schema].condition) isValid = false
         })

         return isValid
      },
      // textValidation: (text: string) => {
      //    return text.match(/^[a-zA-Zа-яА-Я]+$/) ? true : false
      // },
      salarySymbol: (salary: string) => {
         return `${salary} ₼`
      }
   }

   const Schema = {
      name: {
         condition: form.name?.length >= 2 //&& Utils.textValidation(form.name)
      },
      phone: {
         condition: form.phone?.length >= 10
      },
      secondName: {
         condition: form.secondName?.length >= 2 // && Utils.textValidation(form.secondName)
      },
      mainSpecialization: {
         condition: form.mainSpecialty?.length >= 2
      },
      languages: {
         condition: form.languages && form.languages?.length >= 1
      }
   }

   const formUtils = {
      onChangeText: (
         text: any,
         onChange: (...event: any[]) => void,
         name: any,
         trigger: any
      ) => {
         onChange(text)
         trigger(name)
      }
   }

   const { trigger, control, setValue, getValues } = useForm<{ address: string }>({
      defaultValues: {
         address: specialist?.serviceArea
      }
   })

   const { ref } = usePlacesWidget({
      language: i18n?.language,
      options: {
         types: ['address'],
         componentRestrictions: {
            country: 'az'
         },
         strictBounds: true,
         bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(40.273843, 49.693146),
            new google.maps.LatLng(40.51008, 50.005264)
         )
      },
      onPlaceSelected: (place) => {
         setValue('address', place.formatted_address as string)
         setCoordinates({
            lat: place.geometry?.location.lat() as number,
            lon: place.geometry?.location.lng() as number
         })
         Events.onFormChange()
         trigger()
      }
   })

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            active: true
         },
         {
            title: t('work.reviews'),
            onClickHandler: () => {
               navigate(`/reviewWorker/${id as string}`)
            }
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getSpecialist()
   }, [])

   useEffect(() => {
      specialist &&
         setForm({
            name: specialist.name,
            secondName: specialist.secondName,
            mainSpecialty: specialist.mainSpecialty,
            specialties: [],
            languages: [],
            salary: specialist.salary,
            salaryType: specialist.salaryType,
            phone: specialist.phone,
            serviceArea: specialist.serviceArea,
            metaDescription: specialist.metaDescription,
            metaKeywords: []
         })
   }, [specialist])

   useEffect(() => {
      if (specialistResponse == SPECIALIST_RESPONSE.DONE) {
         setForm((prev) => ({
            ...prev,
            languages: specialist?.languages as string[],
            specialties: specialist?.specialties as string[],
            metaKeywords: specialist?.metaKeywords as string[]
         }))
      }
   }, [specialistResponse])

   useEffect(() => {
      setValue('address', form.serviceArea as string)
   }, [form.serviceArea])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="red" onClick={Events.deleteButtonClickHandler}>
                        {t('delete.worker')}
                     </Button>
                     <Button theme="green" onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t('edit')}
               backButtonClickHandler={Events.backButtonClickHanlder}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(specialist?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(specialist?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }
         loading={loading}>
         <SubPagesBar sections={sections} sumRating={specialist?.newReviews} />

         <Container>
            <FlexContainer gap="62px" align="flex-start">
               <Input
                  name="name"
                  type="text"
                  label={t('name')}
                  placeholder={t('enter.name')}
                  value={form.name}
                  onChange={Events.inputChangeHandler}
                  required={true}
                  error={t('required.field')}
                  isValid={isClicked ? Schema.name.condition : true}
               />
               <Input
                  name="secondName"
                  type="text"
                  label={t('lastName')}
                  placeholder={t('enter.lastName')}
                  value={form.secondName}
                  onChange={Events.inputChangeHandler}
                  required={true}
                  error={t('required.field')}
                  isValid={isClicked ? Schema.name.condition : true}
               />
               <Input
                  name="phone"
                  type="text"
                  label={t('phone')}
                  error={t('phone.length.is.incorrect')}
                  placeholder={t('enter.phone')}
                  isValid={isClicked ? Schema.phone.condition : true}
                  value={Utils.toPhoneNumber(form.phone)}
                  onChange={Events.inputChangeHandler}
               />
            </FlexContainer>

            <FlexContainer gap="62px" direction="row" align="flex-start">
               <Input
                  name="mainSpecialty"
                  type="text"
                  label={t('specialization')}
                  placeholder={t('enter.specialization')}
                  value={form.mainSpecialty}
                  onChange={Events.inputChangeHandler}
                  required={true}
                  error={t('required.field')}
                  isValid={isClicked ? Schema.name.condition : true}
               />
               {/* <FlexContainer width="318px" align="flex-start" gap="18px">
                  <Input
                     width="150px"
                     name="salary"
                     type="text"
                     label={t('salary')}
                     placeholder={t('enter.salary')}
                     value={`${(form.salary.toString() + '₼').replaceAll('₼', '') + '₼'}`}
                     onChange={Events.inputChangeHandler}
                     required={true}
                     isValid={isClicked ? Schema.salary.condition : true}
                     error={t('required.field')}
                     currency={true}
                  />
                  <DropDownList
                     width="130px"
                     name="metric"
                     options={metricOptions}
                     label={t('payment.type')}
                     placeholder={t('choose.metric')}
                     value={metricOptions[0].name}
                     onChange={Events.inputChangeHandler}
                  />
               </FlexContainer> */}
               <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                     <>
                        <Input
                           name={'address'}
                           label={t('location')}
                           placeholder={t('enter.location')}
                           width="100%"
                           value={value}
                           isValid={value?.length > 0}
                           error={t('required.field')}
                           cref={ref as any}
                           onChange={(e) =>
                              formUtils.onChangeText(
                                 (e.target as HTMLInputElement).value,
                                 onChange,
                                 'address',
                                 trigger
                              )
                           }
                        />
                     </>
                  )}
                  name="address"
               />
            </FlexContainer>

            {form.languages && form.languages?.length > 0 && (
               <KeywordsInput
                  label={t('languages')}
                  value={form.languages}
                  onChange={Events.onKeywordsLanguageChangeHandler}
                  isValid={isClicked ? form.languages.length > 0 : true}
                  languageKeywords={true}
                  error={t('required.field')}
               />
            )}
            {form.languages && form.languages?.length == 0 && (
               <KeywordsInput
                  label={t('languages')}
                  value={form.languages}
                  onChange={Events.onKeywordsLanguageChangeHandler}
                  isValid={isClicked ? form.languages.length > 0 : true}
                  languageKeywords={true}
                  error={t('required.field')}
               />
            )}
            {form.specialties && form.specialties?.length > 0 && (
               <KeywordsInput
                  label={t('specialities')}
                  value={form.specialties}
                  onChange={Events.onKeywordsSpecialitiesChangeHandler}
               />
            )}
            {form.specialties && form.specialties?.length == 0 && (
               <KeywordsInput
                  label={t('specialities')}
                  value={form.specialties}
                  onChange={Events.onKeywordsSpecialitiesChangeHandler}
               />
            )}

            {form.metaKeywords?.length == 0 && (
               <KeywordsInput
                  width="90%"
                  label={t('meta.keywords')}
                  placeholder={t('meta.keywords')}
                  value={form.metaKeywords}
                  onChange={Events.onKeywordsChangeHandler}
               />
            )}
            {form.metaKeywords?.length > 0 && (
               <KeywordsInput
                  width="90%"
                  label={t('meta.keywords')}
                  placeholder={t('meta.keywords')}
                  value={form.metaKeywords}
                  onChange={Events.onKeywordsChangeHandler}
               />
            )}

            <Textarea
               name="metaDescription"
               width="100%"
               height="140px"
               label={t('meta.description')}
               onChange={Events.onChange}
               placeholder={t('meta.description')}
               margin={'24px'}>
               {form?.metaDescription}
            </Textarea>
         </Container>

         {popupVisiablity && (
            <Popup
               backgroundClickListener={Events.closePopupClickHandler}
               height="223px"
               width="627px">
               <FlexContainer
                  direction="column"
                  gap="25px"
                  justify="center"
                  align="center">
                  <HeaderText>{t('master.deletion')}</HeaderText>
                  <Text>{t('sure')}</Text>
                  <FlexContainer gap="30px" align="center" justify="center">
                     <Button theme="grey" onClick={Events.cancelButtonClickHandler}>
                        {t('cancel')}
                     </Button>
                     <Button theme="red" onClick={Events.removeButtonClickHandler}>
                        {t('delete')}
                     </Button>
                  </FlexContainer>
               </FlexContainer>
            </Popup>
         )}

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}
