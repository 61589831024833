import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   SubPagesBar,
   TSection,
   TSort
} from 'components'
import { colors, ELocales } from 'enums'
import { useTypedSelector } from 'hooks'
import { DragAndDropProduct } from 'pages/Catalog/components'
import { PAGE_LIMIT } from 'pages/Catalog/consts'
import { Image, StyledText } from 'pages/Catalog/styled'
import { FC, SyntheticEvent, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { categoryActions, useGetCategories } from 'store'
import { TForm } from './types'

export const CategoriesIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [page, setPage] = useState<number>(0)
   const { accessToken } = useTypedSelector((state) => state.user)

   const { categoriesData, isLoading, setSize, hasMore, mutate } = useGetCategories({
      field: 'parent',
      value: 'null',
      limit: PAGE_LIMIT,
      page: page,
      lang: ELocales.ru,
      hidden: 'false',
      sortBy: 'order',
      order: 1,
      token: accessToken || ''
   })

   const saveButtonClickHadler = () => {
      dispatch(
         categoryActions.editCategoryOrder({
            data: (categoriesData?.categories || [])?.map((category, index) => ({
               _id: category._id,
               data: {
                  order: index + 1
               }
            }))
         })
      )
   }

   const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
      userSelect: 'none',
      padding: '8px',
      margin: '0 ',
      height: '190px',
      background: isDragging ? colors.light_grey : colors.solid_white,
      ...draggableStyle
   })

   const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      padding: `8px 8px 8px 20px`,
      overflow: 'hidden'
   })

   const paginationSections: TSection[] = useMemo(
      () => [
         {
            title: t('current'),
            active: true
         },
         {
            title: t('new.categories'),
            onClickHandler: () => {
               navigate('/catalog/newCategories')
            }
         }
      ],
      []
   )

   const onDragEnd = (result: DropResult) => {
      const { source, destination } = result

      if (!destination) return

      const reorderedCategories = Array.from(categoriesData?.categories || [])
      const [movedCategory] = reorderedCategories.splice(source.index, 1)
      reorderedCategories.splice(destination.index, 0, movedCategory)

      mutate(
         [
            {
               meta: categoriesData?.meta || null,
               data: reorderedCategories
            }
         ],
         false
      )
   }

   return (
      <AdminPanelContainer
         loading={isLoading}
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="green" onClick={saveButtonClickHadler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t('categories.and.subcategories')}
            />
         }>
         <SubPagesBar sections={paginationSections} />

         <FlexContainer justify="space-between" padding="8px" width="100%" wrap="nowrap">
            <div>
               <StyledText style={{ width: '120px' }}>{t('photo')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '120px' }}>{t('icon')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('title')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('total.product')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('subcategories')}</StyledText>
            </div>
            <div>
               <StyledText style={{ width: '235px' }}>{t('visability')}</StyledText>
            </div>
         </FlexContainer>

         {(categoriesData?.categories?.length || 0) > 0 && (
            <FlexContainer align="center" gap="30px" wrap="nowrap" direction="column">
               <DragDropContext onDragEnd={onDragEnd}>
                  {categoriesData?.categories?.map((item, ind) => (
                     <Droppable droppableId={`${ind}`} direction="vertical" key={ind}>
                        {(provided, snapshot) => (
                           <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}>
                              {provided.placeholder}
                              <Draggable
                                 draggableId={item._id}
                                 index={ind}
                                 key={item._id}>
                                 {(provided, snapshot) => (
                                    <div
                                       ref={provided.innerRef}
                                       {...provided.draggableProps}
                                       {...provided.dragHandleProps}
                                       style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                       )}>
                                       <DragAndDropProduct
                                          key={item._id}
                                          productItem={item}
                                          path="categories"
                                       />
                                    </div>
                                 )}
                              </Draggable>
                           </div>
                        )}
                     </Droppable>
                  ))}
               </DragDropContext>
            </FlexContainer>
         )}
         {hasMore && (
            <FlexContainer justify="center">
               <Button onClick={() => setSize((prev) => prev + 1)}>
                  {t('view.another')}
               </Button>
            </FlexContainer>
         )}
      </AdminPanelContainer>
   )
}
