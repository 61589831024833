import styled from 'styled-components'
import { colors } from '../../../../../../enums'
import { FONT } from '../../../../../../utils'

export const Container = styled.div`
   overflow-y: auto;
   width: 497px;
   height: 368px;

   max-width: 100%;
   padding: 30px;
   border: 1px solid ${colors.border_grey_color};
   border-radius: 14px;

   /* width */
   ::-webkit-scrollbar {
      width: 6px;
   }
`

export const Item = styled.div`
   ${FONT({ color: colors.lighter_black, size: '14px', weight: '400' })}
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`

export const PreloaderContainer = styled.div`
   padding-top: 20px;
   width: 100%;
   height: 100%;
`
