import { LOCALE_KEY } from '../constants'
import { ELocales } from '../enums'
import { TLocales } from '../locales'
import { TSchema } from '../types'

export const getLocale = (): TLocales => {
   return (localStorage.getItem(LOCALE_KEY) || ELocales.default) as TLocales
}

export const validateSchema = (schema: TSchema): boolean => {
   let isValid = true

   Object.keys(schema).some((key) => {
      if (!schema[key].condition) {
         isValid = false
         return true
      }
   })

   return isValid
}

export const createFile = async (
   url: string,
   name: string = 'file',
   defaultType = 'image/*'
): Promise<File> => {
   const response = await fetch(url)
   const data = await response.blob()
   return new File([data], name, {
      type: data.type || defaultType
   })
}
