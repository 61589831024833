import { FC } from 'react'
import { FlexContainer, Label } from '../Styled'
import { TKeywordsInput } from './types'
import { TagsInput } from 'react-tag-input-component'
import { StyledError, StyledInput, TagsInputContainer } from './styled'
import { colors } from 'enums'

const KeywordsInput: FC<TKeywordsInput> = ({
   label,
   height = '45px',
   width = '100%',
   value,
   error,
   isValid,
   placeholder,
   required,
   languageKeywords,
   disabled,
   isChanged,
   onChange
}) => {
   const Events = {
      onChangeTagsInputHandler: (tags: string[]) => {
         onChange(tags)
      }
   }

   return (
      <FlexContainer width={width}>
         {label && <Label>{label}</Label>}
         <TagsInputContainer changedProduct={isChanged} width="100%" height={height}>
            {languageKeywords && (
               <div
                  style={{
                     border: isValid ? '' : '1px solid #BF5E5E',
                     borderRadius: '6px'
                  }}>
                  <StyledInput
                     value={value}
                     onChange={Events.onChangeTagsInputHandler}
                     placeHolder={value.length ? '' : placeholder}
                  />
               </div>
            )}
            {!languageKeywords && (
               <StyledInput
                  value={value}
                  onChange={Events.onChangeTagsInputHandler}
                  placeHolder={value?.length ? '' : placeholder}
                  disabled={disabled}
               />
            )}
            <StyledError className={isValid ? 'error' : ''}>{error}</StyledError>
         </TagsInputContainer>
      </FlexContainer>
   )
}

export default KeywordsInput
