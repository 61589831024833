import { colors } from 'enums'
import styled from 'styled-components'
import { FONT } from 'utils'
import { assets } from '../../assets'
import { TImage } from './types'

export const Image = styled.div<TImage>`
   display: inline-block;
   background-image: ${({ src }) => `url(${src})`};
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   height: 46px;
   width: 46px;
`

export const StyledText = styled.p`
   ${FONT({ color: colors.grey, weight: '500', size: '14px', align: 'center' })}
   padding: 30px;
`
export const PlusIcon = styled.img.attrs({ src: assets.PLUS })``
