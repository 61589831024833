import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { FLEX, FONT } from '../../utils'
import { FlexContainer } from '../Styled'

export const Container = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 115px;
   width: 100%;
`

export const Title = styled.h1`
   ${FONT({ size: '24px', weight: '700' })}
   line-height: 28px;
`

export const ButtonContainer = styled.div`
   display: flex;
   width: 50%;
   justify-content: flex-end;
   gap: 30px;
`
export const DateContainer = styled.div`
   display: flex;
   width: 50%;
   justify-content: flex-start;
   gap: 60px;
`

export const BackButton = styled.button`
   cursor: pointer;
   border: none;
   ${FONT({ weight: '700', size: '24px' })}
   line-height: 28px;
   background-color: transparent;
   ${FLEX({})}

   gap: 14.3px;
`

export const BackButtonIcon = styled.img.attrs({
   src: assets.ARROW_LEFT
})``

export const DateField = styled.span`
   display: flex;
   align-items: center;

   ${FONT({ color: colors.grey })}
`

export const ActionButtonContainer = styled(FlexContainer)`
   gap: 60px;

   @media (max-width: 1200px) {
      gap: 10px;
   }
`
