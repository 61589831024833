import { FC, useEffect, useMemo } from 'react'
import {
   AlignContainer,
   Container,
   ExitButton,
   ExitButtonContainer,
   ExitButtonIcon,
   Logo,
   MenuContainer,
   MenuIcon,
   MenuItem,
   NewMessageCount,
   ProfileContainer,
   ProfileName,
   SpaceBetweenContainer
} from './styled'
import { assets } from '../../assets'
import { useTypedSelector } from '../../hooks'
import { getUserSelector, userActions, supportActions, orderActions } from '../../store'
import { menuItemsSchema } from './schema'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { api } from '../../config'
import { FlexContainer, ProfileImage } from '../Styled'
import { ApiSupportService } from 'store/support/api.service'
import { ApiSpecialistService } from 'store/specialist/api.service'
import { TCompany, TSpecialists } from './types'
import { sliceActions } from 'store/specialist/reducer'
import { ApiOrderService } from 'store/order/api.service'
import { GrCalculator } from 'react-icons/gr'
import { TbBrandShopee } from 'react-icons/tb'

const Sidebar: FC = () => {
   const { t } = useTranslation()
   const { image, secondName, name } = useTypedSelector(getUserSelector)
   const dispatch = useDispatch()
   const location = useLocation()
   const { newMessageCount } = useTypedSelector((state) => state.support)
   const { accessToken } = useTypedSelector((state) => state.user)
   const { role } = useTypedSelector(getUserSelector)
   const { newReviewsCount } = useTypedSelector((state) => state.specialist)
   const { newOrders } = useTypedSelector((state) => state.order)

   const Events = {
      exitButtonHandler: () => {
         dispatch(userActions.logoutRequest())
      }
   }

   const getNewMesageCount = async () => {
      const count: number =
         accessToken &&
         (await ApiSupportService.getRequests({
            token: `Bearer ${accessToken}`,
            status: 'new',
            limit: 1,
            page: 0
         }).then((res: any) => res.data.meta?.totalCount))
      dispatch(supportActions.setNewMessageCount(count))
   }

   const getNewReviews = async () => {
      const specialists: TCompany[] =
         accessToken &&
         (await ApiSpecialistService.getSpecialists({
            token: `Bearer ${accessToken}`,
            order: 1,
            page: 0,
            sortBy: 'createdAt',
            limit: 80
         }).then((res) => res.data.data))

      const newReviews = specialists.reduce(
         (acc, person) => acc + (person?.newReviews || 0),
         0
      )
      dispatch(sliceActions.SET_NEW_REVIEWS(newReviews))
   }

   const getNewOrders = async () => {
      const newOrders =
         accessToken &&
         (await ApiOrderService.getOrders({
            token: `Bearer ${accessToken}`,
            limit: 1,
            sortBy: 'createdAt',
            order: 1
         }).then((res) => res?.data?.meta?.new))

      dispatch(orderActions.setNewOrder(newOrders))
   }

   useEffect(() => {
      !newReviewsCount && getNewReviews()
   }, [])

   useEffect(() => {
      !newMessageCount && getNewMesageCount()
   }, [])

   useEffect(() => {
      !newOrders && getNewOrders()
   }, [])

   return (
      <Container>
         <SpaceBetweenContainer>
            <AlignContainer>
               <Logo />
               <ProfileContainer>
                  <FlexContainer width="auto" align="center">
                     <ProfileImage
                        src={
                           image
                              ? `${api.static}admin/${image}`
                              : assets.DEFAULT_PROFILE_IMAGE
                        }
                        size="36px"
                     />
                  </FlexContainer>
                  <FlexContainer width="auto" align="center">
                     <ProfileName>
                        {name} {secondName}
                     </ProfileName>
                  </FlexContainer>
               </ProfileContainer>

               <MenuContainer>
                  {Object.keys(menuItemsSchema).map((key, index) => {
                     const item: any = menuItemsSchema[key]

                     if (item.name == 'settings') {
                        return (
                           <MenuItem
                              href={item.link}
                              key={index}
                              className={location.pathname.includes(key) ? 'active' : ''}>
                              <MenuIcon src={item.img} />
                              <span>{t(item.name)}</span>
                           </MenuItem>
                        )
                     }

                     return (
                        <>
                           {role?.permissions?.find(
                              (permission) => permission == item.access
                           ) && (
                              <MenuItem
                                 href={item.link}
                                 key={index + '2'}
                                 className={
                                    location.pathname.includes(key) ? 'active' : ''
                                 }>
                                 {item.name == 'calculator' ? (
                                    <GrCalculator />
                                 ) : (
                                    <MenuIcon src={item.img} />
                                 )}

                                 <span>
                                    {item.name === 'manage.workers' && newReviewsCount
                                       ? t(item.name).slice(0, item.name.length + 2) +
                                         '...'
                                       : t(item.name)}
                                 </span>
                                 {item.name === 'support' && newMessageCount ? (
                                    <NewMessageCount>{newMessageCount}</NewMessageCount>
                                 ) : null}
                                 {item.name === 'manage.workers' && newReviewsCount ? (
                                    <NewMessageCount>{newReviewsCount}</NewMessageCount>
                                 ) : null}
                                 {item.name === 'orders' && newOrders ? (
                                    <NewMessageCount>{newOrders}</NewMessageCount>
                                 ) : null}
                              </MenuItem>
                           )}
                        </>
                     )
                  })}
               </MenuContainer>
            </AlignContainer>

            <ExitButtonContainer>
               <ExitButton onClick={Events.exitButtonHandler}>
                  <ExitButtonIcon />
                  <span>{t('exit')}</span>
               </ExitButton>
            </ExitButtonContainer>
         </SpaceBetweenContainer>
      </Container>
   )
}

export default Sidebar
