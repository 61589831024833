export const GET_ADMINS = 'GET_ADMINS'
export const SET_ADMINS = 'SET_ADMINS'
export const CREATE_ADMIN = 'CREATE_ADMIN'
export const DONE = 'DONE'
export const SET_ADMIN = 'SET_ADMIN'
export const SET_ADMIN_STATE = 'SET_ADMIN_STATE'
export const REMOVE_ADMIN = 'REMOVE_ADMIN'
export const GET_ADMIN = 'GET_ADMIN'
export const EDIT_ADMIN = 'EDIT_ADMIN'

export const RESPONSE = {
   CREATED: 'CREATED',
   EDITED: 'EDITED',
   NOT_FOUNDED: 'NOT_FOUNDED'
}
