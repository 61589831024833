import styled, { css } from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { FLEX, FONT } from '../../utils'
import { TIcon, TImageButton } from './types'

export const Container = styled.div`
   ${FLEX({ direction: 'column', justify: 'flex-start', align: 'flex-start' })}

   .photo-input {
      display: none;
   }
`

export const Label = styled.label`
   text-align: center;
   margin-bottom: 8px;
   line-height: 16px;

   ${FONT({ color: colors.black, size: '14px', weight: '500' })}
`

export const PlusIcon = styled.img.attrs({
   src: assets.PLUS
})`
   filter: ${colors.filter_black};
   transition: all 0.4s ease;
   width: 37px;
   height: auto;
`

export const CrossIcon = styled.img.attrs({ src: assets.CROSS })<TIcon>`
   position: absolute;
   top: 0;
   right: 0;
   cursor: pointer;

   ${({ hidden }) =>
      hidden &&
      css`
         display: none;
      `}
`

export const ImageButton = styled.button<TImageButton>`
   cursor: pointer;
   width: 119px;
   height: 119px;
   position: relative;
   ${FLEX({})}
   background-color: ${colors.colorPicker_grey_color};
   border: none;

   &:hover ${PlusIcon} {
      filter: ${colors.filter_blue};
   }

   ${({ src }) =>
      src &&
      css`
         border-radius: 100%;

         ${PlusIcon} {
            display: none;
         }
      `}

   background-image: ${({ src }) => `url(${src})`};
   background-size: cover;
   background-position: center;
`
export const ErrorLable = styled.p`
   ${FONT({ color: colors.red })}
   align-self: center;
   width: 110px;
   word-break: break-all;
`
