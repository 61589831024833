import styled from 'styled-components'
import { colors } from '../../enums'
import { FLEX, FONT } from '../../utils'

export const Container = styled.div`
   width: 100%;
   height: 100vh;
   background-color: ${colors.light_grey};
   ${FLEX({
      align: 'center'
   })}
`

export const FormWrapper = styled.div`
   ${FLEX({
      direction: 'column'
   })}

   width: 100%;
   max-width: 653px;
   height: 543px;
   background-color: ${colors.solid_white};
   box-shadow: 0px 14px 28px rgba(135, 135, 135, 0.16);
   border-radius: 22px;
`

export const Title = styled.h1`
   ${FONT({ color: colors.black, size: '24px', weight: '700' })}

   line-height: 28px;
   margin-top: 35px;
`

export const InputWrapper = styled.div`
   max-width: 90%;

   &.login {
      margin-top: 20px;
   }

   &.password {
      margin-top: 20px;
   }
`

export const ButtonWrapper = styled.div`
   max-width: 90%;
   margin-top: 30px;
   width: 100%;

   ${FLEX({})}
`
