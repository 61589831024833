import { useTranslation } from 'react-i18next'
import { HeaderWrapper, NavButton } from './styled'
import { FiPlus } from 'react-icons/fi'

export const Header = ({
   title,
   button
}: {
   title: React.ReactNode
   button?: React.ReactNode
}) => {
   const { t } = useTranslation()
   return (
      <HeaderWrapper>
         {title}

         {button}
      </HeaderWrapper>
   )
}
