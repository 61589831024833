import { t } from 'i18next'
import { FC, useState, useMemo, SyntheticEvent, useEffect } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   DropDownList,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   Pagination,
   SearchBar,
   SubPagesBar,
   Table,
   TColumnTable,
   TSection,
   TSort
} from '../../../../components'
import { TOption } from '../../../../components/PaginateDropDownList/types'
import { api } from '../../../../config'
import { ELocales } from '../../../../enums'
import { useLocalization, useTypedSelector } from '../../../../hooks'
import {
   categoryActions,
   getMainCategorySelector,
   getMainProductSelector,
   productActions
} from '../../../../store'
import { Image } from '../../styled'
import { utils } from '../../utils'
import { RESPONSE } from '../CurrentProducts/consts'
import { PAGE_LIMIT } from './consts'

export const NewProductsIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const {
      products,
      filters,
      response: productsResponse,
      loading: productLoading
   } = useTypedSelector(getMainProductSelector)
   const { categories } = useTypedSelector(getMainCategorySelector)

   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const [page, setPage] = useState<number>(0)
   const [locale] = useLocalization()
   const [isClicked, setIsClicked] = useState(false)
   const [searchQueryPage, setSearchQueryPage] = useState<number>(0)

   const [searchParams, setSearchParams] = useSearchParams({})

   const [searchForm, setSearchForm] = useState({
      query: filters.query,
      date_start: filters.dateStart,
      date_end: filters.dateEnd
   })

   const [filterForm, setFilterForm] = useState<{ value: string }>({
      value: filters.categoryId
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [selectedItems, setSelectedItems] = useState<number[]>([])

   const Events = {
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         Requests.getProducts()
      },
      loadOptions: async (search: string, loadOptions: any, additions: any) => {
         const page = additions?.page || 0

         const options = await Requests.getCategories()
      },
      paginateDropDownChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement

         setFilterForm((form) => ({ ...form, value: value }))
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      removeCheckedItemsHandler: () => {
         Requests.removeSelectedProducts()
      },
      removeClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(
            productActions.removeProduct({ _id: products.data[index]._id as string })
         )
      },
      updateVisabilityButtonClickHandler: () => {
         Requests.updateVisability()
         setIsClicked(true)
         setRemoveButtonState(false)
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(
            productActions.setFilters({
               query: searchForm.query,
               dateStart: searchForm.date_start,
               dateEnd: searchForm.date_end,
               categoryId: filterForm.value
            })
         )
         navigate(`/products/newProducts/page=${page}/${products?.data[index]._id}`)
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setSelectedItems(ckeckedItemsIndex)
         setRemoveButtonState(hasCheckboxesActiveState)
      },
      resetFilters: () => {
         dispatch(
            productActions.setFilters({
               query: '',
               dateEnd: '',
               dateStart: '',
               categoryId: ''
            })
         )
         window.location.reload()
      }
   }

   const Requests = {
      getProducts: () => {
         dispatch(
            productActions.getProducts({
               limit: PAGE_LIMIT,
               page: page,
               lang: locale as ELocales,
               hidden: 'only',
               category: [filterForm.value],
               ...sortParamsForm,
               ...searchForm
            })
         )
      },
      getCategories: () => {
         dispatch(categoryActions.getCategories({ limit: 1000 }))
      },
      updateVisability: () => {
         const arr: string[] = []
         const forms: {
            show: boolean
            // discountPrice: number | undefined
         }[] = []
         for (let i of selectedItems) {
            arr.push(products.data[i]._id)
            forms.push({
               show: true
               // discountPrice: products.data[i].discountPrice
               //    ? products.data[i].discountPrice
               //    : 0
            })
         }
         dispatch(productActions.updateVisability({ ids: arr, tforms: forms }))
         setSelectedItems([])
      },
      removeSelectedProducts: () => {
         const arr: string[] = []
         for (let i of selectedItems) {
            arr.push(products.data[i]._id)
         }
         dispatch(productActions.removeAllProducts({ ids: arr }))
         setSelectedItems([])
      }
   }

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('current'),
            onClickHandler: () => {
               navigate('/products')
            }
         },
         {
            title: t('new.products'),
            active: true
         },
         {
            title: t('promotion.products'),
            onClickHandler: () => {
               navigate('/products/promotionProducts')
            }
         },
         {
            title: t('statistic'),
            onClickHandler: () => {
               navigate('/statistic')
            }
         },
         {
            title: t('changelog'),
            onClickHandler: () => {
               navigate('/changelog')
            }
         }
      ],
      []
   )

   const data = useMemo(
      () =>
         products?.data.map((product) => {
            return {
               barcode: <>{product.barcode}</>,
               photo: <Image src={`${api.preview}${product.preview}`} />,
               label: <>{product.description && product.description?.title}</>,
               createdAt: (
                  <Moment format="DD.MM.YYYY">
                     {new Date(product?.createdAt as string)}
                  </Moment>
               ),
               category: <>{product.category.title}</>,
               total: <>{product?.amount}</>,
               price: <>{product.price}</>,
               sellStatus: <>{product.amount ? t('available') : t('notavailable')}</>
            }
         }),
      [products]
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('product.code'),
            accessor: 'barcode',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 250
         },
         {
            Header: t('createdAt'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('category'),
            accessor: 'category',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('total'),
            accessor: 'total',
            width: 200
         },
         {
            Header: t('price'),
            accessor: 'price',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('product.status'),
            accessor: 'sellStatus',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getProducts()
      Requests.getCategories()
      setSearchParams({ ...searchParams, page: '' + (page + 1) })
   }, [page, filterForm, tableSortBy, searchForm])

   useEffect(() => {
      if (productsResponse === RESPONSE.UPDATED_VISABILITY) {
         Requests.getProducts()
      } else if (productsResponse === RESPONSE.REMOVED_ALL) {
         Requests.getProducts()
      } else if (productsResponse === RESPONSE.REMOVED) {
         Requests.getProducts()
      }
   }, [productsResponse])

   useEffect(() => {
      if (products?.meta) {
         if (page > Math.ceil(products?.meta.totalCount / PAGE_LIMIT)) {
            setPage(0)
         }
      }
   }, [products?.meta])

   // Set default page uri
   useEffect(() => {
      dispatch(productActions.setProduct({ product: null }))
      dispatch(
         productActions.setFilters({
            query: '',
            dateEnd: '',
            dateStart: '',
            categoryId: ''
         })
      )
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string) - 1
            : 0
      )
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     {removeButtonState && (
                        <Button
                           theme="green"
                           onClick={Events.updateVisabilityButtonClickHandler}>
                           {t('change.visability')}
                        </Button>
                     )}
                     {removeButtonState && (
                        <Button theme="red" onClick={Events.removeCheckedItemsHandler}>
                           {t('remove.all')}
                        </Button>
                     )}
                  </>
               }
               title={t('manage.products')}
            />
         }
         loading={productLoading}>
         <SubPagesBar sections={sections} />
         <FlexContainer direction="column" gap="30px">
            <HeaderWrapper style={{ justifyContent: 'flex-start', width: '100%' }}>
               <FiltersWrapper style={{ justifyContent: 'flex-start', width: '87%' }}>
                  <SearchBar
                     name="query"
                     placeholder={t('search')}
                     value={searchForm.query}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
                  <DateInput
                     name="date_start"
                     value={searchForm.date_start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="date_end"
                     value={searchForm.date_end}
                     onChange={Events.onChangeHandler}
                  />
                  <DropDownList
                     width="20%"
                     placeholder={t('filter.by.category')}
                     options={categories.data
                        .filter(
                           (item) =>
                              !item.show &&
                              item.productsTotalCount &&
                              item.categoriesTotalCount == 0
                        )
                        .map((item) => ({
                           name: item.title,
                           value: item._id
                        }))}
                     value={filterForm.value}
                     onChange={Events.paginateDropDownChangeHandler}
                  />
                  <div onClick={Events.resetFilters}>
                     <Button style="transparant">{t('reset.filters')}</Button>
                  </div>
               </FiltersWrapper>
               <DataTotal>
                  {t('total.products')}: {products?.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            {!isClicked && (
               <Table
                  columns={columns}
                  data={data}
                  sortBy={tableSortBy}
                  checkboxClickHandler={Events.checkboxClickHandler}
                  headerCheckbox={Events.checkedAll}
                  editClickHandler={Events.editButtonClickHandler}
                  removeClickHandler={Events.removeClickHandler}
                  editable
                  removeable
               />
            )}
            {isClicked && (
               <Table
                  columns={columns}
                  data={data}
                  sortBy={tableSortBy}
                  checkboxClickHandler={Events.checkboxClickHandler}
                  headerCheckbox={Events.checkedAll}
                  editClickHandler={Events.editButtonClickHandler}
                  removeClickHandler={Events.removeClickHandler}
                  editable
                  removeable
               />
            )}
         </FlexContainer>

         <Pagination
            page={page}
            pageCount={
               products?.meta ? Math.ceil(products?.meta.totalCount / PAGE_LIMIT) : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}
