import { createAction } from '@reduxjs/toolkit'
import { ActionTypes } from './actionTypes'
import { sliceActions } from './reducer'
import { TEditPagesPayload, TGetSeoPagesPayload } from './types'

export const seoActions = {
   ...sliceActions,
   GET_PAGES: createAction(ActionTypes.getPages, (payload: TGetSeoPagesPayload) => ({
      payload
   })),
   EDIT_PAGES: createAction(ActionTypes.editPages, (payload: TEditPagesPayload) => ({
      payload
   }))
}
