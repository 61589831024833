import { colors } from 'enums'
import { CSSObjectWithLabel, ControlProps } from 'react-select'
import styled from 'styled-components'

export const Container = styled.div`
   margin-top: 33px;
   width: 100%;
   display: flex;
   flex-direction: column;
`

export const FilterWrap = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;

   width: 100%;
`
export const LabelSearch = styled.label`
   position: relative;
   input {
      border-radius: 8px;
      box-sizing: border-box;
      display: block;
      outline: none;
      width: 273px;
      padding: 10px 10px 10px 35px;
      border: 1px solid ${colors.lighter_grey};
      height: 44px;

      font-size: 16px;
      transition: border-color 0.2s;
      color: ${colors.black};
      &[disabled] {
         background-color: ${colors.lighter_grey};
      }
      &:focus {
         border: 1px solid ${colors.black};
      }
   }
   svg {
      color: ${colors.grey};
      width: 25px;
      height: 25px;
      position: absolute;
      top: 9px;
      left: 5px;
   }
`
export const TimeInpustWrap = styled.div`
   display: flex;
   align-items: center;
   gap: 20px;
`
export const selectStyles = {
   control: (
      provided: CSSObjectWithLabel,
      state: ControlProps<{ label: string; value: string }>
   ) => ({
      ...provided,
      backgroundColor: 'transparent',
      width: '273px',
      height: '44px',
      borderRadius: '8px',
      boxShadow: 'none',
      border:
         state.isFocused || state.menuIsOpen
            ? `1px solid ${colors.black}`
            : `1px solid ${colors.lighter_grey}`,
      '&:hover': {
         border: state.isFocused || state.menuIsOpen ? '1px solid #222222' : undefined
      }
   }),
   dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided
      // display: 'none'
   }),
   indicatorSeparator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none'
   })
}

export const SortBarList = styled.ul`
   list-style: none;
   display: flex;
   align-items: center;
   margin-top: 30px;
`
export const SortItem = styled.li<{ $active?: boolean; $growth?: boolean }>`
   display: flex;
   align-items: center;
   font-weight: 500;
   font-size: 14px;
   color: ${({ $active }) => (!$active ? colors.grey : colors.blue)};
   padding: 0 10px;
   gap: 10px;
   &:nth-child(1) {
      padding: 0 0 0 50px;
      width: 220px;
   }
   &:nth-child(2) {
      width: 80px;
   }
   &:nth-child(3) {
      width: 220px;
   }
   &:nth-child(4) {
      width: 160px;
      cursor: pointer;
   }
   &:nth-child(5) {
      width: 200px;
   }
   &:nth-child(6) {
      cursor: pointer;
      width: 120px;
   }
   &:nth-child(7) {
      width: 90px;
   }
   svg {
      transition: rotate 0.3s;
      rotate: ${({ $active, $growth }) => {
         if ($active && $growth) {
            return '0'
         } else if ($active && !$growth) {
            return '180deg'
         } else {
            return '0'
         }
      }};
   }
`
