import { colors } from 'enums'
import styled from 'styled-components'

export const ModalWrap = styled.div`
   width: 500px;
   border-radius: 8px;
   background-color: #ffffff;
   display: flex;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   align-items: flex-start;
   justify-content: flex-start;
   flex-direction: column;
   padding: 20px;
   gap: 30px;
`
export const HedingWrap = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: space-between;
   svg {
      width: 30px;
      height: 30px;
   }
`
export const Button = styled.button`
   width: 195px;
   height: 46px;
   display: flex;
   background-color: ${colors.green};
   outline: none;
   border: none;
   border-radius: 8px;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   font-weight: 500;
   color: ${colors.solid_white};
   cursor: pointer;
   transition: background-color 0.3s;
   margin-left: auto;
   &:hover {
      background-color: #4caa55;
   }
   &[disabled] {
      background-color: ${colors.grey1};
   }
`
