import { t } from 'i18next'

export const productTypeOptions = [
   { name: t('variated.product'), value: 'variated' },
   { name: t('single.product'), value: 'simple' }
]
export const visiablityOptions = [
   { name: t('show'), value: '1' },
   { name: t('not.show'), value: '0' }
]
export const statusOptions = [
   { name: t('in.stock'), value: 'available' },
   { name: t('out.stock'), value: 'notavailable' }
]
