import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   Pagination,
   Popup,
   SearchBar,
   SubPagesBar,
   Table,
   TColumnTable,
   TSection,
   TSort
} from '../../../../../../components'
import { useLocalization, useTypedSelector } from '../../../../../../hooks'
import { TOption } from '../../../../../../components/PaginateDropDownList/types'

import { useNavigate } from 'react-router-dom'
import { ELocales } from '../../../../../../enums'
import { HeaderText, Text } from '../../styled'
import {
   getMainWorkerSelector,
   specialistActions
} from '../../../../../../store/specialist'
import { TSpecialistReview } from '../../../../../../store/specialist/types'
import Moment from 'react-moment'
import { SPECIALIST_RESPONSE } from 'store/specialist/consts'
import { PAGE_LIMIT } from './consts'
import { Rating } from '@mui/material'
import { ApiSpecialistService } from 'store/specialist/api.service'
import { TCompany } from 'components/Sidebar/types'
import { sliceActions } from 'store/specialist/reducer'

export const ReviewIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { loading, specialist, specialistReview, response } =
      useTypedSelector(getMainWorkerSelector)
   const { accessToken } = useTypedSelector((state) => state.user)

   const [page, setPage] = useState<number>(0)
   const [searchParams, setSearchParams] = useSearchParams({})
   const [locale] = useLocalization()
   const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false)
   const [declinedRowId, setDeclinedRowId] = useState<number>()

   const { id } = useParams()

   const [searchForm, setSearchForm] = useState({
      search: '',
      date_start: '',
      date_end: ''
   })

   const [filterForm, setFilterForm] = useState({
      category: { value: '', label: '' } as TOption
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const getNewReviews = async () => {
      const specialists: TCompany[] =
         accessToken &&
         (await ApiSpecialistService.getSpecialists({
            token: `Bearer ${accessToken}`,
            order: 1,
            page: 0,
            sortBy: 'createdAt',
            limit: 80
         }).then((res) => res.data.data))

      const newReviews = specialists.reduce(
         (acc, person) => acc + (person?.newReviews || 0),
         0
      )
      dispatch(sliceActions.SET_NEW_REVIEWS(newReviews))
   }

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const data = useMemo(
      () =>
         specialistReview.data.map((review: TSpecialistReview, index: number) => {
            return {
               updatedAt: (
                  <Moment format="DD.MM.YYYY">
                     {new Date(review?.updatedAt as string)}
                  </Moment>
               ),
               author: (
                  <div>
                     {review.customer
                        ? review?.customer?.name + ' ' + review.customer?.secondName
                        : t('deleted.profile')}
                  </div>
               ),
               status: <div>{t(review?.status)}</div>,
               isNewStatus: review.status === 'new',
               mark: (
                  <Rating
                     value={review?.mark}
                     readOnly={true}
                     precision={0.1}
                     size={'small'}
                     max={5}
                  />
               ),
               comment: <div>{`${review?.comment}`}</div>
            }
         }),
      [specialistReview]
   )

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         Requests.getSpecialistReviews()
      },
      paginateDropDownChangeHandler: (value: TOption) => {
         setFilterForm((form) => ({ ...form, category: value }))
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {},
      addClickHandler: () => {
         navigate('/createWorker')
      },
      deleteButtonClickHandler: () => {
         setPopupVisiablity(true)
      },
      backButtonClickHandler: () => {
         navigate(`/workers/${id as string}`)
      },
      closePopupClickHandler: () => {
         setPopupVisiablity(false)
      },
      cancelButtonClickHandler: () => {
         setPopupVisiablity(false)
      },
      removeButtonClickHandler: () => {
         Requests.deleteSpecialist()
         navigate('/workers')
      },
      declineClickHandler: (e: SyntheticEvent, index: number) => {
         Requests.declineReview(index)
         setDeclinedRowId(index)
      },
      approveClickHandler: (e: SyntheticEvent, index: number) => {
         Requests.approveReview(index)
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('date'),
            accessor: 'updatedAt',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('author'),
            accessor: 'author'
         },
         {
            Header: t('status'),
            accessor: 'status',
            width: 250
         },
         {
            Header: t('review'),
            accessor: 'mark',
            width: 100,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('review.text'),
            accessor: 'comment',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/workers/${id as string}`)
            }
         },
         {
            title: t('work.reviews'),
            active: true
         }
      ],
      []
   )

   const Requests = {
      getSpecialistReviews: () => {
         dispatch(
            specialistActions.GET_REVIEW_BY_SPECIALIST({
               _id: id as string,
               lang: locale as ELocales,
               page: page,
               limit: PAGE_LIMIT,
               ...searchForm,
               ...sortParamsForm
            })
         )
      },
      deleteSpecialist: () => {
         dispatch(specialistActions.DELETE_SPECIALIST({ _id: id as string }))
      },
      declineReview: (index: number) => {
         dispatch(
            specialistActions.EDIT_SPECIALIST_REVIEW({
               _id: specialistReview.data[index]._id as string,
               data: { status: 'hidden' }
            })
         )
      },
      approveReview: async (index: number) => {
         dispatch(
            specialistActions.EDIT_SPECIALIST_REVIEW({
               _id: specialistReview.data[index]._id as string,
               data: { status: 'published' }
            })
         )

         setTimeout(() => {
            getNewReviews()
            Requests.getSpecialist()
         }, 300)
      },
      getSpecialist: () => {
         dispatch(specialistActions.GET_SPECIALIST({ _id: id as string }))
      }
   }

   const Utils = {
      getRate: () => {
         if (specialistReview.meta) {
            const num: number =
               (specialist?.sumRating as number) /
               (specialistReview.meta?.totalCount as number)

            const res = Math.round(num * 10) / 10 ? Math.round(num * 10) / 10 : 0
            // if (res <= 5 && res != 0) return 5
            if (res != Infinity) {
               return res
            }
            return 0
         } else {
            return 0
         }
      }
   }

   useEffect(() => {
      if (response == SPECIALIST_RESPONSE.EDITED) {
         Requests.getSpecialistReviews()
      }
   }, [response])

   useEffect(() => {
      setSearchParams({ ...searchParams, page: '' + page })
   }, [filterForm, tableSortBy, searchForm, response, sortParamsForm])

   useEffect(() => {
      Requests.getSpecialistReviews()
      setSearchParams({ ...searchParams, page: '' + page })
   }, [searchForm, sortParamsForm, page])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string)
            : 0
      )
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="red" onClick={Events.deleteButtonClickHandler}>
                        {t('delete.worker')}
                     </Button>
                  </>
               }
               title={t('edit')}
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(specialist?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(specialist?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }
         loading={loading}>
         <SubPagesBar
            revievPage={true}
            sections={sections}
            sumRating={specialist?.newReviews}
         />

         <FlexContainer direction="column" gap="30px">
            <HeaderWrapper>
               <FiltersWrapper>
                  <SearchBar
                     name="search"
                     placeholder={t('search')}
                     value={searchForm.search}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
                  <DateInput
                     name="date_start"
                     value={searchForm.date_start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="date_end"
                     value={searchForm.date_end}
                     onChange={Events.onChangeHandler}
                  />
               </FiltersWrapper>
               <DataTotal style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <p style={{ alignSelf: 'center', paddingBottom: '2px' }}>
                     {t('total.rating')}:
                  </p>
                  <div style={{ padding: '0px 10px' }}>
                     {Utils.getRate() && (
                        <Rating
                           value={Utils.getRate() as number}
                           readOnly={true}
                           precision={0.1}
                           size={'small'}
                        />
                     )}
                  </div>
                  {Utils.getRate() > 0 && (
                     <p style={{ alignSelf: 'center', paddingBottom: '2px' }}>
                        {Utils.getRate() ? Utils.getRate() : '0'}
                     </p>
                  )}
               </DataTotal>
               <DataTotal>
                  {t('total.reviews')}:{specialistReview.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               columns={columns}
               data={data}
               sortBy={tableSortBy}
               approveClickHandler={Events.approveClickHandler}
               declineClickHandler={Events.declineClickHandler}
               approvable
               declineble
            />
         </FlexContainer>

         {popupVisiablity && (
            <Popup
               backgroundClickListener={Events.closePopupClickHandler}
               height="223px"
               width="627px">
               <FlexContainer
                  direction="column"
                  gap="25px"
                  justify="center"
                  align="center">
                  <HeaderText>{t('master.deletion')}</HeaderText>
                  <Text>{t('sure')}</Text>
                  <FlexContainer gap="30px" align="center" justify="center">
                     <Button theme="grey" onClick={Events.cancelButtonClickHandler}>
                        {t('cancel')}
                     </Button>
                     <Button theme="red" onClick={Events.removeButtonClickHandler}>
                        {t('delete')}
                     </Button>
                  </FlexContainer>
               </FlexContainer>
            </Popup>
         )}

         <Pagination
            page={page}
            pageCount={
               specialistReview.meta
                  ? Math.ceil(specialistReview.meta.totalCount / PAGE_LIMIT)
                  : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}
