import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { BORDER, FLEX, FONT } from '../../utils'

export const Container = styled.div`
   ${FLEX({})}
   position: relative;
`

export const SearchBarTemplate = styled.input`
   border-radius: 8px;
   ${BORDER({ color: colors.border_grey_color })}
   width: 273px;
   height: 45px;

   padding-left: 46px;
   ${FONT({ size: '16px', color: colors.grey })}

   line-height: 19px;
`

export const SearchIcon = styled.img.attrs({
   src: assets.SEARCH
})`
   position: absolute;
   top: 13px;
   left: 17px;
`
