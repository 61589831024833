import { TModeration } from 'store/moderation/types'

export const utils = {
   isDescriptionChanged: (changedProduct: TModeration) => {
      if (!changedProduct?.changes?.description) {
         return
      }

      return {
         description:
            changedProduct?.changes?.description[0]?.description !==
            changedProduct?.product?.description?.description,
         metaDescription:
            changedProduct?.changes?.description[0]?.metaDescription !==
            changedProduct?.product?.description?.metaDescription,
         metaKeywords:
            changedProduct?.changes?.description[0]?.metaKeywords !==
            changedProduct?.product?.description?.metaKeywords,
         title:
            changedProduct?.changes?.description[0]?.title !==
            changedProduct?.product?.description?.title
      }
   }
}
