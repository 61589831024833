export const GET_ORDERS = 'GET_ORDERS'
export const SET_ORDERS = 'SET_ORDERS'
export const GET_ORDER = 'GET_ORDER'
export const SET_ORDER = 'SET_ORDER'
export const EDIT_ORDER = 'EDIT_ORDER'
export const SET_ORDER_STATE = 'SET_ORDER_STATE'
export const GET_ARCHIVE = 'GET_ARCHIVE'
export const SET_ARCHIVE = 'SET_ARCHIVE'
export const GET_ALL_ORDERS_BY_CUSTOMER = 'GET_ALL_ORDERS_BY_CUSTOMER'
export const SET_FILTERS = 'SET_FILTERS'
export const REFUND_MONEY = 'REFUND_MONEY'

export enum ORDER_RESPONSE {
   GET_ORDER_SUCCESS,
   GET_ORDER_ERROR,
   EDIT_SUCCESS,
   EDIT_ERROR
}
