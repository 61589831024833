import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   Pagination,
   PlusIcon,
   SearchBar,
   Table,
   TColumnTable,
   TSort
} from '../../../../components'
import { useLocalization, useTypedSelector } from '../../../../hooks'
import { PAGE_LIMIT } from './consts'
import { TOption } from '../../../../components/PaginateDropDownList/types'

import { useNavigate } from 'react-router-dom'
import { ELocales } from '../../../../enums'
import { specialistActions } from '../../../../store/specialist/action'
import { getMainWorkerSelector } from '../../../../store/specialist'
import Moment from 'react-moment'
import ReactStars from 'react-stars'
import { SPECIALIST_RESPONSE } from 'store/specialist/consts'
import { Rating } from '@mui/material'
import React from 'react'
import { nanoid } from '@reduxjs/toolkit'

export const ManageWorkersIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const {
      specialists,
      filters,
      loading: loading
   } = useTypedSelector(getMainWorkerSelector)

   const [page, setPage] = useState<number>(0)

   const [searchParams, setSearchParams] = useSearchParams({})

   const [locale] = useLocalization()

   const [searchForm, setSearchForm] = useState({
      regex: filters.regex ? filters.regex : '',
      start: filters.start ? filters.start : '',
      end: filters.end ? filters.end : ''
   })

   const [filterForm, setFilterForm] = useState({
      category: { value: '', label: '' } as TOption
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)

   const data = useMemo(
      () =>
         specialists.data.map((item) => {
            const publish_date = new Date(item?.createdAt as string).toLocaleDateString(
               'en-US'
            )

            return {
               id: <React.Fragment key={nanoid()}>{item?.id}</React.Fragment>,
               newReviews: item?.newReviews,
               photo: <>{0}</>,
               name: <React.Fragment key={nanoid()}>{item?.name}</React.Fragment>,
               mainSpecialty: (
                  <React.Fragment key={nanoid()}>{item?.mainSpecialty}</React.Fragment>
               ),
               createdAt: (
                  <Moment key={nanoid()} format="DD.MM.YYYY">
                     {new Date(item?.createdAt as string)}
                  </Moment>
               ),
               rating: (
                  <div key={nanoid()}>
                     <Rating
                        value={item?.rating}
                        readOnly={true}
                        precision={0.1}
                        size={'small'}
                        max={5}
                     />
                  </div>
               ),
               salary: (
                  <React.Fragment key={nanoid()}>{item?.salary + ' ₼'}</React.Fragment>
               )
            }
         }),
      [specialists]
   )

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         // Requests.getProducts()
      },
      paginateDropDownChangeHandler: (value: TOption) => {
         setFilterForm((form) => ({ ...form, category: value }))
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      removeCheckedItemsHandler: () => {},
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(specialistActions.SET_FILTERS({ ...searchForm }))
         navigate(`/workers/${specialists.data[index]._id as string}`)
      },
      addClickHandler: () => {
         navigate('/createWorker')
      },
      deleteAll: () => {
         for (let id of selectedItems) {
            dispatch(
               specialistActions.DELETE_SPECIALIST({
                  _id: specialists.data[id]._id as string
               })
            )
         }
         window.location.reload()
      },
      resetFilters: () => {
         dispatch(
            specialistActions.SET_FILTERS({
               end: '',
               start: '',
               regex: ''
            })
         )
         window.location.reload()
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('id.number'),
            accessor: 'id',
            width: 150
         },
         // {
         //    Header: t('photo'),
         //    accessor: 'photo'
         // },
         {
            Header: t('name'),
            accessor: 'name',
            width: 250
         },
         {
            Header: t('specialization'),
            accessor: 'mainSpecialty',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('createdAt'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('reviews'),
            accessor: 'rating',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         }
         // {
         //    Header: t('salary'),
         //    accessor: 'salary',
         //    width: 200,
         //    sortToggleHandler: Events.sortToggleHandler
         // }
      ],
      []
   )

   const Requests = {
      getSpecialists: () => {
         dispatch(
            specialistActions.GET_SPECIALISTS({
               limit: PAGE_LIMIT,
               page,
               lang: locale as ELocales,
               ...searchForm,
               ...sortParamsForm
            })
         )
      },
      getSpecialistsReviews: () => {
         dispatch(specialistActions.GET_SPECIALISTS_REVIEWS())
      }
   }

   useEffect(() => {
      Requests.getSpecialists()
      Requests.getSpecialistsReviews()
      setSearchParams({ ...searchParams, page: '' + page })
   }, [page, filterForm, tableSortBy, searchForm])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string)
            : 0
      )
      dispatch(
         specialistActions.SET_FILTERS({
            end: '',
            start: '',
            regex: ''
         })
      )
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="red" onClick={Events.deleteAll}>
                        {t('delete.all')}
                     </Button>
                     <Button theme="green" onClick={Events.addClickHandler}>
                        <PlusIcon />
                        {t('add')}
                     </Button>
                  </>
               }
               title={t('manage.workers')}
            />
         }
         loading={loading}>
         <FlexContainer direction="column" gap="30px">
            <HeaderWrapper>
               <FiltersWrapper>
                  <SearchBar
                     name="regex"
                     placeholder={t('search')}
                     value={searchForm.regex}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
                  <DateInput
                     name="start"
                     value={searchForm.start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="end"
                     value={searchForm.end}
                     onChange={Events.onChangeHandler}
                  />
                  <div>
                     <Button style="transparant" onClick={Events.resetFilters}>
                        {t('reset.filters')}
                     </Button>
                  </div>
               </FiltersWrapper>

               <DataTotal>
                  {t('total.workers')}: {specialists.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               columns={columns}
               data={data}
               sortBy={tableSortBy}
               checkboxClickHandler={Events.checkboxClickHandler}
               editClickHandler={Events.editButtonClickHandler}
               editable
            />
         </FlexContainer>

         <Pagination
            page={page}
            pageCount={
               specialists?.meta
                  ? Math.ceil(specialists?.meta.totalCount / PAGE_LIMIT)
                  : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}
