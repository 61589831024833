import { createAction } from '@reduxjs/toolkit'
import { ActionTypes } from './actionTypes'
import { sliceActions } from './reducer'
import { TGetBrandsPayload } from './types'

export const brandsActions = {
   ...sliceActions,
   getBrands: createAction(ActionTypes.GET_BRANDS, (payload: TGetBrandsPayload) => ({
      payload
   }))
}
