import { FC } from 'react'
import { colors } from '../../enums'
import { AlignContainer } from '../Styled'
import { RelativeLoadingBar } from './styled'
import { TPreloader } from './types'

const RelativePreloader: FC<TPreloader> = ({
   loading,
   size = '85px',
   primaryColor = colors.blue,
   secondaryColor = colors.solid_white
}) => {
   return (
      <>
         {loading && (
            <AlignContainer style={{ width: '100%', height: '100%' }}>
               <RelativeLoadingBar size={size} primaryColor={primaryColor} secondaryColor={secondaryColor} />
            </AlignContainer>
         )}
      </>
   )
}

export default RelativePreloader
