import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { TResponse } from '../types'
import { getAccessToken } from '../user'
import { roleActions } from './actions'
import { ApiRoleService } from './api.service'
import { TPatchRolePayload } from './types'

function* getRolesWorker() {
   yield put(roleActions.setRoleState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiRoleService, ApiRoleService.getRoles], {
         token
      })
      if (response.data.data) yield put(roleActions.setRoles(response.data))
   } catch (e) {
      yield put(roleActions.setRoles({ data: [], meta: null }))
   }

   yield put(roleActions.setRoleState({ loading: false }))
}

function* patchRoleWorker({ payload }: PayloadAction<TPatchRolePayload>) {
   yield put(roleActions.setRoleState({ loading: true }))

   const token: string = yield call(getAccessToken)
   console.log(payload)
   try {
      yield call([ApiRoleService, ApiRoleService.patchRole], {
         token,
         ...payload
      })
   } catch (e) {}

   yield put(roleActions.setRoleState({ loading: false }))
}

export function* roleWatcher() {
   yield takeLatest(roleActions.getRoles, getRolesWorker)
   yield takeLatest(roleActions.patchRole, patchRoleWorker)
}
