import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   Checkbox,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   ItemsPerRowGridContainer,
   PhotoPicker
} from '../../../../components'
import { useTypedSelector } from '../../../../hooks'
import {
   ADMIN_RESPONSE,
   getMainAdminSelector,
   getMainRoleSelector,
   getUserSelector,
   roleActions,
   userActions
} from '../../../../store'
import { adminActions } from '../../../../store/admin/action'
import { checkboxNames, titles } from './consts'
import { AllowedBlockTitle, PermissionsHeader } from './styled'
import { TAccess, TForm } from './types'

export const ConfigureRole: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { roles, loading: roleLoading } = useTypedSelector(getMainRoleSelector)
   const { login, role } = useTypedSelector(getUserSelector)

   const rolesOptions = useMemo(
      () => roles.data.map((role) => ({ name: role.title, value: role._id })),
      [roles]
   )

   const [form, setForm] = useState<TForm>({
      _id: '',
      title: '',
      permissions: []
   })

   const Events = {
      backButtonClickHandler: () => {
         navigate('../roles')
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      configureRoleClickHandler: () => {
         form.title != 'RoleExampleSuperUser' &&
            dispatch(roleActions.patchRole({ ...form }))

         if (form._id == role?._id) {
            dispatch(userActions.logout())
         }
      },
      checkboxClickHandler: (e: SyntheticEvent) => {
         const name: string = (e.target as HTMLInputElement).name
         const value: boolean = (e.target as HTMLInputElement).checked

         form.title != 'RoleExampleSuperUser' &&
            setForm({
               ...form,
               permissions: value
                  ? [...new Set(form.permissions.concat(name))]
                  : form.permissions.filter((item) => item != name)
            })
      }
   }

   const Requests = {
      getRoles: () => {
         dispatch(roleActions.getRoles())
      }
   }

   useEffect(() => {
      setForm({
         ...form,
         permissions: roles.data
            .map((role) => {
               if (role._id == form._id) {
                  return role.permissions
               } else {
                  return []
               }
            })
            .filter((item) => item)
            .filter((arr) => arr.length)[0],
         title: roles.data
            .map((role) => {
               if (role._id == form._id) {
                  return role.title
               } else {
                  return ''
               }
            })
            .filter((item) => item)
            .filter((arr) => arr.length)[0]
      })
   }, [form._id])

   useEffect(() => {
      Requests.getRoles()
   }, [])

   return (
      <AdminPanelContainer
         loading={roleLoading}
         Header={
            <Header
               title={t('configure.role')}
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.configureRoleClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               backButtonClickHandler={Events.backButtonClickHandler}
            />
         }>
         <FlexContainer justify="space-between" padding="30px">
            <FlexContainer gap="32px" align="flex-start" width="48%">
               <DropDownList
                  name="_id"
                  label={t('position')}
                  placeholder={t('choose.role')}
                  options={rolesOptions}
                  onChange={Events.inputChangeHandler}
                  value={form._id}
               />
               <Input
                  name="title"
                  label={t('position')}
                  placeholder={t('choose.role')}
                  onChange={Events.inputChangeHandler}
                  value={form.title}
               />
            </FlexContainer>

            <FlexContainer width="48%" direction="column" gap="20px">
               <PermissionsHeader>{t('allowed.titles')}</PermissionsHeader>
               <ItemsPerRowGridContainer
                  perRow={2}
                  columnGap="125px"
                  rowGap="20px"
                  padding="0px">
                  {titles?.map((title, index) => (
                     <FlexContainer gap="12px" key={index}>
                        <Checkbox
                           checked={
                              form?.permissions?.length
                                 ? form?.permissions.filter((item) => item == title)
                                      .length
                                    ? true
                                    : false
                                 : false
                           }
                           name={title}
                           onChange={Events.checkboxClickHandler}
                        />
                        <AllowedBlockTitle>{t(title)}</AllowedBlockTitle>
                     </FlexContainer>
                  ))}
               </ItemsPerRowGridContainer>
            </FlexContainer>
         </FlexContainer>
      </AdminPanelContainer>
   )
}
