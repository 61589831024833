import { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { SPECIALIST_URL } from './config'
import {
   TCreateSpecialistRequestPayload,
   TDeleteSpecialistRequestPayload,
   TEditSpecialistRequestPayload,
   TGetSpecialistRequestPayload,
   TGetSpecialistsReviewsRequestPayload,
   TGetSpecialistsRequestPayload,
   TGetReviewBySpecialistRequestPayload,
   TEditSpecialistReviewRequestPayload
} from './types'

export class ApiSpecialistService extends HttpService {
   static getSpecialists({
      token,
      limit = 10,
      page = 0,
      lang = ELocales.ru,
      ...props
   }: TGetSpecialistsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.getSpecialists}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            limit,
            skip: page * limit,
            lang,
            ...props
         }
      })

      return response
   }

   static getSpecialist({
      token,
      _id
   }: TGetSpecialistRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.getSpecialist}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static createSpecialist({
      data,
      token
   }: TCreateSpecialistRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.createSpecialist}`,
         method: 'POST',
         data: data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static editSpecialist({
      token,
      _id,
      data
   }: TEditSpecialistRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.editSpecialist}/${_id}`,
         method: 'PATCH',
         data: data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static deleteSpecialist({
      token,
      _id
   }: TDeleteSpecialistRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.deleteSpecialist}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getSpecialistsReviews({
      token
   }: TGetSpecialistsReviewsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.getSpecialistsReviews}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getReviewBySpecialist({
      token,
      _id,
      lang,
      page,
      limit,
      ...params
   }: TGetReviewBySpecialistRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.getReviewBySpecialist(_id)}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang,
            specialist: _id,
            skip: page * limit,
            ...params
         }
      })

      return response
   }

   static editSpecialistReview({
      token,
      _id,
      data
   }: TEditSpecialistReviewRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${SPECIALIST_URL.editSpecialistReview}/${_id}`,
         method: 'PATCH',
         data: data,
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
