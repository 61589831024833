import { createSlice } from '@reduxjs/toolkit'
import { TDataWrapper, TState } from '../types'
import { TInitialState, TSetPagesPayload } from './types'

const initialState: TInitialState = {
   pages: [],
   loading: false
}

export const slice = createSlice({
   name: 'seo',
   initialState,
   reducers: {
      SET_STATE: (state, { payload }: TDataWrapper<TState>) => {
         state.loading = payload.loading as boolean
      },
      SET_PAGES: (state, { payload }: TDataWrapper<TSetPagesPayload>) => {
         state.pages = payload.pages
      }
   },

   extraReducers: (builder) => {}
})

export const sliceActions = slice.actions

export default slice.reducer
