import { createReducer } from '@reduxjs/toolkit'
import { adminActions } from './action'
import { TInitialState } from './types'

const initialState: TInitialState = {
   adminList: {
      meta: null,
      data: []
   },
   admin: null,
   loading: false,
   response: null
}

const reducer = createReducer<TInitialState>(initialState, (builder) => {
   builder
      .addCase(adminActions.setAdmins, (state, { payload }) => ({ ...state, adminList: { ...payload } }))
      .addCase(adminActions.setAdmin, (state, { payload }) => ({ ...state, admin: { ...payload } }))
      .addCase(adminActions.setAdminState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})

export default reducer
