import { t } from 'i18next'
import { FC, useMemo, useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   InfinityScroll,
   RelativePreloader,
   SubPagesBar,
   TSection
} from '../../../../components'
import { PreloaderContainer } from '../../../../components/SelectProductPopup/styled'
import { colors, ELocales } from '../../../../enums'
import { useDebounceEffect, useLocalization, useTypedSelector } from '../../../../hooks'
import { getMainProductSelector, productActions } from '../../../../store'
import { TProduct } from '../../../../store/product/types'
import { DragAndDropProduct } from './components'
import { PAGE_LIMIT } from './consts'
import { Container } from './styled'
import { RESPONSE } from 'store/product/consts'

const GRID = 8

export const PromotionalProductsIndexPage: FC = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [locale] = useLocalization()

   const {
      products,
      loading: productLoading,
      response
   } = useTypedSelector(getMainProductSelector)

   const [productsByCategory, setProductsByCategory] = useState<TProduct[][]>([])
   const [requestsListLoading, setRequestListLoading] = useState<boolean>(true)

   const filteredProductsByCategory = useMemo(() => {
      return productsByCategory?.flat().filter((value, index, self) => {
         return self?.findIndex((v) => v?._id === value?._id) === index
      })
   }, [productsByCategory])

   const chunkedProductsArr = useMemo(() => {
      const chunkSize = 7
      const res = []

      for (let i = 0; i < filteredProductsByCategory?.length; i += chunkSize) {
         const chunk = filteredProductsByCategory?.slice(i, i + chunkSize)
         res.push(chunk)
      }

      return res
   }, [filteredProductsByCategory])

   const loadProducts = useMemo(() => {
      if (!products?.meta) {
         return setRequestListLoading(false)
      }
      const load = products?.meta?.totalCount > chunkedProductsArr?.flat()?.length
      setRequestListLoading(load)
      return load
   }, [products])

   const [page, setPage] = useState<number>(0)

   const getListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      padding: GRID,
      overflow: 'auto',
      flex: 1
   })

   const Events = {
      removeCheckedItemsHandler: () => {},
      onDragEnd: (products?: TProduct[][], result?: any, index?: number) => {
         const { source, destination } = result

         // dropped outside the list
         if (!destination) {
            return
         }
         const sInd = +source.droppableId
         const dInd = +destination.droppableId

         if (sInd === dInd) {
            const items = Utils.reorder(
               chunkedProductsArr[sInd],
               source.index,
               destination.index
            )
            const newState = [...chunkedProductsArr]
            newState[sInd] = items as TProduct[]
            setProductsByCategory(newState)
         } else {
            const result = Utils.move(
               chunkedProductsArr[sInd],
               chunkedProductsArr[dInd],
               source,
               destination
            )
            const newState = [...chunkedProductsArr]
            newState[sInd] = result.sourceClone as TProduct[]
            newState[dInd] = result.destClone as TProduct[]
            setProductsByCategory(newState)
         }
      },
      nextInfinityScrollHandler: () => {
         if (!loadProducts) {
            return
         }
         setPage(page + 1)
         Requests.getProducts()
      },
      saveButtonClickHandler: () => {
         Requests.editPromotionProducts()
      }
   }

   const Requests = {
      getProducts: () => {
         dispatch(
            productActions.getProducts({
               limit: PAGE_LIMIT,
               page: page,
               lang: locale as ELocales,
               hidden: 'false',
               hideDuplicates: true,
               sortBy: 'discountOrder',
               order: 1,
               primary: false,
               discount: 1
            })
         )
      },
      editPromotionProducts: () => {
         dispatch(
            productActions.editPromotionProducts({
               data: chunkedProductsArr.flat().map((product, index) => ({
                  _id: product._id,
                  data: {
                     discountOrder: index + 1
                  }
               }))
            })
         )
      }
   }

   const Utils = {
      reorder: (row: TProduct[], startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      },
      move: (
         source: TProduct[],
         destination: TProduct[],
         droppableSource: { index: number; droppableId: string },
         droppableDestination: { droppableId: string; index: number }
      ) => {
         const sourceClone = Array.from(source)
         const destClone = Array.from(destination)
         const [removed] = sourceClone.splice(droppableSource.index, 1)

         destClone.splice(droppableDestination.index, 0, removed)

         return { sourceClone, destClone }
      }
   }

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('current'),
            onClickHandler: () => {
               navigate('/products')
            }
         },
         {
            title: t('new.products'),
            onClickHandler: () => {
               navigate(`/products/newProducts`)
            }
         },
         {
            title: t('promotion.products'),
            active: true
         },
         {
            title: t('statistic'),
            onClickHandler: () => {
               navigate('/statistic')
            }
         },
         {
            title: t('changelog'),
            onClickHandler: () => {
               navigate('/changelog')
            }
         }
      ],
      []
   )

   const getItemStyle = (isDragging: boolean, draggableStyle: any, grid: number) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      margin: `0 20px 0 0`,
      background: colors.solid_white,

      // change background colour if dragging

      // styles we need to apply on draggables
      ...draggableStyle
   })

   useEffect(() => {
      dispatch(
         productActions.setProducts({
            data: [],
            meta: null
         })
      )
   }, [])

   useDebounceEffect(
      () => {
         setPage(0)
         setProductsByCategory([])
         Requests.getProducts()
      },
      200,
      []
   )

   useEffect(() => {
      products.data && setProductsByCategory(productsByCategory.concat([products.data]))
   }, [products])

   useDebounceEffect(
      () => {
         if (response == RESPONSE.EDITED) {
            Requests.getProducts()
         }
      },
      250,
      [response]
   )

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('manage.products')}
               buttonsList={
                  <>
                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }
         loading={productLoading}>
         <SubPagesBar sections={sections} />

         <Container>
            <FlexContainer
               align="center"
               gap="30px"
               width="100%"
               wrap="nowrap"
               direction="column">
               <InfinityScroll
                  next={Events.nextInfinityScrollHandler}
                  loading={requestsListLoading}
                  preloader={
                     <PreloaderContainer>
                        <RelativePreloader loading />
                     </PreloaderContainer>
                  }
                  treshold={[0, 0.2, 0.4, 0.6, 0.8, 1]}>
                  <DragDropContext
                     onDragEnd={(result) => Events.onDragEnd(productsByCategory, result)}>
                     {chunkedProductsArr?.map((item, ind) => (
                        <Droppable
                           droppableId={`${ind}`}
                           direction="horizontal"
                           key={ind}>
                           {(provided: any, snapshot: any) => (
                              <div
                                 ref={provided.innerRef}
                                 style={getListStyle(snapshot.isDraggingOver)}
                                 {...provided.droppableProps}>
                                 {provided.placeholder}
                                 {item?.map((product: TProduct, index: number) => (
                                    <Draggable
                                       draggableId={item[index]._id}
                                       index={index}
                                       key={product._id}>
                                       {(provided, snapshot) => (
                                          <div
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                             style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                GRID
                                             )}>
                                             <DragAndDropProduct
                                                key={product._id}
                                                productItem={product}
                                                page={page}
                                             />
                                          </div>
                                       )}
                                    </Draggable>
                                 ))}
                              </div>
                           )}
                        </Droppable>
                     ))}
                  </DragDropContext>
               </InfinityScroll>
            </FlexContainer>
         </Container>
      </AdminPanelContainer>
   )
}
