// Prod
// export const api = {
//    static: 'https://elem-images.elem.az/public/',
//    public: 'https://api.elem.az/',
//    preview: 'https://elem.az/web/1cweb-pictures/',
//    img: 'https://elem-images.elem.az/public/',
//    brandsPath: 'https://elem.az/web/1cweb-pictures/'
// }

//Dev
export const api = {
   static: 'https://elem-images.elem.az/public/',
   public: 'https://elem-api.kitg.com.ua/',
   preview: 'https://elem.az/web/1cweb-pictures/',
   img: 'https://elem-images.kitg.com.ua/public/',
   brandsPath: 'https://elem.az/web/1cweb-pictures/'
}
