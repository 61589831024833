import { AdminPanelContainer, Pagination } from 'components'
import { Header, FilterBar, EditButton, QuestionItem } from './components'
import {
   ApiCalculatorService,
   categoryActions,
   getMainCategorySelector,
   useGetCalculators
} from 'store'
import { useTypedSelector } from 'hooks'
import { FirsLevelWrap, QuestionList } from './styled'
import { useTranslation } from 'react-i18next'
import { NavButton } from './components/Header/styled'
import { FiPlus } from 'react-icons/fi'
import {
   closestCenter,
   DndContext,
   DragEndEvent,
   PointerSensor,
   useSensor,
   useSensors
} from '@dnd-kit/core'
import {
   arrayMove,
   SortableContext,
   verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const LIMIT = 20

export const Calculator = () => {
   const [page, setPage] = useState(1)
   const { order, sortBy } = useTypedSelector((state) => state.calculator)
   const { accessToken } = useTypedSelector((state) => state.user)

   const params = {
      limit: LIMIT,
      skip: (page - 1) * LIMIT,
      order: order,
      sortBy: 'order',
      isParent: true
   }
   const { t } = useTranslation()

   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(
         categoryActions.getCategories({
            limit: 1000,
            value: null,
            field: 'productsTotalCount',
            hidden: 'false'
         })
      )
   }, [])

   const { questions, mutate } = useGetCalculators(params)

   const sensors = useSensors(
      useSensor(PointerSensor, {
         activationConstraint: {
            distance: 10
         }
      })
   )
   const handleDragEnd = async (event: DragEndEvent) => {
      const { active, over } = event
      if (active.id !== over?.id) {
         const oldIndex =
            questions?.data?.data?.findIndex((item) => item._id === active.id) || 0
         const newIndex =
            questions?.data?.data?.findIndex((item) => item._id === over?.id) || 0

         const newOrder = arrayMove(questions?.data?.data || [], oldIndex, newIndex)

         questions &&
            mutate(
               {
                  ...questions,
                  data: {
                     ...questions.data,
                     data: newOrder
                  }
               },
               false
            )
         const newData = newOrder?.map((item, i) => ({
            _id: item._id,
            data: { ...item, order: i + 1 }
         }))
         await ApiCalculatorService.editQuestionCalculatorBulk(newData, accessToken || '')
      }
   }

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={<h2>{t('calculatorQuestions')}</h2>}
               button={
                  <NavButton to="/calculator/createCalculator">
                     <FiPlus /> {t('create')}
                  </NavButton>
               }
            />
         }>
         <FilterBar />
         <FirsLevelWrap>
            <p>{t('firstStage')}</p>
            <EditButton link="/calculator/first-stage" />
         </FirsLevelWrap>{' '}
         <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext
               items={(questions?.data?.data || [])?.map((item) => item._id)}
               strategy={verticalListSortingStrategy}>
               <QuestionList>
                  {questions?.data?.data?.map((item) => (
                     <QuestionItem key={item._id} item={item} mutate={mutate} />
                  ))}
               </QuestionList>
            </SortableContext>
         </DndContext>
         {questions?.data.meta && +questions?.data?.meta?.totalCount / LIMIT > 1 && (
            <Pagination
               onPageChange={(page) => setPage(page.selected + 1)}
               page={page - 1}
               pageCount={
                  questions?.data.meta
                     ? Number(
                          (
                             (questions?.data?.meta?.totalCount as number) / LIMIT
                          ).toFixed()
                       )
                     : 1
               }
            />
         )}
      </AdminPanelContainer>
   )
}
