import { t } from 'i18next'
import { FC, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   PlusIcon,
   SelectProductPopup,
   SubPagesBar,
   TSection
} from '../../../../../../components'
import { Container } from '../../../../../../components/ContentBlock/styled'
import { useTypedSelector } from '../../../../../../hooks'
import { getMainProductSelector, productActions } from '../../../../../../store'
import { RelatedProduct } from './components'
import { colors } from '../../../../../../enums'

export const EditCategoryProductRelatedProducts: FC = () => {
   const [similarProducts, setSimilarProducts] = useState<any[]>([])
   const [ProductPopupInfo, setProductPopupInfo] = useState({
      isOpen: false,
      index: null as null | number
   })

   const GRID = 8

   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { product, similar, loading } = useTypedSelector(getMainProductSelector)

   const { id } = useParams()

   const getListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      padding: GRID,
      overflow: 'auto',
      flex: 1
   })

   useEffect(() => {
      Request.getSimilarProducts()
   }, [])

   useEffect(() => {
      setSimilarProducts(similar)
   }, [similar])

   const Request = {
      getSimilarProducts: () => {
         product?._id &&
            dispatch(productActions.getSimilarProducts({ _id: product?._id }))
      },

      updateSimilarProducts: () => {
         const newSimilarProducts = similarProducts.map((row) => {
            return row.map((product: any, index: number) => ({
               count: index + 1,
               _id: product?._id?._id
            }))
         })

         dispatch(
            productActions.updateSimilarProducts({
               similar: newSimilarProducts,
               _id: product?._id
            })
         )
      }
   }

   const Events = {
      backButtonClickHandler: () => {
         navigate(`/catalog/edit/${id as string}`)
      },
      saveButtonClickHandler: () => {
         Request.updateSimilarProducts()
      },
      setPopupVisiablity: (isOpen: boolean, index?: number) => {
         if (isOpen && index !== undefined) {
            setProductPopupInfo({ isOpen: true, index })
            return
         }

         setProductPopupInfo({ isOpen: false, index: null })
      },
      onDragEnd: (products: any[], result: any, index: number) => {
         // dropped outside the list
         if (!result.destination) {
            return
         }

         const items = Utils.reorder(
            products,
            result.source.index,
            result.destination.index
         )

         console.log(items, result, index)

         setSimilarProducts((state) =>
            state.map((column, colIndex) => (colIndex === index ? items : column))
         )
      },
      productPopupAddHandler: (products: any) => {
         setSimilarProducts((prev) => {
            return prev.map((productsRow, i) => {
               if (i === ProductPopupInfo.index) {
                  return productsRow.concat(
                     products.map((newProduct: any) => ({
                        _id: newProduct,
                        count: 1
                     }))
                  )
               }

               return productsRow
            })
         })

         setProductPopupInfo({ isOpen: false, index: null })
      },
      deleteSimilarProductHandler: (id: string, index: number) => {
         setSimilarProducts((prev) => {
            return prev.map((productsRow, i) => {
               if (i === index) {
                  return productsRow.filter((product: any) => {
                     return product?._id?._id !== id
                  })
               }

               return productsRow
            })
         })
      }
   }

   const Utils = {
      reorder: (row: any, startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      }
   }

   const Sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/catalog/edit/${product?._id}`)
            }
         },
         {
            title: t('characteristics'),
            onClickHandler: () => {
               navigate(`/catalog/characteristics/${product?._id}`)
            }
         },
         {
            title: t('related.orders'),
            active: true
         },
         {
            title: t('variations'),
            onClickHandler: () => {
               navigate(`/catalog/variations/${product?._id}`)
            }
         }
      ],
      []
   )

   const getItemStyle = (isDragging: boolean, draggableStyle: any, grid: number) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      margin: `0 20px 0 0`,
      background: colors.solid_white,

      // change background colour if dragging

      // styles we need to apply on draggables
      ...draggableStyle
   })

   return (
      <AdminPanelContainer
         loading={loading}
         Header={
            <Header
               title={t('edit.products')}
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
               buttonsList={
                  <>
                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }>
         <SubPagesBar sections={Sections} />

         <Container>
            {similarProducts?.map((similarProductsRow, index) => (
               <FlexContainer
                  align="flex-start"
                  gap="30px"
                  width="100%"
                  wrap="nowrap"
                  padding="30px"
                  key={similarProductsRow[0]?.id?._id}>
                  <Button onClick={() => Events.setPopupVisiablity(true, index)}>
                     <PlusIcon />
                     {t('add')}
                  </Button>
                  <DragDropContext
                     onDragEnd={(result, provided) =>
                        Events.onDragEnd(similarProductsRow, result, index)
                     }>
                     <Droppable droppableId="droppable" direction="horizontal">
                        {(provided: any, snapshot: any) => (
                           <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}>
                              {similarProductsRow?.map(
                                 (similarProduct: any, index: number) => (
                                    <Draggable
                                       key={similarProduct?._id?._id}
                                       draggableId={similarProduct?._id?._id}
                                       index={index}>
                                       {(provided, snapshot) => (
                                          <div
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                             style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                similarProductsRow.lenght
                                             )}>
                                             <RelatedProduct
                                                similarProduct={similarProduct}
                                                rowIndex={index}
                                                deleteProducthandler={(
                                                   id: string,
                                                   index: number
                                                ) => {
                                                   Events.deleteSimilarProductHandler(
                                                      id,
                                                      index
                                                   )
                                                }}
                                             />
                                          </div>
                                       )}
                                    </Draggable>
                                 )
                              )}
                              {provided.placeholder}
                           </div>
                        )}
                     </Droppable>
                  </DragDropContext>

                  {ProductPopupInfo.isOpen && (
                     <SelectProductPopup
                        closeClickHandler={() => Events.setPopupVisiablity(false)}
                        addProductClickHandler={(products: any) => {
                           Events.productPopupAddHandler(products)
                        }}
                     />
                  )}
               </FlexContainer>
            ))}
         </Container>
      </AdminPanelContainer>
   )
}
