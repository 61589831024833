import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from 'enums'

export const LinkWrapper = styled(Link)`
   background-color: ${colors.activeInput};

   width: 36px;
   height: 36px;
   color: #ffffff;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 8px;
   transition: background-color 0.3s;
   &:hover {
      background-color: #5b95c1;
   }
   svg {
      width: 24px;
      height: 24px;
   }
`
