import { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { BRANDS_URL } from './config'
import { TBrendsResponse, TEditOrderBrands, TGetBrandsRequestPayload } from './types'

export class ApiBrandService extends HttpService {
   static getBrands({
      limit = 10,
      page = 0,
      lang = ELocales.default,
      ...props
   }: TGetBrandsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: BRANDS_URL.getBrands(),
         method: 'GET',
         params: {
            limit,
            skip: page * limit,
            lang,
            ...props
         }
      })

      return response
   }
   static async editOrderBrands(data: TEditOrderBrands, token: string) {
      await this.request({
         url: BRANDS_URL.editOrder(),
         method: 'PATCH',
         data,
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
   }
   static async getAsyncBrands({
      limit = 10,
      page = 0,
      lang = ELocales.default,
      ...props
   }: TGetBrandsRequestPayload) {
      const { data } = await this.request<TBrendsResponse>({
         url: BRANDS_URL.getBrands(),
         method: 'GET',
         headers: {
            'nest-cache': 'no-cache'
         },
         params: {
            limit,
            skip: page * limit,
            lang,
            ...props
         }
      })

      return data
   }
}
