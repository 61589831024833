import { createSlice } from '@reduxjs/toolkit'
import { TInitialState } from './types'

const initialState: TInitialState = {
   changedProducts: { data: [], meta: null },
   changedProduct: null,
   filters: {
      search: '',
      date_start: '',
      date_end: ''
   },
   loading: false,
   response: ''
}

export const slice = createSlice({
   name: 'moderation',
   initialState,
   reducers: {
      SET_STATE: (state, { payload }) => {
         state.loading = payload.loading
         state.response = payload.response
      },
      SET_CHANGED_PRODUCTS: (state, { payload }) => {
         state.changedProducts = payload
      },
      SET_CHANGED_PRODUCT: (state, { payload }) => {
         state.changedProduct = payload
      },
      SET_FILTERS: (state, { payload }) => {
         state.filters = payload
      }
   }
})

export const sliceActions = slice.actions

export default slice.reducer
