import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper } from '../types'
import { getAccessToken } from '../user'
import { categoryActions } from './actions'
import { ApiCategoryService } from './api.service'
import {
   TChangeProductOrderPayload,
   TCreateCategoryPayload,
   TEditCategoryOrderPayload,
   TEditCategoryPayload,
   TGetCategoriesPayload,
   TGetCategoryPayload,
   TGetProductsByCategoryPayload,
   TRemoveCategoriesPayload
} from './types'

import { TResponse } from '../types'
import { CATEGORY_RESPONSE } from './consts'
import { i18n } from '../../config'
import { ELocales } from '../../enums'
import { PayloadAction } from '@reduxjs/toolkit'
import { productActions } from 'store/product'

function* getCategoriesWorker({ payload }: TDataWrapper<TGetCategoriesPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiCategoryService, ApiCategoryService.getCategories],
         {
            ...payload,
            lang: i18n.language as ELocales,
            token
         }
      )
      if (response.data.data) yield put(categoryActions.setCategories(response.data))
   } catch (e) {}

   if (payload.value == 'null') {
      yield put(
         categoryActions.setCategoriesState({
            loading: false,
            response: CATEGORY_RESPONSE.GET_SECTIONS
         })
      )
   } else {
      yield put(
         categoryActions.setCategoriesState({
            loading: false,
            response: CATEGORY_RESPONSE.DONE
         })
      )
   }
}

function* getCategoryWorker({ payload }: PayloadAction<TGetCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      const response: TResponse = yield call(
         [ApiCategoryService, ApiCategoryService.getCategory],
         { token, ...payload, lang: i18n.language as ELocales }
      )

      if (response.data) yield put(categoryActions.setCategory(response.data.data))
   } catch (e) {}

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.DONE
      })
   )

   yield put(categoryActions.setCategoriesState({ loading: false }))
}

function* removeCategoriesWorker({ payload }: TDataWrapper<TRemoveCategoriesPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _ids } = payload

   try {
      for (let _id of _ids) {
         yield call([ApiCategoryService, ApiCategoryService.removeCategory], {
            token,
            _id
         })
      }
   } catch (e) {}

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.REMOVED
      })
   )
}

function* createCategoryWorker({ payload }: TDataWrapper<TCreateCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)
   const { data } = payload

   try {
      yield call([ApiCategoryService, ApiCategoryService.createCategory], { token, data })
   } catch (e) {}

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.CREATED
      })
   )
}

function* editCategoryWorker({ payload }: TDataWrapper<TEditCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _id, data } = payload
   try {
      yield call([ApiCategoryService, ApiCategoryService.editCategory], {
         token,
         _id,
         data
      })
   } catch (e) {}

   // yield window.location.reload()

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.EDITED
      })
   )
}

function* getCategoryParentWorker({ payload }: PayloadAction<TGetCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      const response: TResponse = yield call(
         [ApiCategoryService, ApiCategoryService.getCategoryParent],
         {
            token,
            ...payload,
            lang: i18n.language as ELocales
         }
      )

      if (response.data.data) yield put(categoryActions.setCategories(response.data))
   } catch (e) {}

   yield put(categoryActions.setCategoriesState({ loading: false }))
}

function* getCategoriesByParentWorker({ payload }: PayloadAction<TGetCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiCategoryService, ApiCategoryService.getCategoriesByParent],
         {
            token,
            ...payload,
            lang: i18n.language as ELocales
         }
      )
      if (response.data.data) yield put(categoryActions.setCategories(response.data))
   } catch (e) {}

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.DONE
      })
   )

   yield put(categoryActions.setCategoriesState({ loading: false }))
}

function* getProductsByCategoryWorker({
   payload
}: PayloadAction<TGetProductsByCategoryPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))
   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      const response: TResponse = yield call(
         [ApiCategoryService, ApiCategoryService.getProductsByCategory],
         {
            token,
            _id,
            lang: i18n.language as ELocales
         }
      )

      if (response.data) yield put(categoryActions.setProductsByCategory(response.data))
   } catch (e) {}

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.DONE
      })
   )

   yield put(categoryActions.setCategoriesState({ loading: false }))
}

function* editCategoryOrderWorker({ payload }: PayloadAction<TEditCategoryOrderPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiCategoryService, ApiCategoryService.editCategoryBulk], {
         ...payload,
         token
      })
   } catch (e) {}

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.DONE
      })
   )

   yield put(categoryActions.setCategoriesState({ loading: false }))
}

function* changeProductOrderWorker({
   payload
}: PayloadAction<TChangeProductOrderPayload>) {
   yield put(categoryActions.setCategoriesState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiCategoryService, ApiCategoryService.changeProductOrder], {
         data: payload,
         token
      })
   } catch (e) {}

   yield put(
      categoryActions.setCategoriesState({
         loading: false,
         response: CATEGORY_RESPONSE.DONE
      })
   )

   yield put(categoryActions.setCategoriesState({ loading: false }))
}

export function* categoryWatcher() {
   yield takeLatest(categoryActions.getCategories, getCategoriesWorker)
   yield takeLatest(categoryActions.removeCategories, removeCategoriesWorker)
   yield takeLatest(categoryActions.createCategory, createCategoryWorker)
   yield takeLatest(categoryActions.editCategory, editCategoryWorker)
   yield takeLatest(categoryActions.getCategoryParent, getCategoryParentWorker)
   yield takeLatest(categoryActions.getCategory, getCategoryWorker)
   yield takeLatest(categoryActions.getCategoriesByParent, getCategoriesByParentWorker)
   yield takeLatest(categoryActions.getProductsByCategory, getProductsByCategoryWorker)
   yield takeLatest(categoryActions.editCategoryOrder, editCategoryOrderWorker)
   yield takeLatest(categoryActions.changeProductOrder, changeProductOrderWorker)
}
