import { LOCALE_KEY } from '../constants'
import { i18n } from '../config'
import { getLocale } from '../utils'
import { TLocales } from '../locales'

const setLocale = (locale: TLocales) => {
   localStorage.setItem(LOCALE_KEY, locale)
   i18n.changeLanguage(locale)
}

const useLocalization = () => {
   return [getLocale(), setLocale] as const
}

export default useLocalization
