import { api } from 'config'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
   AdminPanelContainer,
   SearchBar,
   TDataTable,
   TColumnTable,
   Pagination,
   Table,
   DateInput,
   PlusIcon,
   FlexContainer,
   HeaderWrapper,
   FiltersWrapper,
   DataTotal,
   ProfileImage,
   Header,
   Button
} from '../../../../components'
import { useTypedSelector } from '../../../../hooks'
import {
   getMainAdminSelector,
   adminActions,
   getMainRoleSelector,
   roleActions
} from '../../../../store'

const SettingsPage: FC = () => {
   const ITEM_PER_PAGE = 8

   const { t } = useTranslation()
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const { adminList, loading } = useTypedSelector(getMainAdminSelector)
   const { filters } = useTypedSelector(getMainRoleSelector)
   const [page, setPage] = useState<number | null>(null)
   const [searchForm, setSearchForm] = useState({
      searchValue: filters.searchValue ? filters.searchValue : '',
      startDateValue: filters.startDateValue ? filters.startDateValue : '',
      endDateValue: filters.endDateValue ? filters.endDateValue : ''
   })

   const [selectedItems, setSelectedItems] = useState<number[]>([])

   const [searchParams, setSearchParams] = useSearchParams({})

   const data: TDataTable[] = useMemo(
      () =>
         adminList.data.map((admin) => {
            const createAt = new Date(admin.createdAt).toLocaleDateString('en-US')
            return {
               id: <>{admin.id}</>,
               photo: <ProfileImage src={api.img + 'admin/' + admin.image} />,
               name: (
                  <>
                     {admin.name} {admin.secondName}
                  </>
               ),
               login: admin.login,
               position: Array.isArray(admin.role) && admin.role[0]?.title,
               created: <p>{createAt}</p>
            }
         }),
      [adminList.data]
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('id'),
            accessor: 'id', // accessor is the "key" in the data
            width: 250
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('name'),
            accessor: 'name',
            width: 250
         },
         {
            Header: t('login'),
            accessor: 'login',
            width: 250
         },
         {
            Header: t('position'),
            accessor: 'position',
            width: 250
         },
         {
            Header: t('created'),
            accessor: 'created',
            width: 250
         }
      ],
      []
   )

   const Events = {
      createClickHandler: () => {
         navigate('./create')
      },
      configureRole: () => {
         navigate('/roles/configure')
      },
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
         Requests.getAdmins()
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         Requests.getAdmins()
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      removeClickHandler: (e: SyntheticEvent, index: number) => {
         const { _id } = adminList.data[index]
         Requests.removeAdmin(_id)
      },
      editClickHandler: (e: SyntheticEvent, index: number) => {
         const { _id } = adminList.data[index]
         dispatch(roleActions.setFilters({ ...searchForm }))
         navigate(`./${_id}`)
      },
      removeCheckedItemsHandler: () => {
         selectedItems.forEach((index) => {
            const { _id } = adminList.data[index]
            Requests.removeAdmin(_id)
         })
         setRemoveButtonState(false)
         setSelectedItems([])
      },
      resetFilters: () => {
         dispatch(
            roleActions.setFilters({
               searchValue: '',
               startDateValue: '',
               endDateValue: ''
            })
         )
         window.location.reload()
      }
   }

   const Requests = {
      getAdmins: () => {
         page !== null &&
            dispatch(
               adminActions.getAdmins({
                  limit: ITEM_PER_PAGE,
                  page: page,
                  searchValue: searchForm.searchValue,
                  dateStart: searchForm.startDateValue,
                  dateEnd: searchForm.endDateValue
               })
            )
      },
      removeAdmin: (_id: string) => {
         dispatch(adminActions.removeAdmin({ _id }))
      }
   }

   // Request to receive data if page changed
   useEffect(() => {
      Requests.getAdmins()
      adminList.data.length &&
         setSearchParams({ ...searchParams, page: '' + ((page as number) + 1) })
   }, [page, searchForm])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string) - 1
            : 0
      )

      dispatch(
         roleActions.setFilters({
            searchValue: '',
            startDateValue: '',
            endDateValue: ''
         })
      )
   }, [])

   // Get data if there is no data
   useEffect(() => {
      if (!loading && adminList.meta?.needReload) Requests.getAdmins()
   })

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('roles')}
               buttonsList={
                  <>
                     {removeButtonState && (
                        <Button theme="red" onClick={Events.removeCheckedItemsHandler}>
                           {t('remove.all')}
                        </Button>
                     )}
                     <Button onClick={Events.createClickHandler} theme="green">
                        <PlusIcon />
                        {t('create')}
                     </Button>
                  </>
               }
            />
         }
         loading={loading}>
         <FlexContainer direction="column" gap="30px" width="95%">
            <HeaderWrapper width="100%">
               <FiltersWrapper width="87%">
                  <SearchBar
                     name="searchValue"
                     placeholder={t('search')}
                     value={searchForm.searchValue}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />

                  <DateInput
                     name="startDateValue"
                     value={searchForm.startDateValue}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="endDateValue"
                     value={searchForm.endDateValue}
                     onChange={Events.onChangeHandler}
                  />
                  <Button style="transparant" onClick={Events.resetFilters}>
                     {t('reset.filters')}
                  </Button>
                  <Button width={150} onClick={Events.configureRole}>
                     <PlusIcon />
                     {t('configure.role')}
                  </Button>
               </FiltersWrapper>

               <DataTotal>
                  {t('total.staff')} {adminList.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               columns={columns}
               data={data}
               checkboxClickHandler={Events.checkboxClickHandler}
               removeClickHandler={Events.removeClickHandler}
               editClickHandler={Events.editClickHandler}
               removeable
               editable
            />
         </FlexContainer>

         <Pagination
            page={page || 0}
            pageCount={
               adminList.meta
                  ? Number(
                       ((adminList?.meta?.totalCount as number) / ITEM_PER_PAGE).toFixed()
                    )
                  : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}

export default SettingsPage
