import { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { api } from 'config'
import {
   TGetCalculatorParams,
   TCalculatorQuestion,
   TGetCalculatorQuestions,
   TEditCalculatorBulkType,
   TGetTreeQuestions
} from './types'

const URL = api.public

export class ApiCalculatorService extends HttpService {
   static async getCalculatorQuestions(
      params: TGetCalculatorParams,
      token: string
   ): Promise<AxiosResponse<TGetCalculatorQuestions>> {
      const response = await this.request({
         url: `${URL}v1/catalog/calculator`,
         params,
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
      return response
   }
   static async getCalculatorQuestion(
      id: string,
      lang: string,
      token: string
   ): Promise<AxiosResponse<TGetTreeQuestions>> {
      const response = await this.request({
         url: `${URL}v1/catalog/calculator/${id}`,
         params: { lang },
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
      return response.data
   }
   static async postQuestionCalculator(
      data: Partial<TCalculatorQuestion>,
      token: string
   ): Promise<AxiosResponse<TCalculatorQuestion>> {
      const response = await this.request({
         url: `${URL}v1/catalog/calculator`,
         method: 'POST',
         data,
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
      return response
   }
   static async deleteCalculator(id: string, token: string) {
      await this.request({
         url: `${URL}v1/catalog/calculator/${id}`,
         method: 'DELETE',
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
   }
   static async postQuestionCalculatorBulk(
      data: Partial<TCalculatorQuestion>[],
      token: string
   ): Promise<AxiosResponse<TCalculatorQuestion[]>> {
      const response = await this.request({
         url: `${URL}v1/catalog/calculator/bulk`,
         method: 'POST',
         data,
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
      return response.data
   }
   static async editQuestionCalculator(
      data: Partial<TCalculatorQuestion>,
      token: string,
      id: string
   ): Promise<AxiosResponse<TCalculatorQuestion>> {
      const response = await this.request({
         url: `${URL}v1/catalog/calculator/${id}`,
         method: 'PATCH',
         data,
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
      return response.data
   }
   static async editQuestionCalculatorBulk(
      data: TEditCalculatorBulkType,
      token: string
   ): Promise<AxiosResponse<TCalculatorQuestion>> {
      const response = await this.request({
         url: `${URL}v1/catalog/calculator/bulk`,
         method: 'PATCH',
         data,
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
      return response.data
   }
}
