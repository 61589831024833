import styled from 'styled-components'
import { colors } from '../../enums'

export const Container = styled.div`
   display: flex;
   flex-direction: column;

   height: 100%;
   width: 100%;

   overflow: auto;

   background-color: ${colors.solid_white};

   box-shadow: 0px 14px 28px rgba(135, 135, 135, 0.16);
   border-radius: 22px;

   padding-bottom: 10px;
`
