import { TCustomer } from 'store/order/types'
import { TSort } from '../../components'
import { TFirebase, TLang } from '../../types'
import { TProduct } from 'store/product/types'
import { TBrand } from 'store/brand/types'

export type TInitialState = {
   clients: TClients
   businessClients: TBusinessClients
   client: TClient | null
   filters: {
      searchValue: string
      dateStart: string
      dateEnd: string
   }
   customerDiscountBrands: TGetCustomerByIdResponse | null
   customerDiscountProducts: TGetCustomerByIdResponse | null
   businessCustomerRequest: TBusinessCustomerRequestData | null
   loading: boolean
   response: TClientResponse
}

export type TSetClientStatePayload = {
   loading?: boolean
   response?: TClientResponse
}

export type TClientResponse = 'NOT_FOUND' | 'DELETED' | null

export type TClients = {
   data: TClient[]
   meta: TMetaClients | null
}

export type TBusinessClients = {
   data: TBusinessClient[]
   meta: TMetaClients | null
}

export type TMetaClients = {
   totalCount: number
   lang: TLang
}

export type TClient = {
   _id: string
   id: string
   firebaseId: string
   email: string
   email_verified: boolean
   firebase: TFirebase
   name: string
   secondName: string
   phone: string
   picture: string
   createdAt: string
   updatedAt: string
   group: TGroup
   orders: number
   addresses?: Taddress[]
   blocked: boolean
   guid?: string
}

export type TBusinessClient = {
   _id: string
   fbVerification: false
   regToken: string
   email: string
   picture: string
   balance: number
   secondName: string
   name: string
   blocked: boolean
   id: number
   createdAt: string
   updatedAt: string
   phone: number
   business: boolean
   orders: number
}

export type Taddress = {
   address: string
   customer: string
   lat: number
   lon: number
   _id: string
}

export type TGroup = {
   _id: string
   name: string
   discount: number
}

export type TGetClientsPayload = {
   page?: number
   limit?: number
   lang?: TLang
   searchValue?: string
   dateStart?: string
   dateEnd?: string
   sortBy?: string
   order?: TSort
}

export type TGetBusinessClientsPayload = {
   page?: number
   limit?: number
   lang?: TLang
   searchValue?: string
   dateStart?: string
   dateEnd?: string
   sortBy?: string
   order?: TSort
   business: boolean
}

export type TGetClientsRequestPayload = {
   token: string
} & TGetClientsPayload

export type TGetBusinessClientsRequestPayload = {
   token: string
} & TGetBusinessClientsPayload

export type TGetClientPayload = {
   _id: string
}

export type TGetClientRequestPayload = {
   token: string
} & TGetClientPayload

export type TSetClientPayload = TClient

export type TGetaddressesPayload = {
   _id: string
}

export type TGetaddressesRequestPayload = TGetaddressesPayload & {
   token: string
}

export type TEditClientPayload = {
   _id: string
   data: {
      group?: {
         discount: number
         name: string
         _id: string
      }
      blocked: boolean
   }
}

export type TEditClientRequestPayload = TEditClientPayload & {
   token: string
}

export type TCustomerDiscount = {
   brand?: string
   product?: string
   customer: string
   discount: number
}

export type TCreateCustomerDiscountPayload = { data: TCustomerDiscount }

export type TCreateCustomerDiscountBulkPayload = {
   createdData: (
      | {
           discount: number
           product: string
           customer: string
        }
      | {
           discount: number
           brand: string
           customer: string
        }
   )[]
}

export type TCreateCustomerDiscountRequestPayload = TCreateCustomerDiscountPayload & {
   token: string
}

export type TCreateCustomerDiscountBulkRequestPayload =
   TCreateCustomerDiscountBulkPayload & {
      token: string
   }

export type TCustomerById = {
   _id: string
   discount: number
   forAllProducts: boolean
   createdAt: string
   updatedAt: string
   customer: TCustomer
   product: TProduct
   brand: TBrand
}

export type TGetCustomerByIdResponse = {
   data: TCustomerById[]
   meta: {
      message: string
      statusCode: number
      lang: string
      limit: number
      skip: number
      totalCount: number
   }
}

export type TGetCustomerDiscountByIdPayload = {
   _id: string
   lang: string
   type: CUSTOMER_DISCOUNT
   page: number
   limit: number
}

export enum CUSTOMER_DISCOUNT {
   brand = 'brand',
   product = 'product',
   allProducts = 'allProducts'
}

export type TGetCustomerDiscountByIdRequestPayload = TGetCustomerDiscountByIdPayload & {
   token: string
}

export type TUpdateCustomerDiscountBulkPayload = {
   updatedData?: (
      | {
           _id: string
           discount: number
           brand: string
           customer: string
        }
      | {
           _id: string
           discount: number
           product: string
           customer: string
        }
   )[]
}

export type TUpdateCustomerDiscountBulkRequestPayload =
   TUpdateCustomerDiscountBulkPayload & {
      token: string
   }

export type TDeleteCustomerDiscountBulkPayload = {
   _id: string[]
}

export type TDeleteCustomerDiscountBulkRequestPayload =
   TDeleteCustomerDiscountBulkPayload & {
      token: string
   }

export type TBusinessCustomerRequest = {
   name: string
   tin: string
   phone: string
   email: string
   _id: string
   createdAt: string
   updatedAt: string
}

export type TBusinessCustomerRequestData = {
   data: TBusinessCustomerRequest[]
   meta: TMetaClients | null
}

export type TGetBusinessCustomerRequestPayload = {
   limit: number
   page: number
   lang: string
}

export type TGetBusinessCustomerRequestPayloadRequest =
   TGetBusinessCustomerRequestPayload & {
      token: string
   }
