import { api } from 'config'

export const PRODUCT_URL = {
   getProducts: `${api.public}v3/catalog/product/search`,
   getProduct: `${api.public}v1/catalog/product`,
   getProductsV1: `${api.public}v1/catalog/product/search`,
   createPhotoProduct: `${api.public}v1/file/product`,
   similarProducts: (id: string) => `${api.public}v1/catalog/product/${id}/similar`,
   removeProduct: `${api.public}v1/catalog/product`,
   editProduct: `${api.public}v1/catalog/product`,
   getVariations: `${api.public}v1/catalog/product/search`,
   getSections: `${api.public}v1/catalog/category`,
   getCategoriesBySections: `${api.public}v1/catalog/category`,
   editPromotionProducts: `${api.public}v1/catalog/productBulk/promotion`,
   getStatFileName: `${api.public}v1/statistics/statsToExcel`,
   getVariationsByCategory: `${api.public}v1/catalog/product/variationsMap`,
   getRecommendedProduct: (_id: string) =>
      `${api.public}v1/catalog/product/${_id}/recommended`
}
