import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { BORDER, FONT } from '../../utils'
import { TStyledButtonProps } from './types'

export const StyledButton = styled.button<TStyledButtonProps>`
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 9px;
   cursor: pointer;
   padding: 14px 16px;
   border-radius: 5px;
   width: 100%;
   line-height: 19px;
   transition: background-color 0.3s ease;
   max-width: ${({ width }) => `${width}px`};
   height: ${({ height }) => `${height}px`};

   ${FONT({ color: colors.solid_white, weight: '600', size: '16px' })}

   &.blue {
      background-color: ${colors.blue};
      ${BORDER({ color: colors.blue })};

      &:hover {
         color: ${colors.blue};
         & > * {
            fill: ${colors.blue};
         }

         & * {
            filter: ${colors.filter_blue};
         }
      }
   }

   &.green {
      background-color: ${colors.green};
      ${BORDER({ color: colors.green })};

      &:hover {
         color: ${colors.green};

         & * {
            filter: ${colors.filter_green};
         }
      }
   }

   &.red {
      background-color: ${colors.red};
      ${BORDER({ color: colors.red })};

      &:hover {
         color: ${colors.red};

         & * {
            filter: ${colors.filter_red};
         }
      }
   }

   &:hover {
      background-color: transparent;
      line-height: 19px;
   }

   &.grey {
      background-color: ${colors.grey};
      ${BORDER({ color: colors.grey })};

      &:hover {
         color: ${colors.grey};

         & * {
            filter: ${colors.filter_grey};
         }
      }
   }

   &:hover {
      background-color: transparent;
      line-height: 19px;
   }

   &.transparant {
      background-color: transparent;

      &.red {
         color: ${colors.red};
      }

      &.green {
         color: ${colors.green};
      }

      &.blue {
         color: ${colors.blue};
      }

      &.grey {
         color: ${colors.grey};
      }

      &:hover {
         color: ${colors.solid_white};

         &.red {
            background-color: ${colors.red};
         }

         &.green {
            background-color: ${colors.green};
         }

         &.blue {
            background-color: ${colors.blue};
         }

         &.grey {
            background-color: ${colors.grey};
         }
      }
   }
`

export const EditButton = styled.button<TStyledButtonProps>`
   width: ${({ width }) => `${width}px`};
   height: ${({ height }) => `${height}px`};
   border-radius: 6px;
   background-color: ${colors.blue};
   border: none;
   cursor: pointer;
   position: relative;
   background-color: ${({ theme }) => `${theme}`};
`

export const RemoveButton = styled(EditButton)`
   background-color: ${colors.red};
`

export const EditButtonIcon = styled.img.attrs({ src: assets.EDIT })`
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   width: auto;
   height: 50%;
`

export const RemoveButtonIcon = styled(EditButtonIcon).attrs({
   src: assets.TRASH_BUCKET
})``

export const ApproveButtonIcon = styled(EditButtonIcon).attrs({
   src: assets.APPROVE_ICON
})``

export const DeclineButtonIcon = styled(EditButtonIcon).attrs({
   src: assets.DECLINE_ICON
})``
