import { colors } from 'enums'
import styled from 'styled-components'

export const ProductCardWrap = styled.div`
   width: 278px;
   height: 152px;
   border-radius: 6px;
   border: 1px solid rgb(244, 244, 244);
   display: flex;
   flex-direction: column;
   padding: 10px 16px;
`
export const ToppWrap = styled.div`
   width: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   margin-bottom: 6px;
   img {
      width: 48px;
      height: 48px;
      border-radius: 4px;
   }
   svg {
      width: 48px;
      height: 48px;
      color: ${colors.grey};
   }
   button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      svg {
         width: 25px;
         height: 25px;
      }
   }
`
export const InfoWrap = styled.div`
   span {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 4px;
      display: block;
      &:nth-child(2) {
         font-weight: 400;
         font-size: 14px;
         margin: 0;
      }
   }
`
export const PriceWrap = styled.div`
   display: flex;
   align-items: center;
   margin-top: auto;
   font-size: 14px;
   span {
      display: flex;
      &:nth-child(1) {
         font-weight: 500;
      }
      &:nth-child(2) {
         color: ${colors.grey};
      }
   }
`
