import { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { SEO_URL } from './config'
import { TGetSeoPagesRequestPaylaod, TPostSeoPageRequestPayload } from './types'

export class ApiSeoService extends HttpService {
   static getPages({
      lang,
      token
   }: TGetSeoPagesRequestPaylaod): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: SEO_URL.getPages,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            lang,
            limit: 100
         }
      })

      return response
   }

   static createPage({
      lang,
      page
   }: TPostSeoPageRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: SEO_URL.createPage,
         method: 'POST',
         params: {
            ...page,
            lang
         }
      })

      return response
   }

   static editPage({
      lang,
      page,
      token
   }: TPostSeoPageRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: SEO_URL.editPage(page._id as string),
         method: 'PUT',
         headers: {
            Authorization: token
         },
         data: {
            ...page,
            lang
         }
      })

      return response
   }
}
