import { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { SUPPORT_URL } from './config'
import { TRequestModifyRequestPayload, TSupportRequestsRequestPayload } from './types'

export class ApiSupportService extends HttpService {
   static getRequests({
      token,
      limit,
      page,
      status = ''
   }: TSupportRequestsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request<TResponse>({
         url: `${SUPPORT_URL.getRequests}?limit=${limit}&skip=${limit * page}${
            status && `&status=${status}`
         }`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
      return response
   }

   static modifyRequest({
      token,
      _id,
      status
   }: TRequestModifyRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request<TResponse>({
         url: `${SUPPORT_URL.modifyRequest}/${_id}`,
         method: 'PATCH',
         data: {
            status
         },
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
