import { api } from 'config'
import { t } from 'i18next'
import { Dispatch, FC, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { TCategory } from 'store/category/types'
import { RelatedProductWrapper } from './styled'

type DragAndDropProps = {
   productItem: TCategory
   path?: string
   setBackId?: Dispatch<SetStateAction<string[]>>
   backIds?: string[]
   id?: string
}

export const DragAndDropProduct: FC<DragAndDropProps> = ({
   productItem,
   path,
   setBackId,
   backIds,
   id
}) => {
   const navigate = useNavigate()

   const Events = {
      onClickHandler: () => {
         setBackId && backIds && setBackId(backIds.concat(id as string))
         navigate(`/catalog/${path}/${productItem._id}`)
      }
   }

   return (
      <RelatedProductWrapper>
         <img
            src={`${api.preview}${productItem.image}`}
            alt="product"
            className="product-img"
            style={{ width: '120px' }}
         />
         <img
            src={`${api.img}icon/${productItem.icon}`}
            alt="product"
            className="product-img"
            style={{ width: '120px' }}
         />
         <div
            className="product-name"
            onClick={Events.onClickHandler}
            style={{
               cursor: 'pointer',
               paddingTop: '20px'
            }}>
            {productItem?.title}
         </div>
         <div className="product-name" style={{ paddingTop: '12px' }}>
            {productItem?.productsTotalCount + ''}
         </div>
         <div className="product-name" style={{ paddingTop: '12px' }}>
            {productItem?.categoriesTotalCount ? productItem?.categoriesTotalCount : '0'}
         </div>
         <div className="product-name" style={{ paddingTop: '12px' }}>
            {productItem?.show ? t('show') : t('not.show')}
         </div>
      </RelatedProductWrapper>
   )
}
