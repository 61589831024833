import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { assets } from '../../../../../../../assets'
import { api } from '../../../../../../../config'
import { useLocalization, useTypedSelector } from '../../../../../../../hooks'
import {
   categoryActions,
   getMainProductSelector,
   productActions
} from '../../../../../../../store'
import { TProduct } from '../../../../../../../store/product/types'
import { RelatedProductWrapper } from '../styled'

type DragAndDropProps = {
   productItem: TProduct
   backIds?: string[]
}

export const DragAndDropProduct: FC<DragAndDropProps> = ({ productItem, backIds }) => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { promotionProducts } = useTypedSelector(getMainProductSelector)
   const [locale] = useLocalization()

   const Events = {
      onDeleteProduct: () => {
         Requests.editProduct()
      },
      onClickHandler: () => {
         dispatch(categoryActions.setIds(backIds))
         navigate(`/products/page=0/${productItem._id}`)
      }
   }
   const Requests = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: productItem._id as string }))
      },
      editProduct: () => {
         dispatch(
            productActions.updateProduct({
               _id: productItem._id as string,
               data: {
                  sku: `${productItem.sku}`,
                  sellStatus: productItem.sellStatus,
                  discountPrice: 0,
                  price: Number(productItem.price),
                  show: productItem.show,
                  category: productItem.category._id,
                  type: productItem.type,
                  preview: `${productItem.preview}`,
                  description: [
                     {
                        lang: locale,
                        metaDescription: productItem.description.metaDescription,
                        metaKeywords: productItem.description.metaKeywords,
                        title: productItem.description.title,
                        description: productItem.description.description,
                        attributes: productItem.description.attributes
                     }
                  ],
                  group: productItem.group._id,
                  amount: Number(productItem.amount),
                  brand: productItem.brand
               }
            })
         )
         if (promotionProducts)
            dispatch(
               productActions.setPromotionProducts(
                  promotionProducts?.filter((item) => item._id != productItem._id)
               )
            )
      }
   }

   return (
      <RelatedProductWrapper>
         <img
            src={assets.CROSS}
            alt="delete"
            className="product-delete"
            onClick={Events.onDeleteProduct}
         />
         <img
            src={`${api.preview}${productItem?.preview}`}
            alt="product"
            className="product-img"
         />
         <div className="product-code">
            {productItem?.barcode ? productItem?.barcode : ''}
         </div>
         <div
            className="product-name"
            onClick={Events.onClickHandler}
            style={{ cursor: 'pointer' }}>
            {productItem?.description?.title}
         </div>
         <div className="product-price">
            {productItem?.discountPrice && (
               <div className="product-price__old">{`${productItem?.price} ₼/шт`} </div>
            )}
            <div className="product-price__new">
               {productItem?.discountPrice
                  ? productItem?.discountPrice
                  : productItem?.price}{' '}
               ₼/шт
            </div>
         </div>
      </RelatedProductWrapper>
   )
}
