import axios, { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { USER_URL } from './config'
import {
   TLogoutRequestPayload,
   TRefreshTokenPayload,
   TRefreshTokenResponse,
   TUserActionsPayload,
   TUserPostResponse
} from './types'

export class ApiUserService extends HttpService {
   static login(data: TUserActionsPayload): Promise<AxiosResponse<TUserPostResponse>> {
      const response = this.request<TUserPostResponse>({
         url: USER_URL.login,
         method: 'POST',
         data
      }).then((response) => {
         axios.defaults.headers.common[
            'Authorization'
         ] = `Bearer ${response.data.accessToken}`
         return response
      })
      return response
   }

   static refresh_token(
      data: TRefreshTokenPayload
   ): Promise<AxiosResponse<TRefreshTokenResponse>> {
      const response = this.request<TRefreshTokenResponse>({
         url: USER_URL.refresh_token,
         method: 'POST',
         data
      })

      return response
   }

   static logout({
      token
   }: TLogoutRequestPayload): Promise<AxiosResponse<TRefreshTokenResponse>> {
      const response = this.request<TRefreshTokenResponse>({
         url: USER_URL.logout,
         method: 'POST',
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
