import { t } from 'i18next'
import { FC, SyntheticEvent, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import {
   AdminPanelContainer,
   DataTotal,
   DateInput,
   FlexContainer,
   Header,
   SearchBar,
   Table,
   TColumnTable,
   Textarea,
   TSort
} from '../../../../../../components'
import { Line, Text } from './styled'

export const EditHistoryIndexPage: FC = () => {
   const navigate = useNavigate()

   const [searchForm, setSearchForm] = useState({
      regex: '',
      date_start: '',
      date_end: ''
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)

   const Events = {
      backButtonClickHandler: () => {
         navigate('/history')
      },
      onChangeHandler: () => {},
      onSubmitSearchHandler: () => {},
      sortToggleHandler: () => {},
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setSelectedItems(ckeckedItemsIndex)
         setRemoveButtonState(hasCheckboxesActiveState)
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('id.client'),
            accessor: 'id',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('client'),
            accessor: 'client',
            width: 250
         },
         {
            Header: t('email'),
            accessor: 'email',
            width: 350
         },
         {
            Header: t('createdAt'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('status'),
            accessor: 'status',
            width: 250
         }
      ],
      []
   )

   const data = useMemo(
      () => [
         {
            id: <>{1}</>,
            photo: <></>,
            email: <>{`email@gmail.com`}</>,
            createdAt: <>{<Moment format="DD.MM.YYYY hh:mm">{new Date()}</Moment>}</>
         }
      ],
      []
   )

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('history.subscribers')}
               backButtonClickHandler={Events.backButtonClickHandler}
            />
         }>
         <FlexContainer padding="30px" direction="column">
            <FlexContainer direction="column" align="flex-start" width="70%" gap="8px">
               <Text>{t('mail.text')}</Text>
               <Textarea placeholder={t('enter.text')} height="270px"></Textarea>
            </FlexContainer>
         </FlexContainer>
         <Line />
         <FlexContainer>
            <FlexContainer padding="30px" align="center" justify="space-between">
               <FlexContainer direction="row" width="280px">
                  <SearchBar
                     name="regex"
                     placeholder={t('search')}
                     value={searchForm.regex}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
               </FlexContainer>
               <FlexContainer gap="30px" width="330px">
                  <DataTotal>
                     {t('total.sends')}: {0} {t('clients')}
                  </DataTotal>
               </FlexContainer>
            </FlexContainer>
         </FlexContainer>
         <Table
            columns={columns}
            data={data}
            sortBy={tableSortBy}
            checkboxClickHandler={Events.checkboxClickHandler}
            headerCheckbox={Events.checkedAll}
            visability={false}
         />
      </AdminPanelContainer>
   )
}
