import { colors } from 'enums'
import styled from 'styled-components'
export const ChangelangWrap = styled.div`
   margin: 10px 20px 20px 30px;
   p {
      font-weight: 500;
      margin-bottom: 10px;
   }
   div {
      display: flex;
      gap: 4px;
      span {
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 10px;
         width: 40px;
         height: 30px;
         cursor: pointer;
         border: 1px solid ${colors.grey1};
         &.active {
            border: 1px solid ${colors.blue};
            background-color: ${colors.blue};
            color: #ffffff;
         }
      }
   }
`
