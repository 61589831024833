import { ELocales } from 'enums'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TDescription } from 'store/product/types'
import {
   AdminPanelContainer,
   FlexContainer,
   Header,
   HoverList,
   Pagination,
   SubPagesBar,
   Table,
   TColumnTable,
   TSection,
   TSort
} from '../../../../../../components'
import { useLocalization, useTypedSelector } from '../../../../../../hooks'
import {
   clientActions,
   CLIENT_RESPONSE,
   getMainClientSelector,
   getMainOrderSelector,
   getOrdersSelector,
   orderActions
} from '../../../../../../store'
import { ITEM_PER_PAGE } from './consts'

const IndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [locale] = useLocalization()

   const { orders, loading: ordersLoading } = useTypedSelector(getMainOrderSelector)
   const { client, loading, response } = useTypedSelector(getMainClientSelector)

   const { id } = useParams()
   const [searchParams, setSearchParams] = useSearchParams({})

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [page, setPage] = useState<number>(0)

   const Requests = {
      getClient: () => {
         dispatch(clientActions.getClient({ _id: id as string }))
      },
      getOrders: () => {
         dispatch(
            orderActions.getAllOrdersByCustomer({
               _id: id as string,
               page: page,
               limit: ITEM_PER_PAGE,
               lang: locale as ELocales,
               ...sortParamsForm
            })
         )
      }
   }

   const Events = {
      backButtonClickHandler: () => {
         navigate('../../clients')
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      editClickHandler: (e: SyntheticEvent, index: number) => {
         navigate(`../orders/${orders.data[index]._id}`)
      }
   }

   const data = useMemo(
      () =>
         orders.data.map((order) => {
            return {
               id: order.id,
               createdAt: (
                  <Moment format="DD.MM.YYYY HH:mm">
                     {new Date(order.createdAt as string)}
                  </Moment>
               ),
               order: (
                  <FlexContainer align="center" justify="center">
                     <FlexContainer
                        direction="column"
                        align="flex-start"
                        justify="flex-start"
                        gap="4px"
                        width="170px">
                        <p style={{ textAlign: 'left' }}>
                           {(order.items[0]?.product?.description as TDescription)?.title}
                        </p>
                        {!!order.items.slice(1).length && (
                           <HoverList
                              items={order.items
                                 .slice(1)
                                 .map(
                                    (item) =>
                                       (item.product?.description as TDescription)?.title
                                 )}
                           />
                        )}
                     </FlexContainer>
                  </FlexContainer>
               ),
               paymentStatus: t(order.paymentStatus),
               orderStatus: t(order.orderStatus)
            }
         }),
      [orders]
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('id'),
            accessor: 'id', // accessor is the "key" in the data
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('date'),
            accessor: 'createdAt',
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('order'),
            accessor: 'order',
            width: 250
         },
         {
            Header: t('payment'),
            accessor: 'paymentStatus',
            width: 350,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('order.status'),
            accessor: 'orderStatus',
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const Sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`../clients/${id}`)
            }
         },
         {
            title: t('orders.history'),
            active: true
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getClient()
   }, [])

   useEffect(() => {
      Requests.getOrders()
      setSearchParams({ ...searchParams, page: '' + page })
   }, [page, client, tableSortBy])

   useEffect(() => {
      if (response == CLIENT_RESPONSE.NOT_FOUND) navigate('../../clients')
   }, [response])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string)
            : 0
      )
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('edit')}
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(client?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(client?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }
         loading={ordersLoading}>
         <SubPagesBar sections={Sections} />

         <Table
            columns={columns}
            data={data}
            sortBy={tableSortBy}
            editable
            editClickHandler={Events.editClickHandler}
         />

         <Pagination
            page={page}
            pageCount={
               orders.meta ? Math.ceil(orders.meta.totalCount / ITEM_PER_PAGE) : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}

export default IndexPage
