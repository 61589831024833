import { DropDownList, FlexContainer, Input, KeywordsInput, Textarea } from 'components'
import { t } from 'i18next'
import { useFormInputHook } from 'pages/Products/hooks'
import { Dispatch, FC } from 'react'
import { TProduct, TProducts } from 'store/product/types'
import { FormContainer } from './styled'

type FormContainerProps = {
   form: TProduct
   editedData: TProduct
   setEditedData: Dispatch<TProduct>
   setForm: Dispatch<TProduct>
   variations: TProducts
   productTypeOptions: { name: string; value: string }[]
   visiablityOptions: { name: string; value: string }[]
   statusOptions: { name: string; value: string }[]
   brandsOptions: { name: string; value: string }[]
}

export const InputFormContainer: FC<FormContainerProps> = ({
   form,
   editedData,
   setForm,
   setEditedData,
   variations,
   visiablityOptions,
   productTypeOptions,
   statusOptions,
   brandsOptions
}) => {
   const {
      inputChangeHandler,
      descriptionInputHandler,
      visibilityChangehandler,
      onKeywordsChangeHandler
   } = useFormInputHook({
      form: form as TProduct,
      editedData: editedData as TProduct,
      setForm,
      setEditedData
   })

   // console.log(form?.price > form?.discountPrice)
   // console.log(form?.discountPrice ? form?.discountPrice : 0)
   // console.log(+form?.price > (form?.discountPrice ? form?.discountPrice : 0))

   return (
      <FormContainer>
         <FlexContainer gap="20px 62px">
            <DropDownList
               width="30%"
               name="type"
               label={t('product.type')}
               options={productTypeOptions}
               value={variations.data.length ? 'variated' : 'simple'}
               disabled={variations.data.length ? true : false}
               onChange={inputChangeHandler}
            />
            <DropDownList
               name="visiablity"
               label={t('visiablity')}
               placeholder={t('change.visability')}
               options={visiablityOptions}
               value={
                  form?.show ? visiablityOptions[0].value : visiablityOptions[1].value
               }
               onChange={visibilityChangehandler}
            />
            <Input
               name="barcode"
               type="number"
               label={t('product.code')}
               placeholder={t('enter.code')}
               value={form?.barcode}
               onChange={inputChangeHandler}
            />
         </FlexContainer>
         <FlexContainer width="100%" gap="20px 62px">
            <Input
               name="title"
               width="64%"
               label={t('name')}
               placeholder={t('enter.name')}
               value={form?.description?.title}
               onChange={descriptionInputHandler}
            />
            <DropDownList
               width="30%"
               name="brand"
               placeholder={t('choose.brand')}
               label={t('brand')}
               options={brandsOptions}
               value={form?.brand}
               onChange={inputChangeHandler}
            />
         </FlexContainer>
         <FlexContainer>
            <Textarea
               name="description"
               height="271px"
               placeholder={t('enter.description')}
               label={t('description')}
               onChange={descriptionInputHandler}>
               {form?.description?.description}
            </Textarea>
         </FlexContainer>

         <FlexContainer gap="30px">
            <FlexContainer gap="20px 62px">
               <Input
                  name="price"
                  label={t('price')}
                  placeholder={t('enter.price')}
                  value={form?.price + ''}
                  onChange={inputChangeHandler}
                  error={t('123')}
               />
               <Input
                  name="discountPrice"
                  label={t('discount.price')}
                  placeholder={t('enter.discount.price')}
                  value={form?.discountPrice ? form.discountPrice + '' : ''}
                  onChange={inputChangeHandler}
                  isValid={+form?.price > (form?.discountPrice ? form?.discountPrice : 0)}
                  error={t('Discount price can`t be higher than price')}
               />
            </FlexContainer>
            <FlexContainer gap="20px 62px">
               <Input
                  name="amount"
                  type="number"
                  label={t('amount')}
                  placeholder={t('enter.amount')}
                  value={form?.amount + ''}
                  onChange={inputChangeHandler}
               />
               <DropDownList
                  name="sellStatus"
                  label={t('status')}
                  options={statusOptions}
                  placeholder={t('enter.status')}
                  value={form?.sellStatus}
                  onChange={inputChangeHandler}
               />
            </FlexContainer>

            {/* refactor metaKeywords */}

            {!form?.description?.metaKeywords && (
               <KeywordsInput
                  label={t('meta.keywords')}
                  placeholder={t('meta.keywords')}
                  value={form?.description?.metaKeywords || []}
                  onChange={onKeywordsChangeHandler}
               />
            )}

            {form?.description?.metaKeywords &&
               form?.description?.metaKeywords.length > 0 && (
                  <KeywordsInput
                     label={t('meta.keywords')}
                     placeholder={t('meta.keywords')}
                     value={form?.description?.metaKeywords}
                     onChange={onKeywordsChangeHandler}
                  />
               )}
            {form?.description?.metaKeywords &&
               form?.description?.metaKeywords.length == 0 && (
                  <KeywordsInput
                     label={t('meta.keywords')}
                     placeholder={t('meta.keywords')}
                     value={form?.description?.metaKeywords}
                     onChange={onKeywordsChangeHandler}
                  />
               )}

            <Textarea
               name="metaDescription"
               width="100%"
               height="140px"
               label={t('meta.description')}
               onChange={descriptionInputHandler}
               placeholder={t('meta.description')}>
               {form?.description?.metaDescription ?? ''}
            </Textarea>
         </FlexContainer>
      </FormContainer>
   )
}
