import { ELocales } from 'enums'
import { FC, ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { TDescription } from 'store/product/types'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   H2,
   Header,
   Input,
   PlusIcon,
   ProductHorizontalCard,
   SelectProductPopup,
   Textarea
} from '../../../../../../components'
import { api } from '../../../../../../config'
import { useLocalization, useTypedSelector } from '../../../../../../hooks'
import {
   ApiProductService,
   getMainOrderSelector,
   getUserSelector,
   orderActions,
   OrderStatuses
} from '../../../../../../store'
import { ORDER_RESPONSE } from '../../../../../../store/order/consts'
import {
   TEditOrderData,
   TItem,
   TOrderStatus,
   TPaymentType
} from '../../../../../../store/order/types'
import { TotalSum, UserContainer } from './styled'

export const EditArchiveIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { id } = useParams()
   const { order, loading, response } = useTypedSelector(getMainOrderSelector)
   const { accessToken } = useTypedSelector(getUserSelector)

   const [activeProductsList, setActiveProductsList] = useState<string[]>([])
   const [isProductPopupActive, setIsProductPopupActive] = useState<boolean>(false)
   const [locale] = useLocalization()

   const [form, setForm] = useState({
      paymentType: order?.paymentType || '',
      orderStatus: '',
      deliveryMethod: '',
      items: [] as TItem[]
   })

   const Events = {
      backButtonClickHandler: () => {
         navigate('../orders/archive')
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      productCardChangeHandler: (input: ReactNode) => {
         const _id: string = (input as HTMLInputElement).name
         const count: number = parseInt((input as HTMLInputElement).value)

         setForm((form) => ({
            ...form,
            items: form.items.map((item) =>
               item.product._id == _id ? { ...item, count } : item
            )
         }))
      },
      addProductClickHandler: (products: string[]) => {
         setIsProductPopupActive(false)
         setActiveProductsList(products)
      },
      closePopupClickHandler: () => {
         setIsProductPopupActive(false)
      },
      addButtonClickHandler: () => {
         setIsProductPopupActive(true)
      },
      removeProductClickHandler: (e: SyntheticEvent, _id: string) => {
         setActiveProductsList(activeProductsList.filter((item) => _id != item))
      },
      saveButtonClickHandler: () => {
         Requests.editOrder()
      }
   }

   const Requests = {
      getOrder: () => {
         dispatch(orderActions.getOrder({ _id: id as string, lang: locale as ELocales }))
      },
      editOrder: () => {
         const data: TEditOrderData = {
            paymentType: form.paymentType as TPaymentType,
            orderStatus: form.orderStatus as TOrderStatus,
            items: form.items.map((item) => ({
               count: item.count,
               product: item.product._id
            }))
         }

         dispatch(orderActions.editOrder({ _id: id as string, data }))
      },
      getProduct: async (_id: string) => {
         const response = await ApiProductService.getProduct({
            _id,
            token: accessToken as string
         })
         return response
      }
   }

   const Utils = {
      totalSum: (): number => {
         let total = 0
         form.items.forEach((item) => {
            total += item.count * item.price
         })
         return parseFloat(total.toFixed(2))
      },
      clearAll: () => {
         setActiveProductsList([])
         dispatch(orderActions.setOrder(null))
         dispatch(orderActions.setOrderState({ response: null }))
      }
   }

   const paymentTypeOptions = [
      {
         name: t('online'),
         value: 'online'
      },
      {
         name: t('cash'),
         value: 'cash'
      }
   ]

   const orderStatusOptions = useMemo(
      () =>
         Object.keys(OrderStatuses).map((prop) => ({
            name: t(prop),
            value: OrderStatuses[prop as keyof typeof OrderStatuses]
         })),
      []
   )

   useEffect(() => {
      Requests.getOrder()
   }, [])

   useEffect(() => {
      if (response == ORDER_RESPONSE.GET_ORDER_ERROR) navigate('../orders')
   }, [response])

   // Set items form
   useEffect(() => {
      order?.items &&
         setForm((form) => ({
            ...form,
            paymentType: order.paymentType,
            orderStatus: order.orderStatus,
            items: order?.items.map((item: TItem) => item) as TItem[]
         }))
      order?.items && setActiveProductsList(order?.items.map((item) => item.product._id))
   }, [order, id])

   useEffect(() => {
      ;(response == ORDER_RESPONSE.GET_ORDER_SUCCESS ||
         response == ORDER_RESPONSE.EDIT_SUCCESS) &&
         (async () => {
            const productIdList = form.items.map((item) => item.product._id)

            // Check for non existing product in form
            for (let activeProductId of activeProductsList) {
               if (!productIdList.includes(activeProductId)) {
                  const response = await Requests.getProduct(activeProductId)
                  const product = response.data.data

                  product &&
                     setForm((form) => ({
                        ...form,
                        items: form.items.concat([
                           {
                              count: 1,
                              price: product.price,
                              product: product
                           }
                        ])
                     }))
               }
            }

            // Remove excessive products
            setForm((form) => ({
               ...form,
               items: form.items.filter((item) => {
                  return activeProductsList.includes(item.product._id)
               })
            }))
         })()
   }, [activeProductsList])

   useEffect(() => {
      response == ORDER_RESPONSE.EDIT_ERROR && navigate('../orders')
      response == ORDER_RESPONSE.GET_ORDER_ERROR && navigate('../orders')
   }, [response])

   useEffect(() => {
      return Utils.clearAll()
   }, [])

   return (
      <AdminPanelContainer
         loading={loading}
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={`${t('order')} №${order?.id}`}
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: `${t('order.date')}:`,
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(order?.createdAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }>
         <FlexContainer style={{ padding: '33px 30px' }} direction="column" gap="30px">
            <FlexContainer gap="62px">
               <UserContainer>
                  <h4>{t('client')}</h4>
                  <span>
                     {order?.customer.name} {order?.customer?.secondName}
                  </span>
                  <span>{order?.customer.email}</span>
                  <span>{order?.customer.phone}</span>
               </UserContainer>

               {order?.receiver.anotherReceiver && (
                  <UserContainer>
                     <h4>{t('receiver')}</h4>
                     <span>{order?.receiver.name}</span>
                     <span>{order?.receiver.phone}</span>
                  </UserContainer>
               )}

               <DropDownList
                  name="paymentType"
                  label={t('payment.type')}
                  placeholder={t('select.payment.type')}
                  options={paymentTypeOptions}
                  value={form.paymentType}
                  onChange={Events.inputChangeHandler}
               />

               <DropDownList
                  name="orderStatus"
                  label={t('order.status')}
                  placeholder={t('select.order.status')}
                  options={orderStatusOptions}
                  value={form.orderStatus}
                  onChange={Events.inputChangeHandler}
               />
            </FlexContainer>
            <FlexContainer gap="62px">
               {/* <DropDownList
                        name='deliveryMethod'
                        label={t('delivery.method')}
                        placeholder={t('select.delivery.method')}
                        options={deliveryMethodOptions}
                        value={form.deliveryMethod}
                        onChange={Events.inputChangeHandler}
                    /> */}
               <Input
                  name="deliveryAddress"
                  label={t('delivery.address')}
                  value={order?.delivery?.address}
                  readonly
               />
            </FlexContainer>
            <FlexContainer>
               <Textarea
                  label={t('comment')}
                  placeholder={t('no.comment')}
                  width="698px"
                  readonly>
                  {order?.comment}
               </Textarea>
            </FlexContainer>
         </FlexContainer>
         <FlexContainer direction="column" gap="30px">
            <H2 style={{ paddingLeft: '30px' }}>{t('product')}</H2>
            <FlexContainer direction="row">
               {form?.items.map((item) => (
                  <ProductHorizontalCard
                     _id={item.product._id}
                     key={item.product._id}
                     name={item.product._id}
                     src={`${api.preview}${item.product.preview}`}
                     productName={(item.product?.description as TDescription)?.title}
                     productNumber={item.product?.sku}
                     price={`${item.price.toFixed(2)} ${t('₼/pc')}`}
                     onChange={Events.productCardChangeHandler}
                     removeButtonClickHandler={Events.removeProductClickHandler}
                     amount={item.count}
                     readonly={order?.paymentStatus != 'unpaid'}
                  />
               ))}
            </FlexContainer>
            {/* <FlexContainer justify="space-between" style={{ padding: '0px 30px' }}>
               {order?.paymentStatus == 'unpaid' && (
                  <Button height={30} width={139} onClick={Events.addButtonClickHandler}>
                     <PlusIcon />
                     {t('add')}
                  </Button>
               )}
               <TotalSum>{`${t('total')}: ${Utils.totalSum()} ₼`}</TotalSum>
            </FlexContainer> */}
            <div style={{ marginLeft: '20px' }}>
               <TotalSum>{`${t('order.price')}: ${Utils.totalSum()} ₼`}</TotalSum>
               <TotalSum>{`${t('delivery.price')}: ${order?.deliveryPrice} ₼`}</TotalSum>
               <TotalSum>{`${t('total.sum')}: ${
                  Utils.totalSum() + (order?.deliveryPrice as number)
               } ₼`}</TotalSum>
            </div>
         </FlexContainer>
         {isProductPopupActive && (
            <SelectProductPopup
               closeClickHandler={Events.closePopupClickHandler}
               addProductClickHandler={Events.addProductClickHandler}
               activeItems={activeProductsList}
            />
         )}
      </AdminPanelContainer>
   )
}
