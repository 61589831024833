import { createAction } from '@reduxjs/toolkit'
import {
   CREATE_PHOTO_PRODUCT,
   CREATE_SIMILAR_PRODUCTS,
   GET_PRODUCT,
   GET_PRODUCTS,
   GET_SIMILAR_PRODUCTS,
   REMOVE_PRODUCT,
   SET_PHOTO_PRODUCT,
   SET_PRODUCT,
   SET_PRODUCTS,
   SET_PRODUCT_STATE,
   SET_SIMILAR_PRODUCTS,
   UPDATE_PRODUCT,
   UPDATE_SIMILAR_PRODUCTS,
   EDIT_PRODUCT,
   GET_VARIATIONS,
   SET_VARIATIONS,
   SET_VARIATION_PARAMETERS,
   GET_SUBCATEGORIES,
   SET_SUBCATEGORIES,
   SET_PROMOTION_PRODUCT_ID,
   SET_PROMOTION_PRODUCTS,
   EDIT_PROMOTION_PRODUCTS,
   SET_SECTION,
   SET_CATEGORY,
   SET_SUBCATEGORY,
   GET_VARIATION_PRODUCTS,
   SET_VARIATION_PRODUCTS,
   UPDATE_VISABILITY,
   REMOVE_ALL_PRODUCTS,
   GET_PRODUCTSV1,
   SET_FILTERS,
   SET_DISCOUNT_PRODUCT_ID,
   GET_STAT_FILE_NAME,
   SET_STAT_FILE_NAME,
   GET_VARIATIONS_BY_CATEGORY,
   SET_VARIATIONS_BY_CATEGORY
} from './consts'
import {
   TCreateSimilarProductsPayload,
   TEditProductPayload,
   TGetProductPayload,
   TGetProductsPayload,
   TGetSimilarProductsPayload,
   TGetVariationsPayload,
   TRemoveProductPayload,
   TSetProductPayload,
   TSetProductStatePayload,
   TSetSimilarProductsPayload,
   TUpdateProductPayload,
   TGetSubCategoriesPayload,
   TProduct,
   TEditPromotionProductsPayload,
   TGetVariationProductsPayload,
   TGetProductsV1Payload,
   TGetVariationsByCategoryPayload
} from './types'

export const productActions = {
   getProducts: createAction(GET_PRODUCTS, (payload: TGetProductsPayload) => ({
      payload
   })),
   setProducts: createAction(SET_PRODUCTS, (payload) => ({ payload })),
   setProductState: createAction(
      SET_PRODUCT_STATE,
      (payload: TSetProductStatePayload) => ({ payload })
   ),
   removeProduct: createAction(REMOVE_PRODUCT, (payload: TRemoveProductPayload) => ({
      payload
   })),
   createPhotoProduct: createAction(CREATE_PHOTO_PRODUCT, (payload) => ({ payload })),
   setPhotoProduct: createAction(SET_PHOTO_PRODUCT, (payload) => ({ payload })),

   getProduct: createAction(GET_PRODUCT, (payload: TGetProductPayload) => ({ payload })),
   setProduct: createAction(SET_PRODUCT, (payload: TSetProductPayload) => ({ payload })),

   updateProduct: createAction(UPDATE_PRODUCT, (payload: TUpdateProductPayload) => ({
      payload
   })),

   createSimilarProducts: createAction(
      CREATE_SIMILAR_PRODUCTS,
      (payload: TCreateSimilarProductsPayload) => ({
         payload
      })
   ),
   getSimilarProducts: createAction(
      GET_SIMILAR_PRODUCTS,
      (payload: TGetSimilarProductsPayload) => ({
         payload
      })
   ),
   updateSimilarProducts: createAction(
      UPDATE_SIMILAR_PRODUCTS,
      (payload: TCreateSimilarProductsPayload) => ({
         payload
      })
   ),
   setSimilarProducts: createAction(
      SET_SIMILAR_PRODUCTS,
      (payload: TSetSimilarProductsPayload) => ({
         payload
      })
   ),
   getVariations: createAction(GET_VARIATIONS, (payload: TGetVariationsPayload) => ({
      payload
   })),
   editProduct: createAction(EDIT_PRODUCT, (payload: TEditProductPayload) => ({
      payload
   })),
   setVariations: createAction(SET_VARIATIONS, (payload) => ({ payload })),
   setVariationParameter: createAction(SET_VARIATION_PARAMETERS, (payload) => ({
      payload
   })),

   getSubCategories: createAction(
      GET_SUBCATEGORIES,
      (payload: TGetSubCategoriesPayload) => ({ payload })
   ),
   setSubCategories: createAction(SET_SUBCATEGORIES, (payload) => ({ payload })),

   setPromotionProductId: createAction(SET_PROMOTION_PRODUCT_ID, (payload) => ({
      payload
   })),
   setPromotionProducts: createAction(SET_PROMOTION_PRODUCTS, (payload: TProduct[]) => ({
      payload
   })),

   editPromotionProducts: createAction(
      EDIT_PROMOTION_PRODUCTS,
      (payload: TEditPromotionProductsPayload) => ({ payload })
   ),

   setSection: createAction(SET_SECTION, (payload) => ({ payload })),
   setCategory: createAction(SET_CATEGORY, (payload) => ({ payload })),
   setSubCategory: createAction(SET_SUBCATEGORY, (payload) => ({ payload })),

   getVariationProducts: createAction(
      GET_VARIATION_PRODUCTS,
      (payload: TGetVariationProductsPayload) => ({ payload })
   ),
   setVariationProducts: createAction(SET_VARIATION_PRODUCTS, (payload) => ({ payload })),

   updateVisability: createAction(UPDATE_VISABILITY, (payload) => ({ payload })),

   removeAllProducts: createAction(REMOVE_ALL_PRODUCTS, (payload) => ({ payload })),

   getProductsV1: createAction(GET_PRODUCTSV1, (payload: TGetProductsV1Payload) => ({
      payload
   })),

   setFilters: createAction(SET_FILTERS, (payload) => ({ payload })),

   setDiscountProductId: createAction(SET_DISCOUNT_PRODUCT_ID, (payload) => ({
      payload
   })),

   getStatFileName: createAction(GET_STAT_FILE_NAME, (payload) => ({ payload })),
   setStatFileName: createAction(SET_STAT_FILE_NAME, (payload) => ({ payload })),

   getVariationByCategory: createAction(
      GET_VARIATIONS_BY_CATEGORY,
      (payload: TGetVariationsByCategoryPayload) => ({ payload })
   ),
   setVariationByCategory: createAction(SET_VARIATIONS_BY_CATEGORY, (payload) => ({
      payload
   }))
}
