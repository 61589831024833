import { TFilterParams } from './types'

export const defaultParams: TFilterParams = {
   date_start: '',
   date_end: '',
   query: '',
   category: undefined,
   sortBy: 'createdAt',
   order: -1
   // page: 0
}
