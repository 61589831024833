import { Alert, Snackbar, Switch } from '@mui/material'
import i18next from 'i18next'
import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { string } from 'zod'
import {
   AdminPanelContainer,
   Button,
   Header,
   SubPagesBar,
   Table
} from '../../../../components'
import { ELocales } from '../../../../enums'
import { useTypedSelector } from '../../../../hooks'
import {
   ApiProductService,
   bannerActions,
   BANNER_RESPONSE,
   brandsActions,
   categoryActions,
   getBannerSelector,
   getMainCategorySelector,
   getUserSelector,
   productActions,
   TPostBannerData
} from '../../../../store'
import { TProduct } from '../../../../store/product/types'
import { TSchema } from '../../../../types'
import { useSubPagesPagination, useTableParams } from '../../hooks'
import { ButtonContainer, PlusIcon } from '../../styled'
import { TBanner } from '../../types'
import { ChangelangWrap } from './styled'

const IndexPage: FC = () => {
   const [form, setForm] = useState<TBanner[]>([])
   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const [isClicked, setClick] = useState(false)
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const { t } = useTranslation()
   const dispatch = useDispatch()

   const { banners, loading, response, lang } = useTypedSelector(getBannerSelector)
   const { accessToken } = useTypedSelector(getUserSelector)

   const { columns, data } = useTableParams({
      banners,
      form,
      setForm,
      isClicked
   })
   const { paginationSections } = useSubPagesPagination()

   const Events = {
      changeLang: (lang: ELocales) => {
         dispatch(bannerActions.changeLang(lang))
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },

      addButtonClickHandler: () => {
         setForm((form) =>
            form.concat([
               {
                  start: new Date(new Date().setDate(new Date().getDate())).toISOString(),
                  end: new Date(
                     new Date().setDate(new Date().getDate() + 1)
                  ).toISOString(),
                  order: '' + (form.length + 1),
                  image: null,
                  destination: 'site',
                  linkType: '',
                  link: '',
                  isProductPopupOpen: false,
                  productTitle: ''
               }
            ])
         )
      },
      removeItemButtonClickHandler: (e: SyntheticEvent, index: number) => {
         setAlertText('removed')
         !!form[index]._id && Requests.removeBanner(form[index]._id as string)
         setForm(
            form
               .filter((item, indx) => indx != index)
               .map((item, index) => ({ ...item, order: (index + 1).toString() }))
         )

         if (form.length > 1) {
            const banners: TPostBannerData[] = []
            Utils.validateForm() &&
               form
                  .filter((item, indx) => indx != index)
                  .map((item, index) => ({ ...item, order: (index + 1).toString() }))
                  .forEach((banner) => {
                     const data = new FormData()

                     data.append(
                        'start',
                        ('' + new Date(banner.start).getTime()) as string
                     )
                     data.append('end', ('' + new Date(banner.end).getTime()) as string)
                     data.append('order', banner.order)
                     banner.destination && data.append('destination', banner.destination)
                     banner.linkType && data.append('linkType', banner.linkType)
                     banner.link && data.append('link', banner.link)

                     banner.image && data.append('img', banner.image)

                     banner._id
                        ? banners.push({ data, _id: banner._id })
                        : banners.push({ data })
                  })

            Requests.saveBanners(banners)
            Requests.getBanners(lang)
         }

         toogleIsAlertOpen(true)
      },
      removeItemsButtonClickHandler: () => {
         setAlertText('removed')

         selectedItems.forEach((index) => {
            form[index]._id && Requests.removeBanner(form[index]._id as string)
         })

         setForm(
            form.filter((banner, index) => {
               if (selectedItems.includes(index)) {
                  return false
               }
               return true
            })
         )

         if (form.length > 1) {
            const banners: TPostBannerData[] = []
            Utils.validateForm() &&
               form
                  .filter((item, index) => index != selectedItems[index])
                  .map((item, index) => ({ ...item, order: (index + 1).toString() }))
                  .forEach((banner) => {
                     const data = new FormData()

                     data.append(
                        'start',
                        ('' + new Date(banner.start).getTime()) as string
                     )
                     data.append('end', ('' + new Date(banner.end).getTime()) as string)
                     data.append('order', banner.order)
                     banner.destination && data.append('destination', banner.destination)
                     banner.linkType && data.append('linkType', banner.linkType)
                     banner.link && data.append('link', banner.link)

                     banner.image && data.append('img', banner.image)

                     banner._id
                        ? banners.push({ data, _id: banner._id })
                        : banners.push({ data })
                  })

            Requests.saveBanners(banners)
            Requests.getBanners(lang)
         }

         setRemoveButtonState(false)
         setSelectedItems([])
         toogleIsAlertOpen(true)
      },
      onSaveClickHandler: () => {
         setAlertText('added')
         setClick(true)
         const banners: TPostBannerData[] = []
         Utils.validateForm() &&
            form.forEach((banner) => {
               const data = new FormData()

               data.append('start', ('' + new Date(banner.start).getTime()) as string)
               data.append('end', ('' + new Date(banner.end).getTime()) as string)
               data.append('order', banner.order)
               banner.destination && data.append('destination', banner.destination)
               banner.linkType && data.append('linkType', banner.linkType)
               banner.link && data.append('link', banner.link)

               banner.image && data.append('img', banner.image)
               data.append('lang', lang)
               banner._id
                  ? banners.push({ data, _id: banner._id })
                  : banners.push({ data })
            })

         Utils.validateForm() && Requests.saveBanners(banners)
         Utils.validateForm() && Requests.getBanners(lang)
         Utils.validateForm() && toogleIsAlertOpen(true)
      }
   }

   const Requests = {
      getBanners: (lang: ELocales) => {
         dispatch(bannerActions.getBanners({ lang }))
      },
      removeBanner: (_id: string) => {
         dispatch(bannerActions.removeBanner({ _id }))
      },
      saveBanners: (banners: TPostBannerData[]) => {
         dispatch(bannerActions.saveBanners({ banners }))
      },
      getBrands: () => {
         dispatch(brandsActions.getBrands({ page: 0, limit: 1000 }))
      },
      getCategories: () => {
         dispatch(
            categoryActions.getCategories({ field: 'productsTotalCount', limit: 1000 })
         )
      },
      getProductById: async (_id: string): Promise<string> => {
         const response = await ApiProductService.getProduct({
            token: accessToken as string,
            _id,
            lang: i18next.language as ELocales
         })
         return (response.data.data as TProduct)?.description?.title
      },
      clearStore: () => {
         dispatch(productActions.setProducts({ data: [], meta: null }))
      }
   }

   const Utils = {
      validateForm: (): boolean => {
         let isValid = true

         form.forEach((banner, index) => {
            Object.keys(schema(index)).forEach((prop) => {
               if (!schema(index)[prop as keyof TSchema].condition) isValid = false
            })
         })

         return isValid
      }
   }

   const schema = useCallback(
      (index: number): TSchema => ({
         image: {
            condition: !!form[index].image || !!banners[index]?.image
         },
         start: {
            condition: form[index].start.length > 3
         },
         end: {
            condition: form[index].end.length > 3
         },
         linkType: {
            condition: form[index].linkType?.length > 3
         },
         link: {
            condition: form[index].link?.length > 3
         },
         number: {
            condition:
               +form[index].order > 0 &&
               form.map((item) => item.order).length ==
                  new Set(form.map((item) => item.order)).size
         }
      }),
      [form]
   )

   useEffect(() => {
      ;(async () => {
         const formBanners = []

         for (const banner of banners) {
            const { start, end, _id, order, destination, linkType, link } = banner

            const productTitle =
               linkType == 'product' && link ? await Requests.getProductById(link) : ''

            formBanners.push({
               start,
               end,
               order: '' + order,
               _id,
               image: null,
               destination,
               linkType,
               link,
               isProductPopupOpen: false,
               productTitle
            })
         }

         setForm(formBanners)
      })()
   }, [banners])

   useEffect(() => {
      Requests.getCategories()
      Requests.clearStore()
   }, [])
   useEffect(() => {
      Requests.clearStore()
      Requests.getBanners(lang)
   }, [lang])
   // Send request if get response from saveBanners request
   useEffect(() => {
      response == BANNER_RESPONSE.SAVED && Requests.getBanners(lang)
   }, [response])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('banners')}
               buttonsList={
                  <>
                     {removeButtonState && (
                        <Button
                           theme="red"
                           onClick={Events.removeItemsButtonClickHandler}>
                           {t('remove.all')}
                        </Button>
                     )}

                     <Button theme="green" onClick={Events.onSaveClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }
         loading={loading}>
         <SubPagesBar sections={paginationSections} />
         <ChangelangWrap>
            <p>{t('bannerLanguage')}</p>
            <div>
               <span
                  onClick={() => Events.changeLang(ELocales.ru)}
                  className={lang === ELocales.ru ? 'active' : ''}>
                  {ELocales.ru}
               </span>
               <span
                  onClick={() => Events.changeLang(ELocales.az)}
                  className={lang === ELocales.az ? 'active' : ''}>
                  {ELocales.az}
               </span>
            </div>
         </ChangelangWrap>
         <Table
            columns={columns}
            data={data}
            checkboxClickHandler={Events.checkboxClickHandler}
            removeClickHandler={Events.removeItemButtonClickHandler}
            removeable
            editClickHandler={() => {}}
         />
         <ButtonContainer>
            <Button width={139} height={40} onClick={Events.addButtonClickHandler}>
               <PlusIcon />
               <span>{t('add')}</span>
            </Button>
         </ButtonContainer>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`banner.successfuly.${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}

export default IndexPage
