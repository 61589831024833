import { colors } from 'enums'
import styled from 'styled-components'
export const ProductItemStyled = styled.li`
   min-height: 74px;
   width: 100%;
   display: flex;
   align-items: center;
   div {
      padding: 0 10px;
      display: flex;
      &:nth-child(1) {
         padding: 0 10px 0 0;
         width: 40px;
      }
      &:nth-child(2) {
         width: 180px;
      }
      &:nth-child(3) {
         width: 80px;
         img {
            width: 58px;
            height: 58px;
            object-fit: cover;
         }
         svg {
            width: 58px;
            height: 58px;
            color: ${colors.grey};
         }
      }
      &:nth-child(4) {
         width: 220px;
      }
      &:nth-child(5) {
         width: 160px;
      }
      &:nth-child(6) {
         width: 200px;
      }
      &:nth-child(7) {
         width: 120px;
      }
      &:nth-child(8) {
         width: 70px;
         justify-content: flex-end;
         padding: 0 0 0 10px;
      }
   }
`
