import { TagsInput } from 'react-tag-input-component'
import styled from 'styled-components'
import { colors } from '../../enums'
import { BORDER, FONT } from '../../utils'
import { TInputContainer, TStyledInputProps } from './types'

export const TagsInputContainer = styled.div<TInputContainer>`
   width: 100%;

   background-color: ${({ changedProduct }) =>
      changedProduct ? colors.changedProduct : ''};
   .rti--tag {
      background-color: ${colors.blue};
      color: ${colors.solid_white};

      button {
         color: ${colors.solid_white};
         font-weight: 900;
      }
   }

   .rti--container {
      border-color: ${colors.border_grey_color};
      width: ${({ width }) => width};
      min-height: ${({ height }) => height};
      height: auto;
   }
   .rti--input {
      background-color: ${({ changedProduct }) =>
         changedProduct ? colors.changedProduct : ''};
   }
`

export const StyledInput = styled(TagsInput)<TStyledInputProps>`
   ${BORDER({})}

   border-color: ${({ error }) => (error ? colors.red : colors.border_grey_color)};
`

export const StyledError = styled.p`
   ${FONT({ color: colors.red })};
   max-width: 320px;
   &.error {
      color: ${colors.solid_white};
   }
`
