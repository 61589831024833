import { colors } from 'enums'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderWithArrow = styled(Link)`
   display: flex;
   align-items: center;
   text-decoration: none;
   font-size: 22px;
   font-weight: 600;
   color: ${colors.black};
   svg {
      width: 20px;
      height: 20px;
      color: ${colors.blue};
      margin-right: 8px;
   }
`
export const ButtonSave = styled.button`
   width: 195px;
   height: 46px;
   display: flex;
   background-color: ${colors.green};
   border: none;
   text-decoration: none;
   cursor: pointer;
   font-size: 16px;
   border-radius: 8px;
   align-items: center;
   justify-content: center;
   color: ${colors.solid_white};
   transition: background-color 0.3s;
   &:hover {
      background-color: #4caa55;
   }
   &[disabled] {
      background-color: ${colors.grey};
   }
`
