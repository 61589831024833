import { AdminPanelContainer } from 'components'
import { Header } from 'pages/Calculator/components'
import { useTranslation } from 'react-i18next'
import { ButtonSave, HeaderWithArrow } from './styled'
import { IoIosArrowBack } from 'react-icons/io'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TCreateCalculatorForm } from './types'
import { TopInputs, Question } from './components'
import { setDefaultQuestion, createData } from './helpers'
import { ApiCalculatorService } from 'store/calculator/api.service'
import { i18n } from 'config'
import { useTypedSelector } from 'hooks'

import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export const CreateCalculator = () => {
   const navigate = useNavigate()
   const {
      handleSubmit,
      register,
      setValue,
      control,
      watch,
      formState: { errors }
   } = useForm<TCreateCalculatorForm>({
      defaultValues: {
         questions: [setDefaultQuestion()],
         typeofrooms: {
            corridor: true,
            livingRoom: true,
            bathroom: true,
            bedroom: true,
            other: true,
            kitchen: true
         }
      }
   })
   const lang = i18n.language
   const { accessToken } = useTypedSelector((state) => state.user)

   const onSubmit: SubmitHandler<TCreateCalculatorForm> = async (data) => {
      const newData = createData(data, lang)
      if (newData) {
         try {
            await ApiCalculatorService.postQuestionCalculatorBulk(
               newData,
               accessToken || ''
            )
            toast.success(t('creationWasSuccessful'))
            navigate(-1)
         } catch (error) {
            toast.error(t('anErrorHasOccurred'))
         }
      } else {
         toast.info(t('fillInAllProducts'))
      }
   }

   const { t } = useTranslation()
   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <AdminPanelContainer
            Header={
               <Header
                  title={
                     <HeaderWithArrow to="/calculator">
                        <IoIosArrowBack />
                        {t('createQuestion')}
                     </HeaderWithArrow>
                  }
                  button={<ButtonSave type="submit">{t('save')}</ButtonSave>}
               />
            }>
            <TopInputs
               watch={watch}
               setValue={setValue}
               register={register}
               control={control}
               errors={errors}
            />
            <Question
               setValue={setValue}
               errors={errors}
               watch={watch}
               register={register}
               control={control}
            />
         </AdminPanelContainer>
      </form>
   )
}
