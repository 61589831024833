import styled from 'styled-components'
import { FONT } from '../../../../utils'

export const ClickbleText = styled.p`
   ${FONT({ size: '16px', weight: '600', color: '#637B96' })}

   &:hover {
      color: black;
      transition: 0.3s;
      cursor: pointer;
   }
`
