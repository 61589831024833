export const GET_SECTIONS = 'GET_SECTIONS'
export const SET_SECTIONS = 'SET_SECTIONS'
export const SET_SECTION_STATE = 'SET_SECTION_STATE'
export const CREATE_SECTION = 'CREATE_SECTION'
export const EDIT_SECTION = 'EDIT_SECTION'
export const REMOVE_SECTIONS = 'REMOVE_SECTIONS'

export enum SECTION_RESPONSE {
   CREATED = 'CREATED',
   EDITED = 'EDITED',
   REMOVED = 'REMOVED'
}
