import styled from 'styled-components'
import { colors } from '../../enums'
import { FONT } from '../../utils'

export const Container = styled.div`
   display: flex;
   border: 1px solid ${colors.table_grey};
   border-radius: 2px;
   height: 32px;
   align-items: center;
   width: 122px;
   max-width: 100%;
   justify-content: space-around;

   user-select: none;
`

export const Button = styled.button`
   border: none;
   background-color: transparent;
   cursor: pointer;
   width: 14px;
   height: 14px;
   img {
      filter: ${colors.filter_black};
      transition: filter 0.2s ease;
   }

   &:hover img {
      filter: ${colors.filter_blue};
   }

   display: flex;
   justify-content: center;
   align-items: center;
`

export const Input = styled.input.attrs({ type: 'number' })`
   border: none;
   width: 30px;
   ${FONT({ align: 'center', size: '14px', weight: '500' })}
   -moz-appearance: textfield;
   outline: none;

   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
`
