import { colors } from 'enums'
import styled from 'styled-components'
import { FONT } from 'utils'

export const BrandsList = styled.div`
   display: flex;
   flex-direction: column;
   padding: 20px 0;
`
export const BrendItem = styled.div`
   display: flex;
   background-color: ${colors.solid_white};
   padding: 5px 20px;
   align-items: center;
   gap: 20px;
   border-bottom: 1px solid ${colors.grey1};
   height: 90px;
   svg {
      width: 80px;
      height: 80px;
      color: ${colors.grey};
   }
   img {
      max-width: 500px;
      max-height: 80px;
      border-radius: 8px;
      /* object-fit: cover; */
      object-position: center;
   }
   p {
      width: 400px;
      font-size: 28px;
      font-weight: 600;
   }
   span {
      width: 60px;
      font-size: 18px;
      font-weight: 600;
   }
`
export const LoadMoreButton = styled.button`
   outline: none;
   background-color: ${colors.blue};
   border: 1px solid ${colors.blue};
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 9px;
   cursor: pointer;
   padding: 14px 16px;
   height: 49px;
   border-radius: 5px;
   width: 195px;
   line-height: 19px;
   ${FONT({ color: colors.solid_white, weight: '600', size: '16px' })}
   transition: background-color 0.3s ease;
   &:hover {
      color: ${colors.blue};
      & > * {
         fill: ${colors.blue};
      }

      & * {
         filter: ${colors.filter_blue};
      }
   }

   &:hover {
      background-color: transparent;
      line-height: 19px;
   }
`
