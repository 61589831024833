import { UseFormWatch } from 'react-hook-form'
import { t } from 'i18next'
import { i18n } from 'config'
import { TEditCalculatorForm } from '../../types'
import { createId } from 'pages/Calculator/Pages/CreateCalculator/helpers'

export const setDefaultElement = (
   watch: UseFormWatch<TEditCalculatorForm>,
   index: number,
   subIndex: number
) => {
   const langInput = i18n.language === 'ru' ? 'questionRu' : 'questionAz'
   const openQuestions = watch(`questions`)
      .slice(0, index + 1)
      .filter((item) => item.closedQuestion === false)
      .map((item) => ({
         label: item[langInput],
         value: 'question',
         type: 'block',
         id: item.id
      }))
   const generalParams = Object.entries(watch('typeofrooms')).filter(
      ([_, value]) => !!value
   ).length
      ? [
           {
              label: t(`totalArea`),
              value: `totalArea`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`wallArea`),
              value: `wallArea`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`maxHeight`),
              value: `maxHeight`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`totalLength`),
              value: `totalLength`,
              type: 'block',
              id: createId()
           },
           {
              label: t(`totalWidth`),
              value: `totalWidth`,
              type: 'block',
              id: createId()
           }
        ]
      : []

   const activeRoomsArray = Object.entries(watch('typeofrooms'))
      .filter(([key, value]) => value && key !== 'other')
      .flatMap(([key]) => [
         {
            label: t(`${key}Area`),
            value: `${key}Area`,
            type: 'block',
            id: createId()
         },
         {
            label: t(`${key}Walls`),
            value: `${key}Walls`,
            type: 'block',
            id: createId()
         },
         {
            label: t(`${key}Length`),
            value: `${key}Length`,
            type: 'block',
            id: createId()
         },
         {
            label: t(`${key}Width`),
            value: `${key}Width`,
            type: 'block',
            id: createId()
         }
      ])

   return [...openQuestions, ...activeRoomsArray, ...generalParams]
}

export const signs = [
   { label: '+', value: '+', type: 'signs', id: createId() },
   { label: '/', value: '/', type: 'signs', id: createId() },
   { label: '-', value: '-', type: 'signs', id: createId() },
   {
      label: 'x',
      value: '*',
      type: 'signs',
      id: createId()
   },
   {
      label: t('inputField'),
      value: 'inputField',
      type: 'signs',
      id: createId(),
      inputValue: ''
   }
]
