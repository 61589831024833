import { Alert, Snackbar } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { ApiSupportService } from 'store/support/api.service'
import {
   SpaceBetweenContainer,
   AdminPanelContainer,
   FlexContainer,
   Input,
   Table,
   TColumnTable,
   TDataTable,
   Textarea,
   DropDownList,
   Button,
   InfinityScroll,
   RelativePreloader,
   Header
} from '../../components'
import { useTypedSelector } from '../../hooks'
import {
   getSupportRequestsSelector,
   getSupportSelector,
   supportActions
} from '../../store'
import { TRequest } from '../../store/support/types'
import { ITEM_PER_PAGE } from './consts'

import {
   Container,
   DateField,
   FormContainer,
   Message,
   PreloaderContainer,
   TableContainer
} from './styled'

const IndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()

   const requests = useTypedSelector(getSupportRequestsSelector)
   const { loading } = useTypedSelector(getSupportSelector)

   const [requestsListLoading, setRequestListLoading] = useState<boolean>(true)
   const [page, setPage] = useState<number>(0)
   const [requestTargetIndex, setRequestTargetIndex] = useState<number>(-1)
   const [supportRequests, setSupportRequests] = useState<TRequest[]>([])
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()
   const { accessToken } = useTypedSelector((state) => state.user)
   const [form, setForm] = useState({
      status: ''
   })

   const getNewMesageCount = async () => {
      const count: number =
         accessToken &&
         (await ApiSupportService.getRequests({
            token: `Bearer ${accessToken}`,
            status: 'new',
            limit: 1,
            page: 0
         }).then((res: any) => res.data.meta?.totalCount))
      dispatch(supportActions.setNewMessageCount(count))
   }

   const statusOptions = useMemo(
      () => [
         {
            name: t('new'),
            value: 'new'
         },
         {
            name: t('unprocessed'),
            value: 'unprocessed'
         },
         {
            name: t('processed'),
            value: 'processed'
         }
      ],
      []
   )

   const data: TDataTable[] = useMemo(
      () =>
         supportRequests.map((request) => {
            return {
               date: (
                  <Moment format="DD.MM.YYYY HH:mm">{new Date(request.createdAt)}</Moment>
               ),
               message: <Message>{request.message}</Message>,
               status: <>{t(request.status)}</>,
               isNewStatus: request?.status === 'new'
            }
         }),
      [supportRequests]
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('date'),
            accessor: 'date' // accessor is the "key" in the data
         },
         {
            Header: t('message'),
            accessor: 'message',
            width: '356px'
         },
         {
            Header: t('status'),
            accessor: 'status'
         }
      ],
      []
   )

   const Requests = {
      getRequests: () => {
         dispatch(supportActions.getRequests({ page, limit: ITEM_PER_PAGE }))
      },
      modifyRequest: async () => {
         dispatch(
            supportActions.modifyRequest({
               _id: supportRequests[requestTargetIndex]._id,
               status: form.status
            })
         )
      }
   }

   const Events = {
      requestClickHandler(index: number) {
         setRequestTargetIndex(index)
      },
      nextInfinityScrollHandler: () => {
         setPage((page) => page + 1)
         Requests.getRequests()
      },
      changeStatusHandler: (e: SyntheticEvent) => {
         setForm((form) => ({
            ...form,
            status: (e.target as HTMLSelectElement).value
         }))
      },
      saveButtonHandler: () => {
         setAlertText('support.message.edited')
         Requests.modifyRequest()
         toogleIsAlertOpen(true)
         setTimeout(getNewMesageCount, 250)
      }
   }

   const Utils = {
      resetStates: () => {
         setSupportRequests([])
         setPage(0)
         setRequestTargetIndex(-1)
         setRequestListLoading(true)
      }
   }

   // Append new support request data in data state
   useEffect(() => {
      if (requests.meta?.totalCount) {
         if (requests.meta?.totalCount > supportRequests.length + requests.data.length) {
            setSupportRequests(supportRequests.concat(requests.data))
         } else if (
            requests.meta?.totalCount ==
            supportRequests.length + requests.data.length
         ) {
            setSupportRequests(supportRequests.concat(requests.data))
            setRequestListLoading(false)
         } else {
            setRequestListLoading(false)
         }
      }
   }, [requests.data])

   // Check if loading was done after saving data and reset all data
   useEffect(() => {
      if (!loading && supportRequests.length) {
         Utils.resetStates()
         Requests.getRequests()
      }
   }, [loading])

   useEffect(() => {
      requestTargetIndex >= 0 &&
         setForm((form) => ({
            ...form,
            status: supportRequests[requestTargetIndex].status
         }))
   }, [requestTargetIndex])

   return (
      <AdminPanelContainer Header={<Header title={t('support')} />} loading={loading}>
         <Container>
            <TableContainer>
               <InfinityScroll
                  next={Events.nextInfinityScrollHandler}
                  loading={requestsListLoading}
                  preloader={
                     <PreloaderContainer>
                        <RelativePreloader loading />
                     </PreloaderContainer>
                  }>
                  <Table
                     columns={columns}
                     data={data}
                     rowClickHandler={Events.requestClickHandler}
                  />
               </InfinityScroll>
            </TableContainer>

            {requestTargetIndex >= 0 && (
               <FormContainer style={{ overflowY: 'auto' }}>
                  <DateField>
                     <Moment format="DD.MM.YYYY HH:mm">
                        {new Date(supportRequests[requestTargetIndex].createdAt)}
                     </Moment>
                  </DateField>
                  <SpaceBetweenContainer
                     key={supportRequests[requestTargetIndex]._id}
                     style={{ marginTop: '49px' }}>
                     <Input
                        name="name"
                        label={t('name')}
                        value={supportRequests[requestTargetIndex].name}
                        readonly
                     />
                     <Input
                        name="phone"
                        label={t('phone')}
                        value={supportRequests[requestTargetIndex].phone}
                        readonly
                     />
                  </SpaceBetweenContainer>
                  <FlexContainer direction="row" style={{ marginTop: '30px' }}>
                     <Textarea label={t('message')} readonly>
                        {supportRequests[requestTargetIndex].message}
                     </Textarea>
                  </FlexContainer>
                  <SpaceBetweenContainer style={{ marginTop: '30px' }}>
                     <DropDownList
                        options={statusOptions}
                        onChange={Events.changeStatusHandler}
                        value={form.status}
                     />
                     <Button width={209} height={45} onClick={Events.saveButtonHandler}>
                        {t('save')}
                     </Button>
                  </SpaceBetweenContainer>
               </FormContainer>
            )}
         </Container>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}

export default IndexPage
