import { createReducer } from '@reduxjs/toolkit'
import { bannerActions } from './actions'
import { TInitialState } from './types'
import i18n from 'config/localization'
import { ELocales } from 'enums'

const InitialState: TInitialState = {
   banners: [],
   loading: false,
   lang: i18n.language as ELocales
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(bannerActions.setBanners, (state, { payload }) => ({
         ...state,
         banners: payload
      }))
      .addCase(bannerActions.changeLang, (state, { payload }) => ({
         ...state,
         lang: payload
      }))
      .addCase(bannerActions.setBannerState, (state, { payload }) => ({
         ...state,
         response: null,
         ...payload
      }))
})

export default reducer
