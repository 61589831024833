import { t } from 'i18next'
import { FC, SyntheticEvent, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import { assets } from '../../../../assets'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   Popup,
   SearchBar,
   Table,
   TColumnTable,
   Textarea,
   TSort
} from '../../../../components'
import { HeaderText, Text } from '../../styled'
import { ClickbleText } from './styled'

export const SubscribersIndexPage: FC = () => {
   const navigate = useNavigate()

   const [searchForm, setSearchForm] = useState({
      regex: ''
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false)

   const Events = {
      onChangeHandler: () => {},
      onSubmitSearchHandler: () => {},
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      editButtonClickHandler: () => {},
      sortToggleHandler: () => {},
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setSelectedItems(ckeckedItemsIndex)
         setRemoveButtonState(hasCheckboxesActiveState)
      },
      closePopupClickHandler: () => {
         setPopupVisiablity(false)
      },
      cancelButtonClickHandler: () => {
         setPopupVisiablity(false)
      },
      saveButtonClickHandler: () => {
         //  Requests.deleteSpecialist()
         //  navigate('/workers')
      },
      headerSaveButtonClickHandler: () => {
         setPopupVisiablity(true)
      },
      historyButtonClickHandler: () => {
         navigate('/history')
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('id.client'),
            accessor: 'id',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('client'),
            accessor: 'client',
            width: 250
         },
         {
            Header: t('email'),
            accessor: 'email',
            width: 350
         },
         {
            Header: t('createdAt'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('status'),
            accessor: 'status',
            width: 250
         }
      ],
      []
   )

   const data = useMemo(
      () => [
         {
            id: <>{1}</>,
            photo: <></>,
            email: <>{`email@gmail.com`}</>,
            createdAt: <>{<Moment format="DD.MM.YYYY hh:mm">{new Date()}</Moment>}</>
         }
      ],
      []
   )

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('subscribers')}
               buttonsList={
                  <>
                     {removeButtonState && (
                        <Button
                           theme="green"
                           onClick={Events.headerSaveButtonClickHandler}>
                           {t('create.mail')}
                        </Button>
                     )}
                  </>
               }
            />
         }>
         <FlexContainer direction="column" gap="30px">
            <FlexContainer padding="30px" justify="space-between" align="center">
               <FlexContainer direction="row" gap="32px" width="500px">
                  <SearchBar
                     name="regex"
                     placeholder={t('search')}
                     value={searchForm.regex}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
                  <FlexContainer gap="10px" width="192px" align="center">
                     <img src={assets.HISTORY_ICON} alt="" />
                     <ClickbleText onClick={Events.historyButtonClickHandler}>
                        {t('history.subscribers')}
                     </ClickbleText>
                  </FlexContainer>
               </FlexContainer>
               <DataTotal>
                  {t('total.subscribers')}: {0}
               </DataTotal>
            </FlexContainer>

            <Table
               columns={columns}
               data={data}
               sortBy={tableSortBy}
               checkboxClickHandler={Events.checkboxClickHandler}
               headerCheckbox={Events.checkedAll}
               visability={false}
            />

            {popupVisiablity && (
               <Popup
                  backgroundClickListener={Events.closePopupClickHandler}
                  height="568px"
                  width="627px">
                  <FlexContainer
                     direction="column"
                     gap="25px"
                     justify="center"
                     align="center">
                     <HeaderText>{t('send.mail')}</HeaderText>
                     <Text>{t('mail.text')}</Text>
                     <Textarea placeholder={t('enter.text')} width="497px" height="330px">
                        {}
                     </Textarea>
                     <FlexContainer gap="30px" align="center" justify="center">
                        <Button theme="grey" onClick={Events.cancelButtonClickHandler}>
                           {t('cancel')}
                        </Button>
                        <Button theme="green" onClick={Events.saveButtonClickHandler}>
                           {t('send')}
                        </Button>
                     </FlexContainer>
                  </FlexContainer>
               </Popup>
            )}
         </FlexContainer>
      </AdminPanelContainer>
   )
}
