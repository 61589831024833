import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { FONT } from '../../utils'

export const Styles = styled.div`
   width: 100%;

   table {
      border-spacing: 0;
      border: 0px solid black;

      border-collapse: collapse;
      width: 100%;

      .checkboxHeader {
         width: 90px;
      }

      .buttonHeader {
         width: auto;
      }

      .visability {
         padding-bottom: 18.5px;
      }

      th {
         height: 74px;
         user-select: none;
         ${FONT({ color: colors.grey, weight: '500', size: '14px', align: 'center' })}

         /* Min size for table */
         padding: 15px;
      }

      td {
         border: 1px solid ${colors.table_grey};
         border-left: 0;
         border-right: 0;
         height: 74px;
         /* Min size for table */
         padding: 3px;
         align-items: center;
         ${FONT({ size: '14px', align: 'center' })}

         & > img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 100%;
            height: 46px;
            width: 46px;
         }

         input[type='checkbox'] {
            height: 18px;
            width: 18px;
            cursor: pointer;
         }
      }
      tr {
         &.has-new-reviews {
            position: relative;
            background-color: #f6fbff;
         }
      }
   }
`
export const NewReviewCount = styled.p`
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background-color: ${colors.red};
   color: ${colors.solid_white};

   /* position: absolute; */
   right: 110px;
   display: flex;
   align-items: center;
   justify-content: center;
`

export const HeaderContentContainer = styled.div`
   display: inline-flex;
   position: relative;
`

export const CheckBoxHeaderContentContainer = styled.div`
   display: inline-flex;
   position: relative;
   padding-bottom: 10px;
`

export const ButtonsContainer = styled.div`
   display: flex;
   gap: 20px;
   justify-content: flex-end;
   margin-right: 30px;
`

export const ArrowDownIcon = styled.img.attrs({
   src: assets.ARROW_DOWN
})`
   position: absolute;

   right: -25px;
   top: -4px;
`

export const ArrowUpIcon = styled(ArrowDownIcon)`
   transform: rotate(180deg);
`

export const StyledText = styled.p`
   ${FONT({ color: colors.grey, weight: '500', size: '14px', align: 'center' })}
   padding: 10px;
`
