import { AdminPanelContainer, Header } from 'components'
import { t } from 'i18next'
import { FC } from 'react'
import { HeaderText } from './styled'

const NotFoundedPage: FC = () => {
   return (
      <AdminPanelContainer Header={<Header />}>
         <div
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               width: '100%',
               height: '100%'
            }}>
            <HeaderText>{t('access.denied')}</HeaderText>
         </div>
      </AdminPanelContainer>
   )
}

export default NotFoundedPage
