import { FC } from 'react'
import { HashRouter } from 'react-router-dom'
import { useTypedSelector } from '../hooks'
import { getUserSelector } from '../store'
import AuthRoutes from './AuthRoutes'
import NotAuthRoutes from './NotAuthRoutes'

const Router: FC = () => {
   const { accessToken } = useTypedSelector(getUserSelector)

   return (
      <HashRouter basename="/">
         {accessToken ? <AuthRoutes /> : <NotAuthRoutes />}
      </HashRouter>
   )
}

export default Router
