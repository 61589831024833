import { FC } from 'react'
import { DeclineButtonIcon, RemoveButton, RemoveButtonIcon } from './styled'
import { TButtonProps } from './types'

const Button: FC<TButtonProps> = ({ width = 36, height = width, onClick }) => {
   return (
      <RemoveButton onClick={onClick} width={width} height={height}>
         <DeclineButtonIcon />
      </RemoveButton>
   )
}

export default Button
