import { TColumnTable, TSort } from 'components'
import { t } from 'i18next'
import { useMemo } from 'react'

type useTableHookProps = {
   sortToggleHandler: (sortBy: string, order: TSort) => void
}

export const useTableHook = ({ sortToggleHandler }: useTableHookProps) => {
   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('product.code'),
            accessor: 'barcode'
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('title'),
            accessor: 'title',
            width: 250,
            sortToggleHandler: sortToggleHandler
         },
         {
            Header: t('updatedAt'),
            accessor: 'updatedAt',
            width: 250,
            sortToggleHandler: sortToggleHandler
         },
         {
            Header: t('changed'),
            accessor: 'changed',
            width: 450
         },
         {
            Header: t('status'),
            accessor: 'approvedValue'
         }
      ],
      []
   )

   return { columns }
}
