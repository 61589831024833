import { Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import { AdminPanelContainer, FlexContainer, SubPagesBar, TSection } from 'components'
import { ELocales } from 'enums'
import { useLocalization, useTypedSelector } from 'hooks'
import { Categories, InputFormContainer, ProductHeader } from 'pages/Products/components'
import {
   productTypeOptions,
   statusOptions,
   visiablityOptions
} from 'pages/Products/consts'
import { useFormInputHook } from 'pages/Products/hooks'
import { utils } from 'pages/Products/utils'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   brandsActions,
   categoryActions,
   getBrandSelector,
   getMainCategorySelector,
   getMainProductSelector,
   productActions
} from 'store'
import { TCategory, TProduct, TSellStatus } from 'store/product/types'
import { Photos } from './components/Photos'
import { ProductImage } from './components/Photos/types'
import { Container } from './styled'

export const EditStatisticIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const {
      product,
      loading: productLoading,
      photoProduct,
      variations
   } = useTypedSelector(getMainProductSelector)
   const { categories, loading: categoryLoading } = useTypedSelector(
      getMainCategorySelector
   )
   const { brands } = useTypedSelector(getBrandSelector)

   const [locale] = useLocalization()
   const { id, pageNumber } = useParams()

   const brandsOptions = brands.items.map((brand) => ({
      value: brand._id,
      name: brand.title
   }))

   const [productImages, setProductImages] = useState<ProductImage[]>([])
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [form, setForm] = useState<
      TProduct & { sectionId?: string; categoryId?: string; subcategoryId?: string }
   >()
   const [editedData, setEditedData] = useState<TProduct>()
   const route = `/statistic?page=${Number(pageNumber) + 1}`

   const schema = {
      discount: {
         condition:
            Number(form?.discountPrice) >= 0 &&
            Number(form?.discountPrice) < Number(form?.price)
      }
   }

   const {
      backButtonClickHandler,
      onUploadPhoto,
      onDeletePhoto,
      categoryChangeHandler,
      sectionChangeHandler,
      subcategoryChangeHandler
   } = useFormInputHook({
      form: form as TProduct,
      editedData: editedData as TProduct,
      setEditedData,
      setForm,
      productImages,
      setProductImages,
      route
   })

   const Events = {
      saveButtonClickHandler: () => {
         Request.editProduct()
      },
      deleteButtonClickHandler: () => {
         Request.removeProduct()
         navigate('/statistic')
      }
   }

   const Request = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: id as string }))
      },
      getBrands: () => {
         dispatch(brandsActions.getBrands({ limit: 1000 }))
      },
      getCategories: () => {
         dispatch(categoryActions.getCategories({ limit: 300 }))
      },
      editProduct: () => {
         utils.isSchemaValid(schema) &&
            dispatch(
               productActions.updateProduct({
                  _id: id as string,
                  data: editedData
               })
            )
         utils.isSchemaValid(schema) && toogleIsAlertOpen(true)
      },
      getSubCategories: (_id: string) => {
         dispatch(
            categoryActions.getCategories({ value: _id, field: 'parent', limit: 100 })
         )
      },
      removeProduct: () => {
         dispatch(productActions.removeProduct({ _id: id as string }))
      },
      getVariations: () => {
         if (product?.group?._id)
            dispatch(
               productActions.getVariations({
                  field: 'group._id',
                  value: product?.group?._id as string,
                  lang: locale as ELocales
               })
            )
      }
   }

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            active: true
         },
         {
            title: t('characteristics'),
            onClickHandler: () => {
               navigate(`/statisticCharacteristics/page=${pageNumber}/${id as string}`)
            }
         },
         {
            title: t('related.orders'),
            onClickHandler: () => {
               navigate(`/statisticRelated/page=${pageNumber}/${id as string}`)
            }
         },
         {
            title: t('variations'),
            onClickHandler: () => {
               navigate(`/statisticVariations/page=${pageNumber}/${id as string}`)
            }
         }
      ],
      []
   )

   useEffect(() => {
      setProductImages((prev) =>
         prev.map((photo: any, index) => {
            if (index === prev.length - 1) {
               return { image: photoProduct.data.gallery[0], order: index + 1 }
            }

            return { ...photo, order: index + 1 }
         })
      )
   }, [photoProduct])

   useEffect(() => {
      if (productImages.length)
         setEditedData((props) => ({
            ...(props as TProduct),
            gallery: productImages
         }))
   }, [productImages])

   useEffect(() => {
      product && setForm(product)
      product?.gallery && setProductImages(product.gallery)
      Request.getVariations()
   }, [product])

   useEffect(() => {
      if (form && editedData) {
         setForm({
            ...form,
            sellStatus: utils.validateAmountOfProduct(
               +form.amount,
               form.sellStatus
            ) as TSellStatus
         })
         setEditedData({
            ...editedData,
            sellStatus: utils.validateAmountOfProduct(
               +form?.amount,
               form?.sellStatus
            ) as TSellStatus
         })
      }
   }, [form?.amount])

   useEffect(() => {
      if (!product?.group?._id)
         dispatch(productActions.setVariations({ data: [], meta: null }))
      Request.getProduct()
      Request.getBrands()
      Request.getVariations()
      Request.getCategories()
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <ProductHeader
               product={product as TProduct}
               backButtonClickHandler={backButtonClickHandler}
               deleteButtonClickHandler={Events.deleteButtonClickHandler}
               saveButtonClickHandler={Events.saveButtonClickHandler}
            />
         }
         loading={productLoading || categoryLoading}>
         <SubPagesBar sections={sections} />
         <Container>
            <InputFormContainer
               form={form as TProduct}
               editedData={editedData as TProduct}
               setEditedData={setEditedData}
               setForm={setForm}
               variations={variations}
               visiablityOptions={visiablityOptions}
               productTypeOptions={productTypeOptions}
               statusOptions={statusOptions}
               brandsOptions={brandsOptions}
            />

            <FlexContainer direction="column" align="center">
               <Categories
                  form={
                     form as TProduct & {
                        sectionId?: string
                        categoryId?: string
                        subcategoryId?: string
                     }
                  }
                  editedData={editedData as TProduct}
                  setForm={setForm}
                  setEditedData={setEditedData}
                  productCategory={product?.category as TCategory}
                  categories={categories}
                  sectionChangeHandler={sectionChangeHandler}
                  categoryChangeHandler={categoryChangeHandler}
                  subcategoryChangeHandler={subcategoryChangeHandler}
               />

               <Photos
                  gallery={productImages}
                  preview={product?.preview}
                  uploadPhoto={onUploadPhoto}
                  deletePhoto={onDeletePhoto}
               />
            </FlexContainer>
         </Container>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t('product.successfuly.edited')}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}
