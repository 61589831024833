import styled from 'styled-components'
import { colors } from '../../enums'
import { TStyles } from './types'

export const Styles = styled.div<TStyles>`
   width: 273px;

   .css-1s2u09g-control,
   .css-1pahdxg-control {
      height: 45px;
      border-radius: 8px;
      border-color: ${({ isValid }) => (isValid ? colors.border_grey_color : colors.red)};
   }

   .css-1pahdxg-control {
      border-color: ${colors.activeInput} !important;
      box-shadow: 0 0 0 1px ${colors.activeInput};
   }

   .css-1okebmr-indicatorSeparator {
      display: none;
   }
`
