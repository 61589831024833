import { createAction } from '@reduxjs/toolkit'
import {
   CHANGE_PRODUCT_ORDER,
   CREATE_CATEGORY,
   EDIT_CATEGORY,
   EDIT_CATEGORY_ORDER,
   GET_CATEGORIES,
   GET_CATEGORIES_BY_PARENT,
   GET_CATEGORY,
   GET_CATEGORY_PARENT,
   GET_PRODUCTS_BY_CATEGORY,
   GET_SUB_CATEGORIES,
   REMOVE_CATEGORIES,
   SET_CATEGORIES,
   SET_CATEGORY,
   SET_CATEGORY_STATE,
   SET_EDIT_BACKID,
   SET_IDS,
   SET_PRODUCTS_BY_CATEGORY,
   SET_SECONDLEVEL_EDIT_BACKID,
   SET_SECTIONS,
   SET_SUB_CATEGORIES
} from './consts'
import {
   TCreateCategoryPayload,
   TEditCategoryOrderPayload,
   TEditCategoryPayload,
   TGetCategoriesPayload,
   TGetCategoryPayload,
   TGetProductsByCategoryPayload,
   TRemoveCategoriesPayload,
   TSetCategoryState
} from './types'

export const categoryActions = {
   getCategories: createAction(GET_CATEGORIES, (payload: TGetCategoriesPayload) => ({
      payload
   })),
   getCategory: createAction(GET_CATEGORY, (payload: TGetCategoryPayload) => ({
      payload
   })),
   setCategory: createAction(SET_CATEGORY, (payload) => ({ payload })),
   getSubCategories: createAction(GET_SUB_CATEGORIES, (payload) => ({
      payload
   })),
   setCategories: createAction(SET_CATEGORIES, (payload) => ({ payload })),
   setSections: createAction(SET_SECTIONS, (payload) => ({ payload })),
   setSubCategories: createAction(SET_SUB_CATEGORIES, (payload) => ({ payload })),
   setCategoriesState: createAction(SET_CATEGORY_STATE, (payload: TSetCategoryState) => ({
      payload
   })),
   createCategory: createAction(CREATE_CATEGORY, (payload: TCreateCategoryPayload) => ({
      payload
   })),
   editCategory: createAction(EDIT_CATEGORY, (payload: TEditCategoryPayload) => ({
      payload
   })),
   removeCategories: createAction(
      REMOVE_CATEGORIES,
      (payload: TRemoveCategoriesPayload) => ({ payload })
   ),
   getCategoryParent: createAction(
      GET_CATEGORY_PARENT,
      (payload: TGetCategoryPayload) => ({ payload })
   ),
   getCategoriesByParent: createAction(
      GET_CATEGORIES_BY_PARENT,
      (payload: TGetCategoryPayload) => ({ payload })
   ),

   setEditBackId: createAction(SET_EDIT_BACKID, (payload) => ({ payload })),
   setSecondLevelEditBackId: createAction(SET_SECONDLEVEL_EDIT_BACKID, (payload) => ({
      payload
   })),

   getProductsByCategory: createAction(
      GET_PRODUCTS_BY_CATEGORY,
      (payload: TGetProductsByCategoryPayload) => ({ payload })
   ),
   setProductsByCategory: createAction(SET_PRODUCTS_BY_CATEGORY, (payload) => ({
      payload
   })),

   setIds: createAction(SET_IDS, (payload) => ({ payload })),

   editCategoryOrder: createAction(
      EDIT_CATEGORY_ORDER,
      (payload: TEditCategoryOrderPayload) => ({ payload })
   ),

   changeProductOrder: createAction(CHANGE_PRODUCT_ORDER, (payload) => ({ payload }))
}
