import { createReducer } from '@reduxjs/toolkit'
import { gridActions } from './actions'
import { TInitialState } from './types'
import { i18n } from 'config'
import { ELocales } from 'enums'

const InitialState: TInitialState = {
   banners: [],
   loading: false,
   lang: i18n.language as ELocales
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(gridActions.setBanners, (state, { payload }) => ({
         ...state,
         banners: payload
      }))
      .addCase(gridActions.changeLang, (state, { payload }) => ({
         ...state,
         lang: payload
      }))
      .addCase(gridActions.setBannerState, (state, { payload }) => ({
         ...state,
         response: null,
         ...payload
      }))
})

export default reducer
