import styled from 'styled-components'
import { colors } from '../../../../../../enums'
import { FONT } from '../../../../../../utils'

export const Container = styled.div`
   display: grid;
   width: 100%;
   height: 100%;
   grid-template-columns: 4fr 1fr;
`

export const FormContainer = styled.div`
   padding: 24px 30px;
   border-right: 1px solid ${colors.table_grey};
`

export const ImageContainer = styled.div`
   display: inline-flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-end;
`

export const AddressLabel = styled.label`
   ${FONT({ size: '14px', weight: '500' })}
   align-self: flex-start;
   margin-left: 14px;
`

export const AddressBar = styled.div`
   width: 100%;
   min-height: 45px;
   background-color: ${colors.gray_light};
   border: 1px solid ${colors.colorPicker_grey_color};
   border-radius: 10px;
   display: flex;
   align-items: center;
   padding: 0px 19px;
   ${FONT({ color: colors.lighter_black, size: '14px' })}
`
