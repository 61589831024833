import { DropDownList } from 'components/DropDownList'
import { ELocales } from 'enums'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { TBrand } from 'store/brand/types'
import { api } from '../../config'
import { useLocalization, useTypedSelector } from '../../hooks'
import {
   brandsActions,
   categoryActions,
   getBrandSelector,
   productActions
} from '../../store'
import { Button } from '../Button'
import { InfinityScroll } from '../InfinityScroll'
import { Popup } from '../Popup'
import { RelativePreloader } from '../Preloader'
import { SearchBar } from '../SearchBar'
import { FlexContainer, H2 } from '../Styled'
import { Table, TColumnTable, TSort } from '../Table'
import { PER_PAGE_ITEMS } from './consts'
import { Image, PreloaderContainer, TableContainer } from './styled'
import { TSelectProductPopup } from './types'

const ProductPopup: FC<TSelectProductPopup> = ({
   closeClickHandler,
   addProductClickHandler,
   activeItems,
   similar
}) => {
   const { t } = useTranslation()
   const dispatch = useDispatch()

   const { brands } = useTypedSelector(getBrandSelector)

   const [brandsList, setBrandsList] = useState<TBrand[]>([])
   const [page, setPage] = useState<number>(0)
   const [loading, setLoading] = useState<boolean>(true)
   const [activeProductsList, setActiveProductsList] = useState<string[]>([])

   const [locale] = useLocalization()

   const [searchForm, setSearchForm] = useState({
      regex: '',
      date_start: '',
      date_end: ''
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [filterForm, setFilterForm] = useState<{ value: string }>({
      value: ''
   })

   const Events = {
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (e: SyntheticEvent, p: any, t: any, index: number) => {
         setActiveProductsList((list) => {
            const isChecked = (e.target as HTMLInputElement).checked
            const arr = list
            let id: string = ''

            brandsList.forEach((brand, i) => {
               if (index == i) id = brand._id
            })

            if (isChecked && id) return [...new Set([...arr].concat([id]))]

            return [...new Set(arr.filter((item) => item != id))]
         })
      },
      nextBrands: () => {
         Requests.getBrands(page)
         setPage((page) => page + 1)
      },
      addProductClickHandler: () => {
         addProductClickHandler &&
            addProductClickHandler(
               brands.items.filter((element) =>
                  activeProductsList.includes(element._id)
               ) as any
            )
      },
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         checkedItemsIndex: number[]
      ) => {
         // setRemoveButtonState(hasCheckboxesActiveState)
         // setSelectedItems(checkedItemsIndex)

         for (let index of checkedItemsIndex) {
            activeProductsList.push(brandsList[index]._id)
         }
      }
   }

   const Requests = {
      getBrands: (page: number) => {
         dispatch(
            brandsActions.getBrands({
               page: page,
               limit: PER_PAGE_ITEMS,
               lang: locale as ELocales,
               ...(searchForm.regex ? { search: searchForm.regex } : {})
            })
         )
      }
   }

   const Utils = {
      clearProducts: () => {
         dispatch(productActions.setProducts({ data: [], meta: null }))
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('logo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 350
         },
         {
            Header: t('total'),
            accessor: 'total',
            width: 350
         },
         {
            Header: t('sells'),
            accessor: 'sales',
            width: 350
         }
      ],
      []
   )

   const data = useMemo(
      () =>
         brandsList.map((brand) => {
            return {
               photo: <Image src={`${api.preview}${brand.image}`} />,
               label: <>{brand.title}</>,
               isChecked: activeProductsList.includes(brand._id),
               total: <>{brand?.productsCount}</>,
               sales: <>{brand?.sales}</>
            }
         }),
      [brandsList, activeProductsList]
   )

   useEffect(() => {
      setBrandsList([])
      Requests.getBrands(0)
      setPage(0)
   }, [searchForm, tableSortBy, filterForm])

   // Append new support request data in data state
   useEffect(() => {
      setLoading(true)
      if (brands.meta?.totalCount) {
         setBrandsList((brandsList) => {
            return brandsList.concat(brands.items)
         })
         brands.meta?.totalCount <= brandsList.length + brands.items.length &&
            setLoading(false)
      }
      if (brands.meta?.totalCount == 0) {
         setLoading(false)
      }
   }, [brands])

   useEffect(() => {
      activeItems && setActiveProductsList(activeItems)
   }, [activeItems])

   useEffect(() => {
      return () => {
         Utils.clearProducts()
      }
   }, [])

   return (
      <Popup backgroundClickListener={closeClickHandler} width="1158px" height="844px">
         <FlexContainer
            direction="column"
            align="center"
            justify="center"
            style={{ padding: '40px 10px' }}
            gap="5px">
            <H2>{t('add.brand')}</H2>
            <FlexContainer
               justify="space-around"
               style={{ marginTop: '33px' }}
               rowGap="20px">
               <SearchBar
                  name="regex"
                  placeholder={t('search')}
                  value={searchForm.regex}
                  onChange={Events.onChangeHandler}
               />
            </FlexContainer>
            <TableContainer>
               <InfinityScroll
                  next={Events.nextBrands}
                  loading={loading}
                  preloader={
                     <PreloaderContainer>
                        <RelativePreloader size="50px" loading />
                     </PreloaderContainer>
                  }>
                  <Table
                     columns={columns}
                     data={data}
                     sortBy={tableSortBy}
                     checkboxClickHandler={Events.checkboxClickHandler}
                     headerCheckbox={Events.checkedAll}
                     visability={false}
                  />
               </InfinityScroll>
            </TableContainer>
            <FlexContainer
               align="center"
               justify="center"
               gap="30px"
               style={{ paddingTop: '30px' }}>
               <Button theme="grey" style="transparant" onClick={closeClickHandler}>
                  {t('cancel')}
               </Button>
               <Button theme="green" onClick={Events.addProductClickHandler}>
                  {t('add')}
               </Button>
            </FlexContainer>
         </FlexContainer>
      </Popup>
   )
}

export default ProductPopup
