import {
   FC,
   SyntheticEvent,
   createRef,
   useEffect,
   useMemo,
   useRef,
   useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   Pagination,
   SearchBar,
   SubPagesBar,
   Table,
   TColumnTable,
   TSection,
   TSort
} from '../../../../components'

import { api } from '../../../../config'
import { useLocalization, useTypedSelector } from '../../../../hooks'
import {
   getMainProductSelector,
   getUserSelector,
   productActions
} from '../../../../store'
import { getMainCategorySelector } from '../../../../store/category'
import { ApiCategoryService } from '../../../../store/category/api.service'
import { DROP_DOWN_LIST_LIMIT, PAGE_LIMIT } from './consts'

import { Image } from '../../styled'
import { TCategory } from '../../../../store/category/types'
import { TOption } from '../../../../components/PaginateDropDownList/types'

import { useNavigate } from 'react-router-dom'
import { ELocales } from '../../../../enums'
import Moment from 'react-moment'
import { utils } from '../../utils'
import { RESPONSE } from 'store/product/consts'
import moment from 'moment'

export const StatisticIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const {
      products,
      filters,
      statFileName,
      loading: productLoading,
      response: productsResponse
   } = useTypedSelector(getMainProductSelector)

   const { accessToken } = useTypedSelector(getUserSelector)
   const [page, setPage] = useState<number>(0)
   const [searchParams, setSearchParams] = useSearchParams({})
   const [locale] = useLocalization()
   const [searchForm, setSearchForm] = useState({
      query: filters.query,
      date_start: filters.dateStart,
      date_end: filters.dateEnd
   })
   const [filterForm, setFilterForm] = useState({
      category: { value: '', label: '' } as TOption
   })
   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const aRef = useRef<HTMLAnchorElement>(null)

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const [isClicked, setIsClicked] = useState(false)
   const [searchQueryPage, setSearchQueryPage] = useState<number>(0)

   const data = useMemo(
      () =>
         products?.data.map((product) => {
            return {
               barcode: <>{product.barcode}</>,
               photo: <Image src={`${api.preview}${product.preview}`} />,
               label: <>{product.description && product.description?.title}</>,
               createdAt: (
                  <Moment format="DD.MM.YYYY">
                     {new Date(product?.createdAt as string)}
                  </Moment>
               ),
               total: <>{product?.amount}</>,
               price: <>{product?.price}</>,
               views: <>{product?.filteredViews}</>,
               popularity: <>{product?.filteredPopularity}</>
            }
         }),
      [products]
   )

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      loadOptions: async (search: string, loadOptions: any, additions: any) => {
         const page = additions?.page || 0

         const options = await Requests.getCategories(page)

         return {
            options: (options.data.data as TCategory[]).map((item) => ({
               value: item._id,
               label: item.title
            })),
            hasMore: options.data.meta.totalCount > page * DROP_DOWN_LIST_LIMIT,
            additional: {
               page: page + 1
            }
         }
      },
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         Requests.getProducts()
      },
      paginateDropDownChangeHandler: (value: TOption) => {
         setFilterForm((form) => ({ ...form, category: value }))
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      removeCheckedItemsHandler: () => {
         Requests.removeSelectedProducts()
      },
      removeClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(
            productActions.removeProduct({ _id: products.data[index]._id as string })
         )
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(
            productActions.setFilters({
               query: searchForm.query,
               dateStart: searchForm.date_start,
               dateEnd: searchForm.date_end
            })
         )
         navigate(`/statistic/page=${page}/${products?.data[index]._id}`)
      },
      updateVisabilityButtonClickHandler: () => {
         Requests.updateVisability()
         setIsClicked(true)
         setRemoveButtonState(false)
      },
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setSelectedItems(ckeckedItemsIndex)
         setRemoveButtonState(hasCheckboxesActiveState)
      },
      resetFilters: () => {
         dispatch(
            productActions.setFilters({
               query: '',
               dateEnd: '',
               dateStart: '',
               categoryId: ''
            })
         )
         window.location.reload()
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('product.code'),
            accessor: 'barcode',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 250
         },
         {
            Header: t('createdAt'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('total'),
            accessor: 'total',
            width: 200
         },
         {
            Header: t('price'),
            accessor: 'price',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('views'),
            accessor: 'views',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('sells'),
            accessor: 'popularity',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('current'),
            onClickHandler: () => {
               navigate(`/products`)
            }
         },
         {
            title: t('new.products'),
            onClickHandler: () => {
               navigate(`/products/newProducts`)
            }
         },
         {
            title: t('promotion.products'),
            onClickHandler: () => {
               navigate('/products/promotionProducts')
            }
         },
         {
            title: t('statistic'),
            active: true
         },
         {
            title: t('changelog'),
            onClickHandler: () => {
               navigate('/changelog')
            }
         }
      ],
      []
   )

   const Requests = {
      getProducts: () => {
         dispatch(
            productActions.getProducts({
               limit: PAGE_LIMIT,
               page: page,
               lang: locale as ELocales,
               hidden: 'false',
               aggregateStats: true,
               ...(searchForm.query ? { query: searchForm.query } : {}),
               ...(searchForm.date_end
                  ? { statsDateEnd: new Date(searchForm.date_end)?.toISOString() }
                  : {}),
               ...(searchForm.date_start
                  ? {
                       statsDateStart: new Date(searchForm.date_start)?.toISOString()
                    }
                  : {}),
               ...sortParamsForm
            })
         )
      },
      getCategories: async (page: number = 0) => {
         const response = await ApiCategoryService.getCategories({
            token: accessToken as string,
            limit: DROP_DOWN_LIST_LIMIT,
            page
         })
         return response
      },
      updateVisability: () => {
         const arr: string[] = []
         const forms: { show: boolean; discountPrice: number | undefined }[] = []
         for (let i of selectedItems) {
            arr.push(products.data[i]._id)
            forms.push({
               show: true,
               discountPrice: products.data[i].discountPrice
                  ? products.data[i].discountPrice
                  : 0
            })
         }
         dispatch(productActions.updateVisability({ ids: arr, tforms: forms }))
         setSelectedItems([])
      },
      removeSelectedProducts: () => {
         const arr: string[] = []
         for (let i of selectedItems) {
            arr.push(products.data[i]._id)
         }
         dispatch(productActions.removeAllProducts({ ids: arr }))
         setSelectedItems([])
      },
      getStatFileName: () => {
         dispatch(productActions.getStatFileName({}))
      }
   }

   useEffect(() => {
      if (productsResponse === RESPONSE.UPDATED_VISABILITY) {
         Requests.getProducts()
      } else if (productsResponse === RESPONSE.REMOVED_ALL) {
         Requests.getProducts()
      } else if (productsResponse === RESPONSE.REMOVED) {
         Requests.getProducts()
      }
   }, [productsResponse])

   useEffect(() => {
      Requests.getProducts()
      setSearchParams({ ...searchParams, page: '' + (page + 1) })
   }, [page, filterForm, tableSortBy, searchForm])

   useEffect(() => {
      if (products?.meta) {
         if (page > Math.ceil(products?.meta.totalCount / PAGE_LIMIT)) {
            setPage(0)
         }
      }
   }, [products?.meta])

   useEffect(() => {
      statFileName && aRef.current?.click()
      statFileName && dispatch(productActions.setStatFileName(''))
   }, [statFileName])

   // Set default page uri
   useEffect(() => {
      dispatch(productActions.setProduct({ product: null }))
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string) - 1
            : 0
      )
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     {removeButtonState && (
                        <Button
                           theme="green"
                           onClick={Events.updateVisabilityButtonClickHandler}>
                           {t('change.visability')}
                        </Button>
                     )}
                     {removeButtonState && (
                        <Button theme="red" onClick={Events.removeCheckedItemsHandler}>
                           {t('remove.all')}
                        </Button>
                     )}
                  </>
               }
               title={t('manage.products')}
            />
         }
         loading={productLoading}>
         <SubPagesBar sections={sections} />
         <FlexContainer direction="column" gap="30px">
            <HeaderWrapper>
               <FiltersWrapper>
                  <SearchBar
                     name="query"
                     placeholder={t('search')}
                     value={searchForm.query}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
                  <DateInput
                     name="date_start"
                     value={searchForm.date_start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="date_end"
                     value={searchForm.date_end}
                     onChange={Events.onChangeHandler}
                  />
                  <div onClick={Events.resetFilters}>
                     <Button style="transparant">{t('reset.filters')}</Button>
                  </div>
               </FiltersWrapper>

               <Button theme="green" width={150} onClick={Requests.getStatFileName}>
                  {t('download.xsls')}
               </Button>
               {statFileName && <a href={`${api.img}${statFileName}`} ref={aRef} />}

               <DataTotal>
                  {t('total.products')}: {products?.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            {!isClicked && (
               <Table
                  columns={columns}
                  data={data}
                  sortBy={tableSortBy}
                  checkboxClickHandler={Events.checkboxClickHandler}
                  headerCheckbox={Events.checkedAll}
                  editClickHandler={Events.editButtonClickHandler}
                  removeClickHandler={Events.removeClickHandler}
                  editable
                  removeable
               />
            )}
            {isClicked && (
               <Table
                  columns={columns}
                  data={data}
                  sortBy={tableSortBy}
                  checkboxClickHandler={Events.checkboxClickHandler}
                  headerCheckbox={Events.checkedAll}
                  editClickHandler={Events.editButtonClickHandler}
                  removeClickHandler={Events.removeClickHandler}
                  editable
                  removeable
               />
            )}
         </FlexContainer>

         <Pagination
            page={page}
            pageCount={
               products?.meta ? Math.ceil(products?.meta.totalCount / PAGE_LIMIT) : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}
