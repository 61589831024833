import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, debounce, delay, put, takeLatest } from 'redux-saga/effects'
import { i18n } from '../../config'
import { ELocales } from '../../enums'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { productActions } from './actions'
import { ApiProductService } from './api.service'
import { RESPONSE } from './consts'
import {
   TCreatePhotoProductRequestPayload,
   TCreateSimilarProductsPayload,
   TEditProductPayload,
   TEditPromotionProductsPayload,
   TGetProductPayload,
   TGetProductsPayload,
   TGetSimilarProductsPayload,
   TGetSubCategoriesPayload,
   TGetVariationProductsPayload,
   TGetVariationsPayload,
   TRemoveProductPayload,
   TUpdateProductPayload,
   TProduct,
   TUpdateVisabilityPayload,
   TForm,
   TRemoveAllProductsPayload,
   TGetProductsV1Payload,
   TGetVariationsByCategoryPayload
} from './types'

function* getProductsWorker({ payload }: TDataWrapper<TGetProductsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)
   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getProducts],
         {
            ...payload,
            token
            // lang: i18n.language as ELocales
         }
      )

      if (response.data) yield put(productActions.setProducts(response.data))
   } catch (e) {
      yield put(productActions.setProducts(null))
   }
   yield put(productActions.setProductState({ loading: false, response: RESPONSE.DONE }))
}

function* removeProductWorker({ payload }: TDataWrapper<TRemoveProductPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      yield call([ApiProductService, ApiProductService.removeProduct], { token, _id })
      yield put(
         productActions.setProductState({
            loading: false,
            response: RESPONSE.REMOVED as any
         })
      )
   } catch (e) {
      yield put(
         productActions.setProductState({
            loading: false,
            response: RESPONSE.NOT_REMOVED as any
         })
      )
   }

   yield put(
      productActions.setProductState({ loading: false, response: RESPONSE.REMOVED })
   )
}

function* getProductWorker({ payload }: TDataWrapper<TGetProductPayload>) {
   yield put(productActions.setProductState({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getProduct],
         {
            token,
            lang: i18n.language as ELocales,
            ...payload
         }
      )

      if (response.data) {
         yield put(productActions.setProduct({ product: response.data.data }))
      }
   } catch (e) {}

   yield put(productActions.setProductState({ loading: false, response: 'DONE' }))
}

function* createPhotoProductWorker({
   payload
}: TDataWrapper<TCreatePhotoProductRequestPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.createPhotoProduct],
         { ...payload, token }
      )

      if (response.data) yield put(productActions.setPhotoProduct(response.data))
   } catch (e) {
      yield put(productActions.setPhotoProduct(null))
   }

   yield put(
      productActions.setProductState({
         loading: false,
         response: RESPONSE.CREATED_PHOTO as any
      })
   )
}

function* updateProductWorker({ payload }: TDataWrapper<TUpdateProductPayload>) {
   yield put(productActions.setProductState({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const res: TResponse = yield call(
         [ApiProductService, ApiProductService.updateProduct],
         {
            token,
            ...payload
         }
      )

      yield put(
         productActions.setProductState({
            loading: false,
            response: RESPONSE.EDITED
         })
      )
   } catch (e) {
      yield put(
         productActions.setProductState({ loading: false, response: RESPONSE.EDIT_ERROR })
      )
   }
}

function* getSimilarProductsWorker({
   payload
}: TDataWrapper<TGetSimilarProductsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getSimilarProducts],
         { ...payload, token, lang: i18n.language as ELocales }
      )

      if (response.data) yield put(productActions.setSimilarProducts(response.data?.data))
   } catch (e) {
      yield put(productActions.setPhotoProduct(null))
   }

   yield put(
      productActions.setProductState({
         loading: false,
         response: RESPONSE.CREATED_PHOTO as any
      })
   )
}

function* updateSimilarProductsWorker({
   payload
}: TDataWrapper<TCreateSimilarProductsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.updateSimilarProducts],
         { _id: payload._id, data: { similar: payload.similar }, token }
      )
   } catch (e) {}

   yield put(
      productActions.setProductState({
         loading: false,
         response: RESPONSE.CREATED_PHOTO as any
      })
   )
}

function* getVaraitionsWorker({ payload }: TDataWrapper<TGetVariationsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getVaraitions],
         { token, ...payload }
      )

      if (response.data) yield put(productActions.setVariations(response.data))
   } catch (e) {
      yield put(productActions.setProducts(null))
   }

   yield put(productActions.setProductState({ loading: false }))
}

function* editProductWorker({ payload }: TDataWrapper<TEditProductPayload>) {
   yield put(productActions.setProductState({ loading: true }))
   const token: string = yield call(getAccessToken)
   const { data, _id } = payload

   try {
      yield call([ApiProductService, ApiProductService.editProduct], { token, _id, data })
      yield put(
         productActions.setProductState({
            loading: false,
            response: RESPONSE.EDITED as any
         })
      )
   } catch (e) {
      yield put(
         productActions.setProductState({
            loading: false,
            response: RESPONSE.EDIT_ERROR as any
         })
      )
   }
   yield put(productActions.setProductState({ loading: false }))
}

function* getSubCategoriesWorker({ payload }: PayloadAction<TGetSubCategoriesPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   const { _id } = payload

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getSubCategories],
         { token, _id, lang: i18n.language as ELocales }
      )

      if (response.data) yield put(productActions.setSubCategories(response.data))
   } catch (e) {}

   yield put(productActions.setProductState({ loading: false }))
}

function* editPromitionProductsWorker({
   payload
}: PayloadAction<TEditPromotionProductsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiProductService, ApiProductService.editPromotionProducts], {
         ...payload,
         token
      })

      yield put(
         productActions.setProductState({ loading: false, response: RESPONSE.EDITED })
      )
   } catch (e) {
      yield put(
         productActions.setProductState({ loading: false, response: RESPONSE.EDIT_ERROR })
      )
   }
}

function* getVaraitionProductsWorker({
   payload
}: PayloadAction<TGetVariationProductsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const ids: string[] = payload.ids
   const products: TProduct[] = []

   try {
      for (let i = 0; i < ids.length; i++) {
         const response: TResponse = yield call(
            [ApiProductService, ApiProductService.getProduct],
            {
               token,
               _id: ids[i],
               lang: i18n.language as ELocales
            }
         )
         products.push(response.data.data)
      }
      if (products) yield put(productActions.setVariationProducts(products))
   } catch (e) {}

   yield put(productActions.setProductState({ loading: false }))
}

function* updateVisabilityWorker({ payload }: PayloadAction<TUpdateVisabilityPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const ids: string[] = payload.ids
   const tforms: TForm[] = payload.tforms

   try {
      const updateTasks = ids.map((id, index) =>
         call([ApiProductService, ApiProductService.editProduct], {
            token,
            _id: id,
            data: tforms[index]
         })
      )

      yield all(updateTasks)
      // window.location.reload()
   } catch (e) {}

   yield put(
      productActions.setProductState({
         loading: false,
         response: RESPONSE.UPDATED_VISABILITY
      })
   )
}

function* removeAllProductsWorker({ payload }: PayloadAction<TRemoveAllProductsPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const ids: string[] = payload.ids

   try {
      for (let id of ids) {
         yield call([ApiProductService, ApiProductService.removeProduct], {
            token,
            _id: id
         })
      }
   } catch (e) {}

   yield put(
      productActions.setProductState({
         loading: false,
         response: RESPONSE.REMOVED_ALL
      })
   )
}

function* getProductsV1Worker({ payload }: PayloadAction<TGetProductsV1Payload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getProductsV1],
         {
            ...payload,
            token
            // lang: i18n.language as ELocales
         }
      )

      if (response.data) yield put(productActions.setProducts(response.data))
   } catch (e) {}

   yield put(productActions.setProductState({ loading: false }))
}

function* getStatFileNameWorker({ payload }: PayloadAction<{}>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getStatFileName],
         {
            token
         }
      )

      if (response.data) yield put(productActions.setStatFileName(response.data.data))
   } catch (e) {}

   yield put(productActions.setProductState({ loading: false }))
}

function* getVariationByCategoryWorker({
   payload
}: PayloadAction<TGetVariationsByCategoryPayload>) {
   yield put(productActions.setProductState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiProductService, ApiProductService.getVaraitionsByCategory],
         {
            ...payload,
            token
         }
      )

      if (response.data) yield put(productActions.setVariationByCategory(response.data))
   } catch (e) {}

   yield put(productActions.setProductState({ loading: false }))
}

export function* productWatcher() {
   yield debounce(200, productActions.getProducts, getProductsWorker)
   yield takeLatest(productActions.removeProduct, removeProductWorker)
   yield takeLatest(productActions.getProduct, getProductWorker)
   yield takeLatest(productActions.createPhotoProduct, createPhotoProductWorker)
   yield takeLatest(productActions.updateProduct, updateProductWorker)
   yield takeLatest(productActions.getSimilarProducts, getSimilarProductsWorker)
   yield takeLatest(productActions.updateSimilarProducts, updateSimilarProductsWorker)
   yield takeLatest(productActions.editProduct, editProductWorker)

   yield takeLatest(productActions.getVariations, getVaraitionsWorker)
   yield takeLatest(productActions.getSubCategories, getSubCategoriesWorker)

   yield takeLatest(productActions.editPromotionProducts, editPromitionProductsWorker)

   yield takeLatest(productActions.getVariationProducts, getVaraitionProductsWorker)

   yield takeLatest(productActions.updateVisability, updateVisabilityWorker)

   yield takeLatest(productActions.removeAllProducts, removeAllProductsWorker)

   yield debounce(200, productActions.getProductsV1, getProductsV1Worker)
   yield takeLatest(productActions.getStatFileName, getStatFileNameWorker)
   yield takeLatest(productActions.getVariationByCategory, getVariationByCategoryWorker)
}
