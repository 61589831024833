import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   Pagination,
   SearchBar,
   SubPagesBar,
   Table,
   TSort
} from 'components'
import { api } from 'config'
import { ELocales } from 'enums'
import { useDebounceEffect, useLocalization, useTypedSelector } from 'hooks'
import { t } from 'i18next'
import { Image } from 'pages/Products/styled'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMainModerationSelector, moderationActions } from 'store/moderation'
import { MODERATION_RESPONSE } from 'store/moderation/consts'
import { TModeration } from 'store/moderation/types'
import { useNavigationHook, useTableHook } from '../../hooks'
import { PAGE_LIMIT } from './consts'

export const ChangelogIndexPage: FC = () => {
   const { sections } = useNavigationHook()
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const [locale] = useLocalization()

   const { changedProducts, loading, filters, response } = useTypedSelector(
      getMainModerationSelector
   )

   const [page, setPage] = useState<number>(0)
   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })
   const [searchForm, setSearchForm] = useState({
      search: filters.search ? filters.search : '',
      date_start: filters.date_start ? filters.date_start : '',
      date_end: filters.date_end ? filters.date_end : ''
   })
   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [checked, setChecked] = useState(false)

   const approvedProducts = useMemo(() => {
      return selectedItems
         ?.map((index) => {
            if (changedProducts.data[index].approved) {
               return ''
            }
            return changedProducts?.data[index]?._id
         })
         .filter((item) => item)
   }, [selectedItems])

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const Events = {
      approveButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(
            moderationActions.approveChanges({ _id: changedProducts.data[index]._id })
         )
      },
      declineButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(
            moderationActions.declineChanges({ _id: changedProducts.data[index]._id })
         )
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(moderationActions.SET_FILTERS({ ...searchForm }))
         navigate(`/changelog/page=${page}/id=${changedProducts.data[index]._id}`)
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         Requests.getChangedProducts()
      },
      resetFilters: () => {
         dispatch(
            moderationActions.SET_FILTERS({
               search: '',
               date_start: '',
               date_end: ''
            })
         )
         window.location.reload()
      },
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         checkedItemsIndex: number[]
      ) => {
         setSelectedItems(checkedItemsIndex)
      },
      appoveChangesBulkClickHandler: () => {
         if (!approvedProducts) {
            return
         }
         Requests.approveChangesBulk(approvedProducts)
      }
   }

   const Requests = {
      getChangedProducts: () => {
         dispatch(
            moderationActions.getChangedProducts({
               lang: locale as ELocales,
               limit: PAGE_LIMIT,
               page: page,
               ...sortParamsForm,
               ...searchForm
            })
         )
      },
      approveChangesBulk: (ids: string[]) => {
         const res = []
         for (let i = 0; i < ids.length; i += 20) {
            res.push(ids.slice(i, i + 20))
         }
         const promises = res.map((arr) =>
            dispatch(
               moderationActions.approveChangesBulk({
                  data: arr,
                  limit: PAGE_LIMIT,
                  page: page
               })
            )
         )

         Promise.all(promises)

         setSelectedItems([])
         setChecked(false)
      }
   }

   const utils = {
      getChangedFields: (product: TModeration, index: number) => {
         if (changedProducts.data[index].changes?.description) {
            const changes: string = Object.keys(product.changes)
               .filter((item) => item != 'description')
               .map((item) => t(item))
               .join(', ')

            const description: string = Object.keys(
               changedProducts.data[index].changes?.description[0]
            )
               .map((item) => t(item))
               .join(', ')

            if (changes?.length && description?.length) {
               return changes + ', ' + description
            }
            if (changes?.length) {
               return changes
            }
            if (description?.length) {
               return description
            }

            return
         }

         if (changedProducts.data[index].changes) {
            const changes: string = Object.keys(product.changes)
               .filter((item) => item != 'description')
               .map((item) => t(item))
               .join(', ')

            if (changes?.length) {
               return changes
            }

            return
         }
      },
      filterFields: (field: string) => {
         const fields: string[] = [
            'amount',
            'barcode',
            'brand',
            'category',
            'description',
            'title',
            'metaKeywords',
            'metaDescription',
            'discountPrice',
            'gallery',
            'price',
            'sellStatus',
            'show',
            'type'
         ]
         if (fields.includes(field)) return t(field)
         return
      }
   }

   const { columns } = useTableHook({ sortToggleHandler: Events.sortToggleHandler })

   const data = useMemo(
      () =>
         changedProducts.data.map((product, index) => {
            return {
               barcode: <>{product.product?.barcode}</>,
               photo: (
                  <Image
                     src={`${api.preview}${
                        product?.changes?.preview
                           ? product?.changes?.preview
                           : product?.product?.preview
                     }`}
                  />
               ),
               title: <>{product.product?.description.title}</>,
               updatedAt: (
                  <Moment format="DD.MM.YYYY HH:mm">
                     {new Date(product?.updatedAt)}
                  </Moment>
               ),
               changed: <>{utils.getChangedFields(product, index)}</>,
               approved: <>{product?.approved}</>,
               approvedValue: (
                  <>
                     {t(
                        product.approved
                           ? 'approved'
                           : product.approved != null
                           ? 'not.approved'
                           : 'default.status'
                     )}
                  </>
               )
            }
         }),
      [changedProducts]
   )

   useDebounceEffect(
      () => {
         if (response == MODERATION_RESPONSE.EDITED) {
            Requests.getChangedProducts()
         }
      },
      250,
      [response]
   )

   useEffect(() => {
      Requests.getChangedProducts()
      dispatch(
         moderationActions.SET_FILTERS({
            search: '',
            date_start: '',
            date_end: ''
         })
      )
   }, [page, searchForm, sortParamsForm])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('manage.products')}
               buttonsList={
                  <>
                     {checked && (
                        <>
                           <Button
                              theme="green"
                              onClick={Events.appoveChangesBulkClickHandler}>
                              {t('approve.changes')}
                           </Button>
                        </>
                     )}
                  </>
               }
            />
         }
         loading={loading}>
         <SubPagesBar sections={sections} />
         <FlexContainer>
            <HeaderWrapper width="95%">
               <FiltersWrapper>
                  <SearchBar
                     name="search"
                     placeholder={t('search')}
                     value={searchForm.search}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
                  <DateInput
                     name="date_start"
                     value={searchForm.date_start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="date_end"
                     value={searchForm.date_end}
                     onChange={Events.onChangeHandler}
                  />
                  <div onClick={Events.resetFilters}>
                     <Button style="transparant">{t('reset.filters')}</Button>
                  </div>
               </FiltersWrapper>
               <DataTotal>
                  {t('total.moderation')}: {changedProducts?.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               data={data}
               columns={columns}
               checkboxClickHandler={() => {}}
               editClickHandler={Events.editButtonClickHandler}
               approveClickHandler={Events.approveButtonClickHandler}
               declineClickHandler={Events.declineButtonClickHandler}
               headerCheckbox={Events.checkedAll}
               headerText=""
               sortBy={tableSortBy}
               approveButtonTheme={'#5EBF66'}
               checkedHeader={checked}
               setCheckedHeader={setChecked}
               declineble
               approvable
               editable
            />
         </FlexContainer>
         <Pagination
            page={page}
            pageCount={
               changedProducts?.meta
                  ? Math.ceil(changedProducts?.meta.totalCount / PAGE_LIMIT)
                  : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}
