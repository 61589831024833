import { TGetRecommendedPayload, TGetRecommendedResponse } from 'store/product/types'
import { TFilterParams } from './types'
import { TProductsState } from '../ModalTieUpProduct/types'

import { useMemo } from 'react'
import useSWR from 'swr'
import { ApiProductService } from 'store'

export const defaultParams: TFilterParams = {
   date_start: '',
   date_end: '',
   query: '',
   category: undefined,
   sortBy: 'createdAt',
   order: -1
   // page: 0
}

export const useGetSimilars = (params: TGetRecommendedPayload) => {
   const { data: similarData, isLoading: similarLoading } = useSWR(
      params._id ? ['getSimilarProducts', params] : null,
      ([, getParams]) => ApiProductService.getRecommended(getParams),

      { revalidateOnFocus: false, parallel: true }
   )
   return { similarData, similarLoading }
}

export const useGetSimilarData = (
   products: TProductsState,
   similarData?: TGetRecommendedResponse
) => {
   const initialSimilarData = products[0]?.product

   const processedData = useMemo(() => {
      return similarData?.data?.length
         ? similarData?.data?.find((el) => el._id === initialSimilarData?._id)
            ? similarData?.data
            : [...similarData?.data, initialSimilarData]
         : [initialSimilarData]
   }, [similarData])
   return { processedData }
}
