import styled, { css } from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { BORDER, FLEX, FONT } from '../../utils'
import {
   TContainer,
   TStyledInputProps,
   TSwitchPasswordVisiableButtonProps
} from './types'

export const SwitchPasswordVisiableButton = styled.img<TSwitchPasswordVisiableButtonProps>`
   position: absolute;
   z-index: 2;
   top: 40px;
   right: 20px;
   cursor: pointer;
   width: 22px;
   height: 16px;
   content: url(${({ visiable }) => (visiable ? assets.OPEN_EYE : assets.CLOSED_EYE)});
`

export const Container = styled.div<TContainer>`
   ${({ align }) => FLEX({ direction: 'column', align, justify: 'center' })}
   width: ${({ width }) => width || 'auto'};
   max-width: 100%;
   position: relative;
`

export const StyledInput = styled.input<TStyledInputProps>`
   width: 100%;
   height: ${({ height }) => height || 'auto'};
   border-radius: 8px;
   padding-left: 6px;

   ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   ${BORDER({})}

   border-color:${({ error }) => (error ? colors.red : colors.border_grey_color)};

   ${FONT({ color: colors.black, size: '16px' })}

   &::placeholder {
      line-height: 19px;
      text-align: left;
      ${FONT({ color: colors.grey, size: '16px' })}
   }
`
export const StyledError = styled.p`
   ${FONT({ color: colors.red })};
   max-width: 320px;
   &.error {
      color: ${colors.solid_white};
   }
`
