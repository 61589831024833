import { createReducer } from '@reduxjs/toolkit'
import { supportActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   requests: {
      data: [],
      meta: null
   },
   loading: false,
   newMessageCount: 0
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(supportActions.setRequests, (state, { payload }) => ({
         ...state,
         requests: payload
      }))
      .addCase(supportActions.setSupportState, (state, { payload }) => ({
         ...state,
         ...payload
      }))
      .addCase(
         supportActions.setNewMessageCount,
         (state, { payload }: { payload: number }) => ({
            ...state,
            newMessageCount: payload
         })
      )
})

export default reducer
