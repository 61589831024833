import { FC } from 'react'
import { ContentBlock } from '../ContentBlock'
import { Preloader } from '../Preloader'

import { Sidebar } from '../Sidebar'
import { Container, MainContentContainer } from './styled'
import { TAdminPanelProps } from './types'

const AdminPanelContainer: FC<TAdminPanelProps> = ({
   children,
   Header,
   loading = false
}) => {
   return (
      <Container>
         <Sidebar />

         <MainContentContainer>
            {Header}
            <ContentBlock>{children}</ContentBlock>
            <Preloader loading={loading} />
         </MainContentContainer>
      </Container>
   )
}

export default AdminPanelContainer
