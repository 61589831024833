import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TSection } from '../../../../components'

export const useSubPagesPagination = () => {
   const { t } = useTranslation()
   const navigate = useNavigate()

   const paginationSections: TSection[] = useMemo(
      () => [
         {
            title: t('first.section'),
            onClickHandler: () => {
               navigate('/banners')
            },
            active: window.location.hash == '#/banners'
         },
         {
            title: t('second.section'),
            onClickHandler: () => {
               navigate('/banners/grid')
            },
            active: window.location.hash == '#/banners/grid'
         }
      ],
      []
   )

   return { paginationSections }
}
