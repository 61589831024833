import { createReducer } from '@reduxjs/toolkit'
import { orderActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   orders: {
      data: [],
      meta: null
   },
   archive: {
      data: [],
      meta: null
   },
   order: null,
   filters: {
      searchValue: '',
      startDateValue: '',
      endDateValue: ''
   },
   loading: false,
   response: null,
   newOrders: 0
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(orderActions.setOrders, (state, { payload }) => ({
         ...state,
         orders: payload
      }))
      .addCase(orderActions.setOrderState, (state, { payload }) => ({
         ...state,
         respones: null,
         ...payload
      }))
      .addCase(orderActions.setOrder, (state, { payload }) => ({
         ...state,
         order: payload
      }))
      .addCase(orderActions.setArchive, (state, { payload }) => ({
         ...state,
         archive: payload
      }))
      .addCase(orderActions.setFilters, (state, { payload }) => ({
         ...state,
         filters: payload
      }))
      .addCase(orderActions.setNewOrder, (state, { payload }) => ({
         ...state,
         newOrders: payload
      }))
})

export default reducer
