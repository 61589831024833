import { TCategories } from 'store/category/types'
import { TCategory } from 'store/product/types'
import { TSchema } from 'types'

export const utils = {
   isBarcode: (string: string) => {
      if (string) {
         return (
            string
               .split('')
               .map((item) => Number(item))
               .filter((item) => item || item == 0).length == 13
         )
      }
   },
   isSchemaValid: (schema: TSchema) => {
      let isValid = true
      Object.keys(schema).forEach((prop) => {
         if (!schema[prop as keyof typeof schema].condition) isValid = false
      })

      return isValid
   },
   validateAmountOfProduct: (amount: number, status: string) => {
      if (amount == 0 && status != 'notavailable') {
         return 'notavailable'
      }
      if (amount > 0 && status != 'available') {
         return 'available'
      }
      return status
   }
}
