import styled from 'styled-components'
import { assets } from '../../assets'
import { TImage } from './types'

export const Image = styled.div<TImage>`
   display: inline-block;
   background-image: ${({ src }) => `url(${src})`};
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   height: 102px;
   width: 175px;
   margin: 14px 0px;
`

export const ButtonContainer = styled.div`
   margin-top: 30px;
   margin-left: 30px;
`

export const PlusIcon = styled.img.attrs({ src: assets.PLUS })``

export const P = styled.p`
   width: 160px;
   /* max-height: 72px; */
   overflow: hidden;
   text-align: left;

   display: flex;
   align-items: start;
`
