import { useState, useMemo, useEffect, SyntheticEvent, FC } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { t } from 'i18next'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   InfinityScroll,
   Input,
   KeywordsInput,
   RelativePreloader,
   Textarea,
   TSort
} from '../../../../../../components'
import { Container, Line, Text } from './styled'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
   categoryActions,
   getMainCategorySelector,
   getMainProductSelector,
   productActions
} from '../../../../../../store'
import {
   useDebounceEffect,
   useLocalization,
   useTypedSelector
} from '../../../../../../hooks'
import { StyledText } from '../../../../styled'
import { TCategory } from './types'
import { PAGE_LIMIT } from '../../../../consts'
import { CATEGORY_RESPONSE } from '../../../../../../store/category/consts'
import { Photos } from '../../../../components/Photos'
import { ProductImage } from '../../../../components/Photos/types'
import { colors, ELocales } from '../../../../../../enums'
import { TProduct } from '../../../../../../store/product/types'
import { PreloaderContainer } from '../../../../../Support/styled'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DragAndDropProduct } from './components'
import { DragAndDropProduct as DragAndDropCategory } from 'pages/Catalog/components'

export const EditCategoryIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const {
      category,
      categories,
      ids,
      loading: categoryLoading,
      response: categoryResponse
   } = useTypedSelector(getMainCategorySelector)

   const { products, response: productsResponse } =
      useTypedSelector(getMainProductSelector)

   const [locale] = useLocalization()
   const { id } = useParams()
   const [productImages, setProductImages] = useState<ProductImage[]>([])
   const [iconImages, setIconImages] = useState<ProductImage[]>([])

   const [backIds, setIds] = useState<string[]>([])
   const [productsByCategory, setProductsByCategory] = useState<TProduct[][]>([[]])
   const [subcategoriesDnd, setSubcategoriesDnd] = useState<TCategory[][]>([[]])
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

   const filteredSubcategoriesDnd = useMemo(() => {
      return [
         subcategoriesDnd?.flat()?.filter((value, index, self) => {
            return (
               self?.findIndex((v) => v?._id === value?._id) === index &&
               self[index]?.parent
            )
         })
      ]
   }, [subcategoriesDnd])

   const filteredProductsByCategory = useMemo(() => {
      return productsByCategory?.flat()?.filter((value, index, self) => {
         return (
            self?.findIndex((v) => v?._id === value?._id) === index &&
            value?.category?._id == id
         )
      })
   }, [productsByCategory])

   const chunkedProductsArr = useMemo(() => {
      const chunkSize = 10
      const res = []

      for (let i = 0; i < filteredProductsByCategory?.length; i += chunkSize) {
         const chunk = filteredProductsByCategory?.slice(i, i + chunkSize)
         res.push(chunk)
      }

      return res
   }, [filteredProductsByCategory])

   const viewMoreCategories = useMemo(() => {
      if (!categories?.meta) {
         return false
      }
      return categories?.meta?.totalCount > filteredSubcategoriesDnd?.flat()?.length
   }, [filteredSubcategoriesDnd])

   const viewMoreProducts = useMemo(() => {
      if (!products?.meta) {
         return false
      }
      return products?.meta?.totalCount > chunkedProductsArr?.flat()?.length
   }, [chunkedProductsArr])

   const visiablityOptions = [
      { name: t('show'), value: '1' },
      { name: t('not.show'), value: '0' }
   ]

   const [form, setForm] = useState<TCategory>({
      _id: '',
      parent: '',
      show: true,
      icon: '',
      image: '',
      categoriesTotalCount: 0,
      productsTotalCount: 0,
      title: '',
      metaKeywords: [],
      metaDescription: ''
   })

   const [page, setPage] = useState<number>(0)

   const Events = {
      backButtonClickHandler: () => {
         backIds.length
            ? navigate(`/catalog/categories/${backIds[backIds.length - 1]}`)
            : navigate(`/catalog/categories`)

         setIds(backIds.slice(0, backIds.length - 1))
      },
      onKeywordsChangeHandler: (metaKeywords: string[]) => {
         setForm({ ...form, metaKeywords })
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         if (categories.data.length > 0) {
            setIds(backIds.concat(id as string))
            navigate(`/catalog/categories/${categories.data[index]._id as string}`)
            dispatch(categoryActions.setIds(backIds))
            return
         }

         if (products.data.length > 0) {
            dispatch(categoryActions.setIds(backIds))
            navigate(`/catalog/edit/${products.data[index]._id as string}`)
            dispatch(categoryActions.setIds(backIds))
            return
         }
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      onPhotoChange: (file: File | null) => {
         setForm({ ...form, image: file as File })
      },
      saveButtonClickHandler: () => {
         Requests.editCategory()
      },
      visibilityChangehandler: (e: SyntheticEvent) => {
         const { value } = e.target as HTMLInputElement

         setForm((props) => ({ ...props, show: !!Number(value) ? true : false }))
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      onUploadPhoto: (e: any) => {
         setProductImages((prev: any) => [
            ...prev,
            {
               order: prev.length + 1,
               image: URL.createObjectURL(e.target.files[0]),
               preview: URL.createObjectURL(e.target.files[0])
            }
         ])

         setForm((prev) => ({ ...prev, image: e.target.files[0] }))
      },
      onUploadIcon: (e: any) => {
         setIconImages((prev: any) => [
            ...prev,
            {
               order: prev.length + 1,
               image: URL.createObjectURL(e.target.files[0]),
               preview: URL.createObjectURL(e.target.files[0])
            }
         ])
         setForm((prev) => ({ ...prev, icon: e.target.files[0] }))
      },
      onDeletePhoto: (order: number) => {
         setProductImages((prev: any) =>
            prev.filter((photo: ProductImage) => {
               return photo.order !== order
            })
         )
      },
      onDeleteIcon: (order: number) => {
         setIconImages((prev: any) =>
            prev.filter((photo: ProductImage) => {
               return photo.order !== order
            })
         )
      },
      onDragEnd: (products?: any[], result?: any, index?: number) => {
         const { source, destination } = result

         // dropped outside the list
         if (!destination) {
            return
         }
         const sInd = +source.droppableId
         const dInd = +destination.droppableId

         if (sInd === dInd) {
            const items = Utils.reorder(
               chunkedProductsArr[sInd],
               source.index,
               destination.index
            )
            const newState = [...chunkedProductsArr]
            newState[sInd] = items as TProduct[]
            setProductsByCategory(newState)
         } else {
            const result = Utils.move(
               chunkedProductsArr[sInd],
               chunkedProductsArr[dInd],
               source,
               destination
            )
            const newState = [...chunkedProductsArr]
            newState[sInd] = result.sourceClone as TProduct[]
            newState[dInd] = result.destClone as TProduct[]
            setProductsByCategory(newState)
         }
      },
      onCategoriesDragEnd: (products?: any[], result?: any, index?: number) => {
         const { source, destination } = result

         // dropped outside the list
         if (!destination) {
            return
         }
         const sInd = +source.droppableId
         const dInd = +destination.droppableId

         if (sInd === dInd) {
            const items = Utils.categoriesReorder(
               filteredSubcategoriesDnd[sInd],
               source.index,
               destination.index
            )
            const newState = [...filteredSubcategoriesDnd]
            newState[sInd] = items as TCategory[]
            setSubcategoriesDnd(newState)
         } else {
            const result = Utils.moveCategories(
               filteredSubcategoriesDnd[sInd],
               filteredSubcategoriesDnd[dInd],
               source,
               destination
            )
            const newState = [...filteredSubcategoriesDnd]
            newState[sInd] = result.sourceClone as TCategory[]
            newState[dInd] = result.destClone as TCategory[]
            setSubcategoriesDnd(newState)
         }
      },
      nextInfinityScrollHandler: () => {},
      viewAdditional: () => {
         if (
            (categories?.meta?.totalCount as number) > productsByCategory?.flat()?.length
         ) {
            setPage(page + 1)
         }
         page != 0 && Requests.getSubCategories()
      },
      viewAdditionalProducts: () => {
         if (
            (products?.meta?.totalCount as number) > chunkedProductsArr?.flat()?.length
         ) {
            setPage(page + 1)
         }
         page != 0 && Requests.getProductsByCategory()
      }
   }

   const Utils = {
      reorder: (row: TProduct[], startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      },
      move: (
         source: TProduct[],
         destination: TProduct[],
         droppableSource: { index: number; droppableId: string },
         droppableDestination: { droppableId: string; index: number }
      ) => {
         const sourceClone = Array.from(source)
         const destClone = Array.from(destination)
         const [removed] = sourceClone.splice(droppableSource.index, 1)

         destClone.splice(droppableDestination.index, 0, removed)

         return { sourceClone, destClone }
      },
      categoriesReorder: (row: TCategory[], startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      },
      moveCategories: (
         source: TCategory[],
         destination: TCategory[],
         droppableSource: { index: number; droppableId: string },
         droppableDestination: { droppableId: string; index: number }
      ) => {
         const sourceClone = Array.from(source)
         const destClone = Array.from(destination)
         const [removed] = sourceClone.splice(droppableSource.index, 1)

         destClone.splice(droppableDestination.index, 0, removed)

         return { sourceClone, destClone }
      }
   }

   const getItemStyle = (isDragging: boolean, draggableStyle: any, grid: number) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      margin: `0 20px 0 0`,
      background: colors.solid_white,

      // change background colour if dragging

      // styles we need to apply on draggables
      ...draggableStyle
   })

   const getListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      padding: 8,
      overflow: 'auto'
   })

   const getCategoriesListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      padding: `8px 8px 8px 20px`,
      overflow: 'auto'
   })

   const Requests = {
      getCategory: () => {
         dispatch(categoryActions.getCategory({ _id: id as string, lang: ELocales.ru }))
      },
      getSubCategories: () => {
         dispatch(
            categoryActions.getCategoriesByParent({
               _id: id as string,
               lang: ELocales.ru,
               page: page,
               limit: PAGE_LIMIT,
               sortBy: 'order',
               order: 1
            })
         )
      },
      editCategory: () => {
         const data = new FormData()

         form?.title &&
            data.append(
               'description[]',
               JSON.stringify({
                  title: form.title,
                  lang: ELocales.ru,
                  metaKeywords: form.metaKeywords,
                  metaDescription: form.metaDescription
               })
            )
         form?.image && data.append('img', form.image as File)
         form?.image && data.append('icon', form.icon as File)
         form?.parent && data.append('parent', form.parent)

         data.append('show', form?.show ? 'true' : 'false')

         dispatch(
            categoryActions.editCategory({
               data,
               _id: form?._id as string
            })
         )

         if (form.show != category?.show) {
            const subCategoryData = new FormData()

            subCategoryData.append('show', form?.show ? 'true' : 'false')

            for (let subCategory of categories.data) {
               dispatch(
                  categoryActions.editCategory({
                     data: subCategoryData,
                     _id: subCategory._id
                  })
               )
            }
         }

         if (category?.categoriesTotalCount == 0) {
            dispatch(
               categoryActions.changeProductOrder(
                  chunkedProductsArr
                     .map((productRow, indx) =>
                        productRow.map((product: TProduct, index: number) => ({
                           _id: product?._id,
                           data: {
                              categoryOrder: index + (indx > 0 ? indx * 10 : indx) + 1
                           }
                        }))
                     )
                     ?.flat()
               )
            )
         }

         if (category?.categoriesTotalCount != 0) {
            dispatch(
               categoryActions.editCategoryOrder({
                  data: subcategoriesDnd?.map((categories) =>
                     categories.map((category, index) => ({
                        _id: category?._id,
                        data: {
                           order: index + 1
                        }
                     }))
                  )[0]
               })
            )
         }
      },
      getProductsByCategory: () => {
         dispatch(
            productActions.getProducts({
               lang: ELocales.ru,
               hidden: 'false',
               category: [id as string],
               limit: PAGE_LIMIT,
               page: page,
               order: 1,
               primary: true,
               sortBy: 'categoryOrder'
            })
         )
      }
   }

   useEffect(() => {
      setSubcategoriesDnd([])
      setProductsByCategory([])

      Requests.getCategory()
      Requests.getSubCategories()
      Requests.getProductsByCategory()
      if (ids.length > 0) {
         setIds(ids)
      }
   }, [])

   useEffect(() => {
      Requests.getSubCategories()
      Requests.getProductsByCategory()
   }, [page])

   useEffect(() => {
      category &&
         setForm({
            _id: category._id,
            parent: category.parent as string,
            show: category.show,
            icon: category.icon,
            image: category.image,
            categoriesTotalCount: category.categoriesTotalCount,
            productsTotalCount: category.productsTotalCount,
            title: category.title,
            metaKeywords: [],
            metaDescription: ''
         })
   }, [category])

   useDebounceEffect(
      () => {
         if (categoryResponse === CATEGORY_RESPONSE.DONE) {
            setForm((prev) => ({
               ...prev,
               metaKeywords: category?.metaKeywords as string[],
               metaDescription: category?.metaDescription as string
            }))
         }
      },
      300,
      [form]
   )

   useDebounceEffect(
      () => {
         if (categoryResponse === CATEGORY_RESPONSE.EDITED) {
            setProductImages([])
            setIconImages([])

            setSubcategoriesDnd([])
            setProductsByCategory([])

            Requests.getCategory()
            Requests.getSubCategories()

            setPage(0)

            Requests.getProductsByCategory()

            toogleIsAlertOpen(true)
         }
      },
      300,
      [categoryResponse]
   )

   useEffect(() => {
      setSubcategoriesDnd([])
      setProductsByCategory([])

      setPage(0)

      Requests.getCategory()
      Requests.getSubCategories()
      Requests.getProductsByCategory()

      setPage(0)
   }, [backIds, id])

   useEffect(() => {
      products && setProductsByCategory(productsByCategory.concat([products.data]))
   }, [products])

   useEffect(() => {
      categories &&
         setSubcategoriesDnd(
            subcategoriesDnd.concat([
               categories.data.slice().sort((a, b) => a.order - b.order) as TCategory[]
            ])
         )
   }, [categories])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t(`${category?.title}`)}
               backButtonClickHandler={Events.backButtonClickHandler}
            />
         }
         loading={categoryLoading}>
         <Container>
            <FlexContainer gap="62px">
               <Input
                  name="title"
                  label={t('title')}
                  placeholder={t('enter.title')}
                  value={form?.title}
                  onChange={Events.inputChangeHandler}
               />
               <DropDownList
                  name="visiablity"
                  label={t('visiablity')}
                  placeholder={t('choose.visability')}
                  options={visiablityOptions}
                  value={
                     form?.show ? visiablityOptions[0].value : visiablityOptions[1].value
                  }
                  onChange={Events.visibilityChangehandler}
               />
               <FlexContainer width="100%">
                  {form?.metaKeywords?.length > 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
                  {form?.metaKeywords?.length == 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
                  {form?.metaKeywords == undefined && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form?.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
               </FlexContainer>
               <FlexContainer>
                  <Textarea
                     name="metaDescription"
                     height="271px"
                     label={t('meta.description')}
                     onChange={Events.inputChangeHandler}>
                     {form?.metaDescription}
                  </Textarea>
               </FlexContainer>
            </FlexContainer>
            <FlexContainer gap="28px" wrap="nowrap" width="200px">
               <FlexContainer>
                  <Photos
                     gallery={productImages}
                     preview={category?.image}
                     label={'photo'}
                     alt={'category img'}
                     id="photo"
                     path=""
                     uploadPhoto={Events.onUploadPhoto}
                     deletePhoto={Events.onDeletePhoto}
                  />
               </FlexContainer>
               <FlexContainer gap="12px" direction="column" justify="flex-start">
                  <Photos
                     gallery={iconImages}
                     preview={category?.icon}
                     label={'icon'}
                     id="icon"
                     path="icon/"
                     alt={'category icon'}
                     uploadPhoto={Events.onUploadIcon}
                     deletePhoto={Events.onDeleteIcon}
                  />
               </FlexContainer>
            </FlexContainer>
         </Container>
         <Line />

         {category && category?.categoriesTotalCount > 0 && (
            <Text>{t('subcategory')}</Text>
         )}
         {category?.categoriesTotalCount === 0 && <Text>{t('products')}</Text>}

         {category && category?.categoriesTotalCount === 0 && (
            <Container>
               <FlexContainer
                  align="center"
                  gap="30px"
                  width="100%"
                  wrap="nowrap"
                  direction="column">
                  <InfinityScroll
                     next={Events.nextInfinityScrollHandler}
                     loading={false}
                     preloader={
                        <PreloaderContainer>
                           <RelativePreloader loading />
                        </PreloaderContainer>
                     }>
                     <DragDropContext
                        onDragEnd={(result) =>
                           Events.onDragEnd(filteredProductsByCategory, result)
                        }>
                        {chunkedProductsArr?.map((item, ind) => (
                           <Droppable
                              droppableId={`${ind}`}
                              direction="horizontal"
                              key={ind}>
                              {(provided: any, snapshot: any) => (
                                 <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}>
                                    {provided.placeholder}
                                    {item?.map((product: TProduct, index: number) => (
                                       <Draggable
                                          draggableId={item[index]?._id}
                                          index={index}
                                          key={product._id}>
                                          {(provided, snapshot) => (
                                             <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                   snapshot.isDragging,
                                                   provided.draggableProps.style,
                                                   8
                                                )}>
                                                <DragAndDropProduct
                                                   key={product._id}
                                                   productItem={product}
                                                />
                                             </div>
                                          )}
                                       </Draggable>
                                    ))}
                                 </div>
                              )}
                           </Droppable>
                        ))}
                     </DragDropContext>
                  </InfinityScroll>
               </FlexContainer>
            </Container>
         )}

         {category && category?.categoriesTotalCount > 0 && (
            <FlexContainer
               justify="space-between"
               padding="8px"
               width="100%"
               wrap="nowrap">
               <div>
                  <StyledText style={{ width: '120px' }}>{t('photo')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '120px' }}>{t('icon')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('title')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('total.product')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('subcategories')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('visability')}</StyledText>
               </div>
            </FlexContainer>
         )}

         {categories.data.length > 0 && (
            <FlexContainer align="center" gap="30px" wrap="nowrap" direction="column">
               <DragDropContext
                  onDragEnd={(result) =>
                     Events.onCategoriesDragEnd(filteredSubcategoriesDnd, result)
                  }>
                  {filteredSubcategoriesDnd?.map((item: TCategory[], ind) => (
                     <Droppable droppableId={`${ind}`} direction="vertical" key={ind}>
                        {(provided: any, snapshot: any) => (
                           <div
                              ref={provided.innerRef}
                              style={getCategoriesListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}>
                              {provided.placeholder}
                              {item.map((product: TCategory, index) => (
                                 <Draggable
                                    draggableId={item[index]?._id}
                                    index={index}
                                    key={product?._id}>
                                    {(provided, snapshot) => (
                                       <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                             snapshot.isDragging,
                                             provided.draggableProps.style,
                                             8
                                          )}>
                                          <DragAndDropCategory
                                             key={product?._id}
                                             id={id}
                                             productItem={product as any}
                                             setBackId={setIds}
                                             backIds={backIds}
                                             path="categories"
                                          />
                                       </div>
                                    )}
                                 </Draggable>
                              ))}
                           </div>
                        )}
                     </Droppable>
                  ))}
               </DragDropContext>
            </FlexContainer>
         )}

         {viewMoreCategories && (
            <FlexContainer justify="center">
               <Button onClick={Events.viewAdditional}>{t('view.another')}</Button>
            </FlexContainer>
         )}

         {viewMoreProducts && (
            <FlexContainer justify="center">
               <Button onClick={Events.viewAdditionalProducts}>
                  {t('view.another')}
               </Button>
            </FlexContainer>
         )}

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t('category.successfuly.edited')}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}
