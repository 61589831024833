import { Alert, Snackbar } from '@mui/material'
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
   AdminPanelContainer,
   AlignContainer,
   Button,
   FlexContainer,
   Header,
   Input,
   PlusIcon,
   RemoveButton
} from '../../components'
import { useTypedSelector } from '../../hooks'
import { bonusActions, getBonusesSelector } from '../../store'
import { TSchema } from '../../types'
import { AddButtonContainer, Container } from './styled'

const IndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const bonuses = useTypedSelector(getBonusesSelector)

   const [form, setForm] = useState(bonuses.data)
   const [isClicked, setClick] = useState(false)
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const schema = useCallback<(index: number) => TSchema>(
      (index: number) => ({
         name: {
            condition: form[index].name?.length >= 3 || form[index].name?.length == 0
         },
         discount: {
            condition:
               (form[index].discount >= 0 && form[index].discount <= 100) ||
               form[index].discount == 0
         }
      }),
      [form]
   )

   const schemaAfterSave = useCallback<(index: number) => TSchema>(
      (index: number) => ({
         name: {
            condition: form[index].name?.length >= 3
         },
         discount: {
            condition: form[index].discount >= 0 && form[index].discount <= 100
         }
      }),
      [form]
   )

   const Events = {
      inputHanler: (e: SyntheticEvent, value: string | number) => {
         const input = e.target as HTMLInputElement
         const bonusContainerIndex = Utils.getBonusIndex(e)

         setForm((form) =>
            form.map((bonus, index) => {
               if (index != bonusContainerIndex) return bonus
               return { ...bonus, [input.name]: value }
            })
         )
      },
      onAddClickHandler: () => {
         setForm(form.concat([{ name: '', discount: 0 }] as typeof form))
      },
      onSaveClickHandler: () => {
         setClick(true)
         setAlertText('bonuses.successfuly.saved')
         Utils.isFormValid() && dispatch(bonusActions.saveBonusesRequest(form))
         toogleIsAlertOpen(true)
      },
      removeButtonClickHandler: (e: SyntheticEvent) => {
         setAlertText('bonuses.successfuly.removed')

         const bonusContainerIndex = Utils.getBonusIndex(e)

         const bonus = form[bonusContainerIndex]

         const id = bonus._id

         id && dispatch(bonusActions.deleteBonusRequest({ id }))

         setForm(
            form.filter((bonus, index) => {
               return index !== bonusContainerIndex
            })
         )
         toogleIsAlertOpen(true)
      }
   }

   const Utils = {
      getBonusIndex: (e: SyntheticEvent): number => {
         const target = e.target as HTMLElement
         const bonusesContainer = target.closest('.bonuses_form_container')
         const bonusContainer = target.closest('.bonus_form_container')
         const bonusContainerIndex = Array.prototype.indexOf.call(
            bonusesContainer?.children,
            bonusContainer
         )

         return bonusContainerIndex
      },
      isFormValid: () => {
         let isValid = true
         const whiteList = ['name', 'discount']
         form.forEach((item, index) => {
            whiteList.forEach((prop) => {
               if (!schema(index)[prop].condition) {
                  isValid = false
               }
            })
         })

         return isValid
      },
      isFormValidSave: () => {
         let isValid = true
         const whiteList = ['name', 'discount']
         form.forEach((item, index) => {
            whiteList.forEach((prop) => {
               if (!schemaAfterSave(index)[prop].condition) {
                  isValid = false
               }
            })
         })

         return isValid
      }
   }

   useEffect(() => {
      dispatch(bonusActions.getBonusesRequest())
   }, [])

   useEffect(() => {
      setForm(bonuses.data)
   }, [bonuses.data])

   useEffect(() => {}, [isClicked])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('bonus.system')}
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.onSaveClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }
         loading={bonuses.loading}>
         <Container>
            <FlexContainer
               direction="column"
               gap="50px"
               className="bonuses_form_container">
               {form.map((bonus, index) => {
                  return (
                     <FlexContainer
                        key={bonus._id}
                        gap="30px"
                        className="bonus_form_container">
                        {!isClicked && (
                           <Input
                              name="name"
                              label={t('client.group')}
                              placeholder={t('enter.client.group')}
                              value={bonus.name}
                              onChange={Events.inputHanler}
                              isValid={schema(index).name.condition}
                           />
                        )}
                        {!isClicked && (
                           <Input
                              name="discount"
                              type="percentage"
                              label={t('discount')}
                              placeholder={t('enter.discount')}
                              value={bonus.discount.toString()}
                              onChange={Events.inputHanler}
                              isValid={schema(index).discount.condition}
                           />
                        )}
                        {isClicked && (
                           <Input
                              name="name"
                              label={t('client.group')}
                              placeholder={t('enter.client.group')}
                              value={bonus.name}
                              onChange={Events.inputHanler}
                              isValid={schemaAfterSave(index).name.condition}
                           />
                        )}
                        {isClicked && (
                           <Input
                              name="discount"
                              type="percentage"
                              label={t('discount')}
                              placeholder={t('enter.discount')}
                              value={bonus.discount.toString()}
                              onChange={Events.inputHanler}
                              isValid={schemaAfterSave(index).discount.condition}
                           />
                        )}
                        <AlignContainer justify="flex-end" align="flex-end">
                           <RemoveButton
                              width={45}
                              onClick={Events.removeButtonClickHandler}
                           />
                        </AlignContainer>
                     </FlexContainer>
                  )
               })}
            </FlexContainer>
         </Container>

         <AddButtonContainer>
            <Button height={40} width={139} onClick={Events.onAddClickHandler}>
               {
                  <>
                     <PlusIcon />
                     {t('add')}
                  </>
               }
            </Button>
         </AddButtonContainer>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}

export default IndexPage
