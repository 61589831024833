import { Alert, Snackbar } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdminPanelContainer, Button, DropDownList, Header } from '../../components'
import { useLocalization } from '../../hooks'
import { TLocales } from '../../locales'
import { Container } from './styled'

const SettingsPage: FC = () => {
   const { t } = useTranslation()
   const [language, setLanguage] = useLocalization()

   const options = [
      { value: 'az', name: t('az') },
      { value: 'ru', name: t('ru') }
   ]

   const [formLanguage, setFormLanguage] = useState<TLocales>(language)
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const Events = {
      clickHandler: () => {
         setAlertText('language.successfuly.changed')
         setLanguage(formLanguage)
         toogleIsAlertOpen(true)
      },
      langChangeHandler: (e: React.SyntheticEvent) => {
         const select = e.target as HTMLSelectElement
         const selected_item = select.value as TLocales

         setFormLanguage(selected_item)
      }
   }

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('settings')}
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.clickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }>
         <Container>
            <DropDownList
               label={t('lang')}
               options={options}
               onChange={Events.langChangeHandler}
               value={formLanguage}
            />
         </Container>
         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}

export default SettingsPage
