import styled, { keyframes } from 'styled-components'

const switchAnimation = keyframes`
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.2);
  }
  100% {
    transform: scaleX(1);
  }
`

export const SwitcherWrap = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 299px;
   height: 40px;
   border: 1px solid rgb(209, 209, 209);
   border-radius: 10px;
   padding: 1px;
   div {
      cursor: pointer;
      border-radius: 8px;
      height: 100%;
      width: 50%;
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease, color 0.3s ease;

      &.active {
         background-color: rgb(99, 123, 150);
         color: #ffffff;
      }
   }
`
