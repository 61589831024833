import { Alert, Snackbar } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
   AdminPanelContainer,
   Button,
   Header,
   Input,
   SubPagesBar,
   Textarea
} from '../../../../components'
import { ELocales } from '../../../../enums'
import { useLocalization, useTypedSelector } from '../../../../hooks'
import { getSeoSelector, seoActions, TSeoPage } from '../../../../store'
import { useSubPagesPagination } from '../../hooks'
import { Container } from '../../styled'
import { SEOTEXT } from '../../types'

const SeoTextPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const { paginationSections } = useSubPagesPagination()
   const { pages, loading } = useTypedSelector(getSeoSelector)

   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const [locale] = useLocalization()

   const [form, setForm] = useState<TSeoPage>({
      _id: '',
      metaTitle: '',
      metaDescription: '',
      metaKeywords: [],
      page: '',
      lang: locale as ELocales
   })
   const Events = {
      saveButtonClickHandler: () => {
         setAlertText('seo.successfuly.saved')
         Requests.editPages()
         toogleIsAlertOpen(true)
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setForm({ ...form, [input.name]: input.value })
      }
   }

   const Requests = {
      getPages: () => {
         dispatch(seoActions.GET_PAGES({}))
      },
      editPages: () => {
         form && dispatch(seoActions.EDIT_PAGES({ pages: [form] }))
      }
   }

   useEffect(() => {
      const page = pages.find((page) => page.page == SEOTEXT)
      page && setForm({ ...page, lang: locale as ELocales })
   }, [pages])

   useEffect(() => {
      Requests.getPages()
   }, [])

   return (
      <>
         <AdminPanelContainer
            loading={loading}
            Header={
               <Header
                  title={t('SEO')}
                  buttonsList={
                     <>
                        <Button theme="green" onClick={Events.saveButtonClickHandler}>
                           {t('save')}
                        </Button>
                     </>
                  }
               />
            }>
            <SubPagesBar sections={paginationSections} />
            <Container gap="30px" direction="column">
               <Input
                  name="metaTitle"
                  label={t('label')}
                  width="100%"
                  value={form?.metaTitle}
                  onChange={Events.onChange}
               />
               <Textarea
                  name="metaDescription"
                  label={t('text')}
                  height="271px"
                  onChange={Events.onChange}>
                  {form?.metaDescription}
               </Textarea>
            </Container>
            <Snackbar
               open={isAlertOpen}
               autoHideDuration={6000}
               onClose={() => toogleIsAlertOpen(false)}
               anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
               <Alert severity="success" sx={{ width: '100%' }}>
                  {t(`${alertText ? alertText : 'added'}`)}
               </Alert>
            </Snackbar>
         </AdminPanelContainer>
      </>
   )
}

export default SeoTextPage
