import { SyntheticEvent } from 'react'
import {
   ArrowDownIcon,
   ArrowUpIcon,
   ButtonsContainer,
   HeaderContentContainer,
   Styles
} from './styled'
import { TTableProps } from './types'
import { useTable } from 'react-table'
import { EditButton, RemoveButton } from '../Button'
import { Checkbox } from '../Checkbox'
import { TableUtils } from 'components/Table'

export const DefaultTable = ({
   columns,
   data,
   editable = false,
   removeable = false,
   checkboxClickHandler,
   rowClickHandler,
   editClickHandler,
   removeClickHandler,
   sortBy = {
      id: null,
      desc: false
   }
}: TTableProps) => {
   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable<any>({
         columns,
         data
      })

   const Events = {
      onSelectCheckboxClickHandler: (e: SyntheticEvent, index: number) => {
         const checkboxes = document.querySelectorAll('.selectColumnCheckbox')
         let hasCheckboxesActiveState = false
         const checkedItemsIndex: number[] = []

         Array.from(checkboxes).forEach((checkbox) => {
            if ((checkbox as HTMLInputElement).checked) {
               hasCheckboxesActiveState = true
               checkedItemsIndex.push(TableUtils.getRowIndex(checkbox as HTMLElement))
            }
         })

         checkboxClickHandler &&
            checkboxClickHandler(e, hasCheckboxesActiveState, checkedItemsIndex, index)
      },

      rowClickHandler: (e: SyntheticEvent) => {
         rowClickHandler &&
            rowClickHandler(TableUtils.getRowIndex(e.target as HTMLElement))
      },

      removeClickListener: (e: SyntheticEvent) => {
         removeClickHandler &&
            removeClickHandler(e, TableUtils.getRowIndex(e.target as HTMLElement))
      },

      editClickListener: (e: SyntheticEvent) => {
         editClickHandler &&
            editClickHandler(e, TableUtils.getRowIndex(e.target as HTMLElement))
      }
   }

   return (
      <Styles>
         <table {...getTableProps()}>
            <thead>
               {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                     {checkboxClickHandler && <th className="checkboxHeader"></th>}

                     {headerGroup.headers.map((column, index) => (
                        <th
                           {...column.getHeaderProps({
                              style: { width: column.width }
                           })}
                           onClick={(e: SyntheticEvent) => {
                              if (column.sortToggleHandler) {
                                 column.sortToggleHandler(
                                    !(sortBy.id && sortBy.desc)
                                       ? (column.id as string)
                                       : '',
                                    sortBy.id ? (sortBy.desc ? '' : 1) : -1
                                 )
                              }
                           }}>
                           <HeaderContentContainer>
                              {column.render('Header')}
                              {sortBy.id == column.id ? (
                                 sortBy.desc ? (
                                    <ArrowUpIcon />
                                 ) : (
                                    <ArrowDownIcon />
                                 )
                              ) : (
                                 ''
                              )}
                           </HeaderContentContainer>
                        </th>
                     ))}

                     {(editable || removeable) && <th className="buttonHeader"></th>}
                  </tr>
               ))}
            </thead>

            <tbody {...getTableBodyProps()}>
               {rows.map((row, index) => {
                  prepareRow(row)
                  return (
                     <tr
                        {...row.getRowProps()}
                        className="row"
                        onClick={Events.rowClickHandler}
                        style={{ cursor: rowClickHandler && 'pointer' }}>
                        {checkboxClickHandler && (
                           <td>
                              <Checkbox
                                 className="selectColumnCheckbox"
                                 onChange={(e) =>
                                    Events.onSelectCheckboxClickHandler(e, index)
                                 }
                                 key={index}
                                 checked={row.original.isChecked}
                              />
                           </td>
                        )}
                        {row.cells.map((cell) => {
                           return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}

                        {(editable || removeable) && (
                           <td>
                              <ButtonsContainer>
                                 {editable && (
                                    <EditButton onClick={Events.editClickListener} />
                                 )}

                                 {removeable && (
                                    <RemoveButton onClick={Events.removeClickListener} />
                                 )}
                              </ButtonsContainer>
                           </td>
                        )}
                     </tr>
                  )
               })}
            </tbody>
         </table>
      </Styles>
   )
}
