export const GET_BANNERS = 'grid/GET_BANNERS'
export const SET_BANNERS = 'grid/SET_BANNERS'
export const REMOVE_BANNER = 'grid/REMOVE_BANNER'
export const SAVE_BANNERS = 'grid/SAVE_BANNERS'
export const SET_BANNER_STATE = 'grid/SET_BANNER_STATE'
export const CHANGE_LANG = 'CHANGE_LANG'

export const RESPONSE = {
   SAVED: 'SAVED'
}
