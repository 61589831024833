import { colors } from 'enums'
import styled from 'styled-components'

export const FirsLevelWrap = styled.div`
   display: flex;
   align-items: center;
   min-height: 73px;
   padding: 0 20px;
   justify-content: space-between;
   width: 100%;
   background-color: ${colors.light_grey};
   font-size: 14px;
   font-weight: 500;
`
export const QuestionList = styled.ul`
   list-style: none;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   width: 100%;
`
