import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TInitialState } from './types'

const initialState: TInitialState = {
   sortBy: 'questionType',
   order: 1
}

export const CalculatorSlice = createSlice({
   name: 'calculatorSlice',
   initialState,
   reducers: {
      setSortBy: (state, { payload }: PayloadAction<string>) => {
         state.sortBy = payload
      },
      setOrder: (state, { payload }: PayloadAction<1 | -1>) => {
         state.order = payload
      }
   }
})

export const calculatorActions = CalculatorSlice.actions
export const calculatorReducer = CalculatorSlice.reducer
