import { createAction } from '@reduxjs/toolkit'
import { GET_ROLES, PATCH_ROLE, SET_FILTERS, SET_ROLES, SET_ROLE_STATE } from './consts'
import { TPatchRolePayload, TRoles, TSetRoleStatePayload } from './types'

export const roleActions = {
   setRoleState: createAction(SET_ROLE_STATE, (payload: TSetRoleStatePayload) => ({
      payload
   })),
   getRoles: createAction(GET_ROLES),
   setRoles: createAction(SET_ROLES, (payload: TRoles) => ({ payload })),
   setFilters: createAction(SET_FILTERS, (payload) => ({ payload })),
   patchRole: createAction(PATCH_ROLE, (payload: TPatchRolePayload) => ({ payload }))
}
