import { Checkbox, FlexContainer, Input } from 'components'
import { FC, useEffect, useMemo, useState } from 'react'
import {
   AddText,
   CheckboxText,
   ClickbleText,
   Container,
   DeleteText,
   HeaderText
} from '../../styled'
import { assets } from 'assets'
import { t } from 'i18next'
import { Card } from '../Card'
import { TProductsTableProps } from './types'
import { CUSTOMER_DISCOUNT } from 'store/client/types'
import { useDispatch } from 'react-redux'
import { clientActions } from 'store'
import { useLocalization } from 'hooks'
import { PAGE_LIMIT } from '../../consts'

export const ProductsTable: FC<TProductsTableProps> = ({
   selectedProducts,
   setSelectedProducts,
   Events,
   isChecked,
   form,
   customerDiscount,
   customerId
}) => {
   const dispatch = useDispatch()
   const [locale] = useLocalization()

   const [page, setPage] = useState<number>(0)

   const prodcutsWithDiscountValue = useMemo(() => {
      const filteredCustomerDiscountByProducts = customerDiscount?.data?.filter((item) =>
         selectedProducts?.map((item) => item?._id)?.includes(item?.product?._id)
      )

      const res = selectedProducts?.map((product) => ({
         ...product,
         discount: filteredCustomerDiscountByProducts.find(
            (item) => item?.product?._id == product._id
         )?.discount
      }))
      return res
   }, [selectedProducts])

   const getProducts = () => {
      dispatch(
         clientActions.getCustomerDiscountProducts({
            _id: customerId as string,
            lang: locale,
            type: CUSTOMER_DISCOUNT.product,
            page: page,
            limit: PAGE_LIMIT
         })
      )
   }

   const pagination = useMemo(
      () => Math.ceil(customerDiscount?.meta?.totalCount / PAGE_LIMIT),
      [customerDiscount?.meta]
   )

   useEffect(() => {
      getProducts()
   }, [page])

   return (
      <>
         {selectedProducts?.length > 0 && (
            <FlexContainer margin="30px" gap="30px">
               <FlexContainer width="210px" direction="column" gap="15px">
                  <HeaderText>{t('discount.for.products')}</HeaderText>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.PLUS_ICON_BLUE} />

                     <AddText onClick={Events.setProductsPopupVisiablity}>
                        {t('add.products.discount')}
                     </AddText>
                  </FlexContainer>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.DELETE_ICON} />

                     <DeleteText onClick={Events.clearProducts}>
                        {t('delete.products')}
                     </DeleteText>
                  </FlexContainer>

                  <FlexContainer
                     gap="8px"
                     style={{ paddingTop: isChecked ? '60px' : '105px' }}
                     align="center">
                     <Checkbox
                        checked={isChecked}
                        onChange={Events.checkedStateHandler}
                     />

                     <CheckboxText style={{ width: '163px' }}>
                        {t('common.discount.for.products')}
                     </CheckboxText>

                     {isChecked && (
                        <Input
                           name="discountPriceProducts"
                           onChange={Events.inputChangeHandler}
                           value={form.discountPriceProducts}
                           placeholder={t('for.all.products')}
                        />
                     )}
                  </FlexContainer>
               </FlexContainer>

               <Container>
                  {prodcutsWithDiscountValue.map((item, index) => (
                     <>
                        <Card
                           productItem={item}
                           selectedProducts={selectedProducts}
                           setSelectedProducts={setSelectedProducts}
                           discountValue={isChecked ? form.discountPriceProducts : ''}
                           defaultDiscountValue={item?.discount ? item.discount + '' : ''}
                           customerDiscount={customerDiscount}
                           index={index}
                        />

                        {index == 6 * (page + 1) + page && pagination > 1 ? (
                           <div
                              style={{
                                 alignSelf: 'center',
                                 justifySelf: 'center',
                                 width: '200px'
                              }}>
                              <div
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                 }}>
                                 <p>
                                    {t('checked') +
                                       ` ${index + 1} ` +
                                       t('from.all') +
                                       ` ${customerDiscount?.meta?.totalCount}`}
                                 </p>
                                 <ClickbleText onClick={() => setPage(page + 1)}>
                                    {t('show.more')}
                                 </ClickbleText>
                              </div>
                           </div>
                        ) : (
                           ''
                        )}
                     </>
                  ))}
               </Container>
            </FlexContainer>
         )}
      </>
   )
}
