import styled from 'styled-components'

export const Image = styled.img`
   width: 46px;
   height: 46px;
`

export const PreloaderContainer = styled.div`
   width: 100%;
   height: 100px;
`

export const TableContainer = styled.div`
   height: 560px;
   overflow-y: auto;
`
