import { useState, useMemo, useEffect, SyntheticEvent, FC } from 'react'
import { t } from 'i18next'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   InfinityScroll,
   Input,
   KeywordsInput,
   Pagination,
   RelativePreloader,
   Table,
   TColumnTable,
   TDataTable,
   Textarea,
   TSort
} from '../../../../../../components'
import { Container, EditImage, Line, PhotoText, Text } from './styled'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
   categoryActions,
   getMainCategorySelector,
   getMainProductSelector,
   productActions
} from '../../../../../../store'
import { useLocalization, useTypedSelector } from '../../../../../../hooks'
import { api } from '../../../../../../config'
import { assets } from '../../../../../../assets'
import { Image, StyledText } from '../../../../styled'
import { TCategory } from './types'
import { PAGE_LIMIT } from '../../../../consts'
import { CATEGORY_RESPONSE } from '../../../../../../store/category/consts'
import { Photos } from '../../../../components/Photos'
import { ProductImage } from '../../../../components/Photos/types'
import { PreloaderContainer } from '../../../../../Support/styled'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { TProduct } from '../../../../../../store/product/types'
import { DragAndDropProduct } from './components'
import { colors, ELocales } from '../../../../../../enums'
import { DragAndDropProduct as DragAndDropCategory } from 'pages/Catalog/components'

export const EditNewCategoryIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const {
      category,
      categories,
      ids,
      loading: categoryLoading,
      response: categoryResponse
   } = useTypedSelector(getMainCategorySelector)

   const { products } = useTypedSelector(getMainProductSelector)

   const [locale] = useLocalization()
   const { id } = useParams()

   const [backIds, setIds] = useState<string[]>([])
   const [productsByCategory, setProductsByCategory] = useState<TProduct[][]>([[]])
   const [subcategoriesDnd, setSubcategoriesDnd] = useState<TCategory[][]>([[]])
   const [requestsListLoading, setRequestListLoading] = useState<boolean>(true)

   const filteredSubcategoriesDnd = useMemo(() => {
      return [
         subcategoriesDnd.flat().filter((value, index, self) => {
            return (
               self.findIndex((v) => v._id === value._id) === index && self[index].parent
            )
         })
      ]
   }, [subcategoriesDnd])

   const filteredProductsByCategory = useMemo(() => {
      return [
         productsByCategory.flat().filter((value, index, self) => {
            return self.findIndex((v) => v?._id === value?._id) === index
         })
      ]
   }, [productsByCategory])

   const viewMoreCategories = useMemo(() => {
      if (categories.meta)
         return categories.meta?.totalCount > filteredSubcategoriesDnd.flat().length
      return false
   }, [filteredSubcategoriesDnd])

   const viewMoreProducts = useMemo(() => {
      if (products.meta)
         return products.meta.totalCount > filteredProductsByCategory.flat().length
      return false
   }, [filteredSubcategoriesDnd])

   const visiablityOptions = [
      { name: t('show'), value: '1' },
      { name: t('not.show'), value: '0' }
   ]

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const [form, setForm] = useState<TCategory>({
      _id: '',
      parent: '',
      show: true,
      icon: '',
      image: '',
      categoriesTotalCount: 0,
      productsTotalCount: 0,
      title: '',
      metaKeywords: [],
      metaDescription: ''
   })

   const [page, setPage] = useState<number>(0)
   const [productImages, setProductImages] = useState<ProductImage[]>([])
   const [iconImages, setIconImages] = useState<ProductImage[]>([])

   const Events = {
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      backButtonClickHandler: () => {
         backIds.length
            ? navigate(`/catalog/newCategories/${backIds[backIds.length - 1]}`)
            : navigate('/catalog/newCategories')
         setIds(backIds.slice(0, backIds.length - 1))
      },
      onKeywordsChangeHandler: (metaKeywords: string[]) => {
         setForm({ ...form, metaKeywords })
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         setIds(backIds.concat(id as string))
         navigate(`/catalog/newCategories/${categories.data[index]._id}`)
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      onPhotoChange: (file: File | null) => {
         setForm({ ...form, image: file as File })
      },
      saveButtonClickHandler: () => {
         Requests.editCategory()
      },
      visibilityChangehandler: (e: SyntheticEvent) => {
         const { value } = e.target as HTMLInputElement

         setForm((props) => ({ ...props, show: !!Number(value) ? true : false }))
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      onUploadPhoto: (e: any) => {
         setProductImages((prev: any) => [
            ...prev,
            {
               order: prev.length + 1,
               image: URL.createObjectURL(e.target.files[0]),
               preview: URL.createObjectURL(e.target.files[0])
            }
         ])

         const data = new FormData()

         setForm((prev) => ({ ...prev, image: e.target.files[0] }))

         // data.append('gallery', e.target.files[0])
      },
      onUploadIcon: (e: any) => {
         setIconImages((prev: any) => [
            ...prev,
            {
               order: prev.length + 1,
               image: URL.createObjectURL(e.target.files[0]),
               preview: URL.createObjectURL(e.target.files[0])
            }
         ])
         setForm((prev) => ({ ...prev, icon: e.target.files[0] }))

         // data.append('gallery', e.target.files[0])
      },
      onDeletePhoto: (order: number) => {
         setProductImages((prev: any) =>
            prev.filter((photo: ProductImage) => {
               return photo.order !== order
            })
         )
      },
      onDeleteIcon: (order: number) => {
         setIconImages((prev: any) =>
            prev.filter((photo: ProductImage) => {
               return photo.order !== order
            })
         )
      },
      onDragEnd: (products?: any[], result?: any, index?: number) => {
         const { source, destination } = result

         // dropped outside the list
         if (!destination) {
            return
         }
         const sInd = +source.droppableId
         const dInd = +destination.droppableId

         if (sInd === dInd) {
            const items = Utils.reorder(
               filteredProductsByCategory[sInd],
               source.index,
               destination.index
            )
            const newState = [...filteredProductsByCategory]
            newState[sInd] = items as TProduct[]
            setProductsByCategory(newState)
         } else {
            const result = Utils.move(
               filteredProductsByCategory[sInd],
               filteredProductsByCategory[dInd],
               source,
               destination
            )
            const newState = [...filteredProductsByCategory]
            newState[sInd] = result.sourceClone as TProduct[]
            newState[dInd] = result.destClone as TProduct[]
            setProductsByCategory(newState)
         }
      },
      onCategoriesDragEnd: (products?: any[], result?: any, index?: number) => {
         const { source, destination } = result

         // dropped outside the list
         if (!destination) {
            return
         }
         const sInd = +source.droppableId
         const dInd = +destination.droppableId

         if (sInd === dInd) {
            const items = Utils.categoriesReorder(
               filteredSubcategoriesDnd[sInd],
               source.index,
               destination.index
            )
            const newState = [...filteredSubcategoriesDnd]
            newState[sInd] = items as TCategory[]
            setSubcategoriesDnd(newState)
         } else {
            const result = Utils.moveCategories(
               filteredSubcategoriesDnd[sInd],
               filteredSubcategoriesDnd[dInd],
               source,
               destination
            )
            const newState = [...filteredSubcategoriesDnd]
            newState[sInd] = result.sourceClone as TCategory[]
            newState[dInd] = result.destClone as TCategory[]
            setSubcategoriesDnd(newState)
         }
      },
      viewAdditional: () => {
         if ((categories.meta?.totalCount as number) > productsByCategory.flat().length)
            setPage(page + 1)
         page != 0 && Requests.getSubCategories()
      },
      nextInfinityScrollHandler: () => {
         setPage((page) => page + 1)
         Requests.getProductsByCategory()
         setRequestListLoading(false)
      }
   }

   const Utils = {
      reorder: (row: TProduct[], startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      },
      move: (
         source: TProduct[],
         destination: TProduct[],
         droppableSource: { index: number; droppableId: string },
         droppableDestination: { droppableId: string; index: number }
      ) => {
         const sourceClone = Array.from(source)
         const destClone = Array.from(destination)
         const [removed] = sourceClone.splice(droppableSource.index, 1)

         destClone.splice(droppableDestination.index, 0, removed)

         return { sourceClone, destClone }
      },
      categoriesReorder: (row: TCategory[], startIndex: number, endIndex: number) => {
         const result = Array.from(row)
         const [removed] = result.splice(startIndex, 1)
         result.splice(endIndex, 0, removed)

         return result
      },
      moveCategories: (
         source: TCategory[],
         destination: TCategory[],
         droppableSource: { index: number; droppableId: string },
         droppableDestination: { droppableId: string; index: number }
      ) => {
         const sourceClone = Array.from(source)
         const destClone = Array.from(destination)
         const [removed] = sourceClone.splice(droppableSource.index, 1)

         destClone.splice(droppableDestination.index, 0, removed)

         return { sourceClone, destClone }
      }
   }

   const getItemStyle = (isDragging: boolean, draggableStyle: any, grid: number) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      margin: `0 20px 0 0`,
      background: colors.solid_white,

      // change background colour if dragging

      // styles we need to apply on draggables
      ...draggableStyle
   })

   const getListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      padding: 8,
      overflow: 'auto',
      flex: 1
   })

   const getCategoriesListStyle = (isDraggingOver: boolean) => ({
      display: 'flex',
      width: '100%',
      // alignItems: 'center',
      flexDirection: 'column',
      padding: `8px 8px 8px 20px`,
      overflow: 'auto'
      // flex: 1
   })

   const Requests = {
      getCategory: () => {
         dispatch(
            categoryActions.getCategory({ _id: id as string, lang: locale as ELocales })
         )
      },
      getSubCategories: () => {
         dispatch(
            categoryActions.getCategoriesByParent({
               _id: id as string,
               lang: locale as ELocales,
               page: page,
               limit: PAGE_LIMIT,
               sortBy: 'order',
               order: 1
            })
         )
      },
      editCategory: () => {
         const data = new FormData()

         form.title &&
            data.append(
               'description[]',
               JSON.stringify({
                  title: form.title,
                  lang: locale,
                  metaKeywords: form.metaKeywords,
                  metaDescription: form.metaDescription
               })
            )
         form.image && data.append('img', form.image as File)
         form.image && data.append('icon', form.icon as File)
         form.parent && data.append('parent', form.parent as string)
         data.append('show', form.show ? 'true' : 'false')

         dispatch(categoryActions.editCategory({ data, _id: form._id as string }))

         if (form.show != category?.show) {
            const subCategoryData = new FormData()
            subCategoryData.append('show', form.show ? 'true' : 'false')
            for (let subCategory of categories.data) {
               dispatch(
                  categoryActions.editCategory({
                     data: subCategoryData,
                     _id: subCategory._id
                  })
               )
            }
         }

         category?.categoriesTotalCount == 0 &&
            dispatch(
               productActions.editPromotionProducts({
                  data: productsByCategory.map((productRow) =>
                     productRow.map((product: TProduct, index: number) => ({
                        _id: product._id,
                        data: {
                           categoryOrder: index + 1
                        },
                        lang: locale as ELocales
                     }))
                  )[0]
               })
            )
         category?.categoriesTotalCount != 0 &&
            dispatch(
               categoryActions.editCategoryOrder({
                  data: subcategoriesDnd.map((categories) =>
                     categories.map((category, index) => ({
                        _id: category._id,
                        data: {
                           order: index + 1
                        }
                     }))
                  )[0]
               })
            )
      },
      getProductsByCategory: () => {
         dispatch(
            productActions.getProducts({
               lang: locale as ELocales,
               hidden: 'false',
               category: [id as string]
            })
         )
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('photo'),
            accessor: 'photo',
            width: 150
         },
         {
            Header: t('label'),
            accessor: 'title',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('total.product'),
            accessor: 'productsTotalCount',
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('visiability'),
            accessor: 'show',
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const data: TDataTable[] = useMemo(() => {
      return categories.data.map((subCategory) => {
         return {
            photo: <Image src={`${api.img}category/${subCategory.image}`} />,
            title: <>{subCategory.title}</>,
            productsTotalCount: <>{subCategory.productsTotalCount}</>,
            show: <>{subCategory.show ? t('show') : t('not.show')}</>
         }
      })
   }, [categories])

   useEffect(() => {
      category &&
         setForm({
            _id: category._id,
            parent: category.parent as string,
            show: category.show,
            icon: category.icon as string,
            image: category.image,
            categoriesTotalCount: category.categoriesTotalCount,
            productsTotalCount: category.productsTotalCount,
            title: category.title,
            metaKeywords: [],
            metaDescription: ''
         })
   }, [category])

   useEffect(() => {
      Requests.getCategory()
      Requests.getSubCategories()
      Requests.getProductsByCategory()
      if (ids.length > 0) {
         setIds(ids)
      }
   }, [])

   useEffect(() => {
      Requests.getSubCategories()
   }, [page])

   useEffect(() => {
      if (categoryResponse === CATEGORY_RESPONSE.DONE) {
         setForm((prev) => ({
            ...prev,
            metaKeywords: category?.metaKeywords as string[],
            metaDescription: category?.metaDescription as string
         }))
      }
   }, [form])

   useEffect(() => {
      if (categoryResponse === CATEGORY_RESPONSE.EDITED) {
         setProductImages([])
         setIconImages([])
         Requests.getCategory()
         Requests.getSubCategories()
      }
   }, [categoryResponse])

   useEffect(() => {
      setSubcategoriesDnd([])
      setPage(0)
      Requests.getCategory()
      Requests.getSubCategories()
      Requests.getProductsByCategory()
   }, [backIds, id])

   useEffect(() => {
      products &&
         setProductsByCategory([
            products.data
               .filter((item) => item.categoryOrder)
               .sort((a, b) => (a.categoryOrder as number) - (b.categoryOrder as number))
         ])
   }, [products])

   useEffect(() => {
      if (page == 0) {
         categories &&
            categories.data.length > 0 &&
            setSubcategoriesDnd(
               subcategoriesDnd
                  .slice(1)
                  .concat([categories.data.slice().sort((a, b) => a.order - b.order)])
            )
      } else {
         categories &&
            categories.data.length > 0 &&
            setSubcategoriesDnd(
               subcategoriesDnd.concat([
                  categories.data.slice().sort((a, b) => a.order - b.order)
               ])
            )
      }
   }, [categories])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t(`${category?.title}`)}
               backButtonClickHandler={Events.backButtonClickHandler}
            />
         }
         loading={categoryLoading}>
         <Container>
            <FlexContainer gap="62px">
               <Input
                  name="title"
                  label={t('title')}
                  placeholder={t('enter.title')}
                  value={form.title}
                  onChange={Events.inputChangeHandler}
               />
               <DropDownList
                  name="visiablity"
                  label={t('visiablity')}
                  placeholder={t('choose.visability')}
                  options={visiablityOptions}
                  value={
                     form?.show ? visiablityOptions[0].value : visiablityOptions[1].value
                  }
                  onChange={Events.visibilityChangehandler}
               />
               <FlexContainer width="100%">
                  {form?.metaKeywords?.length > 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
                  {form?.metaKeywords?.length == 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
                  {form?.metaKeywords == undefined && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
               </FlexContainer>
               <FlexContainer>
                  <Textarea
                     name="metaDescription"
                     height="271px"
                     label={t('meta.description')}
                     onChange={Events.inputChangeHandler}>
                     {form?.metaDescription}
                  </Textarea>
               </FlexContainer>
            </FlexContainer>
            <FlexContainer gap="28px" wrap="nowrap" width="200px">
               <FlexContainer>
                  <Photos
                     gallery={productImages}
                     preview={category?.image}
                     label={'photo'}
                     alt={'category img'}
                     id="photo"
                     path=""
                     uploadPhoto={Events.onUploadPhoto}
                     deletePhoto={Events.onDeletePhoto}
                  />
               </FlexContainer>
               <FlexContainer gap="12px" direction="column" justify="flex-start">
                  <Photos
                     gallery={iconImages}
                     preview={category?.icon}
                     label={'icon'}
                     id="icon"
                     path="icon/"
                     alt={'category icon'}
                     uploadPhoto={Events.onUploadIcon}
                     deletePhoto={Events.onDeleteIcon}
                  />
               </FlexContainer>
            </FlexContainer>
         </Container>
         <Line />

         {category && category?.categoriesTotalCount > 0 && (
            <Text>{t('subcategory')}</Text>
         )}
         {category?.categoriesTotalCount === 0 && <Text>{t('products')}</Text>}

         {category?.categoriesTotalCount === 0 && (
            <Container>
               {productsByCategory[0].length > 0 && (
                  <FlexContainer
                     align="center"
                     gap="30px"
                     width="100%"
                     wrap="nowrap"
                     direction="column">
                     <InfinityScroll
                        next={Events.nextInfinityScrollHandler}
                        loading={requestsListLoading}
                        preloader={
                           <PreloaderContainer>
                              <RelativePreloader loading />
                           </PreloaderContainer>
                        }>
                        <DragDropContext
                           onDragEnd={(result) =>
                              Events.onDragEnd(filteredProductsByCategory, result)
                           }>
                           {filteredProductsByCategory.map((item, ind) => (
                              <Droppable
                                 droppableId={`${ind}`}
                                 direction="horizontal"
                                 key={ind}>
                                 {(provided: any, snapshot: any) => (
                                    <div
                                       ref={provided.innerRef}
                                       style={getListStyle(snapshot.isDraggingOver)}
                                       {...provided.droppableProps}>
                                       {provided.placeholder}
                                       {item.map((product: TProduct, index: number) => (
                                          <Draggable
                                             draggableId={item[index]._id}
                                             index={index}
                                             key={product._id}>
                                             {(provided, snapshot) => (
                                                <div
                                                   ref={provided.innerRef}
                                                   {...provided.draggableProps}
                                                   {...provided.dragHandleProps}
                                                   style={getItemStyle(
                                                      snapshot.isDragging,
                                                      provided.draggableProps.style,
                                                      8
                                                   )}>
                                                   <DragAndDropProduct
                                                      key={product._id}
                                                      productItem={product}
                                                      backIds={backIds}
                                                   />
                                                </div>
                                             )}
                                          </Draggable>
                                       ))}
                                    </div>
                                 )}
                              </Droppable>
                           ))}
                        </DragDropContext>
                     </InfinityScroll>
                  </FlexContainer>
               )}
            </Container>
         )}

         {category && category?.categoriesTotalCount > 0 && (
            <FlexContainer
               justify="space-between"
               padding="8px"
               width="100%"
               wrap="nowrap">
               <div>
                  <StyledText style={{ width: '120px' }}>{t('photo')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '120px' }}>{t('icon')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('title')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('total.product')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('subcategories')}</StyledText>
               </div>
               <div>
                  <StyledText style={{ width: '235px' }}>{t('visability')}</StyledText>
               </div>
            </FlexContainer>
         )}

         {categories.data.length > 0 && (
            <FlexContainer align="center" gap="30px" wrap="nowrap" direction="column">
               <DragDropContext
                  onDragEnd={(result) =>
                     Events.onCategoriesDragEnd(filteredSubcategoriesDnd, result)
                  }>
                  {filteredSubcategoriesDnd.map((item: TCategory[], ind) => (
                     <Droppable droppableId={`${ind}`} direction="vertical" key={ind}>
                        {(provided: any, snapshot: any) => (
                           <div
                              ref={provided.innerRef}
                              style={getCategoriesListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}>
                              {provided.placeholder}
                              {item.map((product: TCategory, index) => (
                                 <Draggable
                                    draggableId={item[index]._id}
                                    index={index}
                                    key={product._id}>
                                    {(provided, snapshot) => (
                                       <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                             snapshot.isDragging,
                                             provided.draggableProps.style,
                                             8
                                          )}>
                                          <DragAndDropCategory
                                             key={product._id}
                                             id={id}
                                             productItem={product as any}
                                             setBackId={setIds}
                                             backIds={backIds}
                                             path="newCategories"
                                          />
                                       </div>
                                    )}
                                 </Draggable>
                              ))}
                           </div>
                        )}
                     </Droppable>
                  ))}
               </DragDropContext>
            </FlexContainer>
         )}
         {viewMoreCategories && (
            <FlexContainer justify="center">
               <Button onClick={Events.viewAdditional}>{t('view.another')}</Button>
            </FlexContainer>
         )}

         {/* {viewMoreProducts && (
            <FlexContainer justify="center">
               <Button onClick={Events.viewAdditionalProducts}>
                  {t('view.another')}
               </Button>
            </FlexContainer>
         )} */}
      </AdminPanelContainer>
   )
}
