import { useTranslation } from 'react-i18next'
import { TQuestionProps } from './types'
import { Controller, useFieldArray } from 'react-hook-form'
import { HideWrap, ParrentQuestionWrap, QeuestionsList, QuestionItem } from './styled'
import { CustomInput } from 'pages/Calculator/components'
import { Answers } from '../Answers/Answers'
import { MdOutlineSubdirectoryArrowRight } from 'react-icons/md'
import { Checkbox } from '@mui/material'

export const Question = ({
   control,
   register,
   watch,
   errors,
   setValue
}: TQuestionProps) => {
   const { t, i18n } = useTranslation()

   const lang = i18n.language
   const langInput = lang === 'ru' ? 'Ru' : 'Az'
   const { fields, append, remove } = useFieldArray({
      control,
      name: 'questions'
   })

   const calculateDepth = (index: number) => {
      const currentQuestion = watch(`questions`)[index]

      if (!currentQuestion.parentId) {
         return 0
      }

      let depth = 0
      let parentId = currentQuestion.parentId || ''

      while (parentId) {
         const parentQuestion = watch(`questions`).find(
            (question) => question.id === parentId
         )

         if (!parentQuestion) {
            break
         }

         depth += 1

         parentId = parentQuestion.parentId || ''
      }

      return depth
   }

   return (
      <>
         {' '}
         <HideWrap>
            <Controller
               control={control}
               name={`questions.${0}.hidden`}
               render={({ field: { onChange, value } }) => (
                  <Checkbox checked={value} onChange={onChange} />
               )}
            />
            <p>{t('hideQuestion')}</p>
         </HideWrap>
         <QeuestionsList>
            {fields?.map((item, index) => {
               const deep = calculateDepth(index)

               const parrentIndex = watch(`questions`).findIndex(
                  (el) => el.id === item.parentId
               )
               const parrentAnswerIndex = watch(`questions`)[
                  parrentIndex
               ]?.answers?.findIndex((el) => el.question === watch(`questions`)[index].id)
               return (
                  <QuestionItem key={item.id} $deep={deep} id={`question${parrentIndex}`}>
                     {index !== 0 && (
                        <ParrentQuestionWrap>
                           <MdOutlineSubdirectoryArrowRight />
                           <span>
                              <p>
                                 {watch(
                                    `questions.${parrentIndex || 0}.question${langInput}`
                                 )}
                              </p>
                              <p>
                                 {watch(
                                    `questions.${parrentIndex || 0}.answers.${
                                       parrentAnswerIndex || 0
                                    }.answer${langInput}`
                                 )}
                              </p>
                           </span>
                        </ParrentQuestionWrap>
                     )}
                     <CustomInput
                        {...register(`questions.${index}.questionAz`, {
                           required: t('required.field')
                        })}
                        placeholder={t('question')}
                        label={`${t(`question`)} AZ`}
                        width="100%"
                        $error={errors.questions?.[index]?.questionAz?.message}
                     />
                     <CustomInput
                        {...register(`questions.${index}.questionRu`, {
                           required: t('required.field')
                        })}
                        placeholder={t('question')}
                        label={`${t(`question`)} RU`}
                        width="100%"
                        $error={errors.questions?.[index]?.questionRu?.message}
                     />
                     <Answers
                        append={append}
                        remove={remove}
                        setValue={setValue}
                        index={index}
                        watch={watch}
                        register={register}
                        control={control}
                        errors={errors}
                     />
                  </QuestionItem>
               )
            })}
         </QeuestionsList>
      </>
   )
}
