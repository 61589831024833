import { createAction } from '@reduxjs/toolkit'
import {
   CREATE_CUSTOMER_DISCOUNT,
   CREATE_CUSTOMER_DISCOUNT_BULK,
   CUSTOMER_DISCOUNT_BULK,
   DELETE_CUSTOMER_DISCOUNT_BULK,
   EDIT_CLIENT,
   GET_BUSINESS_CLIENTS,
   GET_BUSINESS_CUSTOMER_REQUEST,
   GET_CLIENT,
   GET_CLIENTS,
   GET_CUSTOMER_DISCOUNT_BRANDS,
   GET_CUSTOMER_DISCOUNT_PRODUCTS,
   SET_BUSINESS_CLIENTS,
   SET_BUSINESS_CUSTOMER_REQUEST,
   SET_CLIENT,
   SET_CLIENTS,
   SET_CLIENT_STATE,
   SET_CUSTOMER_DISCOUNT_BRANDS,
   SET_CUSTOMER_DISCOUNT_PRODUCTS,
   SET_FILTERS,
   UPDATE_CUSTOMER_DISCOUNT_BULK
} from './consts'
import {
   TEditClientPayload,
   TGetBusinessClientsPayload,
   TGetBusinessCustomerRequestPayload,
   TGetClientPayload,
   TGetClientsPayload,
   TGetCustomerDiscountByIdPayload,
   TSetClientPayload,
   TSetClientStatePayload
} from './types'

export const clientActions = {
   getClients: createAction(GET_CLIENTS, (payload: TGetClientsPayload) => ({ payload })),
   setClients: createAction(SET_CLIENTS, (payload) => ({ payload })),
   setClientState: createAction(SET_CLIENT_STATE, (payload: TSetClientStatePayload) => ({
      payload
   })),
   getClient: createAction(GET_CLIENT, (payload: TGetClientPayload) => ({ payload })),
   setClient: createAction(SET_CLIENT, (payload: TSetClientPayload) => ({ payload })),
   editClient: createAction(EDIT_CLIENT, (payload: TEditClientPayload) => ({ payload })),
   setFilters: createAction(SET_FILTERS, (payload) => ({ payload })),

   getBusinessClients: createAction(
      GET_BUSINESS_CLIENTS,
      (payload: TGetBusinessClientsPayload) => ({ payload })
   ),
   setBusinessClients: createAction(SET_BUSINESS_CLIENTS, (payload) => ({ payload })),

   createCustomerDiscount: createAction(CREATE_CUSTOMER_DISCOUNT, (payload) => ({
      payload
   })),

   createCustomerDiscountBulk: createAction(CREATE_CUSTOMER_DISCOUNT_BULK, (payload) => ({
      payload
   })),

   getCustomerDiscountBrands: createAction(
      GET_CUSTOMER_DISCOUNT_BRANDS,
      (payload: TGetCustomerDiscountByIdPayload) => ({
         payload
      })
   ),

   getCustomerDiscountProducts: createAction(
      GET_CUSTOMER_DISCOUNT_PRODUCTS,
      (payload: TGetCustomerDiscountByIdPayload) => ({
         payload
      })
   ),

   setCustomerDiscountBrands: createAction(SET_CUSTOMER_DISCOUNT_BRANDS, (payload) => ({
      payload
   })),

   setCustomerDiscountProducts: createAction(
      SET_CUSTOMER_DISCOUNT_PRODUCTS,
      (payload) => ({
         payload
      })
   ),

   updateCustomerDiscountBulk: createAction(UPDATE_CUSTOMER_DISCOUNT_BULK, (payload) => ({
      payload
   })),
   deleteCustomerDiscountBulk: createAction(DELETE_CUSTOMER_DISCOUNT_BULK, (payload) => ({
      payload
   })),
   customerDiscountBulk: createAction(CUSTOMER_DISCOUNT_BULK, (payload) => ({ payload })),

   getBusinessCustomerRequsest: createAction(
      GET_BUSINESS_CUSTOMER_REQUEST,
      (payload: TGetBusinessCustomerRequestPayload) => ({ payload })
   ),
   setBusinessCustomerRequest: createAction(SET_BUSINESS_CUSTOMER_REQUEST, (payload) => ({
      payload
   }))
}
