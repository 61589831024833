import { FC } from 'react'
import { StyledButton } from './styled'
import { buttonTheme, TButtonProps } from './types'

const Button: FC<TButtonProps> = ({ children, theme = 'blue', style = 'basic', onClick, width = 195, height = 49 }) => {
   return (
      <StyledButton className={`${theme} ${style}`} onClick={onClick} width={width} height={height}>
         {children}
      </StyledButton>
   )
}

export default Button
