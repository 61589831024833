import styled from 'styled-components'
import { FONT } from '../../../../../../utils'

export const Text = styled.p`
   ${FONT({ weight: '500', size: '14px', align: 'center' })}
`

export const Line = styled.div`
   border-bottom: 1px solid #f4f4f4;
   padding-bottom: 32px;
`
