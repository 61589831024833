import { FC } from 'react'
import { Container, Option, SelectList } from './styled'
import { TDropDownList } from './types'

import { Label } from '../Styled'
import { colors } from 'enums'
import { assets } from 'assets'

const DropDownList: FC<TDropDownList> = ({
   name = '',
   label,
   options,
   value = '',
   onChange,
   placeholder,
   width = '318px',
   height = '45px',
   isValid,
   disabled,
   createDiscount,
   textAlign,
   whiteArrow,
   changedProduct
}) => {
   return (
      <Container width={width}>
         {label && <Label>{label}</Label>}
         {createDiscount && (
            <img
               src={assets.VERTICAL_LINE}
               style={{ left: '460px', position: 'absolute' }}
            />
         )}
         <SelectList
            name={name}
            onChange={onChange}
            value={value}
            height={height}
            textAlign={textAlign}
            whiteArrow={whiteArrow}
            disabled={disabled}
            changedProduct={changedProduct}
            style={{
               border: !isValid ? '' : `1px solid ${colors.red}`,
               backgroundColor: createDiscount ? '#637B96' : '',
               color: createDiscount ? '#fff' : '',
               margin: createDiscount ? '30px' : ''
            }}>
            <Option
               value=""
               disabled
               style={{
                  backgroundColor: createDiscount ? '#fff' : '',
                  color: createDiscount ? `${colors.grey}` : ''
               }}>
               {placeholder}
            </Option>

            {options.map((option, index) => (
               <Option
                  key={index}
                  value={option.value}
                  style={{
                     backgroundColor: createDiscount ? '#fff' : '',
                     color: createDiscount ? '#000' : ''
                  }}>
                  {option.name}
               </Option>
            ))}
         </SelectList>
      </Container>
   )
}

export default DropDownList
