import { t } from 'i18next'
import { TCreateCalculatorForm, TParrentData, TQuestion } from './types'
import { TCalculatorAction, TCalculatorQuestion, TRooms } from 'store/calculator/types'

export const questionsOptions: { label: string; value: TCalculatorAction }[] = [
   { value: 'nothing', label: t('nothing') },
   { value: 'question', label: t('nextQuestion') },
   { value: 'products', label: t('products') },
   { value: 'question&products', label: t('productSubquestion') }
]

export const createId = () => {
   let timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
   return (
      timestamp +
      'xxxxxxxxxxxxxxxx'
         .replace(/[x]/g, function () {
            return ((Math.random() * 16) | 0).toString(16)
         })
         .toLowerCase()
   )
}

export const setDefaultQuestion = (
   parentData?: TParrentData,
   nextId?: string
): TQuestion => ({
   questionAz: '',
   questionRu: '',
   id: nextId || createId(),
   closedQuestion: true,
   answers: [
      {
         answerAz: '',
         answerRu: '',
         action: questionsOptions[0],
         id: createId(),
         default: false
      },
      {
         answerAz: '',
         answerRu: '',
         action: questionsOptions[0],
         id: createId(),
         default: false
      }
   ],
   isParent: true,
   hidden: false,
   ...parentData
})

export const createData = (data: TCreateCalculatorForm, lang: string) => {
   const rooms = Object.entries(data.typeofrooms)
      .filter((item): item is [TRooms, boolean] => Boolean(item[1]))
      .flatMap((item) => item[0])

   for (const question of data.questions) {
      for (const answer of question.answers) {
         const actionsRequiringProducts = ['products', 'question&products']
         if (actionsRequiringProducts.includes(answer.action.value)) {
            if (
               !answer.answerProductsData?.length ||
               answer.answerProductsData.some((el) => el.products?.length !== 3)
            ) {
               return false
            }
         }
      }
   }

   const calculatorData: TCalculatorQuestion[] = data.questions.map((item) => ({
      _id: item.id,
      title: [
         { lang: 'az', value: item.questionAz },
         { lang: 'ru', value: item.questionRu }
      ],
      type: item.closedQuestion ? 'close' : 'open',
      rooms: rooms,
      answers: item.answers.map((el) => ({
         _id: el.id,
         answer: [
            { lang: 'az', value: el.answerAz },
            { lang: 'ru', value: el.answerRu }
         ],
         action: el.action.value,
         question: el.question || undefined,
         default: !!item.hidden && !!el.default,
         products:
            el.answerProductsData?.map((item) => ({
               economproduct: item?.products?.find((pr) => pr.type === 'economproduct')
                  ?.product._id,
               standartproduct: item?.products?.find(
                  (pr) => pr.type === 'standartproduct'
               )?.product._id,
               premiumproduct: item?.products?.find((pr) => pr.type === 'premiumproduct')
                  ?.product._id,
               expression: item.expression
                  ?.map((exp) => {
                     if (exp.value === 'inputField') {
                        return `{${exp.inputValue}}`
                     } else if (exp.type === 'signs' && exp.value !== 'inputField') {
                        return `{${exp.value}}`
                     } else if (exp.type === 'block' && exp.value !== 'question') {
                        return `{${exp.value}}`
                     } else if (exp.value === 'question' && exp.type === 'block') {
                        return `{${exp.id}}`
                     }
                  })
                  .join(',')
                  .replace(/,/g, '')
            })) || []
      })),
      isParent: item.isParent,
      parentId: item.parentId,
      category: data.nameCategory.value,
      hidden: !!item.hidden
   }))

   return calculatorData
}
