import { useTranslation } from 'react-i18next'
import {
   DeclineButton,
   EpmtyBlock,
   ModalTieWrap,
   ProductList,
   ProductTitleWrap,
   ProductsWrap
} from './styled'
import { ModalTieProps, TItemDragTypes, TProductType, TProductsState } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalMaterial } from 'components'
import { Modalproducts } from '../ModalProducts/ModalProducts'
import { FormulaContainer, ProductCard } from './components'
import { toast } from 'react-toastify'
import { setDefaultElement } from './helpers'

export const ModalTieUpProduct = ({
   setValue,
   closeModal,
   watch,
   index,
   subIndex,
   productDataIndex
}: ModalTieProps) => {
   const { t } = useTranslation()

   const [products, setProducts] = useState<TProductsState>([])
   const [openModalProduct, setOpenModalProduct] = useState(false)
   const [formula, setFormula] = useState<TItemDragTypes[]>([])
   const [productType, setProductType] = useState<TProductType | null>(null)
   const [startElements, setStartElements] = useState(() =>
      setDefaultElement(watch, index, subIndex)
   )

   useEffect(() => {
      const currentInput = watch(
         `questions.${index}.answers.${subIndex}.answerProductsData.${productDataIndex}`
      )
      if (currentInput?.expression) {
         setFormula(currentInput.expression)
      }
      if (currentInput?.products) {
         setProducts(currentInput.products)
      }
   }, [])

   useEffect(() => {
      const currentInput = watch(
         `questions.${index}.answers.${subIndex}.answerProductsData.${productDataIndex}`
      )
      if (currentInput?.expression?.length) {
         setStartElements((prev) =>
            prev.filter(
               (item) => !currentInput.expression?.find((el) => el.label === item.label)
            )
         )
      }
   }, [])

   const openModalproduct = (type: TProductType) => {
      setProductType(type)
      setOpenModalProduct(true)
   }
   const closeModalproduct = () => {
      setProductType(null)
      setOpenModalProduct(false)
   }

   const premiumProduct = useMemo(
      () => products.find((item) => item.type === 'premiumproduct'),
      [products]
   )
   const standardProduct = useMemo(
      () => products.find((item) => item.type === 'standartproduct'),
      [products]
   )
   const economProduct = useMemo(
      () => products.find((item) => item.type === 'economproduct'),
      [products]
   )

   const onCreateClick = useCallback(() => {
      if (products.length < 3) {
         toast.info(t('fillInAllProducts'))
         return
      } else {
         const isErrorInput = formula
            .filter((item) => item.value === 'inputField')
            .some((el) => !el.inputValue || +el.inputValue <= 0)

         if (isErrorInput) {
            toast.info(t('fillInTheBlankInput'))
         } else {
            setValue(
               `questions.${index}.answers.${subIndex}.answerProductsData.${productDataIndex}`,
               {
                  // ...watch(`questions.${index}.answers.${subIndex}`),
                  products,
                  expression: formula
               }
            )
            closeModal()
         }
      }
   }, [products, formula])

   return (
      <ModalTieWrap>
         <ModalMaterial close={closeModalproduct} isOpen={openModalProduct}>
            <Modalproducts
               watch={watch}
               productType={productType}
               setProducts={setProducts}
               close={closeModalproduct}
               products={products}
            />
         </ModalMaterial>
         <h2>{t('tieProducts')}</h2>
         <ProductsWrap>
            <ProductTitleWrap>
               <h3>{t('products')}</h3>
               <p>{t('selectProductsCalculateUsingTheFormula')}</p>
            </ProductTitleWrap>
            <ProductList>
               <li>
                  <p>{t('economy')}</p>
                  {!economProduct ? (
                     <EpmtyBlock onClick={() => openModalproduct('economproduct')}>
                        {t('add.product')}
                     </EpmtyBlock>
                  ) : (
                     <ProductCard
                        setProducts={setProducts}
                        type="economproduct"
                        product={
                           products.find((item) => item.type === 'economproduct')?.product
                        }
                     />
                  )}
               </li>
               <li>
                  <p>{t('standard')}</p>
                  {!standardProduct ? (
                     <EpmtyBlock onClick={() => openModalproduct('standartproduct')}>
                        {t('add.product')}
                     </EpmtyBlock>
                  ) : (
                     <ProductCard
                        product={
                           products.find((item) => item.type === 'standartproduct')
                              ?.product
                        }
                        setProducts={setProducts}
                        type="standartproduct"
                     />
                  )}
               </li>
               <li>
                  <p>{t('premium')}</p>
                  {!premiumProduct ? (
                     <EpmtyBlock onClick={() => openModalproduct('premiumproduct')}>
                        {t('add.product')}
                     </EpmtyBlock>
                  ) : (
                     <ProductCard
                        setProducts={setProducts}
                        type="premiumproduct"
                        product={
                           products.find((item) => item.type === 'premiumproduct')
                              ?.product
                        }
                     />
                  )}
               </li>
            </ProductList>
         </ProductsWrap>

         <FormulaContainer
            startElements={startElements}
            setStartElements={setStartElements}
            onCreateClick={onCreateClick}
            watch={watch}
            setFormula={setFormula}
            formula={formula}
         />
         <DeclineButton onClick={closeModal}>{t('cancel')}</DeclineButton>
      </ModalTieWrap>
   )
}
