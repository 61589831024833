import styled from 'styled-components'

import { TStyledLabelProps, TStyledInputProps } from './types'
import { colors } from 'enums'
// style for Input
export const StyledInput = styled.input<TStyledInputProps>`
   border-radius: 8px;
   box-sizing: border-box;
   display: block;
   outline: none;
   /* border: none; */
   padding: ${({ $padding }) => $padding};
   border: 1px solid ${({ $error }) => (!$error ? colors.lighter_grey : colors.red)};
   height: ${({ height }) => height};
   width: 100%;
   font-size: 16px;
   background-color: ${({ fill }) => fill};
   transition: border-color 0.2s;
   color: ${colors.black};
   &[disabled] {
      background-color: ${colors.lighter_grey};
   }
   &:focus {
      border: 1px solid ${({ $error }) => (!$error ? colors.black : colors.red)};
   }
`
export const ShowPasswordButton = styled.button`
   border: none;
   background-color: transparent;
   position: absolute;
   left: 90%;
   bottom: 8px;
   cursor: pointer;
   opacity: 0.5;
   font-size: 12px;
   svg {
      width: 20px;
      height: 20px;
   }
`
// style for label
export const Label = styled.label<TStyledLabelProps>`
   position: relative;
   display: flex;
   flex-direction: column;
   top: 0;
   width: ${({ width }) => width};
   color: ${colors.black};
   span {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600;
   }
`
export const ErrorMessage = styled.div<{ $bottomError: string }>`
   position: absolute;
   font-size: 12px;
   bottom: ${({ $bottomError }) => $bottomError};
   color: ${colors.red};
`
