import { t } from 'i18next'
import { FC, useMemo, useState, useEffect, SyntheticEvent } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   SubPagesBar,
   Table,
   TColumnTable,
   TSection
} from '../../../../../../components'
import { api } from '../../../../../../config'
import { useLocalization, useTypedSelector } from '../../../../../../hooks'
import { getMainProductSelector, productActions } from '../../../../../../store'
import { TProductVariation } from '../../../../../../store/product/types'
import { Image } from '../../../../styled'
import { ELocales } from 'enums'

export const EditCategoryProductVariationsIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const {
      product,
      loading: productLoading,
      variationProducts
   } = useTypedSelector(getMainProductSelector)
   const { variations } = useTypedSelector(getMainProductSelector)

   const { id } = useParams()

   const [locale] = useLocalization()

   const colors: Array<string> = []
   const parameters: Array<string> = []

   const [ids, setIds] = useState<string[]>([])

   const Utils = {
      getParameters: () => {
         const variationsData = variations?.data

         variationsData?.map((variation) =>
            variation.variations.map((item) => {
               if (Object.keys(item).length) {
                  if (item.variation.type === 'color') {
                     item.value.title.map((t) => {
                        if (t.lang === locale) {
                           colors.push(t.title)
                        }
                     })
                  } else {
                     item.value.title.map((t) => {
                        if (t.lang === locale) {
                           parameters.push(t.title)
                        }
                     })
                  }
               }
            })
         )
      }
   }

   const Events = {
      backButtonClickHandler: () => {
         navigate(`/catalog/edit/${product?._id}`)
      },
      saveButtonClickHandler: () => {},
      deleteProduct: () => {
         Requests.removeProduct()
      },
      removeClickHandler: () => {},
      checkboxClickHandler: () => {},
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         navigate(`/variation/${variations.data[index]._id}`)
         Utils.getParameters()
         dispatch(
            productActions.setVariationParameter({
               color: colors[index],
               parameter: parameters[index],
               _id: id as string
            })
         )
      }
   }

   const Sectinos: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/catalog/edit/${id as string}`)
            }
         },
         {
            title: t('characteristics'),
            onClickHandler: () => {
               navigate(`/catalog/characteristics/${id as string}`)
            }
         },
         {
            title: t('related.orders'),
            onClickHandler: () => {
               navigate(`/catalog/related/${id as string}`)
            }
         },
         {
            title: t('variations'),
            active: true
         }
      ],
      []
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('product.code'),
            accessor: 'id',
            width: 150
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 250
         },
         {
            Header: t('publish.date'),
            accessor: 'publish_date',
            width: 200
         },
         {
            Header: t(`color`),
            accessor: 'color',
            width: 200
         },
         {
            Header: t(`parameter`),
            accessor: 'parameter',
            width: 200
         },
         {
            Header: t('total'),
            accessor: 'total',
            width: 200
         },
         {
            Header: t('price'),
            accessor: 'price',
            width: 200
         },
         {
            Header: t('product.status'),
            accessor: 'sellStatus',
            width: 200
         }
      ],
      []
   )

   const Requests = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: id as string }))
      },
      getVariations: () => {
         dispatch(
            productActions.getVariations({
               field: 'group._id',
               value: product?._id as string
            })
         )
      },
      removeProduct: () => {
         dispatch(productActions.removeProduct({ _id: id as string }))
      },
      getVariationProducts: () => {
         const arr: string[] = []
         for (let i = 0; i < variations.data.length; i++) {
            arr.push(variations.data[i]._id)
         }
         dispatch(productActions.getVariationProducts({ ids: arr }))
      }
   }

   const data = useMemo(() => {
      Utils.getParameters()
      return variations.data.map((item, index) => {
         if (parameters.length && variationProducts[index]) {
            return {
               id: <>{item.barcode}</>,
               photo: <Image src={`${api.preview}${variationProducts[index].preview}`} />,
               label: <>{`${variationProducts[index].description.title}`}</>,
               publish_date: (
                  <Moment format="DD.MM.YYYY HH:mm">
                     {new Date(variationProducts[index].createdAt as string)}
                  </Moment>
               ),
               color: <>{`${colors[index] ? colors[index] : ''}`}</>,
               parameter: <>{`${parameters[index] ? parameters[index] : ''}`}</>,
               total: <>{item.amount}</>,
               price: <>{item.price}</>,
               sellStatus: <>{t(`${item.sellStatus}`)}</>
            }
         } else {
            return {
               id: <>{item.barcode}</>,
               photo: <Image src={`${item.preview}`} />,
               label: <>{`${product?.description.title}`}</>,
               publish_date: (
                  <Moment format="DD.MM.YYYY HH:mm">
                     {new Date(item.createdAt as string)}
                  </Moment>
               ),
               color: <>{`${colors[index] ? colors[index] : ''}`}</>,
               total: <>{item.amount}</>,
               price: <>{item.price}</>,
               sellStatus: <>{t(`${item.sellStatus}`)}</>
            }
         }
      })
   }, [variationProducts])

   useEffect(() => {
      Requests.getVariationProducts()
   }, [variations])

   useEffect(() => {
      Requests.getVariations()
      Requests.getProduct()
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
               buttonsList={
                  <>
                     {true && (
                        <Button theme="red" height={46} onClick={Events.deleteProduct}>
                           {t('delete.product')}
                        </Button>
                     )}
                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t('edit.products')}
            />
         }
         loading={productLoading}>
         <SubPagesBar sections={Sectinos} />
         <FlexContainer direction="column">
            <Table
               columns={columns}
               data={data}
               checkboxClickHandler={Events.checkboxClickHandler}
               editClickHandler={Events.editButtonClickHandler}
               editable
               removeable
            />
         </FlexContainer>
      </AdminPanelContainer>
   )
}
