import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { colors } from '../../enums'
import { Styles } from './styled'
import { TCheckbox } from './types'

const Checkbox: FC<TCheckbox> = ({
   checked,
   defaultChecked,
   onChange,
   size = '19px',
   className,
   name
}) => {
   const [isChecked, setIsChecked] = useState<boolean>(false)

   const Events = {
      onChange: (e: SyntheticEvent) => {
         setIsChecked(!isChecked)
         onChange && onChange(e)
      }
   }

   useEffect(() => {
      checked != null && setIsChecked(checked)
   }, [checked])

   return (
      <Styles size={size}>
         <label>
            <input
               name={name}
               className={className}
               type="checkbox"
               onChange={Events.onChange}
               checked={checked}
               defaultChecked={defaultChecked}
            />

            <svg
               className={`checkbox ${isChecked ? 'checkbox--active' : ''}`}
               aria-hidden="true"
               viewBox="0 0 15 11"
               fill="none">
               <path
                  d="M1 4.5L5 9L14 1"
                  strokeWidth="2"
                  stroke={isChecked ? colors.solid_white : 'none'}
               />
            </svg>
         </label>
      </Styles>
   )
}

export default Checkbox
