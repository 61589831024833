import { colors } from 'enums'
import styled from 'styled-components'

export const ModalTieWrap = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: #ffffff;
   border-radius: 16px;
   width: 934px;
   padding: 40px 30px;
   overflow: auto;
   display: flex;
   align-items: center;
   flex-direction: column;
   height: 98%;
   overflow: auto;
`
export const Title = styled.h2`
   margin: 0 auto 30px;
   display: flex;
`
export const ProductsWrap = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   border-bottom: 1px solid rgb(209, 209, 209);
   padding-bottom: 30px;
`
export const ProductTitleWrap = styled.div`
   margin: 30px 0 20px;
   h3 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
   }
   p {
      color: ${colors.grey};
   }
`
export const ProductList = styled.ul`
   list-style: none;
   display: flex;
   align-items: center;
   justify-content: space-between;
   li {
      display: flex;
      flex-direction: column;
      p {
         font-size: 14px;
         font-weight: 500;
         margin-bottom: 12px;
      }
   }
`
export const EpmtyBlock = styled.div`
   width: 278px;
   height: 152px;
   border: 1px solid rgb(244, 244, 244);
   border-radius: 6px;
   background: rgb(246, 250, 255);
   cursor: pointer;
   color: ${colors.blue};
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 500;
`
export const DeclineButton = styled.button`
   border: 1px solid rgb(129, 129, 129);
   border-radius: 8px;
   width: 100%;
   min-height: 45px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: transparent;
   cursor: pointer;
   margin-top: 30px;
`

export const FormulaWrap = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   padding-bottom: 30px;
   h4 {
      font-weight: 500;
   }
`
