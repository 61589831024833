import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TSection } from '../../../../components'

export const useSubPagesPagination = () => {
   const { t } = useTranslation()
   const navigate = useNavigate()

   const paginationSections: TSection[] = useMemo(
      () => [
         {
            title: t('metadata'),
            onClickHandler: () => {
               navigate('/seo')
            },
            active: window.location.hash == '#/seo'
         },
         {
            title: t('SEO.text'),
            onClickHandler: () => {
               navigate('/seo/text')
            },
            active: window.location.hash == '#/seo/text'
         }
      ],
      []
   )

   return { paginationSections }
}
