import styled, { css } from 'styled-components'
import { colors } from '../../enums'
import { FONT } from '../../utils'
import { TButton } from './types'

export const Container = styled.div`
   width: 100%;
   min-height: 57px;
   height: 57px;
   border-bottom: 1px solid ${colors.table_grey};
   position: relative;
`

const ActiveButtonCss = css`
   background-color: ${colors.blue};
   color: ${colors.solid_white};
`

export const Button = styled.button<TButton>`
   cursor: pointer;
   height: 100%;
   border: none;
   background-color: transparent;
   ${FONT({ size: '16px', weight: '500' })}
   padding: 0 30px;
   transition: all 0.1s ease-in-out;

   &:hover {
      ${ActiveButtonCss}
   }
   ${({ active }) => active && ActiveButtonCss}
`
export const NewReviewEditStyle = styled.p<{ revievPage?: boolean }>`
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background-color: ${({ revievPage }) =>
      !revievPage ? colors.red : colors.solid_white};
   color: ${({ revievPage }) => (!revievPage ? colors.solid_white : colors.blue)};

   position: absolute;
   left: 375px;
   top: 15px;
   display: flex;
   align-items: center;
   justify-content: center;
`
export const UnpaidStatusCount = styled.span<{ revievPage?: boolean }>`
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background-color: ${({ revievPage }) =>
      !revievPage ? colors.red : colors.solid_white};
   color: ${({ revievPage }) => (!revievPage ? colors.solid_white : colors.blue)};

   position: absolute;
   left: 150px;
   top: 15px;
   display: flex;
   align-items: center;
   justify-content: center;
`
export const PaidStatus = styled.span<{ revievPage?: boolean }>`
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background-color: ${({ revievPage }) =>
      !revievPage ? colors.red : colors.solid_white};
   color: ${({ revievPage }) => (!revievPage ? colors.solid_white : colors.blue)};

   position: absolute;
   left: 305px;
   top: 15px;
   display: flex;
   align-items: center;
   justify-content: center;
`
