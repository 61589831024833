import useSWR from 'swr'
import { TGetQuestionCategoriesParams, TQuestionsCatogoriesResponse } from './types'
import { QuestionCategoriesService } from './api.service'
import { i18n } from 'config'
import { useTypedSelector } from 'hooks'

export const useGetQuestionCategories = (params: TGetQuestionCategoriesParams) => {
   const { accessToken } = useTypedSelector((state) => state.user)
   const lang = i18n.language
   const allParams = { lang, ...params }
   const {
      data: questionsCategories,
      error,
      isLoading,
      mutate
   } = useSWR(
      ['getQuestionCategories', allParams],
      ([, par]) =>
         QuestionCategoriesService.getQuestionsCategories(par, accessToken || ''),
      { revalidateOnFocus: false, keepPreviousData: true }
   )
   return { questionsCategories, error, isLoading, mutate }
}

export const useGetQuestionCategory = (id?: string) => {
   const { accessToken } = useTypedSelector((state) => state.user)
   const lang = i18n.language
   const {
      data: questionCategory,
      error,
      isLoading,
      mutate
   } = useSWR(
      id ? ['getQuestionCategory', id] : null,
      ([, id]) =>
         QuestionCategoriesService.getQuestionCategory(id, lang, accessToken || ''),
      { revalidateOnFocus: false, keepPreviousData: true }
   )
   return { questionCategory, error, isLoading, mutate }
}
