import { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { BONUS_URL } from './config'
import {
   TBonusesRequestPayload,
   TChangeBonusRequestPayload,
   TDeleteBonusRequestPayload,
   TSaveBonusesRequestPayload
} from './types'

export class ApiBonusService extends HttpService {
   static getBonuses({ token }: TBonusesRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: BONUS_URL.getBonuses,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static save({ token, data }: TSaveBonusesRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: BONUS_URL.save,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static change({ token, data }: TChangeBonusRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: BONUS_URL.change + `/${data._id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static delete({ token, id }: TDeleteBonusRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: BONUS_URL.delete + `/${id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
