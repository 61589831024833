import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { BORDER, FLEX } from '../../utils'

export const Styles = styled.div`
   ${FLEX({})}
   align-self: bottom;

   .pagination {
      margin: 40px auto;
      display: flex;
      list-style: none;
      outline: none;
      user-select: none;
   }

   .pagination > .active > a {
      color: #fff;
      border: 1px solid ${colors.blue};
   }
   .pagination > li > a {
      border-radius: 6px;
      padding: 9px 13px;
      margin: 3px;
      outline: none;
      cursor: pointer;
      border: 1px solid ${colors.table_grey};
   }
   .pagination > .active > a,
   .pagination > .active > span,
   .pagination > .active > a:hover,
   .pagination > .active > span:hover,
   .pagination > .active > a:focus,
   .pagination > .active > span:focus {
      background-color: ${colors.blue};
      outline: none;
   }
   .pagination > li > a,
   .pagination > li > span {
      color: ${colors.blue};
   }
   .pagination > li:first-child > a,
   .pagination > li:first-child > span,
   .pagination > li:last-child > a,
   .pagination > li:last-child > span {
      border-radius: unset;
   }

   .pagination > li.previous > a,
   .pagination > li.next > a {
      border: none;
   }
`

export const ArrowLeftIcon = styled.img.attrs({
   src: assets.ARROW_LEFT
})``

export const ArrowRightIcon = styled.img.attrs({
   src: assets.ARROW_LEFT
})`
   transform: rotate(180deg);
`
