import { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { ROLE_URL } from './config'
import { TPatchRolePayload, TRequestPayload } from './types'

export class ApiRoleService extends HttpService {
   static getRoles({ token }: TRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ROLE_URL.getRoles}`,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static patchRole({
      token,
      ...payload
   }: TRequestPayload & TPatchRolePayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${ROLE_URL.patchRole}/${payload._id}`,
         method: 'PATCH',
         data: { ...payload },
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
