import useSWR from 'swr'
import { TGetCalculatorParams } from './types'
import { ApiCalculatorService } from './api.service'
import { useTypedSelector } from 'hooks'
import { i18n } from 'config'

export const useGetCalculators = (params: TGetCalculatorParams) => {
   const { accessToken } = useTypedSelector((state) => state.user)
   const lang = i18n.language
   const allParams = { lang, ...params }
   const {
      data: questions,
      isLoading,
      error,
      mutate
   } = useSWR(
      ['getCalculators', allParams],
      ([, par]) => ApiCalculatorService.getCalculatorQuestions(par, accessToken || ''),
      { revalidateOnFocus: false, keepPreviousData: true }
   )
   return { questions, isLoading, error, mutate }
}

export const useGetCalculator = (id?: string) => {
   const { accessToken } = useTypedSelector((state) => state.user)
   const lang = i18n.language
   const { data, isLoading, error, mutate } = useSWR(
      id ? ['getCalculatorById', id] : null,
      ([, id]) => ApiCalculatorService.getCalculatorQuestion(id, lang, accessToken || ''),
      { revalidateOnFocus: false, keepPreviousData: true }
   )
   return { data, isLoading, error, mutate }
}
