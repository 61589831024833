import styled from 'styled-components'
import { colors } from '../../../../../../enums'

export const Container = styled.div`
   display: grid;
   width: 100%;
   height: 100%;
   grid-template-columns: 3fr 1fr;
`

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 30px;
   padding: 24px 30px;
   border-right: 1px solid ${colors.table_grey};
`
export const PhotoBlock = styled.div`
   position: relative;
`
export const ImageClose = styled.img`
   position: absolute;
   right: -10px;
   top: -5px;
   cursor: pointer;
`
