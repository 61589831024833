import { Alert, Snackbar } from '@mui/material'
import { useTypedSelector } from 'hooks'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { assets } from '../../assets'
import { Button, Input } from '../../components'
import { getUserSelector, userActions } from '../../store'
import { TSchema } from '../../types'
import { validateSchema } from '../../utils'
import { ButtonWrapper, Container, FormWrapper, InputWrapper, Title } from './styled'

const LoginPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()

   const { error } = useTypedSelector(getUserSelector)

   const [form, setForm] = useState({
      login: '',
      password: ''
   })

   const [isAlertOpen, toogleAlertOpen] = useState(false)

   const schema = useMemo<TSchema>(
      () => ({
         login: {
            condition: form.login.length > 3
         },
         password: {
            condition: form.password.length > 3
         }
      }),
      [form]
   )

   const Events = {
      loginButtonHandler: () => {
         if (validateSchema(schema)) {
            dispatch(userActions.request(form))
         }
      },
      inputHandler: (e: React.SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      }
   }

   useEffect(() => {
      if (error) {
         if (error.length) {
            toogleAlertOpen(true)
         }
      }
   }, [error])

   return (
      <Container>
         <FormWrapper>
            <img src={assets.LOGO}></img>
            <Title>{t('login.form.title')}</Title>

            <form
               onSubmit={(e) => {
                  e.preventDefault()
               }}>
               <InputWrapper className="login">
                  <Input
                     name="login"
                     label={t('login')}
                     placeholder={t('placeholder.login')}
                     value={form.login}
                     onChange={Events.inputHandler}
                     isValid={schema.login.condition || !form.login}
                     required
                  />
               </InputWrapper>

               <InputWrapper className="password">
                  <Input
                     name="password"
                     type="password"
                     label={t('password')}
                     placeholder={t('placeholder.password')}
                     value={form.password}
                     onChange={Events.inputHandler}
                     isValid={schema.password.condition || !form.password}
                     required
                  />
               </InputWrapper>

               <ButtonWrapper>
                  <Button onClick={Events.loginButtonHandler}>{t('login.button')}</Button>
               </ButtonWrapper>
            </form>
         </FormWrapper>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => {
               toogleAlertOpen(false)
            }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="error" sx={{ width: '100%' }}>
               {t('login.error')}
            </Alert>
         </Snackbar>
      </Container>
   )
}

export default LoginPage
