import styled from 'styled-components'
import { colors } from '../../enums'
import { FONT } from '../../utils'
import { TStyledTextarea } from './types'

export const StyledTextarea = styled.textarea<TStyledTextarea>`
   width: 100%;
   height: ${({ height }) => height};

   border: 1px solid ${colors.border_grey_color};
   border-radius: 8px;
   resize: none;
   padding: 14px;

   ${FONT({ size: '16px' })}
   max-width:100%;
`
