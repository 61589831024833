import { useTranslation } from 'react-i18next'
import { FilterBarWrap, FilterItem } from './styled'
import { MdArrowDropDown } from 'react-icons/md'
import { useTypedSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { calculatorActions } from 'store/calculator'

export const FilterBar = () => {
   const { t } = useTranslation()
   const { sortBy, order } = useTypedSelector((state) => state.calculator)
   const dispatch = useDispatch()

   const onSortClick = (sorting: string) => {
      dispatch(calculatorActions.setSortBy(sorting))
      if (order === 1) {
         dispatch(calculatorActions.setOrder(-1))
      } else {
         dispatch(calculatorActions.setOrder(1))
      }
   }

   return (
      <FilterBarWrap>
         <FilterItem>{''}</FilterItem>
         <FilterItem>{t('questionsCategory')}</FilterItem>
         <FilterItem
            // onClick={() => onSortClick('type')}
            $active={sortBy === 'type'}
            $up={order === 1}>
            {t('mainQuestion')}
            {/* <MdArrowDropDown /> */}
         </FilterItem>
         <FilterItem
         // onClick={() => onSortClick('room')}
         // $active={sortBy === 'room'}
         // $up={order === 1}
         >
            {t('roomType')}
         </FilterItem>
         <FilterItem>{''}</FilterItem>
      </FilterBarWrap>
   )
}
