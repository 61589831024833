import { t } from 'i18next'
import { FC, useMemo, SyntheticEvent, useState, useEffect } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   SubPagesBar,
   Textarea,
   TSection
} from '../../../../../../../../../../components'
import { useLocalization, useTypedSelector } from '../../../../../../../../../../hooks'
import {
   getMainProductSelector,
   productActions
} from '../../../../../../../../../../store'
import { FormContainer } from '../../../Main/styled'
import { Text } from './styled'

export const EditStatisticVariationIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { id, pageNumber } = useParams()

   const { product, loading: productLoading } = useTypedSelector(getMainProductSelector)
   const { parameters } = useTypedSelector(getMainProductSelector)
   const [locale] = useLocalization()

   const productTypeOptions = [
      { name: t('single.product'), value: 'variated' },
      { name: t('variation.product'), value: 'simple' }
   ]
   const visiablityOptions = [
      { name: t('show'), value: '1' },
      { name: t('not.show'), value: '0' }
   ]
   const statusOptions = [
      { name: t('in.stock'), value: 'available' },
      { name: t('out.stock'), value: 'notavailable' }
   ]

   const [form, setForm] = useState({
      productType: productTypeOptions[0].value,
      show: true,
      barcode: '',
      name: '',
      description: '',
      section: '',
      category: '',
      price: '',
      amount: '',
      discountPrice: '',
      status: statusOptions[0].value,
      photos: [] as File[],
      metaKeyword: [''],
      metaDescription: ''
   })

   const Events = {
      backButtonClickHandler: () => {
         navigate(`/statisticVariations/page=${pageNumber}/`)
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      saveButtonClickHandler: () => {
         Request.editProduct()
      },
      deleteButtonClickHandler: () => {
         Request.removeProduct()
      }
   }

   const Request = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: id as string }))
      },
      editProduct: () => {
         dispatch(
            productActions.editProduct({
               _id: id as string,
               data: {
                  sku: form.barcode.length > 2 ? form.barcode : `${product?.barcode}`,
                  sellStatus: form.status.length > 2 ? form.status : product?.sellStatus,
                  discountPrice: form.discountPrice
                     ? Number(form.discountPrice)
                     : Number(product?.discountPrice),
                  price: form.price ? Number(form.price) : Number(product?.price),
                  show: form.show,
                  category: product?.category._id,
                  type: form.productType,
                  description: [
                     {
                        lang: locale,
                        metaDescription:
                           form.metaDescription?.length > 2
                              ? form.metaDescription
                              : product?.description.metaDescription,
                        metaKeywords: form.metaKeyword.length
                           ? form.metaKeyword
                           : product?.description.metaKeywords,
                        title:
                           form.name.length > 2 ? form.name : product?.description.title,
                        description:
                           form.description.length > 2
                              ? form.description
                              : product?.description.description,
                        attributes: product?.description.attributes
                     }
                  ],
                  amount: Number(form.amount)
               }
            })
         )
      },
      removeProduct: () => {
         dispatch(productActions.removeProduct({ _id: id as string }))
      }
   }

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/statistic/${parameters?._id}`)
            }
         },
         {
            title: t('characteristics'),
            onClickHandler: () => {
               navigate(`/statisticCharacteristics/${parameters?._id}`)
            }
         },
         {
            title: t('related.orders'),
            onClickHandler: () => {
               navigate(`/statisticRelated/${id as string}`)
            }
         },
         {
            title: t('variations'),
            active: true
         }
      ],
      []
   )

   useEffect(() => {
      product &&
         setForm({
            productType: product.type,
            show: product.show,
            barcode: '' + product.barcode,
            name: product.description?.title,
            description: product.description?.description,
            section: '',
            category: '',
            price: product.price + '',
            amount: product.amount + '',
            discountPrice: '' + product.discountPrice,
            status: statusOptions[0].value,
            photos: [],
            metaKeyword: product.description.metaKeywords,
            metaDescription: product.description.metaDescription
         })
   }, [product])

   useEffect(() => {
      Request.getProduct()
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               backButtonClickHandler={Events.backButtonClickHandler}
               title={t('edit')}
               buttonsList={
                  <>
                     <Button theme="red" onClick={Events.deleteButtonClickHandler}>
                        {t('delete.product')}
                     </Button>
                     <Button theme="green" onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }
         loading={productLoading}>
         <SubPagesBar sections={sections} />
         <FlexContainer>
            <FormContainer>
               <FlexContainer gap="62px" width="100%">
                  <DropDownList
                     name="visiablity"
                     label={t('visiablity')}
                     options={visiablityOptions}
                     value={
                        visiablityOptions.find(
                           (value) => !!parseInt(value.value) === form.show
                        )?.name
                     }
                     onChange={Events.inputChangeHandler}
                  />
                  <Input
                     name="barcode"
                     type="text"
                     label={t('product.code')}
                     placeholder={t('enter.code')}
                     value={form.barcode}
                     onChange={Events.inputChangeHandler}
                  />
                  <FlexContainer direction="column" gap="8px" width="100px">
                     <Text>Цвет</Text>
                     <p>{`${parameters?.color}`}</p>
                  </FlexContainer>
               </FlexContainer>
               <FlexContainer width="100%">
                  <Input
                     name="name"
                     width="100%"
                     label={t('name')}
                     placeholder={t('enter.name')}
                     value={form.name}
                     onChange={Events.inputChangeHandler}
                  />
               </FlexContainer>
               <FlexContainer>
                  <Textarea
                     name="description"
                     height="271px"
                     placeholder={t('enter.description')}
                     label={t('description')}
                     onChange={Events.inputChangeHandler}>
                     {form.description}
                  </Textarea>
               </FlexContainer>

               <FlexContainer gap="45px">
                  <FlexContainer gap="32px">
                     <Input
                        name="parameter"
                        type="text"
                        label={t('parameter')}
                        placeholder={t('enter.parameter')}
                        value={parameters?.parameter}
                        onChange={Events.inputChangeHandler}
                     />
                     <Input
                        name="amount"
                        type="number"
                        label={t('amount')}
                        placeholder={t('enter.amount')}
                        value={form.amount}
                        onChange={Events.inputChangeHandler}
                     />
                     <DropDownList
                        name="status"
                        label={t('status')}
                        options={statusOptions}
                        placeholder={t('enter.status')}
                        value={form.status}
                        onChange={Events.inputChangeHandler}
                     />
                  </FlexContainer>
                  <FlexContainer gap="62px">
                     <Input
                        name="price"
                        type="number"
                        label={t('price')}
                        placeholder={t('enter.price')}
                        value={form.price}
                        onChange={Events.inputChangeHandler}
                     />
                     <Input
                        name="discountPrice"
                        type="number"
                        label={t('discount.price')}
                        placeholder={t('enter.discount.price')}
                        value={form.discountPrice}
                        onChange={Events.inputChangeHandler}
                     />
                  </FlexContainer>
               </FlexContainer>

               <FlexContainer width="100%">
                  <Input
                     name="metaKeywords"
                     width="100%"
                     label={t('meta.keywords')}
                     value={product?.description.metaKeywords[0]}
                     onChange={Events.inputChangeHandler}
                  />
               </FlexContainer>
               <FlexContainer>
                  <Textarea
                     name="meataDescription"
                     height="271px"
                     label={t('description')}
                     onChange={Events.inputChangeHandler}>
                     {product?.description.metaDescription}
                  </Textarea>
               </FlexContainer>
            </FormContainer>
         </FlexContainer>
      </AdminPanelContainer>
   )
}
