import { useTranslation } from 'react-i18next'
import {
   DeclineButton,
   EpmtyBlock,
   ModalTieWrap,
   ProductList,
   ProductTitleWrap,
   ProductsWrap
} from './styled'
import { ModalTieProps, TProductType } from './types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalMaterial } from 'components'
import { Modalproducts } from '../ModalProducts/ModalProducts'
import { FormulaContainer, ProductCard } from './components'
import { toast } from 'react-toastify'
import { formatFormula, setDefaultElement } from './helpers'
import { useTypedSelector } from 'hooks'
import { useForm } from 'react-hook-form'
import { TCreateCalculatorForm, TFirstStage } from '../../types'
import { editProduct } from '../../helpers'
import _ from 'lodash'
import { categoryActions } from 'store'
import { ELocales } from 'enums'
import { useDispatch } from 'react-redux'

export const ModalTieUpProduct = ({
   products,
   initProducts,
   formula,
   order,
   editOrder,
   setFormula,
   setProducts,
   closeModal
}: ModalTieProps) => {
   const { t, i18n } = useTranslation()
   const lang = i18n.language
   const { accessToken } = useTypedSelector((state) => state.user)

   const { watch } = useForm<TCreateCalculatorForm>({
      defaultValues: {
         typeofrooms: {
            corridor: true,
            livingRoom: true,
            bathroom: true,
            bedroom: true,
            other: true,
            kitchen: true
         }
      }
   })

   const [openModalProduct, setOpenModalProduct] = useState(false)
   const [productType, setProductType] = useState<TProductType | null>(null)
   const [startElements, setStartElements] = useState(() => setDefaultElement(watch))
   const dispatch = useDispatch()

   const openModalproduct = (type: TProductType) => {
      setProductType(type)
      setOpenModalProduct(true)
   }
   const closeModalproduct = () => {
      setProductType(null)
      setOpenModalProduct(false)
   }

   const premiumProduct = useMemo(
      () => products.find((item) => item.type === 'premiumproduct'),
      [products]
   )
   const standardProduct = useMemo(
      () => products.find((item) => item.type === 'standartproduct'),
      [products]
   )
   const economProduct = useMemo(
      () => products.find((item) => item.type === 'economproduct'),
      [products]
   )

   const onCreateClick = useCallback(async () => {
      if (products.length < 3) {
         toast.info(t('fillInAllProducts'))
         return
      }
      if (
         formula
            .filter((item) => item.value === 'inputField')
            .some((el) => !el.inputValue || +el.inputValue <= 0)
      ) {
         toast.info(t('fillInTheBlankInput'))
         return
      }
      try {
         const firstStage: TFirstStage[] = products.map((product) => {
            const formattedExpression = formatFormula(formula)

            switch (product.type) {
               case 'economproduct':
                  return {
                     _id: product.product._id,
                     data: {
                        firstStageEconom: true,
                        firstStageStandart: false,
                        firstStagePremium: false,
                        firstStageEconomOrder: !_.isNull(editOrder) ? editOrder : order,
                        firstStageStandartOrder: !_.isNull(editOrder) ? editOrder : order,
                        firstStagePremiumOrder: !_.isNull(editOrder) ? editOrder : order,
                        expression: formattedExpression
                     }
                  }
               case 'standartproduct':
                  return {
                     _id: product.product._id,
                     data: {
                        firstStageEconom: false,
                        firstStageStandart: true,
                        firstStagePremium: false,
                        firstStageEconomOrder: !_.isNull(editOrder) ? editOrder : order,
                        firstStageStandartOrder: !_.isNull(editOrder) ? editOrder : order,
                        firstStagePremiumOrder: !_.isNull(editOrder) ? editOrder : order,
                        expression: formattedExpression
                     }
                  }
               case 'premiumproduct':
                  return {
                     _id: product.product._id,
                     data: {
                        firstStageEconom: false,
                        firstStageStandart: false,
                        firstStagePremium: true,
                        firstStageEconomOrder: !_.isNull(editOrder) ? editOrder : order,
                        firstStageStandartOrder: !_.isNull(editOrder) ? editOrder : order,
                        firstStagePremiumOrder: !_.isNull(editOrder) ? editOrder : order,
                        expression: formattedExpression
                     }
                  }
               default:
                  return {
                     _id: product.product._id,
                     data: {
                        firstStageEconom: false,
                        firstStageStandart: false,
                        firstStagePremium: false,
                        firstStageEconomOrder: null,
                        firstStageStandartOrder: null,
                        firstStagePremiumOrder: null,
                        expression: formattedExpression
                     }
                  }
            }
         })

         const firstStageCombined = firstStage.reduce((acc: TFirstStage[], current) => {
            const existingProduct = acc.find((product) => product._id === current._id)

            if (existingProduct) {
               existingProduct.data.firstStageEconom =
                  existingProduct.data.firstStageEconom || current.data.firstStageEconom

               existingProduct.data.firstStageStandart =
                  existingProduct.data.firstStageStandart ||
                  current.data.firstStageStandart

               existingProduct.data.firstStagePremium =
                  existingProduct.data.firstStagePremium || current.data.firstStagePremium

               existingProduct.data.firstStageEconomOrder =
                  existingProduct.data.firstStageEconomOrder ||
                  current.data.firstStageEconomOrder

               existingProduct.data.firstStageStandartOrder =
                  existingProduct.data.firstStageStandartOrder ||
                  current.data.firstStageStandartOrder

               existingProduct.data.firstStagePremiumOrder =
                  existingProduct.data.firstStagePremiumOrder ||
                  current.data.firstStagePremiumOrder
            } else {
               acc.push(current)
            }

            return acc
         }, [])

         const deleteOldProducts = initProducts.map((product) => ({
            _id: product?.product?._id,
            data: {
               firstStageEconom: false,
               firstStageStandart: false,
               firstStagePremium: false,
               firstStageEconomOrder: null,
               firstStageStandartOrder: null,
               firstStagePremiumOrder: null,
               expression: ''
            }
         }))

         deleteOldProducts.length > 0 &&
            (await editProduct({
               token: accessToken as string,
               payload: deleteOldProducts
            }))
         await editProduct({
            token: accessToken as string,
            payload: firstStageCombined
         })

         closeModal()
      } catch (error) {}
   }, [products, formula])

   useEffect(() => {
      dispatch(
         categoryActions.getCategories({
            limit: 400,
            page: 0,
            lang: lang as ELocales,
            hidden: 'false',
            sortBy: 'order',
            order: 1
         })
      )
   }, [])

   return (
      <ModalTieWrap>
         <ModalMaterial close={closeModalproduct} isOpen={openModalProduct}>
            <Modalproducts
               productType={productType}
               setProducts={setProducts}
               close={closeModalproduct}
               products={products}
            />
         </ModalMaterial>
         <h2>{t('tieProducts')}</h2>
         <ProductsWrap>
            <ProductTitleWrap>
               <h3>{t('products')}</h3>
               <p>{t('selectProductsCalculateUsingTheFormula')}</p>
            </ProductTitleWrap>
            <ProductList>
               <li>
                  <p>{t('economy')}</p>
                  {!economProduct ? (
                     <EpmtyBlock onClick={() => openModalproduct('economproduct')}>
                        {t('add.product')}
                     </EpmtyBlock>
                  ) : (
                     <ProductCard
                        setProducts={setProducts}
                        type="economproduct"
                        product={
                           products.find((item) => item.type === 'economproduct')?.product
                        }
                     />
                  )}
               </li>
               <li>
                  <p>{t('standard')}</p>
                  {!standardProduct ? (
                     <EpmtyBlock onClick={() => openModalproduct('standartproduct')}>
                        {t('add.product')}
                     </EpmtyBlock>
                  ) : (
                     <ProductCard
                        product={
                           products.find((item) => item.type === 'standartproduct')
                              ?.product
                        }
                        setProducts={setProducts}
                        type="standartproduct"
                     />
                  )}
               </li>
               <li>
                  <p>{t('premium')}</p>
                  {!premiumProduct ? (
                     <EpmtyBlock onClick={() => openModalproduct('premiumproduct')}>
                        {t('add.product')}
                     </EpmtyBlock>
                  ) : (
                     <ProductCard
                        setProducts={setProducts}
                        type="premiumproduct"
                        product={
                           products.find((item) => item.type === 'premiumproduct')
                              ?.product
                        }
                     />
                  )}
               </li>
            </ProductList>
         </ProductsWrap>

         <FormulaContainer
            startElements={startElements}
            setStartElements={setStartElements}
            onCreateClick={onCreateClick}
            setFormula={setFormula}
            formula={formula}
         />
         <DeclineButton onClick={closeModal}>{t('cancel')}</DeclineButton>
      </ModalTieWrap>
   )
}
