import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TInitialState, TSpecialistResponse } from './types'

const initialState: TInitialState = {
   specialists: { data: [], meta: null },
   specialistsReviews: { data: [], meta: null },
   specialist: null,
   specialistReview: { data: [], declinedRowId: '', meta: null },
   filters: {
      start: '',
      end: '',
      regex: ''
   },
   loading: false,
   response: null,
   meta: null,
   newReviewsCount: 0
}

export const slice = createSlice({
   name: 'specialist',
   initialState,
   reducers: {
      SET_STATE: (state, { payload }) => {
         state.loading = payload.loading
         state.response = payload.response
         state.meta = payload.meta
      },
      SET_SPECIALISTS: (state, { payload }) => {
         state.specialists = payload
      },
      SET_SPECIALIST: (state, { payload }) => {
         state.specialist = payload
      },
      SET_SPECIALISTS_REVIEWS: (state, { payload }) => {
         state.specialistsReviews = payload
      },
      SET_SPECIALIST_REVIEW: (state, { payload }) => {
         state.specialistReview = payload
      },
      SET_DECLINED_ROW_ID: (state, { payload }) => {
         state.specialistReview.declinedRowId = payload
      },
      SET_FILTERS: (state, { payload }) => {
         state.filters = payload
      },
      SET_NEW_REVIEWS: (state, { payload }: PayloadAction<number>) => {
         state.newReviewsCount = payload
      }
   }
})

export const sliceActions = slice.actions

export default slice.reducer
