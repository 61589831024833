import { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TGetAdminRequestPayload } from '../admin/types'
import { TResponse } from '../types'
import { CLIENT_URL } from './config'
import {
   TCreateCustomerDiscountBulkRequestPayload,
   TCreateCustomerDiscountRequestPayload,
   TDeleteCustomerDiscountBulkRequestPayload,
   TEditClientRequestPayload,
   TGetBusinessClientsRequestPayload,
   TGetBusinessCustomerRequestPayloadRequest,
   TGetClientRequestPayload,
   TGetClientsRequestPayload,
   TGetCustomerDiscountByIdRequestPayload,
   TUpdateCustomerDiscountBulkRequestPayload
} from './types'

export class ApiClientService extends HttpService {
   static getClients({
      token,
      limit = 0,
      page = 0,
      lang = ELocales.ru,
      dateStart = '',
      dateEnd = '',
      searchValue = '',
      sortBy = '',
      order = ''
   }: TGetClientsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClients}?limit=${limit}&skip=${
            page * limit
         }&lang=${lang}&regex=${searchValue}&start=${dateStart}&end=${dateEnd}&sortBy=${sortBy}&order=${order}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getBusinessClients({
      token,
      limit = 0,
      page = 0,
      lang = ELocales.ru,
      ...params
   }: TGetBusinessClientsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClients}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            skip: page * limit,
            lang,
            ...params
         }
      })

      return response
   }

   static getClient({
      token,
      _id
   }: TGetClientRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClient}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getClientAddresses({
      token,
      _id
   }: TGetAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClientAddress}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static editClient({
      token,
      _id,
      data
   }: TEditClientRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.editClient}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static createCustomerDiscount({
      token,
      data
   }: TCreateCustomerDiscountRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.createCustomerDiscount}`,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static createCustomerDiscountBulk({
      token,
      createdData
   }: TCreateCustomerDiscountBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.createCustomerDiscountBulk}`,
         method: 'POST',
         data: createdData,
         headers: {
            Authorization: token
         }
      })
   }

   static getCustomerDiscountById({
      token,
      _id,
      lang,
      page,
      limit,
      type
   }: TGetCustomerDiscountByIdRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.getCustomerDiscountById(_id)}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            customer: _id,
            lang: lang,
            skip: page * limit,
            type,
            limit
         }
      })
   }

   static updateCustomerDiscountBulk({
      token,
      updatedData
   }: TUpdateCustomerDiscountBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.updateCustomerDiscountBulk}`,
         method: 'PATCH',
         data: updatedData,
         headers: {
            Authorization: token
         }
      })
   }

   static deleteCustomerDiscountBulk({
      token,
      _id
   }: TDeleteCustomerDiscountBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.deleteCustomerDiscountBulk}`,
         method: 'DELETE',
         data: { _id: _id },
         headers: {
            Authorization: token
         }
      })
   }

   static getBusinessCustomerRequest({
      token,
      limit,
      page,
      lang
   }: TGetBusinessCustomerRequestPayloadRequest): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.getBusinessCustomerRequest}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            skip: page * limit,
            limit,
            lang
         }
      })
   }
}
