import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   PlusIcon,
   ProfileImage,
   SearchBar,
   SubPagesBar,
   Table,
   TColumnTable,
   TDataTable,
   TSection,
   TSort
} from 'components'
import { useTypedSelector } from 'hooks'
import { t } from 'i18next'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clientActions, getMainClientSelector } from 'store'
import { ITEM_PER_PAGE } from './consts'
import { api } from 'config'

export const BusinessClients: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { filters, businessClients, loading } = useTypedSelector(getMainClientSelector)

   const [searchForm, setSearchForm] = useState({
      regex: filters.searchValue ? filters.searchValue : '',
      start: filters.dateStart ? filters.dateStart : '',
      end: filters.dateEnd ? filters.dateEnd : ''
   })

   const [page, setPage] = useState<number>(0)

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const Events = {
      createButtonClickHandler: () => {},
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(clientActions.setFilters({ ...searchForm }))
         navigate(`/businessClients/${businessClients.data[index]._id}`)
      },
      resetFilters: () => {
         dispatch(
            clientActions.setFilters({ searchValue: '', dateStart: '', dateEnd: '' })
         )
         window.location.reload()
      }
   }

   const Requests = {
      getBusinessClients: () => {
         dispatch(
            clientActions.getBusinessClients({
               limit: ITEM_PER_PAGE,
               ...searchForm,
               ...sortParamsForm,
               page,
               business: true
            })
         )
      }
   }

   const data: TDataTable[] = useMemo(
      () =>
         businessClients.data.map((client) => {
            const register_date = new Date(client.createdAt)
            return {
               id: client.id,
               photo: (
                  <ProfileImage
                     src={`${
                        client.picture?.includes('http')
                           ? client.picture
                           : `${api.static}/customer/${client.picture}`
                     }`}
                  />
               ),
               name: client.name,
               contacts: client.email,
               phone: client.phone,
               createdAt: <Moment format="DD.MM.YYYY HH:mm">{register_date}</Moment>,
               status: t('b2bClient'),
               orders: client.orders
            }
         }),
      [businessClients]
   )

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('b2cClients'),
            onClickHandler: () => {
               navigate('/clients')
            }
         },
         {
            title: t('b2bClients'),
            active: true
         },
         {
            title: t('b2bRequest'),
            onClickHandler: () => {
               navigate('/businessCustomerRequest')
            }
         }
      ],
      []
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('clients.id'),
            accessor: 'id', // accessor is the "key" in the data
            width: 300
         },
         {
            Header: t('photo'),
            accessor: 'photo',
            width: 50
         },
         {
            Header: t('client'),
            accessor: 'name',
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('contacts'),
            accessor: 'contacts',
            width: 200
         },
         {
            Header: t('registration.date'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('client.status'),
            accessor: 'status',
            width: 300
         },
         {
            Header: t('count.orders'),
            accessor: 'orders'
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getBusinessClients()
   }, [page, tableSortBy, searchForm])

   return (
      <AdminPanelContainer
         Header={<Header title={t('manage.clients')} />}
         loading={loading}>
         <SubPagesBar sections={sections} />
         <FlexContainer gap="30px" direction="column">
            <HeaderWrapper>
               <FiltersWrapper>
                  <SearchBar
                     name="regex"
                     placeholder={t('search')}
                     value={searchForm.regex}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />

                  <DateInput
                     name="start"
                     value={searchForm.start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="end"
                     value={searchForm.end}
                     onChange={Events.onChangeHandler}
                  />
                  <div>
                     <Button style="transparant" onClick={Events.resetFilters}>
                        {t('reset.filters')}
                     </Button>
                  </div>
               </FiltersWrapper>

               <DataTotal>
                  {t('total.clients')}: {businessClients.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               columns={columns}
               data={data}
               sortBy={tableSortBy}
               editable
               editClickHandler={Events.editButtonClickHandler}
            />
         </FlexContainer>
      </AdminPanelContainer>
   )
}
