import { colors } from 'enums'
import { FC } from 'react'
import { FlexContainer, Label } from '../Styled'
import { StyledTextarea } from './styled'
import { TTextarea } from './types'

const Textarea: FC<TTextarea> = ({
   children,
   label,
   readonly,
   height = '192px',
   width = '100%',
   placeholder = '',
   onChange,
   name = '',
   margin,
   changedProduct,
   disabled
}) => {
   return (
      <>
         <FlexContainer direction="column" width={width}>
            <Label>{label}</Label>
            <StyledTextarea
               name={name}
               readOnly={readonly}
               value={children as string}
               height={height}
               width={width}
               placeholder={placeholder}
               onChange={onChange}
               style={{
                  marginBottom: margin ? margin : '',
                  backgroundColor: changedProduct ? '#EEF4FA' : ''
               }}
               disabled={disabled}
            />
         </FlexContainer>
      </>
   )
}

export default Textarea
