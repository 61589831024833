export const GET_PRODUCTS = 'GET_PRODUCTS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_PRODUCT_STATE = 'SET_PRODUCT_STATE'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_VARIATIONS = 'GET_VARIATIONS'
export const SET_VARIATIONS = 'SET_VARIATIONS'
export const SET_VARIATION_PARAMETERS = 'SET_VARIATION_PARAMETERS'
export const CREATE_PHOTO_PRODUCT = 'CREATE_PHOTO_PRODUCT'
export const SET_PHOTO_PRODUCT = 'SET_PHOTO_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const SET_SIMILAR_PRODUCTS = 'SET_SIMILAR_PRODUCTS'
export const GET_SIMILAR_PRODUCTS = 'GET_SIMILAR_PRODUCTS'
export const UPDATE_SIMILAR_PRODUCTS = 'UPDATE_SIMILAR_PRODUCTS'
export const CREATE_SIMILAR_PRODUCTS = 'CREATE_SIMILAR_PRODUCTS'
export const GET_SECTIONS = 'GET_SECTIONS'
export const SET_SECTIONS = 'SET_SECTIONS'
export const GET_CATEGORY_BY_SECTION = 'GET_CATEGORY_BY_SECTION'
export const SET_CATEGORY_BY_SECTION = 'SET_CATEGORY_BY_SECTION'
export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES'
export const SET_SUBCATEGORIES = 'SET_SUBCATEGORIES'
export const SET_PROMOTION_PRODUCT_ID = 'SET_PROMOTION_PRODUCT_ID'
export const SET_PROMOTION_PRODUCTS = 'SET_PROMOTION_PRODUCTS'
export const EDIT_PROMOTION_PRODUCTS = 'EDIT_PROMOTION_PRODUCTS'
export const SET_SECTION = 'SET_SECTION'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_SUBCATEGORY = 'SET_SUBCATEGORY'
export const GET_VARIATION_PRODUCTS = 'GET_VARIATION_PRODUCTS'
export const SET_VARIATION_PRODUCTS = 'SET_VARIATION_PRODUCTS'
export const UPDATE_VISABILITY = 'UPDATE_VISABILITY'
export const REMOVE_ALL_PRODUCTS = 'REMOVE_ALL'
export const GET_PRODUCTSV1 = 'GET_PRODUCTSV1'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_DISCOUNT_PRODUCT_ID = 'SET_DISCOUNT_PRODUCT_ID'
export const GET_STAT_FILE_NAME = 'GET_STAT_FILE_NAME'
export const SET_STAT_FILE_NAME = 'SET_STAT_FILE_NAME'

export const GET_VARIATIONS_BY_CATEGORY = 'GET_VARIATIONS_BY_CATEGORY'
export const SET_VARIATIONS_BY_CATEGORY = 'SET_VARIATIONS_BY_CATEGORY'

export enum RESPONSE {
   EDITED_VARIATIONS_VALUE = 'EDITED_VARIATIONS_VALUE',
   EDITED_VARIATIONS = 'EDITED_VARIATIONS',
   EDITED_GROUP = 'EDITED_GROUP',
   CREATED_GROUP = 'CREATED_GROUP',
   CREATED = 'CREATED',
   EDITED = 'EDITED',
   EDIT_ERROR = 'EDIT_ERROR',
   NOT_FOUNDED = 'NOT_FOUNDED',
   REMOVED = 'REMOVED',
   REMOVED_ALL = 'REMOVED_ALL',
   NOT_REMOVED = 'NOT_REMOVED',
   CREATED_VARIATION_BULK = 'CREATED_VARIATION_BULK',
   CREATED_PHOTO = 'CREATED_PHOTO',
   PRODUCT_GOT = 'PRODUCT_GOT',
   DONE = 'DONE',
   GET_SECTIONS = 'GET_SECTIONS',
   UPDATED_VISABILITY = 'UPDATED_VISABILITY'
}
