import { useTranslation } from 'react-i18next'
import {
   ButtonWrap,
   DragWrap,
   FormulaElement,
   FormulaElementList,
   FormulaWrap,
   ProductTitleWrap,
   SinsWrap
} from './styled'
import { FormulaContainerProps, TItemDragTypes } from '../../types'
import { DragEvent, memo, useEffect } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { TbDragDrop } from 'react-icons/tb'
import { filterDuplicates, signs, validateBlocksSpacing } from '../../helpers'

export const FormulaContainer = memo(
   ({
      formula,
      setFormula,
      onCreateClick,
      startElements,
      setStartElements
   }: FormulaContainerProps) => {
      const { t } = useTranslation()

      const onDragStart = (e: DragEvent<HTMLLIElement>, item: TItemDragTypes) => {
         e.dataTransfer.setData('element', JSON.stringify(item))
      }

      const onDrop = (e: DragEvent<HTMLUListElement>) => {
         const item: TItemDragTypes = JSON.parse(e.dataTransfer.getData('element'))
         if (item.type !== 'signs') {
            setStartElements((prev) => prev.filter((el) => el.id !== item.id))
         }
         setFormula((prev) => [...prev, item])
      }

      const onClear = () => {
         const nonSignElements = formula.filter((item) => item.type !== 'signs')
         setStartElements((prev) => [...prev, ...nonSignElements])
         setFormula([])
      }
      const onDragOver = (e: DragEvent<HTMLUListElement>) => {
         e.preventDefault()
      }

      useEffect(() => {
         setStartElements(filterDuplicates(formula, startElements))
      }, [formula])

      return (
         <FormulaWrap>
            <ProductTitleWrap>
               <h3>{t('calculationByFormula')}</h3>
               <p>{t('dragCompositeFormulasAndSigns')}</p>
            </ProductTitleWrap>
            <h4>{t('compositeForFormula')}</h4>
            <FormulaElementList>
               {startElements.map((item) => (
                  <li
                     draggable={validateBlocksSpacing(formula)}
                     onDragStart={(e) => onDragStart(e, item)}
                     key={nanoid(5)}>
                     {item.label}
                  </li>
               ))}
            </FormulaElementList>
            <h4>{t('signs')}</h4>
            <SinsWrap>
               {signs.map((item) => (
                  <li draggable key={nanoid()} onDragStart={(e) => onDragStart(e, item)}>
                     {item.label}
                  </li>
               ))}
            </SinsWrap>
            <h4>{t('placeForCalculation')}</h4>
            <DragWrap>
               <ul onDrop={onDrop} onDragOver={onDragOver}>
                  {!formula.length && <TbDragDrop />}
                  {formula.map((item, index) => (
                     <FormulaElement key={nanoid()}>
                        {item.value === 'inputField' ? (
                           <input
                              placeholder={t('enterNmberGreater0')}
                              type="number"
                              step={0.1}
                              min={0}
                              autoFocus
                              value={item.inputValue}
                              onChange={(e) => {
                                 const updatedFormula = [...formula]
                                 updatedFormula[index] = {
                                    ...item,
                                    inputValue: e.target.value
                                 }
                                 setFormula(updatedFormula)
                              }}
                           />
                        ) : (
                           item.label
                        )}
                     </FormulaElement>
                  ))}
               </ul>
               <ButtonWrap>
                  <button
                     onClick={onCreateClick}
                     className="add"
                     disabled={!formula.length}
                     type="button">
                     {t('calculate')}
                  </button>
                  {formula.length ? (
                     <button
                        onClick={onClear}
                        className="clear"
                        disabled={!formula.length}
                        type="button">
                        {t('clear')}
                     </button>
                  ) : null}
               </ButtonWrap>
            </DragWrap>
         </FormulaWrap>
      )
   }
)
