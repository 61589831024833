import { createReducer } from '@reduxjs/toolkit'
import { categoryActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
   categories: {
      meta: null,
      data: []
   },
   category: null,
   products: {
      data: [],
      meta: null
   },
   ids: [],
   loading: false,
   response: null
}

const reducer = createReducer<TInitialState>(InitialState, (builder) => {
   builder
      .addCase(categoryActions.setCategories, (state, { payload }) => ({
         ...state,
         categories: payload
      }))
      .addCase(categoryActions.setCategoriesState, (state, { payload }) => ({
         ...state,
         response: null,
         ...payload
      }))
      .addCase(categoryActions.setCategory, (state, { payload }) => ({
         ...state,
         category: payload
      }))
      .addCase(categoryActions.setProductsByCategory, (state, { payload }) => ({
         ...state,
         products: payload
      }))
      .addCase(categoryActions.setIds, (state, { payload }) => ({
         ...state,
         ids: payload
      }))
})

export default reducer
