import { call, put, takeLatest } from 'redux-saga/effects'
import { i18n } from '../../config'
import { ELocales } from '../../enums'
import { TDataWrapper, TResponse } from '../types'
import { brandsActions } from './actions'
import { ApiBrandService } from './api.service'
import { TGetBrandsPayload } from './types'

function* getBrandsWorker({ payload }: TDataWrapper<TGetBrandsPayload>) {
   try {
      const response: TResponse = yield call(
         [ApiBrandService, ApiBrandService.getBrands],
         {
            ...payload,
            lang: i18n.language as ELocales
         }
      )
      if (response.data)
         yield put(
            brandsActions.setBrands({
               items: response.data.data,
               meta: response.data.meta
            })
         )
   } catch (e) {
      yield put(brandsActions.setBrands({ error: true }))
   }
}

export function* brandWatcher() {
   yield takeLatest(brandsActions.getBrands, getBrandsWorker)
}
