import { TCategory } from 'store/product/types'

type useCategoryHookProps = {
   category: TCategory
}

class Category {
   private categoryList: TCategory[]
   private basicCategory: TCategory

   constructor(basicCategory: TCategory) {
      this.categoryList = []
      this.basicCategory = basicCategory
   }

   private extractAllCategoriesFromBasicCategory(category: TCategory) {
      if (category?._id) {
         this.categoryList.push(category)
      }

      if (category?.parent == null) {
         return
      }

      if (!Object.keys(category?.parent).length) {
         return
      }

      this.extractAllCategoriesFromBasicCategory(category?.parent as TCategory)
   }

   get() {
      this.extractAllCategoriesFromBasicCategory(this.basicCategory)

      return this.categoryList
   }
}

export const useCategoryHook = ({ category }: useCategoryHookProps) => {
   return { categoryList: new Category(category).get() }
}
