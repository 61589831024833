import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { authMiddleware } from './middlewares'
import { userActionsList } from './user'
import { ConnectionActionsList } from './connection'

const persistedReducer = persistReducer(
   {
      key: 'root',
      storage,
      whitelist: ['user', 'product'],
      debug: true
   },
   rootReducer
)

const authBlackList = [userActionsList, ConnectionActionsList]
const authWhiteList = [{}]

const sagaMiddleware = createSagaMiddleware()

const middlewares = [
   // authMiddleware({ blackList: authBlackList, whiteList: authWhiteList }),
   sagaMiddleware
]
const store = configureStore({
   reducer: persistedReducer,
   middleware: middlewares,
   devTools: true
})

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store
