import { ProductImage } from 'pages/Products/pages'
import { Dispatch, SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { productActions } from 'store'
import { TProduct } from 'store/product/types'

type useFormInputHookProps = {
   form: TProduct & { sectionId?: string; categoryId?: string; subcategoryId?: string }
   editedData: TProduct
   setForm: Dispatch<
      TProduct & { sectionId?: string; categoryId?: string; subcategoryId?: string }
   >
   setEditedData: Dispatch<TProduct>
   productImages?: ProductImage[]
   setProductImages?: Dispatch<ProductImage[]>
   route?: string
}

export const useFormInputHook = ({
   form,
   setForm,
   editedData,
   setEditedData,
   route,
   productImages,
   setProductImages
}: useFormInputHookProps) => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const backButtonClickHandler = () => {
      if (route) navigate(route)
   }

   const inputChangeHandler = (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement

      // if (Number.isNaN(+value)) {
      setForm({
         ...form,
         [name]: value
      })
      setEditedData({
         ...editedData,
         [name]: value
      })
      return
      // }

      // setForm({
      //    ...form,
      //    [name]: +value
      // })
      // setEditedData({
      //    ...editedData,
      //    [name]: +value
      // })
   }

   const descriptionInputHandler = (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement

      setForm({
         ...form,
         description: {
            ...form?.description,
            lang: form?.description?.lang,
            [name]: value
         }
      })
      setEditedData({
         ...editedData,
         description: {
            ...editedData?.description,
            lang: form?.description?.lang,
            [name]: value
         }
      })
   }

   const visibilityChangehandler = (e: SyntheticEvent) => {
      const { value } = e.target as HTMLInputElement

      if (Number.isNaN(+value)) {
         return
      }
      setForm({
         ...form,
         show: Number(value) ? true : false
      })
      setEditedData({
         ...editedData,
         show: Number(value) ? true : false
      })
   }

   const onKeywordsChangeHandler = (metakeywords: string[]) => {
      if (metakeywords?.length) {
         setForm({
            ...form,
            description: { ...form?.description, metaKeywords: metakeywords }
         })
         setEditedData({
            ...editedData,
            description: { ...editedData?.description, metaKeywords: metakeywords }
         })
      }
   }

   const onUploadPhoto = (e: SyntheticEvent) => {
      const file = (e.target as HTMLInputElement).files

      if (file && productImages) {
         setProductImages &&
            setProductImages([
               ...productImages,
               {
                  order: productImages.length + 1,
                  image: URL.createObjectURL(file[0]),
                  preview: URL.createObjectURL(file[0])
               }
            ])

         const data = new FormData()

         data.append('gallery', file[0])

         dispatch(productActions.createPhotoProduct({ data }))

         return
      }

      return
   }

   const onDeletePhoto = (order: number) => {
      setProductImages &&
         productImages &&
         setProductImages(
            productImages.filter((photo: ProductImage) => {
               return photo.order !== order
            })
         )
   }

   const sectionChangeHandler = (e: SyntheticEvent) => {
      const { value } = e.target as HTMLInputElement

      setForm({ ...form, sectionId: value })
   }

   const categoryChangeHandler = (e: SyntheticEvent) => {
      const { value } = e.target as HTMLInputElement

      setForm({ ...form, categoryId: value })
   }

   const subcategoryChangeHandler = (e: SyntheticEvent) => {
      const { value } = e.target as HTMLInputElement

      setForm({ ...form, subcategoryId: value })
   }

   return {
      backButtonClickHandler,
      inputChangeHandler,
      descriptionInputHandler,
      visibilityChangehandler,
      onKeywordsChangeHandler,
      onUploadPhoto,
      onDeletePhoto,
      sectionChangeHandler,
      categoryChangeHandler,
      subcategoryChangeHandler
   }
}
