import { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { ADMINS_URL } from './config'
import {
   TGetAdminsRequestPayload,
   TCreateAdminRequestPayload,
   TRemoveAdminRequestPayload,
   TGetAdminRequestPayload,
   TEditAdminRequestPayload
} from './types'

export class ApiAdminService extends HttpService {
   static getAdmins({
      limit = 0,
      page = 0,
      token,
      dateStart,
      dateEnd,
      searchValue
   }: TGetAdminsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request<TResponse>({
         url:
            ADMINS_URL.getAdmins +
            `?limit=${limit}&skip=${page * limit}&value=${searchValue}&start=${dateStart}&end=${dateEnd}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static createAdmin({ data, token }: TCreateAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request<TResponse>({
         url: ADMINS_URL.default,
         method: 'POST',
         data: data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static removeAdmin({ _id, token }: TRemoveAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request<TResponse>({
         url: `${ADMINS_URL.removeAdmin}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getAdmin({ _id, token }: TGetAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request<TResponse>({
         url: `${ADMINS_URL.getAdmin}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })
      return response
   }

   static editAdmin({ data, token, _id }: TEditAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request<TResponse>({
         url: `${ADMINS_URL.editAdmin}/${_id}`,
         method: 'PATCH',
         data: data,
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
