import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   Header,
   Input,
   ItemsPerRowGridContainer,
   PhotoPicker
} from '../../../../components'
import { useTypedSelector } from '../../../../hooks'
import {
   ADMIN_RESPONSE,
   getMainAdminSelector,
   getMainRoleSelector,
   getRolesSelector,
   roleActions
} from '../../../../store'
import { adminActions } from '../../../../store/admin/action'
import { TForm } from './types'

const CreatePage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { roles, loading: roleLoading } = useTypedSelector(getMainRoleSelector)
   const {
      admin,
      loading: adminLoading,
      response
   } = useTypedSelector(getMainAdminSelector)

   const rolesOptions = useMemo(
      () => roles.data.map((role) => ({ name: role.title, value: role._id })),
      [roles]
   )

   const [form, setForm] = useState<TForm>({
      name: '',
      surname: '',
      login: '',
      password: '',
      position: '',
      profile_photo: null
   })

   const Schema = {
      login: {
         condition: form.login.length >= 4 || form.login.length == 0
      },
      password: {
         condition: form.password.length >= 4 || form.password.length == 0
      },
      name: {
         condition: form.name.length >= 2 || form.name.length == 0
      },
      surname: {
         condition: form.surname.length >= 2 || form.surname.length == 0
      }
   }

   const Events = {
      backButtonClickHandler: () => {
         navigate('../roles')
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      onProfilePhotoChange: (file: File | null) => {
         setForm({ ...form, profile_photo: file })
      },
      createAdminHandler: () => {
         const data = new FormData()
         data.append('name', form.name)
         data.append('secondName', form.surname)

         data.append('login', form.login)
         data.append('password', form.password)
         data.append('role', form.position || rolesOptions[0].value)
         data.append('img', form.profile_photo as File)

         Utils.isSchemaValid() && dispatch(adminActions.createAdmin({ data }))
      }
   }

   const Requests = {
      getRoles: () => {
         dispatch(roleActions.getRoles())
      }
   }

   const Utils = {
      isSchemaValid: () => {
         let isValid = true
         Object.keys(Schema).forEach((prop) => {
            if (!Schema[prop as keyof typeof Schema].condition) isValid = false
         })

         return isValid
      }
   }

   // Get response from admin response saga
   useEffect(() => {
      if (response == ADMIN_RESPONSE.CREATED) {
         navigate('../roles')
      }
   }, [response])

   useEffect(() => {
      Requests.getRoles()
   }, [])

   return (
      <AdminPanelContainer
         loading={roleLoading || adminLoading}
         Header={
            <Header
               title={t('create')}
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.createAdminHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               backButtonClickHandler={Events.backButtonClickHandler}
            />
         }>
         <ItemsPerRowGridContainer
            perRow={2}
            width="700px"
            columnGap="62px"
            rowGap="30px">
            <Input
               name="name"
               label={t('name')}
               placeholder={t('enter.name')}
               onChange={Events.inputChangeHandler}
               value={form.name}
               isValid={Schema.name.condition}
            />
            <Input
               name="surname"
               label={t('surname')}
               placeholder={t('enter.surname')}
               onChange={Events.inputChangeHandler}
               value={form.surname}
               isValid={Schema.surname.condition}
            />
            <Input
               name="login"
               label={t('login')}
               placeholder={t('enter.login.limit')}
               onChange={Events.inputChangeHandler}
               value={form.login}
               isValid={Schema.login.condition}
            />
            <Input
               name="password"
               label={t('password')}
               placeholder={t('enter.password.limit')}
               onChange={Events.inputChangeHandler}
               value={form.password}
               isValid={Schema.password.condition}
            />
            <DropDownList
               name="position"
               label={t('position')}
               placeholder={t('choose.role')}
               options={rolesOptions}
               onChange={Events.inputChangeHandler}
               value={form.position}
            />
            <PhotoPicker
               label={t('profile.photo')}
               onChange={Events.onProfilePhotoChange}
            />
         </ItemsPerRowGridContainer>
      </AdminPanelContainer>
   )
}

export default CreatePage
