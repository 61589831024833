import { api } from 'config'

export const CLIENT_URL = {
   getClients: `${api.public}v1/customer`,
   getClient: `${api.public}v1/customer`,
   getClientAddress: `${api.public}v1/customerAddress`,
   editClient: `${api.public}v1/customer`,
   createCustomerDiscount: `${api.public}v1/customerDiscount`,
   createCustomerDiscountBulk: `${api.public}v1/customerDiscount/bulk`,
   getCustomerDiscountById: (_id: string) => `${api.public}v1/customerDiscount`,
   updateCustomerDiscountBulk: `${api.public}v1/customerDiscount/bulk`,
   deleteCustomerDiscountBulk: `${api.public}v1/customerDiscount/bulk`,
   getBusinessCustomerRequest: `${api.public}v1/businessCustomerRequest`
}
