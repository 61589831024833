import { combineReducers } from 'redux'
import { AdminsReducer } from './admin'
import { BannerReducer } from './banner'
import { BonusReducer } from './bonus'
import { brandReducer } from './brand'
import { CategoryReducer } from './category'
import { ClientReducer } from './client'
import { ConnectionReducer } from './connection'
import { GridReducer } from './grid'
import { OrderReducer } from './order'
import { ProductReducer } from './product'
import { RoleReducer } from './role'
import { SectionReducer } from './section'
import { seoReducer } from './seo'
import { SupportReducer } from './support'
import { UserReducer } from './user'
import { specialistReducer } from './specialist'
import { moderationReducer } from './moderation'
import { calculatorReducer } from './calculator'

export default combineReducers({
   user: UserReducer,
   admin: AdminsReducer,
   banner: BannerReducer,
   section: SectionReducer,
   product: ProductReducer,
   order: OrderReducer,
   client: ClientReducer,
   bonus: BonusReducer,
   support: SupportReducer,
   connection: ConnectionReducer,
   role: RoleReducer,
   category: CategoryReducer,
   brand: brandReducer,
   grid: GridReducer,
   seo: seoReducer,
   specialist: specialistReducer,
   moderation: moderationReducer,
   calculator: calculatorReducer
})
