import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { FLEX, FONT } from '../../utils'
import {
   TItemsPerRowGridContainer,
   TProfileImage,
   TFlexContainer,
   TAlignContainer,
   TSpaceBeetwen,
   TFilterContainer,
   THeaderWrapper
} from './types'

export const FlexContainer = styled.div<TFlexContainer>`
   display: flex;
   flex-direction: ${({ direction }) => direction || 'row'};
   gap: ${({ gap }) => (gap ? gap : '0px')};
   width: ${({ width }) => width || '100%'};
   height: ${({ height }) => height};
   align-items: ${({ align }) => align};
   justify-content: ${({ justify: jusitify }) => jusitify};
   position: ${({ position }) => position};
   flex-wrap: ${({ wrap }) => wrap || 'wrap'};
   row-gap: ${({ rowGap }) => (rowGap ? rowGap : 'auto')};
   column-gap: ${({ columnGap }) => (columnGap ? columnGap : 'auto')};
   padding: ${({ padding }) => (padding ? padding : '0px')};
   margin: ${({ margin }) => (margin ? margin : '0px')};
`

export const HeaderWrapper = styled.div<THeaderWrapper>`
   ${FLEX({ justify: 'space-between' })}

   width: ${({ width }) => width || ''};
   margin-top: 30px;
   margin-left: 30px;
   margin-right: 30px;
`
export const DataTotal = styled.div`
   ${FONT({ size: '14px', weight: '500' })}
`
export const VerticalSpaceBetweenContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
`

export const FiltersWrapper = styled.div<TFilterContainer>`
   ${FLEX({ justify: 'flex-start' })}
   width: ${({ width }) => width || ''};
   padding: 0;
   margin: 0;
   gap: 20px;
`

export const PlusIcon = styled.img.attrs({ src: assets.PLUS })``

export const ProfileImage = styled.div<TProfileImage>`
   background-image: ${({ src }) => `url(${src})`};
   background-size: cover;
   background-position: center;
   height: ${({ size }) => size || '46px'};
   width: ${({ size }) => size || '46px'};
   border-radius: 100%;
   margin: 0 auto;
`

export const ItemsPerRowGridContainer = styled.div<TItemsPerRowGridContainer>`
   display: grid;
   grid-template-columns: ${({ perRow }) => `repeat(${perRow},1fr)`};
   align-items: flex-start;
   width: ${({ width }) => width || 'auto'};
   height: auto;
   max-height: ${({ height }) => height || '0px'};
   padding: ${({ padding }) => padding || '30px'};
   column-gap: ${({ columnGap }) => columnGap || '0px'};
   row-gap: ${({ rowGap }) => rowGap || '0px'};
`

export const AlignContainer = styled.div<TAlignContainer>`
   display: flex;
   align-items: ${({ align }) => align || 'center'};
   justify-content: ${({ justify: jusitify }) => jusitify || 'center'};
`

export const Label = styled.label`
   margin-bottom: 8px;
   margin-left: 15px;
   line-height: 16px;
   ${FONT({ color: colors.black, size: '14px', weight: '500' })}
`

export const SpaceBetweenContainer = styled.div<TSpaceBeetwen>`
   display: flex;
   justify-content: space-between;
   flex-direction: ${({ direction }) => direction || 'row'};
`

export const H2 = styled.h2`
   ${FONT({ size: '24px', weight: '700' })}
`
export const ProductPhoto = styled.div`
   position: relative;
   width: 119px;
   height: 119px;
   .product-photo {
      width: 100%;
      object-fit: contain;
      max-height: 119px;
      max-width: 119px;
   }
   .product-photo-delete {
      cursor: pointer;
      position: absolute;
      top: -4px;
      right: 7px;
      &:before,
      &:after {
         content: '';
         position: absolute;
         width: 16px;
         height: 2px;
         background: ${colors.red};
      }
      &:before {
         transform: rotate(45deg);
      }
      &:after {
         transform: rotate(-45deg);
      }
   }
`
export const PhotoLoaderButton = styled.label`
   display: flex;
   justify-content: center;
   align-items: center;
   background: ${colors.colorPicker_grey_color};
   cursor: pointer;
   width: 119px;
   height: 119px;
   overflow: hidden;
`
