import { AxiosResponse } from 'axios'
import { api } from 'config'
import { HttpService } from 'services'
import {
   TQuestionsCatogoriesResponse,
   TGetQuestionCategoriesParams,
   TQuestionCategory,
   TCreateQuestionCategory
} from './types'

const URL = api.public

export class QuestionCategoriesService extends HttpService {
   static async getQuestionsCategories(
      params: TGetQuestionCategoriesParams,
      token: string
   ): Promise<AxiosResponse<TQuestionsCatogoriesResponse>> {
      const response = await this.request({
         url: `${URL}v1/catalog/questionCategory`,
         params,
         headers: {
            'nest-cache': 'no-cache',
            Expires: '0',
            Authorization: `Bearer ${token}`
         }
      })
      return response
   }
   static async getQuestionCategory(
      id: string,
      lang: string,
      token: string
   ): Promise<AxiosResponse<TQuestionCategory>> {
      const response = await this.request({
         url: `${URL}v1/catalog/questionCategory/${id}`,
         params: { lang },
         headers: {
            'nest-cache': 'no-cache',

            Authorization: `Bearer ${token}`
         }
      })
      return response
   }
   static async createQuestionCategory(
      data: TCreateQuestionCategory,
      lang: string,
      token: string
   ): Promise<AxiosResponse<TQuestionCategory>> {
      const response = await this.request<TQuestionCategory>({
         url: `${URL}v1/catalog/questionCategory`,
         method: 'POST',
         data,
         params: { lang },
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
      return response
   }
   static async deleteQuestionCategory(id: string, token: string) {
      await this.request({
         url: `${URL}v1/catalog/questionCategory/${id}`,
         method: 'DELETE',
         headers: {
            'nest-cache': 'no-cache',
            Authorization: `Bearer ${token}`
         }
      })
   }
}
