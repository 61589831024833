import { AxiosResponse } from 'axios'
import { HttpService } from '../../services'
import { TResponse } from '../types'
import { BANNER_URL } from './config'
import {
   TCreateBannerRequestPayload,
   TEditBannerRequestPayload,
   TRemoveBannerRequestPayload
} from './types'
import { ELocales } from 'enums'
export class ApiBannerService extends HttpService {
   static getBanners({
      token,
      lang
   }: {
      token: string
      lang?: ELocales
   }): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: BANNER_URL.getBanners,
         method: 'GET',

         params: {
            limit: 1000,
            lang: lang || undefined
         },
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static createBanner({
      token,
      data
   }: TCreateBannerRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: BANNER_URL.createBanner,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static removeBanner({
      token,
      _id
   }: TRemoveBannerRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${BANNER_URL.createBanner}/${_id}`,
         method: 'DELETE',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static editBanner({
      token,
      data,
      _id
   }: TEditBannerRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${BANNER_URL.createBanner}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }
}
