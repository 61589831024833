import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { clientActions } from './actions'
import { ApiClientService } from './api.service'
import { RESPONSE } from './consts'
import {
   Taddress,
   TClientResponse,
   TCreateCustomerDiscountBulkPayload,
   TCreateCustomerDiscountPayload,
   TDeleteCustomerDiscountBulkPayload,
   TEditClientPayload,
   TGetaddressesPayload,
   TGetBusinessClientsPayload,
   TGetBusinessCustomerRequestPayload,
   TGetClientPayload,
   TGetClientsPayload,
   TGetCustomerDiscountByIdPayload,
   TUpdateCustomerDiscountBulkPayload
} from './types'

function* getClientAddresses({ _id }: TGetaddressesPayload) {
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiClientService, ApiClientService.getClientAddresses],
         { token, _id }
      )

      if (response.data.data) return response.data.data
   } catch (e) {}

   return []
}

function* getClientsWorker({ payload }: TDataWrapper<TGetClientsPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiClientService, ApiClientService.getClients],
         { ...payload, token }
      )
      if (response.data) yield put(clientActions.setClients(response.data))
   } catch (e) {
      yield put(clientActions.setClients({ data: [], meta: null }))
   }

   yield put(clientActions.setClientState({ loading: false }))
}

function* getBusinessClientsWorker({
   payload
}: PayloadAction<TGetBusinessClientsPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiClientService, ApiClientService.getBusinessClients],
         { ...payload, token }
      )
      if (response.data) yield put(clientActions.setBusinessClients(response.data))
   } catch (e) {
      yield put(clientActions.setBusinessClients({ data: [], meta: null }))
   }

   yield put(clientActions.setClientState({ loading: false }))
}

function* getClientWorker({ payload }: TDataWrapper<TGetClientPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const { _id } = payload
   const token: string = yield call(getAccessToken)

   const addresses: Taddress[] = yield call(getClientAddresses, { _id })

   try {
      const response: TResponse = yield call(
         [ApiClientService, ApiClientService.getClient],
         { _id, token }
      )
      if (response.data.data) {
         yield put(clientActions.setClient({ ...response.data.data, addresses }))
         yield put(clientActions.setClientState({ loading: false }))
      } else {
         yield put(
            clientActions.setClientState({
               loading: false,
               response: RESPONSE.NOT_FOUND as TClientResponse
            })
         )
      }
   } catch (e) {
      yield put(
         clientActions.setClientState({
            loading: false,
            response: RESPONSE.NOT_FOUND as TClientResponse
         })
      )
   }
}

function* editClientWorker({ payload }: TDataWrapper<TEditClientPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const { _id, data } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiClientService, ApiClientService.editClient], { _id, data, token })
   } catch (e) {}

   yield put(clientActions.setClientState({ loading: false }))

   yield put(clientActions.getClient({ _id }))
}

function* createCustomerDiscountWorker({
   payload
}: PayloadAction<TCreateCustomerDiscountPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const { data } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiClientService, ApiClientService.createCustomerDiscount], {
         data,
         token
      })
   } catch (e) {}

   yield put(clientActions.setClientState({ loading: false }))
}

function* getCustomerDiscountBrandsWorker({
   payload
}: PayloadAction<TGetCustomerDiscountByIdPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiClientService, ApiClientService.getCustomerDiscountById],
         {
            ...payload,
            token
         }
      )
      if (response) yield put(clientActions.setCustomerDiscountBrands(response.data))
   } catch (e) {}

   yield put(clientActions.setClientState({ loading: false }))
}

function* getCustomerDiscountProductsWorker({
   payload
}: PayloadAction<TGetCustomerDiscountByIdPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiClientService, ApiClientService.getCustomerDiscountById],
         {
            ...payload,
            token
         }
      )
      if (response) yield put(clientActions.setCustomerDiscountProducts(response.data))
   } catch (e) {}

   yield put(clientActions.setClientState({ loading: false }))
}

function* customerDiscountBulkWorker({
   payload
}: PayloadAction<
   TUpdateCustomerDiscountBulkPayload & TCreateCustomerDiscountBulkPayload
>) {
   yield put(clientActions.setClientState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      if (payload?.createdData.length) {
         yield call([ApiClientService, ApiClientService.createCustomerDiscountBulk], {
            createdData: payload.createdData,
            token
         })
      }
      if (payload?.updatedData?.length) {
         yield call([ApiClientService, ApiClientService.updateCustomerDiscountBulk], {
            updatedData: payload.updatedData,
            token
         })
      }
   } catch (e) {}

   yield put(clientActions.setClientState({ loading: false }))
}

function* deleteCustomerDiscountBulkWorker({
   payload
}: PayloadAction<TDeleteCustomerDiscountBulkPayload>) {
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiClientService, ApiClientService.deleteCustomerDiscountBulk], {
         _id: payload._id,
         token
      })
   } catch (e) {}

   yield put(clientActions.setClientState({ response: 'DELETED' }))
}

function* getBusinessCustomerRequestWorker({
   payload
}: PayloadAction<TGetBusinessCustomerRequestPayload>) {
   yield put(clientActions.setClientState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiClientService, ApiClientService.getBusinessCustomerRequest],
         {
            ...payload,
            token
         }
      )

      if (response.data)
         yield put(clientActions.setBusinessCustomerRequest(response.data))
   } catch (e) {}

   yield put(clientActions.setClientState({ loading: false }))
}

export function* clientWatcher() {
   yield takeLatest(clientActions.getClients, getClientsWorker)
   yield takeLatest(clientActions.getBusinessClients, getBusinessClientsWorker)
   yield takeLatest(clientActions.getClient, getClientWorker)
   yield takeLatest(clientActions.editClient, editClientWorker)
   yield takeLatest(clientActions.createCustomerDiscount, createCustomerDiscountWorker)
   yield takeLatest(
      clientActions.getCustomerDiscountBrands,
      getCustomerDiscountBrandsWorker
   )
   yield takeLatest(
      clientActions.getCustomerDiscountProducts,
      getCustomerDiscountProductsWorker
   )
   yield takeLatest(
      clientActions.deleteCustomerDiscountBulk,
      deleteCustomerDiscountBulkWorker
   )
   yield takeLatest(clientActions.customerDiscountBulk, customerDiscountBulkWorker)
   yield takeLatest(
      clientActions.getBusinessCustomerRequsest,
      getBusinessCustomerRequestWorker
   )
}
