import { assets } from 'assets'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   SelectBrandPopup,
   SelectProductPopup,
   SubPagesBar,
   TSection
} from 'components'
import { ELocales } from 'enums'
import { useLocalization, useTypedSelector } from 'hooks'
import i18next, { t } from 'i18next'
import { FC, SyntheticEvent, useEffect, useMemo, useState, Dispatch } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   getMainProductSelector,
   ApiProductService,
   getUserSelector,
   clientActions,
   getMainClientSelector
} from 'store'
import { TBrand } from 'store/brand/types'
import { TProduct } from 'store/product/types'
import { BrandsTable, DiscountHeader, ProductsTable } from './components'
import { Line } from './styled'
import { CUSTOMER_DISCOUNT, TGetCustomerByIdResponse } from 'store/client/types'
import { PAGE_LIMIT, discountOptions } from './consts'
import _ from 'lodash'

export const DiscountSystem: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [locale] = useLocalization()

   const { id } = useParams()

   const { loading: productLoading } = useTypedSelector(getMainProductSelector)

   const { client, customerDiscountBrands, customerDiscountProducts, loading, response } =
      useTypedSelector(getMainClientSelector)
   const { accessToken } = useTypedSelector(getUserSelector)

   const [form, setForm] = useState({
      discountPriceProducts: '',
      discountPriceBrands: '',
      discountOption: ''
   })
   const [isCheckedBrands, setCheckedBrands] = useState(false)
   const [isChecked, setChecked] = useState<boolean>(false)
   const [productsPopupVisability, setProductsPopupVisability] = useState(false)
   const [brandsPopupVisability, setBrandsPopupVisability] = useState(false)

   const [selectedProducts, setSelectedProducts] = useState<
      (TProduct & { discount?: number })[]
   >([])
   const [selectedBrands, setSelectedBrands] = useState<
      (TBrand & { discount?: number })[]
   >([])

   const filteredProducts = useMemo(() => {
      const uniqueArr = _.uniqWith(selectedProducts, _.isEqual)

      return uniqueArr
   }, [selectedProducts])

   const filteredBrands = useMemo(() => {
      return _.uniq(selectedBrands)
   }, [selectedBrands])

   const Events = {
      backButtonClickHandler: () => {
         navigate(`/businessClients/${id as string}`)
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement

         if (name === 'discountOption' && value === 'products.discount') {
            setProductsPopupVisability(true)
            return
         }
         if (name === 'discountOption' && value === 'brands.discount') {
            setBrandsPopupVisability(true)
            return
         }

         setForm((props) => ({ ...props, [name]: value }))
      },
      checkedBrandsStateHandler: (e: SyntheticEvent) => {
         setCheckedBrands(!isCheckedBrands)
      },
      checkedStateHandler: () => {
         setChecked(!isChecked)
      },
      setProductsPopupVisiablity: () => {
         setProductsPopupVisability(!productsPopupVisability)
      },
      setBrandsPopupVisiablity: () => {
         setBrandsPopupVisability(!brandsPopupVisability)
      },
      productPopupAddHandler: async (products: string[]) => {
         const res = []
         for (let id of products) {
            const product = await Requests.getProductById(id)
            res.push(product)
         }
         setSelectedProducts(
            selectedProducts
               .concat(res as any)
               .flat()
               .filter((value, index, self) => {
                  return self.findIndex((v) => v._id === value._id) === index
               })
         )
         setProductsPopupVisability(false)
      },
      clearProducts: () => {
         dispatch(
            clientActions.deleteCustomerDiscountBulk({
               _id: customerDiscountProducts?.data
                  ?.filter((item) =>
                     filteredProducts?.map((item) => item._id).includes(item.product._id)
                  )
                  .map((item) => item._id)
            })
         )

         setSelectedProducts([])
      },
      clearBrands: () => {
         dispatch(
            clientActions.deleteCustomerDiscountBulk({
               _id: customerDiscountBrands?.data
                  .filter((item) =>
                     filteredBrands?.map((item) => item._id).includes(item?.brand?._id)
                  )
                  .map((item) => item._id)
            })
         )

         setSelectedBrands([])
      },
      clearAll: () => {
         dispatch(
            clientActions.deleteCustomerDiscountBulk({
               _id: customerDiscountBrands?.data
                  .filter((item) =>
                     filteredBrands?.map((item) => item._id).includes(item?.brand?._id)
                  )
                  .map((item) => item._id)
                  .concat(
                     customerDiscountProducts?.data
                        ?.filter((item) =>
                           filteredProducts
                              ?.map((item) => item._id)
                              .includes(item.product._id)
                        )
                        .map((item) => item._id) as string[]
                  )
            })
         )

         setSelectedBrands([])
         setSelectedProducts([])
      },
      saveCustomerDiscount: () => {
         const updatedDataProducts = filteredProducts
            ?.filter(
               (item) =>
                  item.discount &&
                  customerDiscountProducts?.data
                     .map((item) => item?.product?._id)
                     .includes(item?._id)
            )
            .map((item) => ({
               _id: customerDiscountProducts?.data.find(
                  (i) => i?.product?._id == item._id
               )?._id,
               discount: item?.discount,
               product: item?.guid,
               customer: client?._id
            }))

         const createdDataProducts = filteredProducts
            ?.filter(
               (item) =>
                  (item.discount || +form.discountPriceProducts) &&
                  !customerDiscountProducts?.data
                     .map((item) => item?.product?._id)
                     .includes(item?._id)
            )
            .map((item) => ({
               discount: item?.discount ? item.discount : form.discountPriceProducts,
               product: item?.guid,
               customer: client?._id
            }))

         const createdDataBrands = filteredBrands
            ?.filter(
               (item) =>
                  (item.discount || +form.discountPriceBrands) &&
                  !customerDiscountBrands?.data
                     .map((item) => item?.brand?._id)
                     .includes(item?._id)
            )
            .map((item) => ({
               discount: item?.discount ? item.discount : form.discountPriceBrands,
               brand: item?._id,
               customer: client?._id
            }))

         const updatedDataBrands = filteredBrands
            ?.filter(
               (item) =>
                  item.discount &&
                  customerDiscountBrands?.data
                     .map((item) => item?.brand?._id)
                     .includes(item?._id)
            )
            .map((item) => ({
               _id: customerDiscountBrands?.data.find((i) => i?.brand?._id == item?._id)
                  ?._id,
               discount: item?.discount,
               brand: item?._id,
               customer: client?._id
            }))

         dispatch(
            clientActions.customerDiscountBulk({
               updatedData: [...updatedDataBrands, ...updatedDataProducts],
               createdData: [...createdDataBrands, ...createdDataProducts]
            })
         )
      }
   }

   const Requests = {
      getProductById: async (_id: string): Promise<string> => {
         const response = await ApiProductService.getProduct({
            token: accessToken as string,
            _id,
            lang: i18next.language as ELocales
         })

         return response.data.data
      },
      getCustomerDiscountProducts: () => {
         dispatch(
            clientActions.getCustomerDiscountProducts({
               _id: id as string,
               lang: locale,
               type: CUSTOMER_DISCOUNT.product,
               page: 0,
               limit: PAGE_LIMIT
            })
         )
      },
      getCustomerDiscountBrands: () => {
         dispatch(
            clientActions.getCustomerDiscountBrands({
               _id: id as string,
               lang: locale,
               type: CUSTOMER_DISCOUNT.brand,
               page: 0,
               limit: PAGE_LIMIT
            })
         )
      },
      getClient: () => {
         dispatch(clientActions.getClient({ _id: id as string }))
      }
   }

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/businessClients/${id}`)
            }
         },
         {
            title: t('orders.history'),
            onClickHandler: () => {
               // navigate('/')
            }
         },
         {
            title: t('discount.system'),
            active: true
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getClient()
      Requests.getCustomerDiscountBrands()
      Requests.getCustomerDiscountProducts()
   }, [])

   useEffect(() => {
      customerDiscountBrands?.data &&
         setSelectedBrands(
            selectedBrands.concat(
               customerDiscountBrands?.data
                  .filter((item) => item?.brand?._id)
                  .map((item) => item?.brand) as TBrand[]
            )
         )
   }, [customerDiscountBrands])

   useEffect(() => {
      customerDiscountProducts?.data &&
         setSelectedProducts(
            selectedProducts.concat(
               customerDiscountProducts?.data
                  .filter((item) => item?.product?._id)
                  .map((item) => item?.product) as TProduct[]
            )
         )
   }, [customerDiscountProducts])

   useEffect(() => {
      if (response == 'DELETED') {
         window.location.reload()
      }
   }, [response])

   return (
      <AdminPanelContainer
         Header={<DiscountHeader Events={Events} />}
         loading={productLoading || loading}>
         <SubPagesBar sections={sections} />
         <FlexContainer padding="0px" direction="column" width="95%">
            {client?._id && filteredProducts?.length > 0 && (
               <ProductsTable
                  form={form}
                  selectedProducts={filteredProducts}
                  setSelectedProducts={setSelectedProducts}
                  Events={Events}
                  isChecked={isChecked}
                  customerDiscount={customerDiscountProducts as TGetCustomerByIdResponse}
                  customerId={client?._id}
               />
            )}

            {(filteredProducts?.length > 0 || filteredBrands?.length > 0) && <Line />}

            {client?._id && filteredBrands?.length > 0 && (
               <BrandsTable
                  form={form}
                  selectedBrands={filteredBrands}
                  setSelectedBrands={setSelectedBrands}
                  Events={Events}
                  customerDiscount={customerDiscountBrands as TGetCustomerByIdResponse}
                  isCheckedBrands={isCheckedBrands}
                  isChecked={isChecked}
                  customerId={client?._id}
               />
            )}
         </FlexContainer>

         <DropDownList
            width="189px"
            name="discountOption"
            placeholder={t('create')}
            options={discountOptions}
            onChange={Events.inputChangeHandler}
            createDiscount={true}
            textAlign={'center'}
            whiteArrow={true}
         />

         {productsPopupVisability && (
            <SelectProductPopup
               activeItems={selectedProducts.map((item) => item._id)}
               closeClickHandler={() => Events.setProductsPopupVisiablity()}
               addProductClickHandler={(products) => {
                  Events.productPopupAddHandler(products)
               }}
               checkAll={true}
               discountPopup={true}
            />
         )}

         {brandsPopupVisability && (
            <SelectBrandPopup
               closeClickHandler={() => Events.setBrandsPopupVisiablity()}
               addProductClickHandler={(brands) => {
                  setSelectedBrands(selectedBrands.concat(brands))
                  setBrandsPopupVisability(false)
               }}
            />
         )}
      </AdminPanelContainer>
   )
}
