import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   SearchBar,
   SubPagesBar,
   TColumnTable,
   TDataTable,
   TSection,
   TSort,
   Table
} from 'components'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clientActions, getMainClientSelector } from 'store'
import { useLocalization, useTypedSelector } from 'hooks'

export const BusinessCustomerRequest: FC = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { businessCustomerRequest, loading, filters } =
      useTypedSelector(getMainClientSelector)

   const [searchForm, setSearchForm] = useState({
      searchValue: filters.searchValue ? filters.searchValue : '',
      dateStart: filters.dateStart ? filters.dateStart : '',
      dateEnd: filters.dateEnd ? filters.dateEnd : ''
   })
   const [searchParams, setSearchParams] = useSearchParams({})
   const [page, setPage] = useState<number>(0)
   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })
   const [locale] = useLocalization()

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const Events = {
      onChangeHandler: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: () => {},
      resetFilters: () => {},
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      editButtonClickHandler: () => {}
   }

   const Requests = {
      getBusinessCustomerRequest: () => {
         dispatch(
            clientActions.getBusinessCustomerRequsest({
               limit: 10,
               lang: locale,
               page: page,
               ...searchForm,
               ...sortParamsForm
            })
         )
      }
   }

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('b2cClients'),
            onClickHandler: () => {
               navigate('/clients')
            }
         },
         {
            title: t('b2bClients'),
            onClickHandler: () => {
               navigate('/businessClients')
            }
         },
         {
            title: t('b2bRequest'),
            active: true
         }
      ],
      []
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('request.id'),
            accessor: 'id',
            width: 300
         },
         {
            Header: t('company.name'),
            accessor: 'name',
            width: 50
         },
         {
            Header: t('INN'),
            accessor: 'inn',
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('phone'),
            accessor: 'phone',
            width: 200
         },
         {
            Header: t('email'),
            accessor: 'email',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('send.data'),
            accessor: 'date'
         }
      ],
      []
   )

   const data: TDataTable[] = useMemo(() => {
      if (businessCustomerRequest?.data) {
         return businessCustomerRequest?.data.map((item, index) => ({
            id: <>{index}</>,
            name: <>{item?.name}</>,
            inn: <>{item?.tin}</>,
            phone: <>{item?.phone}</>,
            email: <>{item?.email}</>,
            date: <>{item?.createdAt}</>
         }))
      }
      return []
   }, [businessCustomerRequest])

   useEffect(() => {
      Requests.getBusinessCustomerRequest()
      setSearchParams({ ...searchParams, page: '' + (page + 1) })
   }, [page, tableSortBy, searchForm])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string) - 1
            : 0
      )

      dispatch(clientActions.setFilters({ searchValue: '', dateStart: '', dateEnd: '' }))
   }, [])

   return (
      <AdminPanelContainer
         Header={<Header title={t('manage.clients')} />}
         loading={loading}>
         <SubPagesBar sections={sections} />

         <FlexContainer gap="30px" direction="column">
            <HeaderWrapper>
               <FiltersWrapper>
                  <SearchBar
                     name="searchValue"
                     placeholder={t('search')}
                     value={searchForm.searchValue}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />

                  <DateInput
                     name="dateStart"
                     value={searchForm.dateStart}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="dateEnd"
                     value={searchForm.dateEnd}
                     onChange={Events.onChangeHandler}
                  />
                  <div>
                     <Button style="transparant" onClick={Events.resetFilters}>
                        {t('reset.filters')}
                     </Button>
                  </div>
               </FiltersWrapper>

               <DataTotal>
                  {t('total.requests')}: {businessCustomerRequest?.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               columns={columns}
               data={data}
               sortBy={tableSortBy}
               editable
               editClickHandler={Events.editButtonClickHandler}
            />
         </FlexContainer>
      </AdminPanelContainer>
   )
}
