import { i18n } from 'config'
import useSWRInfinite from 'swr/infinite'
import { TBrendsResponse, TGetBrandsRequestPayload } from './types'
import { ELocales } from 'enums'
import { ApiBrandService } from './api.service'

export const useGetBrandsInfinity = (params: TGetBrandsRequestPayload) => {
   const lang = i18n.language as ELocales

   const allParams: TGetBrandsRequestPayload = { lang, ...params }
   const getKey = (pageIndex: number, previousPageData: TBrendsResponse) => {
      if (previousPageData && !previousPageData?.data?.length) return null
      return ['getProducts', { ...allParams, page: pageIndex }]
   }
   const { data, size, setSize, mutate, isLoading } = useSWRInfinite(
      getKey,
      ([, par]) => {
         if (typeof par === 'object') return ApiBrandService.getAsyncBrands(par)
      },
      { revalidateOnFocus: false, keepPreviousData: true }
   )

   const loadMoreClick = () => {
      setSize(size + 1)
   }

   const brands: TBrendsResponse[] =
      data?.filter((brand): brand is TBrendsResponse => Boolean(brand)) || []

   return { brands, size, setSize, mutate, isLoading, loadMoreClick }
}
