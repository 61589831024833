import { colors } from 'enums'
import styled from 'styled-components'

export const ButtonWrap = styled.span`
   display: flex;
   flex-direction: column;
   height: 100%;
   gap: 12px;
   button {
      cursor: pointer;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      outline: none;
      width: 120px;
      border: none;

      &.add {
         height: 100%;
         background: ${colors.blue};
         color: #ffffff;
         &[disabled] {
            background: ${colors.gray_light};
            color: ${colors.grey};
         }
      }
      &.clear {
         color: ${colors.red};
         border: 1px solid ${colors.red};
         border-radius: 8px;
         height: 45px;
         background: transparent;
         &[disabled] {
            background: ${colors.gray_light};
            color: ${colors.grey};
         }
      }
   }
`
export const DragWrap = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 20px;
   ul {
      padding: 8px;
      list-style: none;
      width: 730px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      border: 1px solid rgb(209, 209, 209);
      border-radius: 8px;
      min-height: 148px;
      svg {
         color: ${colors.grey1};
         width: 80px;
         height: 80px;
         margin: 30px auto;
      }
   }
`
export const FormulaElement = styled.li`
   display: flex;
   align-items: center;
   justify-content: center;
   height: 52px;
   min-width: 52px;
   padding: 10px 12px;
   border-radius: 8px;
   height: 52px;
   font-size: 14px;
   font-weight: 500;
   cursor: grab;
   background-color: ${colors.light_grey};
   input {
      border: none;
      height: 30px;
      outline: none;
      width: 115px;
      background: transparent;
   }
`
export const FormulaElementList = styled.ul`
   list-style: none;
   display: flex;
   flex-wrap: wrap;
   gap: 12px;
   margin: 20px 0;
   li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      padding: 10px 12px;
      border-radius: 8px;
      height: 52px;
      font-size: 14px;
      font-weight: 500;
      cursor: grab;
      background-color: ${colors.light_grey};
   }
`
export const SinsWrap = styled.ul`
   list-style: none;
   display: flex;
   gap: 12px;
   margin: 20px 0;
   li {
      border: 1px solid ${colors.grey1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 52px;
      height: 52px;
      cursor: grab;
      &:last-child {
         width: 144px;
      }
   }
`
export const FormulaWrap = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   padding-bottom: 30px;
   h4 {
      font-weight: 500;
   }
`
export const ProductTitleWrap = styled.div`
   margin: 30px 0 20px;
   h3 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
   }
   p {
      color: ${colors.grey};
   }
`
