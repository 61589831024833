import { Controller, useFieldArray } from 'react-hook-form'
import {
   AnswerItem,
   AnswerWrap,
   AnswersContainer,
   InputButtons,
   InputsWrap,
   ProductButton,
   SelectWrap,
   selectStyles,
   ProductButtonWrap,
   CheckboxWrap
} from './styled'
import { CustomInput } from 'pages/Calculator/components'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { IoMdAdd } from 'react-icons/io'
import { RxCross2 } from 'react-icons/rx'
import { TAnswersProps } from './types'
import { useRef, useState } from 'react'
import { ModalTieUpProduct } from '../ModalTieUpProduct/ModalTieUpProduct'
import { ModalMaterial } from 'components'
import { onSelectChange, removeQuestionsAndSubquestions } from './helpers'
import { IoClose } from 'react-icons/io5'
import { MdRemoveRedEye } from 'react-icons/md'
import { Switcher } from 'pages/Calculator/Pages/CreateCalculator/components'
import {
   createId,
   questionsOptions
} from 'pages/Calculator/Pages/CreateCalculator/helpers'
import { Checkbox } from '@mui/material'

export const Answers = ({
   index,
   control,
   register,
   errors,
   append,
   watch,
   setValue
}: TAnswersProps) => {
   const [visibleModal, setVisibleModal] = useState(false)
   const {
      fields: answerFields,
      append: answerAppend,
      remove: answerRemove
   } = useFieldArray({
      control,
      name: `questions.${index}.answers`
   })
   const { t } = useTranslation()

   const stateIndex = useRef<number>(0)
   const productDataIndex = useRef<number>(0)

   const onSwitcherChange = (value: boolean) => {
      if (value && answerFields.length < 2) {
         answerAppend({
            answerAz: '',
            answerRu: '',
            action: questionsOptions[0],
            id: createId(),
            default: false
         })
      } else if (!value) {
         const currentAnswers = watch(`questions.${index}.answers`)
         for (let i = currentAnswers.length - 1; i > 0; i--) {
            const nextQuestionId = currentAnswers[i].question || ''
            removeQuestionsAndSubquestions(nextQuestionId, watch, setValue)
            answerRemove(i)
         }
      }
   }

   const onDeleteInput = (subindex: number, nextQuestionId: string) => {
      answerRemove(subindex)
      removeQuestionsAndSubquestions(nextQuestionId, watch, setValue)
   }

   const onProductButtonClick = (currentSubIndex: number, productDataIndx: number) => {
      stateIndex.current = currentSubIndex
      productDataIndex.current = productDataIndx

      setVisibleModal(true)
   }

   const closeModal = () => {
      stateIndex.current = 0
      setVisibleModal(false)
   }

   const onActionClick = (value: unknown, subIndex: number, nextQuestionId: string) => {
      onSelectChange(value, subIndex, nextQuestionId, watch, setValue, index, append)
   }
   const onAddProduct = (subIndex: number) => {
      setValue(`questions.${index}.answers.${subIndex}.answerProductsData`, [
         ...(watch(`questions.${index}.answers.${subIndex}.answerProductsData`) || []),
         { products: [], expression: [] }
      ])
   }

   const onDeleteProductClick = (
      index: number,
      subIndex: number,
      productDataIndex: number
   ) => {
      const currentProductsData = watch(
         `questions.${index}.answers.${subIndex}.answerProductsData`
      )

      const newProductsData = currentProductsData?.filter(
         (_, idx) => idx !== productDataIndex
      )

      setValue(
         `questions.${index}.answers.${subIndex}.answerProductsData`,
         newProductsData
      )
   }
   const onCheckboxChange = (onChange: (...event: any[]) => void, subIndex: number) => {
      const answers = watch(`questions.${index}.answers`)
      answers.forEach((_, idx) => {
         if (idx !== subIndex) {
            setValue(`questions.${index}.answers.${idx}.default`, false)
         }
      })
      onChange(true)
   }
   return (
      <>
         <Controller
            control={control}
            name={`questions.${index}.closedQuestion`}
            render={({ field: { value, onChange } }) => (
               <Switcher
                  onChange={(v) => {
                     onChange(v), onSwitcherChange(v)
                  }}
                  value={value}
               />
            )}
         />
         <AnswerWrap>
            {answerFields.map((item, subIndex) => {
               const currentAction = watch(
                  `questions.${index}.answers.${subIndex}.action.value`
               )

               // const isAllproducts =
               //    watch(`questions.${index}.answers.${subIndex}.products`)?.length === 3

               const visibleButton =
                  currentAction === 'question&products' || currentAction === 'products'
               return (
                  <AnswerItem key={item.id}>
                     <InputsWrap>
                        {watch(`questions.${index}.closedQuestion`) && (
                           <>
                              {subIndex === 0 && (
                                 <InputButtons
                                    disabled={answerFields.length >= 6}
                                    type="button"
                                    onClick={() =>
                                       answerAppend({
                                          answerAz: '',
                                          answerRu: '',
                                          action: questionsOptions[0],
                                          id: createId(),
                                          default: false
                                       })
                                    }>
                                    <IoMdAdd />
                                    {t('add')}
                                 </InputButtons>
                              )}
                              {answerFields.length > 2 && subIndex !== 0 && (
                                 <InputButtons
                                    type="button"
                                    onClick={() =>
                                       onDeleteInput(
                                          subIndex,
                                          watch(
                                             `questions.${index}.answers.${subIndex}.question`
                                          ) || ''
                                       )
                                    }>
                                    <RxCross2 />
                                    {t('delete')}
                                 </InputButtons>
                              )}
                              <AnswersContainer>
                                 {index === 0 && watch(`questions.${0}.hidden`) && (
                                    <CheckboxWrap>
                                       <Controller
                                          control={control}
                                          name={`questions.${0}.answers.${subIndex}.default`}
                                          render={({ field: { value, onChange } }) => (
                                             <Checkbox
                                                style={{ padding: 0 }}
                                                onChange={() =>
                                                   onCheckboxChange(onChange, subIndex)
                                                }
                                                checked={value}
                                             />
                                          )}
                                       />{' '}
                                       <p>{t('defaultResponse')}</p>
                                    </CheckboxWrap>
                                 )}
                                 <CustomInput
                                    style={{ minWidth: '300px' }}
                                    // disabled={currentAction === 'products'}
                                    {...register(
                                       `questions.${index}.answers.${subIndex}.answerAz`,
                                       {
                                          required: {
                                             value: currentAction !== 'products',
                                             message: t('required.field')
                                          }
                                       }
                                    )}
                                    placeholder={t('answer')}
                                    label={`${t(`answer`)} AZ ${subIndex + 1}`}
                                    width="90%"
                                    $error={
                                       errors?.questions?.[index]?.answers?.[subIndex]
                                          ?.answerAz?.message
                                    }
                                 />
                                 <CustomInput
                                    style={{ minWidth: '300px' }}
                                    // disabled={currentAction === 'products'}
                                    {...register(
                                       `questions.${index}.answers.${subIndex}.answerRu`,
                                       {
                                          required: {
                                             value: currentAction !== 'products',
                                             message: t('required.field')
                                          }
                                       }
                                    )}
                                    placeholder={t('answer')}
                                    label={`${t(`answer`)} RU ${subIndex + 1}`}
                                    width="90%"
                                    $error={
                                       errors?.questions?.[index]?.answers?.[subIndex]
                                          ?.answerRu?.message
                                    }
                                 />
                              </AnswersContainer>
                           </>
                        )}

                        <SelectWrap>
                           <p>{t('action')}</p>
                           <Controller
                              control={control}
                              name={`questions.${index}.answers.${subIndex}.action`}
                              render={({ field: { value, onChange } }) => (
                                 <Select
                                    styles={selectStyles}
                                    options={questionsOptions}
                                    value={value}
                                    onChange={(v) => {
                                       onActionClick(
                                          v,
                                          subIndex,
                                          watch(
                                             `questions.${index}.answers.${subIndex}.question`
                                          ) || ''
                                       ),
                                          onChange(v)
                                    }}
                                 />
                              )}
                           />
                        </SelectWrap>
                        <div>
                           {visibleButton && (
                              <ProductButton
                                 onClick={() => onAddProduct(subIndex)}
                                 type="button"
                                 $isAllproducts={false}>
                                 {t('add')}
                              </ProductButton>
                           )}{' '}
                           {watch(
                              `questions.${index}.answers.${subIndex}.answerProductsData`
                           )?.map((productData, productDataIndex) => {
                              const isAllproducts = productData?.products?.length === 3

                              return (
                                 <ProductButtonWrap key={productDataIndex}>
                                    <IoClose
                                       className="delete"
                                       onClick={() =>
                                          onDeleteProductClick(
                                             index,
                                             subIndex,
                                             productDataIndex
                                          )
                                       }
                                    />
                                    {visibleButton && (
                                       <ProductButton
                                          $isAllproducts={isAllproducts}
                                          disabled={!watch(`nameCategory`)?.value}
                                          onClick={() =>
                                             onProductButtonClick(
                                                subIndex,
                                                productDataIndex
                                             )
                                          }
                                          type="button">
                                          {!isAllproducts ? (
                                             t('tieProducts')
                                          ) : (
                                             <>
                                                <MdRemoveRedEye /> {t('viewEditProducts')}
                                             </>
                                          )}
                                       </ProductButton>
                                    )}
                                 </ProductButtonWrap>
                              )
                           })}
                        </div>
                     </InputsWrap>
                     <ModalMaterial close={closeModal} isOpen={visibleModal}>
                        <ModalTieUpProduct
                           productDataIndex={productDataIndex.current}
                           closeModal={closeModal}
                           watch={watch}
                           setValue={setValue}
                           index={index}
                           subIndex={stateIndex.current}
                        />
                     </ModalMaterial>
                  </AnswerItem>
               )
            })}
         </AnswerWrap>
      </>
   )
}
