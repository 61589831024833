import { colors } from '../../enums'
import {
   TFlexConstructorArgs,
   TStyledConstructor,
   TShadowConstructorArgs,
   TBorderConstructorArgs,
   TFontConstructorArgs
} from './types'

export const FLEX: TStyledConstructor<TFlexConstructorArgs> = ({
   direction = 'row',
   justify = 'center',
   align = 'center',
   wrap = 'wrap',
   gap = '0px'
}) => {
   return `
      display: flex;
      align-items: ${align};
      justify-content: ${justify};
      flex-direction: ${direction};
      flex-wrap: ${wrap};
      gap: ${gap};
    `
}

export const SHADOW: TStyledConstructor<TShadowConstructorArgs> = ({
   x = 0,
   y = 0,
   blur = 6,
   color = '#0000000F'
}) => {
   return `
      box-shadow: ${x}px ${y}px ${blur}px ${color};
    `
}

export const BORDER: TStyledConstructor<TBorderConstructorArgs> = ({
   width = 1,
   style = 'solid',
   color = '#DFDFDF'
}) => {
   return `
      border: ${width}px ${style} ${color};
    `
}

export const FONT: TStyledConstructor<TFontConstructorArgs> = ({
   color = colors.black,
   family = 'ROBOTO, sans-serif',
   weight = '400',
   size = '12px',
   align = 'left'
}) => {
   return `
    text-align: ${align};
    font-family: ${family};
    font-weight: ${weight};
    font-size: ${size};
    color: ${color};
  `
}
