import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { assets } from '../../assets'
import { Container, Button, Input } from './styled'
import { TCountBar } from './typest'

const CountBar: FC<TCountBar> = ({ value, onChange, name }) => {
   const [count, setCount] = useState<number>(0)
   const inputRef = useRef<HTMLInputElement>(null)

   const Events = {
      plusButtonClickHandler: () => {
         setCount((count) => count + 1)
      },
      minusButtonClickHandler: () => {
         count > 0 && setCount((count) => count - 1)
      }
   }

   useEffect(() => {
      value && setCount(value)
   }, [value])

   useEffect(() => {
      onChange && onChange(inputRef.current)
   }, [count])

   return (
      <Container>
         <Button onClick={Events.minusButtonClickHandler}>
            <img src={assets.MINUS} />
         </Button>
         <Input
            name={name}
            value={count}
            ref={inputRef}
            onChange={(e) => onChange && onChange(e.target as ReactNode)}
         />
         <Button onClick={Events.plusButtonClickHandler}>
            <img src={assets.PLUS} />
         </Button>
      </Container>
   )
}

export default CountBar
