import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TProduct } from 'store/product/types'
import { Container } from './styled'
import { assets } from 'assets'
import { api } from 'config'

type ProductItemProps = {
   productItem: TProduct
}

export const ProductItem = ({ productItem }: ProductItemProps) => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   return (
      <Container>
         <img
            src={`${api.preview}${productItem?.preview}`}
            alt="product"
            className="product-img"
         />
         <div className="product-code">
            {productItem?.barcode ? productItem?.barcode : ''}
         </div>
         <div className="product-name" onClick={() => {}} style={{ cursor: 'pointer' }}>
            {productItem?.description?.title}
         </div>
         <div className="product-price">
         <div className="product-price__new">
               {productItem?.discountPrice
                  ? productItem?.discountPrice
                  : productItem?.price}{' '}
               ₼/шт
            </div>

            {productItem?.discountPrice && (
               <div className="product-price__old">{`${productItem?.price} ₼/шт`} </div>
            )}
         </div>
      </Container>
   )
}
