export const GET_BANNERS = 'GET_BANNERS'
export const SET_BANNERS = 'SET_BANNERS'
export const REMOVE_BANNER = 'REMOVE_BANNER'
export const SAVE_BANNERS = 'SAVE_BANNERS'
export const SET_BANNER_STATE = 'SET_BANNER_STATE'
export const CHANGE_LANG = 'CHANGE_LANG'

export const RESPONSE = {
   SAVED: 'SAVED'
}
