import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   KeywordsInput,
   Textarea
} from 'components'
import locales from 'enums/locales'
import { useTypedSelector } from 'hooks'
import { t } from 'i18next'
import { ProductImage } from 'pages/Catalog/components/Photos/types'
import { Photos } from 'pages/Products/pages/CurrentProducts/pages/Edit/pages/Main/components/Photos'
import { FormContainer } from 'pages/Products/pages/CurrentProducts/pages/Edit/pages/Main/styled'
import { FC, SyntheticEvent, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   brandsActions,
   categoryActions,
   getBrandSelector,
   getMainCategorySelector,
   getMainProductSelector,
   productActions
} from 'store'
import { getMainModerationSelector, moderationActions } from 'store/moderation'
import { MODERATION_RESPONSE } from 'store/moderation/consts'
import { TChangesProduct, TModeration, TProduct } from 'store/moderation/types'
import { TDescription } from 'store/product/types'
import { useChangesHook } from '../../hooks/useChangesHook'
import { ClickbleText, Container } from './styled'
import { utils } from './utils'

export const ModerateChangesIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { changedProduct, loading, response } = useTypedSelector(
      getMainModerationSelector
   )
   const { photoProduct } = useTypedSelector(getMainProductSelector)
   const { brands } = useTypedSelector(getBrandSelector)
   const { categories } = useTypedSelector(getMainCategorySelector)

   const { id, page } = useParams()

   const [form, setForm] = useState<TProduct>()
   const [changes, setChanges] = useState<TChangesProduct>()
   const [editedData, setEditedData] = useState<TProduct>()
   const [productDescription, setProductDescription] = useState<TDescription>()
   const [beforeChanges, setBeforeChanges] = useState<boolean>(false)
   const [productImages, setProductImages] = useState<ProductImage[]>([])

   const {
      type,
      visability,
      barcode,
      title,
      brand,
      description,
      price,
      discountPrice,
      amount,
      status,
      metaDescription,
      category,
      gallery,
      metaKeywords
   } = useChangesHook({
      changedProduct: changedProduct as TModeration,
      beforeChanges,
      form: form as TProduct,
      changes: changes as TChangesProduct,
      productImages: productImages as ProductImage[],
      description: productDescription as TDescription
   })

   const productTypeOptions = [
      { name: t('variated.product'), value: 'variated' },
      { name: t('single.product'), value: 'simple' }
   ]
   const visiablityOptions = [
      { name: t('show'), value: '1' },
      { name: t('not.show'), value: '0' }
   ]
   const statusOptions = [
      { name: t('in.stock'), value: 'available' },
      { name: t('out.stock'), value: 'notavailable' }
   ]
   const brandsOptions = brands.items.map((brand) => ({
      value: brand._id,
      name: brand.title
   }))

   const isDescriptionChanged = utils.isDescriptionChanged(changedProduct as TModeration)

   const Events = {
      approveButtonClickHandler: () => {
         const galleryChanges = {
            ...changes,
            gallery: changedProduct?.changes?.gallery
               ? changedProduct?.changes?.gallery
               : changedProduct?.product.gallery,
            description: [productDescription]
         }

         const intersection: any[] = Object.values(
            changedProduct?.changes as object
         ).filter(
            (value) => !Object.values(galleryChanges as TChangesProduct).includes(value)
         )

         const moderationDescription: TDescription = {
            ...(productDescription as TDescription),
            lang: changedProduct?.product.description.lang as locales
         }

         if (!beforeChanges && intersection.length) {
            dispatch(
               moderationActions.approveChanges({
                  _id: id as string,
                  changes: {
                     changes: {
                        ...changes,
                        description: [moderationDescription]
                     } as TChangesProduct
                  }
               })
            )
            return
         }

         if (beforeChanges && intersection.length) {
            dispatch(
               moderationActions.approveChanges({
                  _id: id as string,
                  changes: {
                     changes: {
                        ...changes,
                        description: [moderationDescription]
                     } as TChangesProduct
                  }
               })
            )
            return
         }

         if (changedProduct?.product.gallery != productImages) {
            dispatch(
               moderationActions.approveChanges({
                  _id: id as string,
                  changes: { changes: changes as TChangesProduct }
               })
            )
            return
         }

         dispatch(moderationActions.approveChanges({ _id: id as string }))
      },
      declineButtonClickHandler: () => {
         dispatch(moderationActions.declineChanges({ _id: id as string }))
      },
      backButtonClickHandler: () => {
         navigate(`/changelog?page=${page}`)
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement

         if (name == 'description') {
            setProductDescription((props) => ({
               ...(props as TDescription),
               description: value
            }))
            setEditedData((props) => ({
               ...(props as TProduct),
               description: productDescription as TDescription
            }))
            setForm((props) => ({
               ...(props as TProduct),
               description: productDescription as TDescription
            }))
         }

         if (name == 'title') {
            setProductDescription((props) => ({
               ...(props as TDescription),
               title: value
            }))
            setEditedData((props) => ({
               ...(props as TProduct),
               description: productDescription as TDescription
            }))
            setForm((props) => ({
               ...(props as TProduct),
               description: productDescription as TDescription
            }))
         }

         if (
            name != 'title' &&
            name != 'description' &&
            name != 'discountPrice' &&
            name != 'price'
         ) {
            setEditedData((props) => ({ ...(props as TProduct), [name]: value }))
            setForm((props) => ({ ...(props as TProduct), [name]: value }))
            setChanges((props) => ({ ...(props as TChangesProduct), [name]: value }))
         }
         if (name == 'discountPrice' || name == 'price') {
            setEditedData((props) => ({ ...(props as TProduct), [name]: +value }))
            setForm((props) => ({ ...(props as TProduct), [name]: +value }))
            setChanges((props) => ({ ...(props as TChangesProduct), [name]: +value }))
         }
      },
      visibilityChangehandler: (e: SyntheticEvent) => {
         const { value } = e.target as HTMLInputElement

         setForm((props) => ({
            ...(props as TProduct),
            show: !!Number(value) ? true : false
         }))
         setEditedData((props) => ({
            ...(props as TProduct),
            show: !!Number(value) ? true : false
         }))
         setChanges((props) => ({
            ...(props as TChangesProduct),
            show: !!Number(value) ? true : false
         }))
      },
      typeChangehandler: (e: SyntheticEvent) => {
         const { value } = e.target as HTMLInputElement

         setForm((props) => ({
            ...(props as TProduct),
            type: value as 'variated' | 'simple'
         }))
         setChanges((props) => ({
            ...(props as TChangesProduct),
            type: value as 'variated' | 'simple'
         }))
         setEditedData((props) => ({ ...(props as TProduct), productType: value }))
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement

         setProductDescription((props) => ({
            ...(props as TDescription),
            metaDescription: input.value
         }))
         setEditedData((props) => ({
            ...(props as TProduct),
            description: productDescription as TDescription
         }))
         setForm((props) => ({
            ...(props as TProduct),
            description: productDescription as TDescription
         }))
      },
      onKeywordsChangeHandler: (metaKeywords: string[]) => {
         if (metaKeywords.length > 0) {
            if (productDescription?.title) {
               setProductDescription((props) => ({
                  ...(props as TDescription),
                  metaKeywords: metaKeywords
               }))
               setEditedData((props) => ({
                  ...(props as TProduct),
                  description: productDescription as TDescription
               }))
               setChanges((props) => ({
                  ...(props as TChangesProduct),
                  description: [productDescription as TDescription]
               }))
            }

            setForm((props) => ({
               ...(props as TProduct),
               description: {
                  ...(productDescription as TDescription),
                  metaKeywords: metaKeywords
               }
            }))
         }
      },
      onUploadPhoto: (e: any) => {
         setProductImages((prev: any) => [
            ...prev,
            {
               order: prev.length + 1,
               image: URL.createObjectURL(e.target.files[0]),
               preview: URL.createObjectURL(e.target.files[0])
            }
         ])

         const data = new FormData()

         data.append('gallery', e.target.files[0])

         dispatch(productActions.createPhotoProduct({ data }))
      },
      onDeletePhoto: (order: number) => {
         setProductImages((prev: ProductImage[]) =>
            prev?.filter((photo: ProductImage) => {
               return photo.order !== order
            })
         )
      },
      beforeChangesClickHandler: () => {
         setBeforeChanges(!beforeChanges)
      }
   }

   const Requests = {
      getChangedProduct: () => {
         dispatch(moderationActions.getChangedProduct({ _id: id as string }))
      },
      getBrands: () => {
         dispatch(brandsActions.getBrands({ limit: 10000 }))
      },
      patchChanges: () => {
         dispatch(
            moderationActions.patchChanges({
               _id: id as string,
               data: editedData as TProduct
            })
         )
      },
      getCategories: () => {
         dispatch(categoryActions.getCategories({ limit: 1000 }))
      }
   }

   useEffect(() => {
      setProductImages((prev) =>
         prev?.map((photo: any, index) => {
            if (index === prev.length - 1) {
               return { image: photoProduct.data.gallery[0], order: index + 1 }
            }

            return { ...photo, order: index + 1 }
         })
      )
   }, [photoProduct])

   useEffect(() => {
      if (productImages?.length) {
         setEditedData((props) => ({
            ...(props as TProduct),
            gallery: productImages
         }))
         setChanges((props) => ({
            ...(props as TChangesProduct),
            gallery: productImages
         }))
         setForm((props) => ({
            ...(props as TProduct),
            gallery: productImages
         }))
         return
      }
      setForm((props) => ({
         ...(props as TProduct),
         gallery: productImages
      }))
   }, [productImages])

   useEffect(() => {
      changedProduct && setForm(changedProduct.product)

      changedProduct?.changes?.gallery &&
         setProductImages(changedProduct?.changes?.gallery)

      changedProduct?.product?.gallery &&
         (!changedProduct?.changes?.gallery ||
            changedProduct?.changes?.gallery.length == 0) &&
         setProductImages(changedProduct?.product?.gallery)

      changedProduct && setChanges(changedProduct.changes)

      if (!changedProduct?.changes?.description)
         changedProduct?.product?.description &&
            setProductDescription(changedProduct.product.description)

      if (changedProduct?.changes?.description && changedProduct?.product?.description) {
         const changedDescription = changedProduct.changes.description[0]
         const productDescription = changedProduct.product.description

         setProductDescription(Object.assign({}, productDescription, changedDescription))

         return
      }
   }, [changedProduct])

   useEffect(() => {
      productDescription &&
         !beforeChanges &&
         setChanges((props) => ({
            ...(props as TChangesProduct),
            description: [productDescription as TDescription]
         }))
   }, [productDescription])

   useEffect(() => {
      if (response == MODERATION_RESPONSE.EDITED) {
         Requests.getChangedProduct()
      }
   }, [response])

   useEffect(() => {
      Requests.getChangedProduct()
      Requests.getBrands()
      Requests.getCategories()
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('field.changes')}
               backButtonClickHandler={Events.backButtonClickHandler}
               clickbleText={true}
               buttonsList={
                  <>
                     {changedProduct?.approved == null && (
                        <>
                           {!beforeChanges && (
                              <ClickbleText onClick={Events.beforeChangesClickHandler}>
                                 {t('before.changes')}
                              </ClickbleText>
                           )}
                           {beforeChanges && (
                              <ClickbleText
                                 style={{ width: '135px' }}
                                 onClick={Events.beforeChangesClickHandler}>
                                 {t('after.changes')}
                              </ClickbleText>
                           )}
                           <Button
                              theme="red"
                              onClick={Events.declineButtonClickHandler}
                              width={210}>
                              {t('decline.changes')}
                           </Button>
                           <Button
                              theme="green"
                              onClick={Events.approveButtonClickHandler}>
                              {t('approve.changes')}
                           </Button>
                        </>
                     )}
                     {changedProduct?.approved != null && (
                        <p>
                           {t(
                              changedProduct.approved
                                 ? 'approved'
                                 : changedProduct.approved != null
                                 ? 'not.approved'
                                 : 'default.status'
                           )}
                        </p>
                     )}
                  </>
               }
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(form?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(form?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }
         loading={loading}>
         <Container>
            <FormContainer>
               <FlexContainer gap="20px 62px">
                  <DropDownList
                     width="30%"
                     name="type"
                     label={t('product.type')}
                     placeholder={t('choose.type')}
                     options={productTypeOptions}
                     value={type}
                     onChange={Events.typeChangehandler}
                     changedProduct={changes?.type ? true : false}
                     disabled={changedProduct?.product?.type == 'simple' ? false : true}
                  />
                  <DropDownList
                     name="visiablity"
                     label={t('visiablity')}
                     options={visiablityOptions}
                     value={
                        visability
                           ? visiablityOptions[0].value
                           : visiablityOptions[1].value
                     }
                     onChange={Events.visibilityChangehandler}
                     changedProduct={changedProduct?.changes?.show}
                     disabled={beforeChanges}
                  />
                  <Input
                     name="barcode"
                     type="text"
                     label={t('product.code')}
                     placeholder={t('enter.code')}
                     value={barcode}
                     changedProduct={changedProduct?.changes?.barcode ? true : false}
                     onChange={Events.inputChangeHandler}
                     disabled={beforeChanges}
                  />
               </FlexContainer>
               <FlexContainer width="100%" gap="20px 62px">
                  <Input
                     name="title"
                     width="63%"
                     label={t('title')}
                     placeholder={t('enter.title')}
                     value={title}
                     changedProduct={isDescriptionChanged?.title ? true : false}
                     onChange={Events.inputChangeHandler}
                     disabled={beforeChanges}
                  />
                  <DropDownList
                     width="30%"
                     name="brand"
                     label={t('brand')}
                     placeholder={t('choose.brand')}
                     options={brandsOptions}
                     value={brand}
                     changedProduct={changedProduct?.changes?.brand ? true : false}
                     onChange={Events.inputChangeHandler}
                     disabled={beforeChanges}
                  />
               </FlexContainer>
               <FlexContainer>
                  <Textarea
                     name="description"
                     height="271px"
                     placeholder={t('enter.description')}
                     label={t('description')}
                     onChange={Events.inputChangeHandler}
                     changedProduct={isDescriptionChanged?.description ? true : false}
                     disabled={beforeChanges}>
                     {description}
                  </Textarea>
               </FlexContainer>

               <FlexContainer gap="30px">
                  <FlexContainer gap="20px 62px">
                     <Input
                        name="price"
                        type="number"
                        label={t('price')}
                        placeholder={t('enter.price')}
                        value={`${price}`}
                        changedProduct={
                           changedProduct?.changes?.price?.toString() ? true : false
                        }
                        onChange={Events.inputChangeHandler}
                        error={t('123')}
                        disabled={beforeChanges}
                     />
                     <Input
                        name="discountPrice"
                        type="number"
                        label={t('discount.price')}
                        placeholder={t('enter.discount.price')}
                        value={discountPrice + ''}
                        changedProduct={
                           changedProduct?.changes?.discountPrice?.toString()
                              ? true
                              : false
                        }
                        onChange={Events.inputChangeHandler}
                        error={t('Discount price can`t be higher than price')}
                        disabled={beforeChanges}
                     />
                  </FlexContainer>
                  <FlexContainer gap="20px 62px">
                     <Input
                        name="amount"
                        type="number"
                        label={t('amount')}
                        placeholder={t('enter.amount')}
                        value={amount + ''}
                        changedProduct={
                           changedProduct?.changes?.amount?.toString() ? true : false
                        }
                        onChange={Events.inputChangeHandler}
                        disabled={beforeChanges}
                     />
                     <DropDownList
                        name="sellStatus"
                        label={t('status')}
                        options={statusOptions}
                        placeholder={t('enter.status')}
                        value={status}
                        changedProduct={
                           changedProduct?.changes?.sellStatus ? true : false
                        }
                        onChange={Events.inputChangeHandler}
                        disabled={beforeChanges}
                     />
                  </FlexContainer>

                  {metaKeywords == undefined && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        placeholder={t('meta.keywords')}
                        value={metaKeywords || []}
                        isChanged={isDescriptionChanged?.metaKeywords ? true : false}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
                  {metaKeywords && metaKeywords?.length == 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        placeholder={t('meta.keywords')}
                        value={metaKeywords || []}
                        isChanged={isDescriptionChanged?.metaKeywords ? true : false}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
                  {metaKeywords && metaKeywords?.length > 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        placeholder={t('meta.keywords')}
                        value={metaKeywords}
                        isChanged={isDescriptionChanged?.metaKeywords ? true : false}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}

                  <Textarea
                     name="metaDescription"
                     width="100%"
                     height="140px"
                     label={t('meta.description')}
                     onChange={Events.onChange}
                     placeholder={t('meta.description')}
                     changedProduct={isDescriptionChanged?.metaDescription ? true : false}
                     disabled={beforeChanges}>
                     {metaDescription}
                  </Textarea>
               </FlexContainer>
            </FormContainer>

            <FlexContainer
               direction="column"
               gap="30px"
               style={{ marginTop: '8px', padding: '24px 30px' }}>
               <DropDownList
                  name="category"
                  label={t('category')}
                  options={categories.data.map((item) => ({
                     name: item.title,
                     value: item._id
                  }))}
                  value={category}
                  placeholder={t('choose.category')}
                  width="100%"
                  onChange={Events.inputChangeHandler}
                  changedProduct={changedProduct?.changes?.category ? true : false}
                  disabled={beforeChanges}
               />
               <Photos
                  gallery={gallery}
                  preview={
                     changedProduct?.changes?.preview
                        ? changedProduct?.changes?.preview
                        : changedProduct?.product?.preview
                  }
                  uploadPhoto={Events.onUploadPhoto}
                  deletePhoto={Events.onDeletePhoto}
               />
            </FlexContainer>
         </Container>
      </AdminPanelContainer>
   )
}
