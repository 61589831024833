import { colors } from 'enums'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: space-between;
   height: 115px;
`
export const NavButton = styled(Link)`
   width: 195px;
   height: 46px;
   display: flex;
   background-color: ${colors.green};
   border: none;
   text-decoration: none;
   border-radius: 8px;
   align-items: center;
   justify-content: center;
   color: ${colors.solid_white};
   transition: background-color 0.3s;
   svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
   }

   &:hover {
      background-color: #4caa55;
   }
`
