import styled from 'styled-components'
import { assets } from '../../assets'
import { colors } from '../../enums'
import { BORDER, FLEX, FONT } from '../../utils'
import { TContainer, TSelectList } from './types'

export const Container = styled.div<TContainer>`
   ${FLEX({ direction: 'column', align: 'flex-start' })}
   width: ${({ width }) => width};
`

export const SelectList = styled.select<TSelectList>`
   width: 100%;
   height: ${({ height }) => height};

   ${BORDER({ color: colors.border_grey_color })}
   border-radius: 8px;

   ${FONT({ size: '16px' })}
   line-height: 19px;

   cursor: pointer;
   padding-left: ${({ textAlign }) => (textAlign ? '50px' : '14px')};
   padding-right: 14px;

   -webkit-appearance: none;
   -moz-appearance: none;
   background-image: ${({ whiteArrow }) =>
      whiteArrow ? `url(${assets.WHITE_ARROW_DOWN})` : `url(${assets.ARROW_DOWN})`};
   background-repeat: no-repeat;
   background-position-x: ${({ whiteArrow }) => (whiteArrow ? '90%' : '97%')};
   background-position-y: ${({ whiteArrow }) => (whiteArrow ? '20px' : '10px')};
   text-overflow: ellipsis;

   background-color: ${({ changedProduct }) => (changedProduct ? '#EEF4FA' : '')};
`

export const Option = styled.option`
   cursor: pointer !important;
   height: 100px;
`
