import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../../hooks'
import { clientActions, getMainClientSelector } from '../../../../store'
import Moment from 'react-moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ITEM_PER_PAGE } from './consts'
import { api } from '../../../../config'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FiltersWrapper,
   FlexContainer,
   Header,
   HeaderWrapper,
   Pagination,
   ProfileImage,
   SearchBar,
   SubPagesBar,
   Table,
   TColumnTable,
   TDataTable,
   TSection,
   TSort
} from 'components'

const IndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { clients, loading, filters } = useTypedSelector(getMainClientSelector)

   const [page, setPage] = useState<number>(0)
   const [searchParams, setSearchParams] = useSearchParams({})
   const [searchForm, setSearchForm] = useState({
      searchValue: filters.searchValue ? filters.searchValue : '',
      dateStart: filters.dateStart ? filters.dateStart : '',
      dateEnd: filters.dateEnd ? filters.dateEnd : ''
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const Requests = {
      getClients: () => {
         dispatch(
            clientActions.getClients({
               limit: ITEM_PER_PAGE,
               ...searchForm,
               ...sortParamsForm,
               page
            })
         )
      }
   }

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      onSubmitSearchHandler: (e: SyntheticEvent) => {
         e.preventDefault()
         Requests.getClients()
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      createButtonClickHandler: () => {
         navigate('./create')
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(clientActions.setFilters({ ...searchForm }))
         clients && navigate(`./${clients.data[index]._id}`)
      },
      resetFilters: () => {
         dispatch(
            clientActions.setFilters({ searchValue: '', dateStart: '', dateEnd: '' })
         )
         window.location.reload()
      }
   }

   const data: TDataTable[] = useMemo(
      () =>
         clients.data.map((client) => {
            const register_date = new Date(client.createdAt)
            return {
               id: client.id,
               photo: (
                  <ProfileImage
                     src={`${
                        client.picture?.includes('http')
                           ? client.picture
                           : `${api.static}/customer/${client.picture}`
                     }`}
                  />
               ),
               name: client.name,
               contacts: client.email,
               phone: client.phone,
               createdAt: <Moment format="DD.MM.YYYY HH:mm">{register_date}</Moment>,
               client_status: t('b2cClient'),
               orders: client.orders
            }
         }),
      [clients]
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('clients.id'),
            accessor: 'id', // accessor is the "key" in the data
            width: 300
         },
         {
            Header: t('photo'),
            accessor: 'photo',
            width: 50
         },
         {
            Header: t('client'),
            accessor: 'name',
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('contacts'),
            accessor: 'contacts',
            width: 200
         },
         {
            Header: t('phone'),
            accessor: 'phone',
            width: 200
         },
         {
            Header: t('registration.date'),
            accessor: 'createdAt',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('client.status'),
            accessor: 'client_status',
            width: 300
         },
         {
            Header: t('count.orders'),
            accessor: 'orders'
         }
      ],
      []
   )

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('b2cClients'),
            active: true
         },
         {
            title: t('b2bClients'),
            onClickHandler: () => {
               navigate('/businessClients')
            }
         },
         {
            title: t('b2bRequest'),
            onClickHandler: () => {
               navigate('/businessCustomerRequest')
            }
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getClients()
      setSearchParams({ ...searchParams, page: '' + (page + 1) })
   }, [page, tableSortBy, searchForm])

   // Set default page uri
   useEffect(() => {
      setPage(
         !isNaN(parseInt(searchParams.get('page') as string))
            ? parseInt(searchParams.get('page') as string) - 1
            : 0
      )

      dispatch(clientActions.setFilters({ searchValue: '', dateStart: '', dateEnd: '' }))
   }, [])

   return (
      <AdminPanelContainer
         Header={<Header title={t('manage.clients')} />}
         loading={loading}>
         <SubPagesBar sections={sections} />
         <FlexContainer gap="30px" direction="column">
            <HeaderWrapper>
               <FiltersWrapper>
                  <SearchBar
                     name="searchValue"
                     placeholder={t('search')}
                     value={searchForm.searchValue}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />

                  <DateInput
                     name="dateStart"
                     value={searchForm.dateStart}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="dateEnd"
                     value={searchForm.dateEnd}
                     onChange={Events.onChangeHandler}
                  />
                  <div style={{}}>
                     <Button style="transparant" onClick={Events.resetFilters}>
                        {t('reset.filters')}
                     </Button>
                  </div>
               </FiltersWrapper>

               <DataTotal>
                  {t('total.clients')}: {clients.meta?.totalCount}
               </DataTotal>
            </HeaderWrapper>

            <Table
               columns={columns}
               data={data}
               sortBy={tableSortBy}
               editable
               editClickHandler={Events.editButtonClickHandler}
            />
         </FlexContainer>

         <Pagination
            page={page}
            pageCount={
               clients.meta ? Math.ceil(clients.meta.totalCount / ITEM_PER_PAGE) : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />
      </AdminPanelContainer>
   )
}

export default IndexPage
