import { call, put, take, takeLatest } from 'redux-saga/effects'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { adminActions } from './action'
import { ApiAdminService } from './api.service'
import { RESPONSE } from './consts'
import {
   TAdminResponse,
   TCreateAdminPayload,
   TEditAdminPayload,
   TGetAdminPayload,
   TGetAdminsPayload,
   TRemoveAdminPayload
} from './types'

function* getAdminsWorker({ payload }: TDataWrapper<TGetAdminsPayload>) {
   yield put(adminActions.setAdminState({ loading: true }))
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiAdminService, ApiAdminService.getAdmins], { ...payload, token })
      if (response.data.data) yield put(adminActions.setAdmins(response.data))
   } catch (e) {
      yield put(adminActions.setAdmins({ data: [], meta: null }))
   }

   yield put(adminActions.setAdminState({ loading: false }))
}

function* createAdminWorker({ payload }: TDataWrapper<TCreateAdminPayload>) {
   yield put(adminActions.setAdminState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiAdminService, ApiAdminService.createAdmin], { ...payload, token })
      if (response.data.data) yield put(adminActions.setAdmin({ ...response.data.data }))
   } catch (e) {
      yield put(adminActions.setAdmin(null))
   }

   yield put(adminActions.setAdminState({ loading: false, response: RESPONSE.CREATED as TAdminResponse }))
}

function* removeAdminWorker({ payload }: TDataWrapper<TRemoveAdminPayload>) {
   yield put(adminActions.setAdminState({ loading: true }))

   const { _id } = payload
   const token: string = yield call(getAccessToken)

   try {
      yield call([ApiAdminService, ApiAdminService.removeAdmin], { token, _id })

      yield put(adminActions.setAdmins({ data: [], meta: { needReload: true } }))
      yield put(adminActions.setAdmin({ notFound: true }))
   } catch (e) {}

   yield put(adminActions.setAdminState({ loading: false }))
}

function* getAdminWorker({ payload }: TDataWrapper<TGetAdminPayload>) {
   yield put(adminActions.setAdminState({ loading: true }))

   const { _id } = payload
   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call([ApiAdminService, ApiAdminService.getAdmin], { _id, token })
      if (response.data.data) yield put(adminActions.setAdmin(response.data.data))
      yield put(adminActions.setAdminState({ loading: false }))
   } catch (e) {
      yield put(adminActions.setAdminState({ loading: false, response: RESPONSE.NOT_FOUNDED as TAdminResponse }))
   }
}

function* editAdminWorker({ payload }: TDataWrapper<TEditAdminPayload>) {
   yield put(adminActions.setAdminState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { data, _id } = payload

   try {
      yield call([ApiAdminService, ApiAdminService.editAdmin], { token, data, _id })
   } catch (e) {}

   yield put(adminActions.setAdminState({ loading: false, response: RESPONSE.EDITED as TAdminResponse }))
}

export function* adminWatcher() {
   yield takeLatest(adminActions.getAdmins, getAdminsWorker)
   yield takeLatest(adminActions.createAdmin, createAdminWorker)
   yield takeLatest(adminActions.removeAdmin, removeAdminWorker)
   yield takeLatest(adminActions.getAdmin, getAdminWorker)
   yield takeLatest(adminActions.editAdmin, editAdminWorker)
}
