import { AdminPanelContainer } from 'components'
import { ButtonSave, HeaderWithArrow } from '../CreateCalculator/styled'
import { IoIosArrowBack } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { Header } from 'pages/Calculator/components'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiCalculatorService, getMainCategorySelector, useGetCalculator } from 'store'
import { Question, TopInputs } from './components'
import { TEditCalculatorForm } from './types'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTypedSelector } from 'hooks'
import { useEffect } from 'react'
import { createData, setDefaultValue, useGetProducts } from './helpers'
import { TProduct } from 'store/product/types'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const EditCalculator = () => {
   const { id } = useParams()
   const { data, mutate } = useGetCalculator(id)
   const navigate = useNavigate()
   const { t, i18n } = useTranslation()
   const {
      handleSubmit,
      register,
      setValue,
      control,
      watch,
      formState: { errors }
   } = useForm<TEditCalculatorForm>()
   const { accessToken } = useTypedSelector((state) => state.user)

   const currentValue = data?.data

   const mainAnswersProducts = data?.data?.answers
      ?.flatMap((el) =>
         el.products?.flatMap((item) => [
            item.economproduct,
            item.premiumproduct,
            item.standartproduct
         ])
      )
      .filter((pr): pr is string => typeof pr === 'string')

   const childAnswersProducts = data?.data?.childQuestions
      ?.flatMap((child) => child.answers)
      ?.flatMap((answer) =>
         answer.products
            ?.map((product) => [
               product.economproduct,
               product.premiumproduct,
               product.standartproduct
            ])
            .flat()
      )
      .flat()
      .filter((item): item is string => typeof item === 'string')

   const arrayProducts = [
      ...new Set([...(mainAnswersProducts ?? []), ...(childAnswersProducts ?? [])])
   ]

   const { products } = useGetProducts(arrayProducts)

   const productsInAnswers = products?.data?.data as TProduct[] | undefined

   const { categories } = useTypedSelector(getMainCategorySelector)
   const isReady =
      !!categories?.data.length &&
      !!currentValue &&
      (arrayProducts?.length ? !!productsInAnswers : true)

   useEffect(() => {
      if (isReady) {
         setDefaultValue({
            setValue,
            categories: categories.data,
            currentValue,
            productsInAnswers
         })
      }
   }, [isReady])

   const onSubmit: SubmitHandler<TEditCalculatorForm> = async (data) => {
      const newData = createData(data)

      if (newData) {
         const arrayOldIds = [
            currentValue?._id || '',
            ...(currentValue?.childQuestions?.map((item) => item._id) || [])
         ].filter(Boolean)

         if (
            newData?.length === arrayOldIds?.length &&
            newData?.every((el) => arrayOldIds.includes(el._id))
         ) {
            try {
               await ApiCalculatorService.editQuestionCalculatorBulk(
                  newData,
                  accessToken || ''
               )
               toast.success(t('questionUpdatedSuccessfully'))
               mutate()
               navigate(-1)
            } catch (error) {
               toast.error(t('anErrorHasOccurred'))
            }
         } else {
            try {
               const deletedAray = arrayOldIds.filter(
                  (el) => !newData.find((item) => item._id === el)
               )
               const createdArray = newData
                  .filter((el) => !arrayOldIds.includes(el._id))
                  .map((item) => item.data)

               const editedArray = newData.filter(
                  (item) => !createdArray.find((el) => el._id === item._id)
               )
               for (const deleteId of deletedAray) {
                  await ApiCalculatorService.deleteCalculator(deleteId, accessToken || '')
               }
               await ApiCalculatorService.editQuestionCalculatorBulk(
                  editedArray,
                  accessToken || ''
               )
               createdArray.length &&
                  (await ApiCalculatorService.postQuestionCalculatorBulk(
                     createdArray,
                     accessToken || ''
                  ))
               toast.success(t('questionUpdatedSuccessfully'))
               mutate()
               navigate(-1)
            } catch {
               toast.error(t('anErrorHasOccurred'))
            }
         }
      } else {
         toast.info(t('fillInAllProducts'))
      }
   }

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <AdminPanelContainer
            Header={
               <Header
                  title={
                     <HeaderWithArrow to="/calculator">
                        <IoIosArrowBack />
                        {t('editQuestion')}
                     </HeaderWithArrow>
                  }
                  button={<ButtonSave type="submit">{t('save')}</ButtonSave>}
               />
            }>
            <TopInputs
               watch={watch}
               setValue={setValue}
               register={register}
               control={control}
               errors={errors}
            />
            <Question
               setValue={setValue}
               errors={errors}
               watch={watch}
               register={register}
               control={control}
            />
         </AdminPanelContainer>
      </form>
   )
}
