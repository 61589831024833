enum colors {
   solid_white = '#FFFFFF',
   filter_white = 'brightness(100%)',

   solid_black = '#000000',
   filter_black = 'brightness(0%)',

   activeInput = '#637B96',
   lighter_grey = '#D1D1D1',
   light_grey = '#F4F5F7',
   darker_grey = '#F0F7FF',
   grey = '#818181',
   background_panel_grey = '#E5E5E5',
   border_grey_color = '#E3E3E3',
   table_grey = '#F4F4F4',
   colorPicker_grey_color = '#EDEDED',
   gray_light = '#F5F5F5',
   grey1 = '#C7C7C7',
   black = '#222222',
   lighter_black = '#333333',
   blue = '#637B96',
   filter_blue = 'brightness(0) saturate(100%) invert(46%) sepia(43%) saturate(289%) hue-rotate(171deg) brightness(92%) contrast(88%)',

   dark_blue = '#2F4660',
   red = '#BF5E5E',
   filter_red = 'brightness(0) saturate(100%) invert(40%) sepia(5%) saturate(6018%) hue-rotate(314deg) brightness(111%) contrast(80%)',
   filter_grey = 'invert(54%) sepia(0%) saturate(0%) hue-rotate(169deg) brightness(94%) contrast(82%)',

   green = '#5EBF66',
   filter_green = 'brightness(0) saturate(100%) invert(61%) sepia(88%) saturate(277%) hue-rotate(75deg) brightness(91%) contrast(86%)',

   popupBackground = 'rgba(99, 123, 150, 0.4)',

   changedProduct = '#EEF4FA'
}

export default colors
