import { Button, Header } from 'components'
import { t } from 'i18next'
import { FC } from 'react'
import Moment from 'react-moment'
import { THeaderProps } from './types'

export const DiscountHeader: FC<THeaderProps> = ({ Events }) => {
   return (
      <Header
         title={t('edit')}
         backButtonClickHandler={Events.backButtonClickHandler}
         dates={[
            {
               info: t('createdAt.date'),
               date: <Moment format="DD.MM.YYYY HH:mm">{new Date()}</Moment>
            },
            {
               info: t('updatedAt.date'),
               date: <Moment format="DD.MM.YYYY HH:mm">{new Date()}</Moment>
            }
         ]}
         buttonsList={
            <>
               <Button theme="red" height={46} onClick={Events.clearAll}>
                  {t('clear')}
               </Button>

               <Button theme="green" height={46} onClick={Events.saveCustomerDiscount}>
                  {t('save')}
               </Button>
            </>
         }
      />
   )
}
