import { DropDownList, FlexContainer } from 'components'
import { t } from 'i18next'
import { useCategoryHook } from 'pages/Products/hooks'
import { utils } from 'pages/Products/utils'
import { Dispatch, FC, SyntheticEvent, useEffect, useState } from 'react'
import { TCategories } from 'store/category/types'
import { TCategory, TProduct } from 'store/product/types'

type CategoriesProps = {
   form: TProduct & { sectionId?: string; categoryId?: string; subcategoryId?: string }
   editedData: TProduct
   productCategory: TCategory
   categories: TCategories
   sectionChangeHandler: (e: SyntheticEvent) => void
   categoryChangeHandler: (e: SyntheticEvent) => void
   subcategoryChangeHandler: (e: SyntheticEvent) => void
   setForm: Dispatch<
      TProduct & { sectionId?: string; categoryId?: string; subcategoryId?: string }
   >
   setEditedData: Dispatch<TProduct>
}

export const Categories: FC<CategoriesProps> = ({
   form,
   editedData,
   productCategory,
   categories,
   sectionChangeHandler,
   categoryChangeHandler,
   subcategoryChangeHandler,
   setForm,
   setEditedData
}) => {
   const { categoryList } = useCategoryHook({
      category: productCategory as TCategory
   })

   const parentCategoryId = categoryList.slice(-1)[0]?.parent
      ? categoryList.slice(-1)[0]?.parent
      : null

   const updatedCategoryList = parentCategoryId
      ? categoryList
           .concat(categories.data.filter((item) => item._id == parentCategoryId)[0])
           .filter((item) => item)
      : categoryList.filter((item) => item)

   const subcategoryFilter = (index: number) => {
      if (updatedCategoryList.length >= 2) {
         return index - 1
      }
      return index
   }

   const sectionId = (category: TCategory) =>
      form?.sectionId ? form?.sectionId : (category.parent as string)

   const sectionLength = (category: TCategory) =>
      categories.data.filter((item) => item.parent == sectionId(category)).length

   useEffect(() => {
      setForm({ ...form, categoryId: '', subcategoryId: '' })
   }, [form?.sectionId])

   useEffect(() => {
      if (form?.sectionId)
         setEditedData({ ...editedData, category: form?.sectionId as any })
   }, [form?.sectionId])

   useEffect(() => {
      if (form?.categoryId)
         setEditedData({ ...editedData, category: form?.categoryId as any })
      setForm({ ...form, subcategoryId: '' })
   }, [form?.categoryId])

   useEffect(() => {
      if (form?.subcategoryId)
         setEditedData({ ...editedData, category: form?.subcategoryId as any })
   }, [form?.subcategoryId])

   useEffect(() => {
      setForm({ ...form, sectionId: '', categoryId: '', subcategoryId: '' })
   }, [])

   return (
      <FlexContainer direction="column" gap="30px" margin="8px 0 0 0" padding="24px 30px">
         {/* default three categories */}

         {!form?.sectionId &&
            updatedCategoryList.length > 2 &&
            updatedCategoryList.reverse().map((category, index) => {
               if (category?.parent == null && !form?.sectionId) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="section"
                        label={t('section')}
                        placeholder={t('choose.section')}
                        options={categories.data
                           .filter((item) => item.parent == null)
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={form?.sectionId ? form?.sectionId : category?._id}
                        onChange={sectionChangeHandler}
                     />
                  )
               }

               if (
                  typeof category?.parent == 'string' &&
                  sectionLength(category) &&
                  (!form?.categoryId || !form?.sectionId)
               ) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="category"
                        label={t('category')}
                        placeholder={t('choose.category')}
                        options={categories.data
                           .filter((item) => item.parent == sectionId(category))
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={form?.categoryId ? form?.categoryId : category._id}
                        onChange={categoryChangeHandler}
                     />
                  )
               }

               if (
                  typeof category?.parent == 'object' &&
                  !form?.categoryId &&
                  !form?.subcategoryId
               ) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="subcategory"
                        label={t('subcategory')}
                        placeholder={t('choose.subcategory')}
                        options={categories.data
                           .filter(
                              (item) =>
                                 item.parent ==
                                 updatedCategoryList[subcategoryFilter(index)]?._id
                           )
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={category._id}
                        onChange={subcategoryChangeHandler}
                     />
                  )
               }

               // changed categoryId and not changed subcategory

               if (
                  form?.categoryId &&
                  categories.data.filter((item) => item.parent == form.categoryId)
                     .length &&
                  !form?.subcategoryId
               ) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="subcategory"
                        label={t('subcategory')}
                        placeholder={t('choose.subcategory')}
                        options={categories.data
                           .filter((item) => item.parent == form.categoryId)
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={form?.subcategoryId}
                        onChange={subcategoryChangeHandler}
                     />
                  )
               }

               // changed subcategoryID and not changed categoryId

               if (form?.subcategoryId && !form?.categoryId) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="subcategory"
                        label={t('subcategory')}
                        placeholder={t('choose.subcategory')}
                        options={categories.data
                           .filter(
                              (item) =>
                                 item.parent ==
                                 updatedCategoryList[subcategoryFilter(index)]?._id
                           )
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={form?.subcategoryId}
                        onChange={subcategoryChangeHandler}
                     />
                  )
               }

               //changed subcategory and category

               if (form?.subcategoryId && form?.categoryId) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="subcategory"
                        label={t('subcategory')}
                        placeholder={t('choose.subcategory')}
                        options={categories.data
                           .filter((item) => item.parent == form.categoryId)
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={form?.subcategoryId}
                        onChange={subcategoryChangeHandler}
                     />
                  )
               }
            })}

         {/* default two categories */}

         {!form?.sectionId &&
            updatedCategoryList.length == 2 &&
            updatedCategoryList.reverse().map((category, index) => {
               if (category?.parent == null && !form?.sectionId) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="section"
                        label={t('section')}
                        placeholder={t('choose.section')}
                        options={categories.data
                           .filter((item) => item.parent == null)
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={form?.sectionId ? form?.sectionId : category?._id}
                        onChange={sectionChangeHandler}
                     />
                  )
               }

               if (
                  typeof category?.parent == 'object' &&
                  (!form?.sectionId || !form?.categoryId)
               ) {
                  return (
                     <DropDownList
                        width="100%"
                        key={index}
                        name="category"
                        label={t('category')}
                        placeholder={t('choose.category')}
                        options={categories.data
                           .filter(
                              (item) =>
                                 item.parent ==
                                 updatedCategoryList[subcategoryFilter(index)]?._id
                           )
                           .map((category) => ({
                              name: category.title,
                              value: category._id
                           }))}
                        value={form?.categoryId ? form?.categoryId : category._id}
                        onChange={categoryChangeHandler}
                     />
                  )
               }
            })}

         {/* default one category */}

         {updatedCategoryList.length == 1 &&
            updatedCategoryList.map((category) => (
               <DropDownList
                  width="100%"
                  name="section"
                  label={t('section')}
                  placeholder={t('choose.section')}
                  options={categories.data
                     .filter((item) => item.parent == null)
                     .map((category) => ({
                        name: category.title,
                        value: category._id
                     }))}
                  value={form?.sectionId ? form.sectionId : category._id}
                  onChange={sectionChangeHandler}
               />
            ))}

         {/* setted categories */}

         {updatedCategoryList.length != 1 && form?.sectionId && (
            <DropDownList
               width="100%"
               name="section"
               label={t('section')}
               placeholder={t('choose.section')}
               options={categories.data
                  .filter((item) => item.parent == null)
                  .map((category) => ({
                     name: category.title,
                     value: category._id
                  }))}
               value={form?.sectionId}
               onChange={sectionChangeHandler}
            />
         )}
         {form?.sectionId && (
            <DropDownList
               width="100%"
               name="category"
               label={t('category')}
               placeholder={t('choose.category')}
               options={categories.data
                  .filter((item) => item.parent == form.sectionId)
                  .map((category) => ({
                     name: category.title,
                     value: category._id
                  }))}
               value={form?.categoryId}
               onChange={categoryChangeHandler}
            />
         )}
         {form?.sectionId &&
            form?.categoryId &&
            categories.data.filter((item) => item.parent == form.categoryId).length >
               0 && (
               <DropDownList
                  width="100%"
                  name="subcategory"
                  label={t('subcategory')}
                  placeholder={t('choose.subcategory')}
                  options={categories.data
                     .filter((item) => item.parent == form.categoryId)
                     .map((category) => ({
                        name: category.title,
                        value: category._id
                     }))}
                  value={form?.subcategoryId}
                  onChange={subcategoryChangeHandler}
               />
            )}
      </FlexContainer>
   )
}
