import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   KeywordsInput,
   SubPagesBar,
   Textarea,
   TSection
} from '../../../../../../components'
import { useLocalization, useTypedSelector } from '../../../../../../hooks'
import {
   brandsActions,
   categoryActions,
   getBrandSelector,
   getMainProductSelector,
   productActions
} from '../../../../../../store'
import { Photos } from './components/Photos'
import { ProductImage } from './components/Photos/types'
import { PAGE_LIMIT } from './consts'
import { FormContainer, Container } from './styled'
import { TForm } from './types'

export const EditCategoryProductIndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const {
      product,
      loading: productLoading,
      photoProduct,
      subcategories
   } = useTypedSelector(getMainProductSelector)

   const { brands } = useTypedSelector(getBrandSelector)
   const [locale] = useLocalization()

   const { id } = useParams()

   const productTypeOptions = [
      { name: t('variated.product'), value: 'variated' },
      { name: t('single.product'), value: 'simple' }
   ]
   const visiablityOptions = [
      { name: t('show'), value: '1' },
      { name: t('not.show'), value: '0' }
   ]
   const statusOptions = [
      { name: t('in.stock'), value: 'available' },
      { name: t('out.stock'), value: 'notavailable' }
   ]
   const brandsOptions = brands.items.map((brand) => ({
      value: brand._id,
      name: brand.title
   }))

   const [brandOptions, setBrandOptions] = useState<{ name: string; value: string }[]>([
      { name: '', value: '' }
   ])

   const [sectionsOption, setSections] = useState<{ name: string; value: string }[]>([
      { name: '', value: '' }
   ])

   const [categoryOption, setCategories] = useState<{ name: string; value: string }[]>([
      {
         name: '',
         value: ''
      }
   ])

   const [subCategoryOption, setSubCategories] = useState<
      { name: string; value: string }[]
   >([{ name: '', value: '' }])

   const [sectionValue, setSectionValue] = useState('')

   const [categoryValue, setCategoryValue] = useState('')

   const [subcategoryValue, setSubCategoryValue] = useState('')

   const [brandValue, setBrandValue] = useState('')

   const [saveId, setSaveId] = useState('')

   const [productImages, setProductImages] = useState<ProductImage[]>([])

   const [form, setForm] = useState<TForm>({
      productType: '',
      show: true,
      barcode: '',
      name: '',
      description: '',
      category: '',
      price: '',
      amount: '',
      discountPrice: '',
      status: '',
      metaKeywords: [],
      metaDescription: '',
      brand: '',
      gallery: []
   })

   const schema = {
      discount: {
         condition:
            Number(form.discountPrice) >= 0 &&
            Number(form.discountPrice) < Number(form.price)
      }
   }

   const Events = {
      backButtonClickHandler: () => {
         navigate(`/catalog/categories/${product?.category._id as string}`)
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement

         if (name === 'section') {
            setSectionValue(value)
            setSaveId(value)
         }

         if (name === 'category') {
            setCategoryValue(value)
            setSaveId(value)
         }

         if (name === 'subcategory') {
            setSubCategoryValue(value)
            setSaveId(value)
         }

         if (name === 'brand') {
            setBrandValue(value)
         }

         setForm((props) => ({ ...props, [name]: value }))
      },
      visibilityChangehandler: (e: SyntheticEvent) => {
         const { value } = e.target as HTMLInputElement

         setForm((props) => ({ ...props, show: !!Number(value) ? true : false }))
      },
      typeChangehandler: (e: SyntheticEvent) => {
         const { value } = e.target as HTMLInputElement

         setForm((props) => ({ ...props, productType: value }))
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement

         setForm({ ...form, [input.name]: input.value })
      },
      onKeywordsChangeHandler: (metaKeywords: string[]) => {
         setForm({
            ...form,
            metaKeywords: metaKeywords
         })
      },
      onUploadPhoto: (e: any) => {
         setProductImages((prev: any) => [
            ...prev,
            {
               order: prev.length + 1,
               image: URL.createObjectURL(e.target.files[0]),
               preview: URL.createObjectURL(e.target.files[0])
            }
         ])

         const data = new FormData()

         data.append('gallery', e.target.files[0])

         dispatch(productActions.createPhotoProduct({ data }))
      },
      onDeletePhoto: (order: number) => {
         setProductImages((prev: ProductImage[]) =>
            prev.filter((photo: ProductImage) => {
               return photo.order !== order
            })
         )
      },
      saveButtonClickHandler: () => {
         Request.editProduct()
      },
      deleteButtonClickHandler: () => {
         Request.removeProduct()
         navigate('../products')
      }
   }

   const Request = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: id as string }))
      },
      getBrands: () => {
         dispatch(brandsActions.getBrands({ limit: 10000 }))
      },
      getCategories: () => {
         dispatch(categoryActions.getCategories({ limit: PAGE_LIMIT }))
      },
      editProduct: () => {
         Utils.isSchemaValid() &&
            dispatch(
               productActions.updateProduct({
                  _id: id as string,
                  data: {
                     sku: form.barcode.length > 2 ? form.barcode : `${product?.barcode}`,
                     sellStatus:
                        form.status.length > 2 ? form.status : product?.sellStatus,
                     discountPrice: form.discountPrice === '' ? 0 : +form.discountPrice,
                     price: form.price ? Number(form.price) : Number(product?.price),
                     show: form.show,
                     category: saveId.length > 2 ? saveId : product?.category._id,
                     type: form.productType,
                     preview: `${product?.preview}`,
                     gallery: productImages,
                     description: [
                        {
                           lang: locale,
                           metaDescription: form.metaDescription,
                           metaKeywords: form.metaKeywords,
                           title:
                              form.name.length > 2
                                 ? form.name
                                 : product?.description.title,
                           description:
                              form.description.length > 2
                                 ? form.description
                                 : product?.description.description,
                           attributes: product?.description.attributes
                        }
                     ],
                     group: product?.group._id,
                     amount: Number(form.amount),
                     brand: form.brand ? form.brand : product?.brand
                  }
               })
            )
      },
      getSubCategories: () => {
         dispatch(productActions.getSubCategories({ _id: categoryValue as string }))
      },
      removeProduct: () => {
         dispatch(productActions.removeProduct({ _id: id as string }))
      }
   }

   const Utils = {
      isSchemaValid: () => {
         let isValid = true
         Object.keys(schema).forEach((prop) => {
            if (!schema[prop as keyof typeof schema].condition) isValid = false
         })

         return isValid
      }
   }

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            active: true
         },
         {
            title: t('characteristics'),
            onClickHandler: () => {
               navigate(`/catalog/characteristics/${id as string}`)
            }
         },
         {
            title: t('related.orders'),
            onClickHandler: () => {
               navigate(`/catalog/related/${id as string}`)
            }
         },
         {
            title: t('variations'),
            onClickHandler: () => {
               navigate(`/catalog/variations/${id as string}`)
            }
         }
      ],
      []
   )

   useEffect(() => {
      Request.getProduct()
      Request.getCategories()
      Request.getBrands()
   }, [])

   useEffect(() => {
      setProductImages((prev) =>
         prev.map((photo: any, index) => {
            if (index === prev.length - 1) {
               return { image: photoProduct.data.gallery[0], order: index + 1 }
            }

            return { ...photo, order: index + 1 }
         })
      )
   }, [photoProduct])

   useEffect(() => {
      product &&
         setForm({
            productType: product.type,
            show: product.show,
            barcode: '' + product.barcode,
            name: product.description?.title,
            description: product.description?.description,
            category: product.category._id,
            price: product.price + '',
            amount: product.amount + '',
            discountPrice: product.discountPrice ? product.discountPrice + '' : '',
            status: product.sellStatus,
            metaKeywords: product.description.metaKeywords,
            metaDescription: product.description.metaDescription,
            brand: product.brand as string,
            gallery: product.gallery
         })
      product?.gallery && setProductImages(product.gallery)

      if (product?.category._id) {
         setSectionValue('')
         setCategoryValue('')
         setSubCategoryValue('')
      }
   }, [product])

   useEffect(() => {
      setCategoryValue('')
   }, [sectionValue])

   useEffect(() => {
      Request.getSubCategories()
   }, [categoryValue])

   useEffect(() => {
      setSubCategories(
         subcategories?.data.map((item) => ({ name: item.title, value: item._id }))
      )
   }, [subcategories])

   useEffect(() => {
      brands &&
         setBrandOptions(
            brands.items.map((item) => ({ name: item.title, value: item._id }))
         )
   }, [brands])

   useEffect(() => {
      if (Number(form.amount) == 0) {
         setForm({
            ...form,
            status: 'notavailable'
         })
      }
      if (Number(form.amount) > 0) {
         setForm({
            ...form,
            status: 'available'
         })
      }
   }, [form.amount])

   return (
      <AdminPanelContainer
         Header={
            <Header
               backButtonClickHandler={Events.backButtonClickHandler}
               title={t('edit')}
               buttonsList={
                  <>
                     <Button theme="red" onClick={Events.deleteButtonClickHandler}>
                        {t('delete')}
                     </Button>
                     <Button theme="green" onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }
         loading={productLoading}>
         <SubPagesBar sections={sections} />
         <Container>
            <FormContainer>
               <FlexContainer gap="20px 62px">
                  <DropDownList
                     width="30%"
                     name="type"
                     label={t('product.type')}
                     options={productTypeOptions}
                     value={
                        form.productType == 'variated'
                           ? productTypeOptions[0].value
                           : productTypeOptions[1].value
                     }
                     onChange={Events.typeChangehandler}
                  />
                  <DropDownList
                     name="visiablity"
                     label={t('visiablity')}
                     options={visiablityOptions}
                     value={
                        form?.show
                           ? visiablityOptions[0].value
                           : visiablityOptions[1].value
                     }
                     onChange={Events.visibilityChangehandler}
                  />
                  <Input
                     name="barcode"
                     type="text"
                     label={t('product.code')}
                     placeholder={t('enter.code')}
                     value={form.barcode}
                     onChange={Events.inputChangeHandler}
                  />
               </FlexContainer>
               <FlexContainer width="100%" gap="20px 62px">
                  <Input
                     name="name"
                     width="64%"
                     label={t('name')}
                     placeholder={t('enter.name')}
                     value={form.name}
                     onChange={Events.inputChangeHandler}
                  />
                  <DropDownList
                     width="30%"
                     name="brand"
                     placeholder={t('choose.brand')}
                     label={t('brand')}
                     options={brandsOptions}
                     value={form.brand ? form.brand : product?.brand}
                     onChange={Events.inputChangeHandler}
                  />
               </FlexContainer>
               <FlexContainer>
                  <Textarea
                     name="description"
                     height="271px"
                     placeholder={t('enter.description')}
                     label={t('description')}
                     onChange={Events.inputChangeHandler}>
                     {form.description}
                  </Textarea>
               </FlexContainer>

               <FlexContainer gap="30px">
                  <FlexContainer gap="20px 62px">
                     <Input
                        name="price"
                        label={t('price')}
                        placeholder={t('enter.price')}
                        value={form?.price + ''}
                        onChange={Events.inputChangeHandler}
                     />
                     <Input
                        name="discountPrice"
                        label={t('discount.price')}
                        placeholder={t('enter.discount.price')}
                        value={form.discountPrice ? form.discountPrice + '' : ''}
                        onChange={Events.inputChangeHandler}
                        isValid={schema.discount.condition}
                        error={t('Discount price can`t be higher than price')}
                     />
                  </FlexContainer>
                  <FlexContainer gap="20px 62px">
                     <Input
                        name="amount"
                        label={t('amount')}
                        placeholder={t('enter.amount')}
                        value={form.amount}
                        onChange={Events.inputChangeHandler}
                     />
                     <DropDownList
                        name="status"
                        label={t('status')}
                        options={statusOptions}
                        placeholder={t('enter.status')}
                        value={form.status}
                        onChange={Events.inputChangeHandler}
                     />
                  </FlexContainer>

                  {form.metaKeywords.length > 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}
                  {form.metaKeywords.length == 0 && (
                     <KeywordsInput
                        label={t('meta.keywords')}
                        value={form.metaKeywords}
                        onChange={Events.onKeywordsChangeHandler}
                     />
                  )}

                  <Textarea
                     name="metaDescription"
                     width="100%"
                     height="140px"
                     label={t('meta.description')}
                     onChange={Events.onChange}
                     placeholder={t('meta.description')}>
                     {form.metaDescription}
                  </Textarea>
               </FlexContainer>
            </FormContainer>

            <FlexContainer direction="column" align="center" gap="">
               <FlexContainer
                  direction="column"
                  gap="30px"
                  style={{ marginTop: '8px', padding: '24px 30px' }}>
                  <DropDownList
                     name="section"
                     label={t('section')}
                     options={sectionsOption}
                     value={
                        sectionValue
                           ? sectionValue
                           : (product?.category.parent as any).parent._id
                     }
                     placeholder={t('choose.section')}
                     width="100%"
                     onChange={Events.inputChangeHandler}
                  />
                  {(product?.category._id || sectionValue) &&
                     (sectionsOption.length ? product : categoryOption.length != 0) && (
                        <DropDownList
                           name="category"
                           label={t('category')}
                           options={
                              categoryOption.length > 1
                                 ? categoryOption
                                 : [
                                      {
                                         name: (product?.category.parent as any)
                                            .title as string,
                                         value: (product?.category.parent as any)
                                            ._id as string
                                      }
                                   ]
                           }
                           value={
                              categoryValue || sectionValue
                                 ? categoryValue
                                 : (product?.category.parent as any)._id
                           }
                           placeholder={t('choose.category')}
                           width="100%"
                           onChange={Events.inputChangeHandler}
                        />
                     )}
                  {(product?.category._id || categoryValue) &&
                     (categoryOption.length === 0
                        ? product
                        : subCategoryOption.length != 0) && (
                        <DropDownList
                           name="subcategory"
                           label={t('subcategory')}
                           options={
                              subCategoryOption.length > 1
                                 ? subCategoryOption
                                 : [
                                      {
                                         name: product?.category.title as string,
                                         value: product?.category._id as string
                                      }
                                   ]
                           }
                           value={
                              subcategoryValue || categoryValue
                                 ? subcategoryValue
                                 : product?.category._id
                           }
                           placeholder={t('choose.subcategory')}
                           width="100%"
                           onChange={Events.inputChangeHandler}
                        />
                     )}
               </FlexContainer>
               <Photos
                  gallery={productImages}
                  preview={product?.preview}
                  uploadPhoto={Events.onUploadPhoto}
                  deletePhoto={Events.onDeletePhoto}
               />
            </FlexContainer>
         </Container>
      </AdminPanelContainer>
   )
}
