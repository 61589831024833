import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   Label,
   PhotoPicker,
   ProfileImage,
   SubPagesBar,
   TSection
} from 'components'
import { useTypedSelector } from 'hooks'
import { t } from 'i18next'
import { AddressLabel, ImageContainer } from 'pages/Clients/pages/Edit/pages/Main/styled'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate, useParams } from 'react-router-dom'
import { clientActions, getMainClientSelector } from 'store'
import { Container } from '../../styled'
import { TForm } from './types'
import { api } from 'config'
import { useDispatch } from 'react-redux'

export const EditBusinessClient: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { id } = useParams()

   const {
      client,
      loading: clientLoading,
      response
   } = useTypedSelector(getMainClientSelector)

   const [form, setForm] = useState<TForm>({
      blocked: false,
      balance: 0,
      createdAt: '',
      email: '',
      id: '',
      name: '',
      orders: 0,
      updatedAt: '',
      _id: '',
      secondName: '',
      picture: '',
      phone: '',
      profilePhoto: ''
   })

   const statusOptions = [{ name: 'business', value: 'business' }]

   const Events = {
      backButtonClickHandler: () => {
         navigate('/businessClients')
      },
      unblockUserButtonClickHandler: () => {
         Requests.unblockUser()
      },
      blockUserButtonClickHandler: () => {
         Requests.blockUser()
      },
      saveButtonClickHandler: () => {},
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      }
   }

   const Requests = {
      getClient: () => {
         dispatch(clientActions.getClient({ _id: id as string }))
      },
      editClient: () => {
         dispatch(clientActions.editClient({ _id: id as string, data: { ...form } }))
      },
      blockUser: () => {
         dispatch(
            clientActions.editClient({
               _id: id as string,
               data: { blocked: true }
            })
         )
      },
      unblockUser: () => {
         dispatch(
            clientActions.editClient({
               _id: id as string,
               data: { blocked: false }
            })
         )
      }
   }
   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            active: true
         },
         {
            title: t('orders.history'),
            onClickHandler: () => {
               // navigate('/')
            }
         },
         {
            title: t('discount.system'),
            onClickHandler: () => {
               navigate(`/discountSystem/${id}`)
            }
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getClient()
   }, [])

   useEffect(() => {
      client &&
         setForm({
            ...form,
            blocked: client.blocked,
            createdAt: client.createdAt,
            email: client.email,
            id: client.id,
            name: client.name,
            orders: client.orders,
            updatedAt: client.updatedAt,
            _id: client._id,
            picture: client.picture,
            secondName: client.secondName,
            phone: client.phone
         })
   }, [client])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('edit')}
               backButtonClickHandler={Events.backButtonClickHandler}
               buttonsList={
                  <>
                     {!client?.blocked && (
                        <Button
                           theme="red"
                           height={46}
                           onClick={Events.blockUserButtonClickHandler}
                           style="transparant">
                           {t('block')}
                        </Button>
                     )}

                     {!!client?.blocked && (
                        <Button
                           theme="green"
                           height={46}
                           onClick={Events.unblockUserButtonClickHandler}
                           style="transparant">
                           {t('unblock')}
                        </Button>
                     )}

                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: <Moment format="DD.MM.YYYY HH:mm">{new Date()}</Moment>
                  },
                  {
                     info: t('updatedAt.date'),
                     date: <Moment format="DD.MM.YYYY HH:mm">{new Date()}</Moment>
                  }
               ]}
            />
         }
         loading={clientLoading}>
         <SubPagesBar sections={sections} />
         <Container>
            <FlexContainer
               gap="30px"
               justify="flex-start"
               align="flex-start"
               direction="column">
               <FlexContainer gap="30px">
                  <Input
                     width="65.5%"
                     name="name"
                     type="text"
                     label={t('title')}
                     onChange={Events.inputChangeHandler}
                     value={form.name}
                  />
                  <DropDownList
                     width="31%"
                     name="group"
                     label={t('status')}
                     options={statusOptions}
                     onChange={Events.inputChangeHandler}
                     // value={t('business')}
                     placeholder={t('business')}
                     disabled={true}
                  />
               </FlexContainer>

               <FlexContainer gap="30px" align="flex-start">
                  <Input
                     width="31%"
                     name="phone"
                     type="text"
                     label={t('phone')}
                     onChange={Events.inputChangeHandler}
                     value={form.phone}
                  />
                  <Input
                     width="31%"
                     name="email"
                     type="text"
                     label={t('email')}
                     onChange={Events.inputChangeHandler}
                     value={form.email}
                  />
                  <ImageContainer>
                     <Label>{t('profile.photo')}</Label>
                     <ProfileImage
                        src={`${
                           client?.picture?.includes('http')
                              ? client.picture
                              : `${api.static}/customer/${client?.picture}`
                        }`}
                     />
                  </ImageContainer>
               </FlexContainer>
            </FlexContainer>
         </Container>
      </AdminPanelContainer>
   )
}
