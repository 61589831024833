import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { store, userActions } from 'store'
import { USER_URL } from 'store/user/config'

const refreshingToken = async (refreshToken: string) => {
   const { data } = await axios.post<{
      data: {
         refreshToken: string
         accessToken: string
      }
      meta: { message: string; statusCode: number }
   }>(USER_URL.refresh_token, { refreshToken })
   return data
}

type QueueItem = {
   resolve: (token: string) => void
   reject: (error: AxiosError) => void
}

let isRefreshing: boolean = false
let failedRequestsQueue: QueueItem[] = []

const processQueue = (error: AxiosError | null, token: string | null) => {
   failedRequestsQueue.forEach((prom) => {
      if (error) {
         prom.reject(error)
      } else if (token) {
         prom.resolve(token)
      }
   })
   failedRequestsQueue = []
}

export class HttpService {
   static async request<T = any>(
      axiosConfig: AxiosRequestConfig
   ): Promise<AxiosResponse<T>> {
      try {
         const response = await axios.request(axiosConfig)
         return response
      } catch (e) {
         const error = e as AxiosError
         const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean }

         if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            if (!isRefreshing) {
               isRefreshing = true
               const refreshToken = store.getState().user.refreshToken

               try {
                  const data = await refreshingToken(refreshToken || '')
                  axios.defaults.headers.common[
                     'Authorization'
                  ] = `Bearer ${data.data.accessToken}`
                  const prevUserData = store.getState().user
                  store.dispatch(
                     userActions.auth({
                        ...prevUserData,
                        accessToken: data.data.accessToken,
                        refreshToken: data.data.refreshToken
                     })
                  )
                  if (!originalRequest.headers) {
                     originalRequest.headers = {}
                  }

                  originalRequest.headers[
                     'Authorization'
                  ] = `Bearer ${data.data.accessToken}`

                  processQueue(null, data.data.accessToken)
                  return axios(originalRequest)
               } catch (refreshError) {
                  const error = refreshError as AxiosError
                  processQueue(error, null)
                  store.dispatch(userActions.logout())
                  return Promise.reject(refreshError)
               } finally {
                  isRefreshing = false
               }
            } else {
               return new Promise((resolve, reject) => {
                  failedRequestsQueue.push({
                     resolve: (newToken: string) => {
                        if (!originalRequest.headers) {
                           originalRequest.headers = {}
                        }
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`
                        resolve(axios(originalRequest))
                     },
                     reject
                  })
               })
            }
         }

         return Promise.reject(error)
      }
   }
}
