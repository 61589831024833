import {
   AdminPanelContainer,
   Button,
   DefaultTable,
   Header,
   ModalMaterial,
   Pagination,
   TSort
} from 'components'
import { useTranslation } from 'react-i18next'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalTieUpProduct, ProductItem } from './components'
import { useLocalization, useTypedSelector } from 'hooks'
import { useGetFirstStage } from './useGetFirstStage'
import { TProduct } from 'store/product/types'
import { editProduct } from './helpers'
import { TFirstStage, TItemDragTypes, TProductsState } from './types'
import { parseFormula } from './components/ModalTieUpProduct/helpers'
import _ from 'lodash'

export const FirstStage = () => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const { accessToken } = useTypedSelector((state) => state.user)
   const [locale] = useLocalization()

   const [modal, setModal] = useState(false)
   const [page, setPage] = useState<number>(0)
   const [products, setProducts] = useState<TProductsState>([])
   const [formula, setFormula] = useState<TItemDragTypes[]>([])
   const [initProducts, setInitProducts] = useState<TProductsState>([])
   const [order, setOrder] = useState<number>(0)
   const [editOrder, setEditOrder] = useState<number | null>(null)

   const { econom, standart, premium, mutateEconom, mutateStandart, mutatePremium } =
      useGetFirstStage({ locale, accessToken, page })

   const columns = [
      {
         Header: t('economy'),
         accessor: 'econom',
         sortToggleHandler: () => {}
      },
      {
         Header: t('standard'),
         accessor: 'standart',
         sortToggleHandler: () => {}
      },
      {
         Header: t('premium'),
         accessor: 'premium',
         sortToggleHandler: () => {}
      }
   ]

   const data = useMemo(() => {
      if (!econom?.data || !standart?.data || !premium?.data) {
         return []
      }

      return econom?.data?.map((item, index) => ({
         econom: <ProductItem productItem={item} />,
         standart: <ProductItem productItem={standart?.data[index]} />,
         premium: <ProductItem productItem={premium?.data[index]} />
      }))
   }, [econom, standart, premium])

   const totalCount = useMemo(() => {
      return Math.ceil((econom?.meta?.totalCount ?? 0) / 10)
   }, [econom, standart, premium])

   const removeItems = async (e: SyntheticEvent, index: number) => {
      const econom = data![index]?.econom?.props?.productItem
      const standart = data![index]?.standart?.props?.productItem
      const premium = data![index]?.premium?.props?.productItem

      const items: TProduct[] = [econom, standart, premium]

      try {
         const firstStage: TFirstStage[] = items.map((product) => ({
            _id: product?._id,
            data: {
               firstStageEconom: false,
               firstStageStandart: false,
               firstStagePremium: false,
               firstStageEconomOrder: null,
               firstStageStandartOrder: null,
               firstStagePremiumOrder: null,
               expression: ''
            }
         }))

         await editProduct({ token: accessToken as string, payload: firstStage })

         closeModal()
      } catch (error) {
         console.log(error)
      }
   }

   const editItems = async (e: SyntheticEvent, index: number) => {
      const economProduct = data![index]?.econom?.props?.productItem
      const standartProduct = data![index]?.standart?.props?.productItem
      const premiumProduct = data![index]?.premium?.props?.productItem

      const items: TProductsState = [
         {
            type: 'economproduct',
            product: economProduct
         },
         {
            type: 'standartproduct',
            product: standartProduct
         },
         {
            type: 'premiumproduct',
            product: premiumProduct
         }
      ]

      const expression = items.filter((item) => item?.product?.expression)![0]?.product
         ?.expression

      const inheritedIndx = economProduct?.firstStageEconomOrder

      const indx = inheritedIndx ?? econom?.meta?.totalCount

      setProducts(items)
      setInitProducts(items)
      expression && setFormula(parseFormula(expression))
      !_.isNull(indx) && setEditOrder(indx)
      setModal(true)
   }

   const closeModal = () => {
      setModal(false)
      setProducts([])
      setFormula([])
      setOrder(0)
      setEditOrder(null)

      mutateEconom()
      mutateStandart()
      mutatePremium()
   }

   useEffect(() => {
      const index = econom?.data?.slice(-1)![0]?.firstStageEconomOrder! + 1

      if (_.isNumber(index) && !Number.isNaN(index)) {
         setOrder(index)
      }
   }, [econom])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('firstStage')}
               backButtonClickHandler={() => navigate('/calculator')}
               buttonsList={
                  <>
                     <Button theme="green" onClick={() => setModal(true)}>
                        {t('create')}
                     </Button>
                  </>
               }
            />
         }>
         <DefaultTable
            columns={columns}
            data={data}
            removeClickHandler={removeItems}
            editClickHandler={editItems}
            removeable
            editable
         />

         {modal && (
            <ModalMaterial close={closeModal} isOpen={modal}>
               <ModalTieUpProduct
                  products={products}
                  initProducts={initProducts}
                  formula={formula}
                  order={order}
                  editOrder={editOrder}
                  setFormula={setFormula}
                  setProducts={setProducts}
                  closeModal={closeModal}
               />
            </ModalMaterial>
         )}

         <Pagination
            onPageChange={(page) => setPage(page.selected)}
            page={page}
            pageCount={totalCount}
         />
      </AdminPanelContainer>
   )
}
