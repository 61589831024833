import { createAction } from '@reduxjs/toolkit'
import { ActionTypes } from './actionTypes'
import { sliceActions } from './reducer'
import {
   TCreateSpecialistPayload,
   TDeleteSpecialistPayload,
   TEditSpecialistPayload,
   TEditSpecialistReviewPayload,
   TGetReviewBySpecialistPayload,
   TGetSpecialistPayload,
   TGetSpecialistsPayload
} from './types'

export const specialistActions = {
   ...sliceActions,
   GET_SPECIALISTS: createAction(
      ActionTypes.getSpecialists,
      (payload: TGetSpecialistsPayload) => ({
         payload
      })
   ),
   CREATE_SPECIALIST: createAction(
      ActionTypes.createSpecialist,
      (payload: TCreateSpecialistPayload) => ({
         payload
      })
   ),
   GET_SPECIALIST: createAction(
      ActionTypes.getSpecialist,
      (payload: TGetSpecialistPayload) => ({
         payload
      })
   ),
   EDIT_SPECIALIST: createAction(
      ActionTypes.editSpecialist,
      (payload: TEditSpecialistPayload) => ({
         payload
      })
   ),
   DELETE_SPECIALIST: createAction(
      ActionTypes.deleteSpecialist,
      (payload: TDeleteSpecialistPayload) => ({ payload })
   ),
   GET_SPECIALISTS_REVIEWS: createAction(ActionTypes.getSpecialistsReviews),
   GET_REVIEW_BY_SPECIALIST: createAction(
      ActionTypes.getReviewBySpecialist,
      (payload: TGetReviewBySpecialistPayload) => ({ payload })
   ),
   EDIT_SPECIALIST_REVIEW: createAction(
      ActionTypes.editSpecialistReview,
      (payload: TEditSpecialistReviewPayload) => ({ payload })
   )
}
