import { TReadOnlyObject } from '../types'
import logo from './icons/logo.svg'
import open_eye from './icons/eye_open.svg'
import closed_eye from './icons/eye_closed.svg'
import defalt_profile_image from './icons/default_profile_image.png'
import orders from './icons/orders.svg'
import manage_product from './icons/manage_orders.svg'
import manage_clients from './icons/manage_clients.svg'
import categories from './icons/categories_n_subcategories.svg'
import support from './icons/support.svg'
import bonus_system from './icons/system_bonus.svg'
import banners from './icons/banners.svg'
import roles from './icons/roles.svg'
import delivery from './icons/delivery.svg'
import settings from './icons/settings.svg'
import exit from './icons/exit.svg'
import arrow_down from './icons/arrow_down.svg'
import plus from './icons/plus.svg'
import search from './icons/search.svg'
import calendar from './icons/calendar.svg'
import edit from './icons/edit.svg'
import trash_bucket from './icons/trash_bucket.svg'
import arrow_left from './icons/arrow_left.svg'
import cross from './icons/cross.svg'
import cross_gray from './icons/gray_cross.svg'
import minus from './icons/minus.svg'
import seo_icon from './icons/seo_icon.svg'
import plus_black from './icons/plus_black.svg'
import categories_icon from './icons/categories.svg'
import worker_icon from './icons/worker_icon.svg'
import approve_icon from './icons/approve_icon.svg'
import decline_icon from './icons/decline_icon.svg'
import subscribers from './icons/subscribers_icon.svg'
import history_icon from './icons/history_icon.svg'
import plus_icon_blue from './icons/plus_icon_blue.svg'
import delete_icon from './icons/delete_icon.svg'
import white_arrow_down from './icons/white_arrow_down.svg'
import vertical_line from './icons/vertical_line.svg'
import calculator from './icons/calculator.svg'
import brands from './icons/brands.svg'

export const assets: TReadOnlyObject<string> = {
   LOGO: logo,
   OPEN_EYE: open_eye,
   CLOSED_EYE: closed_eye,
   DEFAULT_PROFILE_IMAGE: defalt_profile_image,
   ORDERS: orders,
   MANAGE_PRODUCT: manage_product,
   MANAGE_CLIENTS: manage_clients,
   CATEGORIES: categories,
   SUPPORT: support,
   BONUS_SYSTEM: bonus_system,
   BANNERS: banners,
   ROLES: roles,
   DELIVERY: delivery,
   SETTINGS: settings,
   EXIT: exit,
   ARROW_DOWN: arrow_down,
   PLUS: plus,
   MINUS: minus,
   SEARCH: search,
   CALENDAR: calendar,
   EDIT: edit,
   TRASH_BUCKET: trash_bucket,
   ARROW_LEFT: arrow_left,
   CROSS: cross,
   CROSS_GRAY: cross_gray,
   SEO_ICON: seo_icon,
   PLUS_BLACK: plus_black,
   CATEGORIES_ICON: categories_icon,
   WORKER_ICON: worker_icon,
   APPROVE_ICON: approve_icon,
   DECLINE_ICON: decline_icon,
   SUBSCRIBERS_ICON: subscribers,
   HISTORY_ICON: history_icon,
   PLUS_ICON_BLUE: plus_icon_blue,
   DELETE_ICON: delete_icon,
   WHITE_ARROW_DOWN: white_arrow_down,
   VERTICAL_LINE: vertical_line,
   CALCULATOR: calculator,
   BRANDS: brands
}

export { default as GlobalStyles } from './GlobalStyles'
