import { createGlobalStyle } from 'styled-components'
import { colors } from '../enums'

export default createGlobalStyle`


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box ;
}

body, html {
  font-family: 'Roboto', sans-serif;

overflow: hidden;
margin: 0;
padding: 0;
height: 100%;
}


/* width */
::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: ${colors.colorPicker_grey_color};
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 14px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 14px;
 
  background: ${colors.blue};
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

`
