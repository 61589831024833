import styled from 'styled-components'
import { FONT } from '../../../../../../../../utils'

export const FlexWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 30px;
   margin-top: 30px;
   margin-left: 30px;
`

export const Dots = styled.div`
   flex: 1;
   border-bottom: 1px dashed #c6c6c6;
   margin-bottom: 4px;
   margin-left: 4px;
   margin-right: 4px;
`

export const Text = styled.p`
   ${FONT({ size: '16px', weight: '400' })}
`
