import {
   AdminPanelContainer,
   Button,
   FlexContainer,
   Header,
   Pagination,
   SubPagesBar,
   Table,
   TColumnTable,
   TSection
} from 'components'
import { api } from 'config'
import { ELocales } from 'enums'
import { useDebounceEffect, useLocalization, useTypedSelector } from 'hooks'
import { t } from 'i18next'
import { Image } from 'pages/Products/styled'
import { FC, useMemo, useState, useEffect, SyntheticEvent, useCallback } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   ApiProductService,
   getMainProductSelector,
   getUserSelector,
   productActions
} from 'store'
import { RESPONSE } from 'store/product/consts'
import { PAGE_LIMIT } from './consts'
import { Alert, Snackbar } from '@mui/material'

export const NewProductsVariationsIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const {
      product,
      loading: productLoading,
      response,
      variationProducts,
      variationsByCategory
   } = useTypedSelector(getMainProductSelector)
   const { accessToken } = useTypedSelector(getUserSelector)

   const [locale] = useLocalization()

   const { id, pageNumber } = useParams()

   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const [page, setPage] = useState<number>(0)
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [primaryProducts, setPrimaryProducts] = useState<
      { checked: boolean; index: number }[]
   >([])

   const productsId = useMemo(() => {
      return variationsByCategory.data?.variations.map((item) => item.product)
   }, [variationsByCategory])

   const _variations = useMemo(() => {
      if (variationsByCategory.data)
         return variationsByCategory.data.variations.map((item) => item.variations).flat()
      return []
   }, [variationsByCategory])

   const colors = useMemo(() => {
      if (_variations) {
         const _parameters = _variations.map((item) => item.value.title)

         return _parameters.map((item) => item.find((i) => i.lang == locale)?.title)
      }
      return []
   }, [variationsByCategory])

   const parameters = useMemo(() => {
      if (_variations) {
         const _parameters = _variations.map((item) => item.variation.title)

         return _parameters.map((item) => item.find((i) => i.lang == locale)?.title)
      }
      return []
   }, [variationsByCategory])

   const Events = {
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      },
      backButtonClickHandler: () => {
         navigate(`/products/newProducts/page=${pageNumber}/${id as string}`)
      },
      saveButtonClickHandler: () => {},
      changePrimaryClickHandler: async () => {
         const res = primaryProducts.filter((item) => {
            if (variationProducts[item.index].primary === item.checked) {
               return false
            }
            return true
         })

         res.map(async (item) => {
            await Requests.editProduct({
               _id: variationProducts[item.index]._id,
               data: item.checked
            })
         })

         toogleIsAlertOpen(true)
      },
      checkPrimary: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         checkedItems: { checked: boolean; index: number }[]
      ) => {
         setPrimaryProducts(checkedItems)
      },
      deleteProduct: () => {
         Requests.removeProduct()
         navigate(`/products?page=${pageNumber}`)
      },
      removeClickHandler: (e: SyntheticEvent, index: number) => {
         dispatch(
            productActions.removeProduct({ _id: variationProducts[index]._id as string })
         )
      },
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         checkedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(checkedItemsIndex)
      },
      editButtonClickHandler: (e: SyntheticEvent, index: number) => {
         navigate(
            `/products/newProducts/variation/page=${pageNumber}/${variationProducts[index]._id}`
         )
         dispatch(
            productActions.setVariationParameter({
               color: colors[index],
               parameter: parameters[index],
               _id: id as string
            })
         )
      },
      removeCheckedItemsHandler: () => {
         Requests.removeSelectedProducts()
      }
   }

   const Sectinos: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/products/newProducts/page=${pageNumber}/${id as string}`)
            }
         },
         {
            title: t('characteristics'),
            onClickHandler: () => {
               navigate(
                  `/products/newProducts/characteristics/page=${pageNumber}/${
                     id as string
                  }`
               )
            }
         },
         {
            title: t('related.orders'),
            onClickHandler: () => {
               navigate(
                  `/products/newProducts/related/page=${pageNumber}/${id as string}`
               )
            }
         },
         {
            title: t('variations'),
            active: true
         }
      ],
      []
   )

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('product.code'),
            accessor: 'id',
            width: 150
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 250
         },
         {
            Header: t('publish.date'),
            accessor: 'publish_date',
            width: 200
         },
         {
            Header: t(`color`),
            accessor: 'color',
            width: 200
         },
         {
            Header: t(`parameter`),
            accessor: 'parameter',
            width: 200
         },
         {
            Header: t('total'),
            accessor: 'total',
            width: 200
         },
         {
            Header: t('price'),
            accessor: 'price',
            width: 200
         },
         {
            Header: t('primary'),
            accessor: 'primary',
            width: 200
         },
         {
            Header: t('product.status'),
            accessor: 'sellStatus',
            width: 200
         },
         {
            Header: t('category'),
            accessor: 'category',
            width: 200
         }
      ],
      []
   )

   const Requests = {
      getProduct: () => {
         dispatch(productActions.getProduct({ _id: id as string }))
      },
      getVariations: () => {
         if (product?.group?._id) {
            dispatch(
               productActions.getVariationByCategory({
                  group: product?.group?._id,
                  category: product?.category?._id,
                  lang: locale as ELocales,
                  page: page,
                  limit: PAGE_LIMIT
               })
            )
         }
      },
      removeProduct: () => {
         dispatch(productActions.removeProduct({ _id: id as string }))
      },
      getVariationProducts: () => {
         if (!product?.group?._id || !productsId?.length) {
            return
         }
         dispatch(productActions.getVariationProducts({ ids: productsId as string[] }))
      },
      removeSelectedProducts: () => {
         const arr: string[] = []
         for (let i of selectedItems) {
            arr.push(variationProducts[i]._id)
         }
         dispatch(productActions.removeAllProducts({ ids: arr }))
         setSelectedItems([])
      },
      editProduct: async (request: { _id: string; data: boolean }) => {
         await ApiProductService.editProduct({
            token: (`Bearer ` + accessToken) as string,
            _id: request._id,
            data: { primary: request.data }
         })
      }
   }

   const data = useMemo(() => {
      return variationProducts.map((item, index) => ({
         id: <>{item?.barcode}</>,
         photo: <Image src={`${api.preview}${item?.preview}`} />,
         label: <>{`${item?.description?.title}`}</>,
         publish_date: (
            <Moment format="DD.MM.YYYY HH:mm">
               {new Date(item?.createdAt as string)}
            </Moment>
         ),
         color: <>{`${colors[index] ? colors[index] : ''}`}</>,
         parameter: <>{`${parameters[index] ? parameters[index] : ''}`}</>,
         total: <>{item?.amount}</>,
         price: <>{item?.price}</>,
         primary: <>{primaryProducts[index]?.checked || item._id === product?._id}</>,
         sellStatus: <>{t(`${item?.amount ? item?.sellStatus : t('notavailable')}`)}</>,
         category: <>{item?.category?.title}</>
      }))
   }, [variationProducts, primaryProducts])

   useEffect(() => {
      Requests.getVariationProducts()
   }, [page, variationsByCategory])

   useDebounceEffect(
      () => {
         if (response == RESPONSE.REMOVED) {
            Requests.getVariationProducts()
         } else if (response === RESPONSE.REMOVED_ALL) {
            Requests.getVariationProducts()
         }
      },
      300,
      [response]
   )

   useEffect(() => {
      if (variationProducts) {
         setPrimaryProducts(
            variationProducts.map((item, index) => ({
               checked: item.primary as boolean,
               index: index
            }))
         )
      }
   }, [])

   useEffect(() => {
      Requests.getVariations()
      Requests.getProduct()
      return () => {
         dispatch(productActions.setVariationProducts([]))
         dispatch(productActions.setVariations({ data: [], meta: null }))
      }
   }, [])

   return (
      <AdminPanelContainer
         Header={
            <Header
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(product?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
               buttonsList={
                  <>
                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.changePrimaryClickHandler}>
                        {t('change.primary')}
                     </Button>
                     {removeButtonState && (
                        <Button
                           theme="red"
                           height={46}
                           onClick={Events.removeCheckedItemsHandler}>
                           {t('delete.variations')}
                        </Button>
                     )}
                     <Button theme="red" height={46} onClick={Events.deleteProduct}>
                        {t('delete.product')}
                     </Button>
                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t('edit.products')}
            />
         }
         loading={productLoading}>
         <SubPagesBar sections={Sectinos} />
         <FlexContainer direction="column">
            <Table
               columns={columns}
               data={data}
               checkboxClickHandler={Events.checkboxClickHandler}
               editClickHandler={Events.editButtonClickHandler}
               removeClickHandler={Events.removeClickHandler}
               primaryClickHandler={Events.checkPrimary}
               editable
               removeable
            />
         </FlexContainer>

         <Pagination
            page={page}
            pageCount={
               variationsByCategory?.meta
                  ? Math.ceil(variationsByCategory?.meta.totalCount / PAGE_LIMIT)
                  : 1
            }
            onPageChange={Events.onPageChangeHandler}
         />

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t('primary.product.successfuly.changed')}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}
