import { t } from 'i18next'
import { FC, SyntheticEvent, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DataTotal,
   DateInput,
   FlexContainer,
   Header,
   Popup,
   SearchBar,
   Table,
   TColumnTable,
   Textarea,
   TSort
} from '../../../../components'

export const HistoryIndexPage: FC = () => {
   const navigate = useNavigate()

   const [searchForm, setSearchForm] = useState({
      regex: '',
      date_start: '',
      date_end: ''
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [selectedItems, setSelectedItems] = useState<number[]>([])
   const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)
   const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false)

   const Events = {
      onChangeHandler: () => {},
      onSubmitSearchHandler: () => {},
      checkboxClickHandler: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(ckeckedItemsIndex)
      },
      editButtonClickHandler: () => {
         navigate(`/history/${'1'}`)
      },
      sortToggleHandler: () => {},
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         ckeckedItemsIndex: number[]
      ) => {
         setSelectedItems(ckeckedItemsIndex)
         setRemoveButtonState(hasCheckboxesActiveState)
      },
      closePopupClickHandler: () => {
         setPopupVisiablity(false)
      },
      cancelButtonClickHandler: () => {
         setPopupVisiablity(false)
      },
      saveButtonClickHandler: () => {},
      headerSaveButtonClickHandler: () => {
         setPopupVisiablity(true)
      },
      historyButtonClickHandler: () => {
         navigate('/history')
      },
      backButtonClickHandler: () => {
         navigate('/subscribers')
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('createdAt'),
            accessor: 'createdAt',
            width: 250,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('addressees'),
            accessor: 'addressees',
            width: 450
         },
         {
            Header: t('mail.text'),
            accessor: 'mailText',
            width: 550
         }
      ],
      []
   )

   const data = useMemo(
      () => [
         {
            createdAt: <>{<Moment format="DD.MM.YYYY hh:mm">{new Date()}</Moment>}</>,
            addressees: <></>,
            mailText: <>{`mailText`}</>
         }
      ],
      []
   )

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('history.subscribers')}
               buttonsList={
                  <>
                     {/* {removeButtonState && (
                        <Button
                           theme="green"
                           onClick={Events.headerSaveButtonClickHandler}>
                           {t('create.mail')}
                        </Button>
                     )} */}
                  </>
               }
               backButtonClickHandler={Events.backButtonClickHandler}
            />
         }>
         <FlexContainer direction="column" gap="30px">
            <FlexContainer padding="30px" align="center" gap="60px">
               <FlexContainer direction="row" width="280px">
                  <SearchBar
                     name="regex"
                     placeholder={t('search')}
                     value={searchForm.regex}
                     onChange={Events.onChangeHandler}
                     onSubmit={Events.onSubmitSearchHandler}
                  />
               </FlexContainer>
               <FlexContainer gap="30px" width="330px">
                  <DateInput
                     name="date_start"
                     value={searchForm.date_start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="date_end"
                     value={searchForm.date_end}
                     onChange={Events.onChangeHandler}
                  />
               </FlexContainer>
            </FlexContainer>

            <Table
               columns={columns}
               data={data}
               sortBy={tableSortBy}
               checkboxClickHandler={Events.checkboxClickHandler}
               editClickHandler={Events.editButtonClickHandler}
               editable
            />
         </FlexContainer>
      </AdminPanelContainer>
   )
}
