import { Alert, Snackbar } from '@mui/material'
import { from } from 'form-data'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   Label,
   ProfileImage,
   SubPagesBar,
   TSection
} from '../../../../../../components'
import { api } from '../../../../../../config'
import { useTypedSelector } from '../../../../../../hooks'
import {
   bonusActions,
   clientActions,
   CLIENT_RESPONSE,
   getBonusesSelector,
   getMainClientSelector
} from '../../../../../../store'
import {
   AddressBar,
   AddressLabel,
   Container,
   FormContainer,
   ImageContainer
} from './styled'
import { TForm } from './types'

const IndexPage: FC = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const {
      client,
      loading: clientLoading,
      response
   } = useTypedSelector(getMainClientSelector)
   const { loading: bonusesLoading, data: bonuses } = useTypedSelector(getBonusesSelector)

   const { id } = useParams()

   const statusOptions = useMemo(
      () => bonuses.map((bonus) => ({ name: bonus.name, value: bonus._id })),
      [bonuses]
   )

   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const [form, setForm] = useState<TForm>({
      group: { discount: 0, name: '', _id: '' },
      blocked: false,
      balance: 0,
      createdAt: '',
      email: '',
      fbVerification: false,
      id: 0,
      name: '',
      orders: 0,
      updatedAt: '',
      _id: '',
      secondName: '',
      picture: '',
      phone: ''
   })

   const Events = {
      unblockUserButtonClickHandler: () => {
         Requests.unblockUser()
      },
      blockUserButtonClickHandler: () => {
         Requests.blockUser()
      },
      backButtonClickHandler: () => {
         navigate('../clients')
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement
         setForm((props) => ({ ...props, [name]: value }))
      },
      saveButtonClickHandler: () => {
         setAlertText('profile.successfuly.edited')
         Requests.editClient()
         toogleIsAlertOpen(true)
      }
   }

   const Requests = {
      getClient: () => {
         dispatch(clientActions.getClient({ _id: id as string }))
      },
      getStatuses: () => {
         dispatch(bonusActions.getBonusesRequest())
      },
      editClient: () => {
         if (form.group) {
            dispatch(clientActions.editClient({ _id: id as string, data: { ...form } }))
         }
      },
      blockUser: () => {
         dispatch(
            clientActions.editClient({
               _id: id as string,
               data: { blocked: true }
            })
         )
      },
      unblockUser: () => {
         dispatch(
            clientActions.editClient({
               _id: id as string,
               data: { blocked: false }
            })
         )
      }
   }

   const Sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            active: true
         },
         {
            title: t('orders.history'),
            onClickHandler: () => {
               navigate('./history')
            }
         }
      ],
      []
   )

   useEffect(() => {
      Requests.getClient()
      Requests.getStatuses()
   }, [])

   useEffect(() => {
      if (response == CLIENT_RESPONSE.NOT_FOUND) navigate('../clients')
   }, [response])

   useEffect(() => {
      client &&
         setForm({
            ...form,
            blocked: client.blocked,
            createdAt: client.createdAt,
            email: client.email,
            fbVerification: form.fbVerification,
            id: client.id,
            name: client.name,
            orders: client.orders,
            updatedAt: client.updatedAt,
            _id: client._id,
            picture: client.picture,
            secondName: client.secondName,
            phone: client.phone
         })
   }, [client])

   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('edit')}
               backButtonClickHandler={Events.backButtonClickHandler}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(client?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(client?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
               buttonsList={
                  <>
                     {!client?.blocked && (
                        <Button
                           theme="red"
                           height={46}
                           onClick={Events.blockUserButtonClickHandler}
                           style="transparant">
                           {t('block')}
                        </Button>
                     )}

                     {!!client?.blocked && (
                        <Button
                           theme="green"
                           height={46}
                           onClick={Events.unblockUserButtonClickHandler}
                           style="transparant">
                           {t('unblock')}
                        </Button>
                     )}

                     <Button
                        theme="green"
                        height={46}
                        onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
            />
         }
         loading={bonusesLoading || clientLoading}>
         <SubPagesBar sections={Sections} />
         <Container>
            <FormContainer>
               <FlexContainer columnGap="20px" rowGap="30px" style={{ height: '0px' }}>
                  <Input
                     width="280px"
                     name="name"
                     type="text"
                     label={t('name')}
                     onChange={Events.inputChangeHandler}
                     value={form.name}
                     disabled={true}
                  />
                  <Input
                     width="280px"
                     name="secondName"
                     label={t('lastName')}
                     onChange={Events.inputChangeHandler}
                     value={form.secondName}
                     disabled={true}
                  />
                  <DropDownList
                     width="300px"
                     name="group"
                     label={t('status')}
                     options={statusOptions}
                     onChange={Events.inputChangeHandler}
                     // value={t('business')}
                     placeholder={t('private')}
                     disabled={true}
                  />
                  {client?.group && (
                     <DropDownList
                        name="group"
                        label={t('status')}
                        options={statusOptions}
                        onChange={Events.inputChangeHandler}
                        value={form.group.name || (client?.group.name as string)}
                     />
                  )}
                  <Input
                     width="280px"
                     name="phone"
                     label={t('phone')}
                     onChange={Events.inputChangeHandler}
                     value={form.phone}
                     disabled={true}
                  />
                  <Input
                     width="280px"
                     name="email"
                     label={t('email')}
                     onChange={Events.inputChangeHandler}
                     value={form.email}
                     disabled={true}
                  />

                  <ImageContainer>
                     <Label>{t('profile.photo')}</Label>
                     <ProfileImage
                        src={`${
                           client?.picture?.includes('http')
                              ? client.picture
                              : `${api.static}/customer/${client?.picture}`
                        }`}
                     />
                  </ImageContainer>
               </FlexContainer>
            </FormContainer>
            <FlexContainer
               direction="column"
               align="center"
               style={{ padding: '24px 30px' }}>
               <AddressLabel>{t('addresses')}</AddressLabel>
               <FlexContainer direction="column" gap="30px" style={{ marginTop: '8px' }}>
                  {client?.addresses?.map((address, index) => (
                     <AddressBar key={index}>{address.address}</AddressBar>
                  ))}
               </FlexContainer>
            </FlexContainer>
         </Container>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}

export default IndexPage
