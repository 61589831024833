import styled from 'styled-components'

export const Container = styled.div`
   display: flex;
   flex-direction: column;
   gap: 30px;
   width: 99%;
   height: 100%;
   padding-top: 24px;
   padding-left: 30px;
`
