import { api } from 'config'
import { HttpService } from 'services'
import { PRODUCT_URL } from 'store/product/config'
import { TGetProductsPayload, TProducts } from 'store/product/types'
import { TFirstStage } from './types'

export const getProducts = async ({
   token,
   params
}: {
   token: string
   params?: TGetProductsPayload
}): Promise<TProducts> => {
   const response = await HttpService.request({
      url: `${PRODUCT_URL.getProducts}`,
      method: 'GET',
      headers: {
         Authorization: token,
         'Nest-Cache': 'no-cache'
      },
      params
   })

   return response.data
}

export const editProduct = async ({
   token,
   payload
}: {
   token: string
   payload: TFirstStage[]
}): Promise<TProducts> => {
   const response = await HttpService.request({
      url: `${api.public}v1/catalog/productBulk/firstStage`,
      method: 'PATCH',
      headers: {
         Authorization: 'Bearer ' + token
      },
      data: payload
   })

   return response.data
}
