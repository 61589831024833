import { api } from 'config'

export const CATEGORY_URL = {
   getCategories: `${api.public}v1/catalog/category`,
   removeCategory: `${api.public}v1/catalog/category`,
   editCategory: `${api.public}v1/catalog/category`,
   createCategory: `${api.public}v1/catalog/category`,
   getCategory: `${api.public}v1/catalog/category`,
   getCategoryParent: `${api.public}v1/catalog/category`,
   getProductsByCategory: (id: string) =>
      `${api.public}v1/catalog/product/${id}/byCategory`,
   editCategoryOrder: `${api.public}v1/catalog/category/categoryBulk`,
   changeProductOrder: `${api.public}v1/catalog/productBulk/category`
}
