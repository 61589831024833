import { FC } from 'react'
import ReactPaginate from 'react-paginate'
import { ArrowLeftIcon, ArrowRightIcon, Styles } from './styled'
import { TPagination } from './types'

const Pagination: FC<TPagination> = ({ page = undefined, pageCount = 1, onPageChange }) => {
   return (
      <Styles>
         <ReactPaginate
            previousLabel={<ArrowLeftIcon />}
            nextLabel={<ArrowRightIcon />}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            onPageChange={onPageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={page}
         />
      </Styles>
   )
}

export default Pagination
