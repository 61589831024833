import styled from 'styled-components'

import { TImage } from './types'

export const Image = styled.div<TImage>`
   display: inline-block;
   background-image: ${({ src }) => `url(${src})`};
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   border-radius: 50%;
   height: 46px;
   width: 46px;
`

export const Container = styled.div`
   display: flex;
   flex-direction: column;
   gap: 30px;
   width: 95%;
   height: 100%;
   padding-top: 24px;
   padding-left: 30px;
`
