import { call, put, takeLatest } from 'redux-saga/effects'
import { ApiCategoryService } from '..'
import { i18n } from '../../config'
import { ELocales } from '../../enums'
import { TDataWrapper, TResponse } from '../types'
import { getAccessToken } from '../user'
import { sectionActions } from './actions'
import { SECTION_RESPONSE } from './consts'
import {
   TCreateSectionPayload,
   TEditSectionPayload,
   TRemoveSectionsPayload,
   TSectionPayload
} from './types'

function* getSectionWorker({ payload }: TDataWrapper<TSectionPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)

   try {
      const response: TResponse = yield call(
         [ApiCategoryService, ApiCategoryService.getCategories],
         {
            ...payload,
            lang: i18n.language as ELocales,
            token,
            field: 'parent',
            value: null
         }
      )
      if (response.data) yield put(sectionActions.setSections(response.data))
   } catch (e) {
      yield put(sectionActions.setSections({ data: [], meta: null }))
   }

   yield put(sectionActions.setSectionState({ loading: false }))
}

function* createSectionWorker({ payload }: TDataWrapper<TCreateSectionPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { data } = payload

   try {
      yield call([ApiCategoryService, ApiCategoryService.createCategory], { token, data })
   } catch (e) {}

   yield put(
      sectionActions.setSectionState({
         loading: false,
         response: SECTION_RESPONSE.CREATED
      })
   )
}

function* editSectionWorker({ payload }: TDataWrapper<TEditSectionPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { data, id } = payload

   // try {
   //    yield call([ApiCategoryService, ApiCategoryService.editCategory], {
   //       token,
   //       data,
   //       _id: id
   //    })
   // } catch (e) {}

   yield put(
      sectionActions.setSectionState({
         loading: false,
         response: SECTION_RESPONSE.EDITED
      })
   )
}

function* removeSectionsWorker({ payload }: TDataWrapper<TRemoveSectionsPayload>) {
   yield put(sectionActions.setSectionState({ loading: true }))

   const token: string = yield call(getAccessToken)
   const { ids } = payload

   try {
      for (let _id of ids) {
         yield call([ApiCategoryService, ApiCategoryService.removeCategory], {
            _id,
            token
         })
      }
   } catch (e) {}

   yield put(
      sectionActions.setSectionState({
         loading: false,
         response: SECTION_RESPONSE.REMOVED
      })
   )
}

export function* sectionWatcher() {
   yield takeLatest(sectionActions.getSections, getSectionWorker)
   yield takeLatest(sectionActions.createSection, createSectionWorker)
   yield takeLatest(sectionActions.editSection, editSectionWorker)
   yield takeLatest(sectionActions.removeSections, removeSectionsWorker)
}
