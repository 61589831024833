import { t } from 'i18next'
import { FC, useMemo, SyntheticEvent, useState, useEffect } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
   AdminPanelContainer,
   Button,
   DropDownList,
   FlexContainer,
   Header,
   Input,
   KeywordsInput,
   SubPagesBar,
   Textarea,
   TSection
} from '../../../../components'
import { i18n } from '../../../../config'
import { useTypedSelector } from '../../../../hooks'
import { getMainWorkerSelector } from '../../../../store/specialist'
import { specialistActions } from '../../../../store/specialist/action'
import { PHONE_NUMBER_LIMIT } from '../EditWorker/pages/Main/consts'
import { Controller, useForm } from 'react-hook-form'
import { Container } from './styled'
import { Alert, Snackbar } from '@mui/material'

export const CreateWorkerIndexPage: FC = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { loading, response: specialistResponse } =
      useTypedSelector(getMainWorkerSelector)

   const metricOptions = [{ name: t('meters'), value: 'meters' }]
   const [isClicked, setClick] = useState(false)
   const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
   const [alertText, setAlertText] = useState<string>()

   const [form, setForm] = useState({
      name: '',
      secondName: '',
      mainSpecialty: '',
      specialties: [],
      languages: [],
      metaDescription: '',
      metaKeywords: [],
      salary: 0,
      salaryType: '',
      phone: '',
      serviceArea: ''
   })

   const [coordinates, setCoordinates] = useState<{
      lat: number | null
      lon: number | null
   }>({
      lat: null,
      lon: null
   })

   const Schema = {
      name: {
         condition: form.name.length >= 2
      },
      phone: {
         condition: form.phone.length >= 10 || form.phone.length == 10
      },
      secondName: {
         condition: form.secondName.length >= 2
      },
      mainSpecialization: {
         condition: form.mainSpecialty.length >= 2
      },
      language: {
         condition: form.languages.length >= 1
      },
      salary: {
         condition: form.salary > 0 || form.salary == 0
      }
   }

   const Events = {
      saveButtonClickHandler: () => {
         Utils.isSchemaValid() && setAlertText('worker.successfuly.created')
         setClick(true)
         Utils.isSchemaValid() && Requests.createSpecialist()
         Utils.isSchemaValid() && toogleIsAlertOpen(true)
      },
      backButtonClickHanlder: () => {
         navigate('/workers')
      },
      onKeywordsChangeHandler: (specialties: string[]) => {
         setForm({
            ...form,
            metaKeywords: specialties as never
         })
      },
      onKeywordsSpecChangeHandler: (specialties: string[]) => {
         setForm({
            ...form,
            specialties: specialties as never
         })
      },
      onKeywordsLanguageChangeHandler: (languages: string[]) => {
         setForm({
            ...form,
            languages: languages as never
         })
      },
      inputChangeHandler: (e: SyntheticEvent) => {
         const { name, value } = e.target as HTMLInputElement

         setForm((props) => ({ ...props, [name]: value }))
      },
      onFormChange: () => {
         setForm((props) => ({
            ...props,
            serviceArea: getValues().address
         }))
      },
      onChange: (e: SyntheticEvent) => {
         const input = e.target as HTMLInputElement

         setForm({ ...form, [input.name]: input.value })
      }
   }

   const Requests = {
      createSpecialist: () => {
         dispatch(
            specialistActions.CREATE_SPECIALIST({
               data: {
                  name: form.name,
                  secondName: form.secondName,
                  mainSpecialty: form.mainSpecialty,
                  specialties: form.specialties,
                  languages: form.languages,
                  salary: Number(form.salary),
                  salaryType: form.salaryType,
                  serviceArea: getValues().address,
                  phone: Utils.toPhoneNumber(form.phone),
                  metaKeywords: form.metaKeywords,
                  metaDescription: form.metaDescription
               }
            })
         )
      }
   }

   const Utils = {
      removeNonNumber: (phone: string): string => {
         if (!phone) return ''
         return phone.replace(/\D/g, '').substring(0, PHONE_NUMBER_LIMIT - 1)
      },
      toPhoneNumber: function (phone: string): string {
         const value = this.removeNonNumber(phone)

         if (!value.length) return ''
         if (value.length == 1) return `${value}`
         if (value.length <= 4) {
            return `${value.substring(0, 1)}${value.substring(1)}`
         }

         return `${value.substring(0, 1)}${value.substring(1, 4)}${value.substring(
            4,
            7
         )}${value.substring(7, 9)}${value.substring(9)}`.trim()
      },
      isSchemaValid: () => {
         let isValid = true
         Object.keys(Schema).forEach((prop) => {
            if (!Schema[prop as keyof typeof Schema].condition) {
               console.log(prop)
               isValid = false
            }
         })

         return isValid
      }
      // textValidation: (text: string) => {
      //    if (text.length) {
      //       return text.match(/^[a-zA-Zа-яА-Я]+$/) ? true : false
      //    } else {
      //       return true
      //    }
      // }
   }

   const formUtils = {
      onChangeText: (
         text: any,
         onChange: (...event: any[]) => void,
         name: any,
         trigger: any
      ) => {
         onChange(text)
         trigger(name)
      }
   }

   const {
      trigger,
      control,
      setValue,
      getValues,
      formState: { errors, isValid }
   } = useForm<{ address: string }>({
      defaultValues: { address: '' }
   })

   const { ref } = usePlacesWidget({
      language: i18n?.language,
      options: {
         types: ['address'],
         componentRestrictions: {
            country: 'az'
         },
         strictBounds: true,
         bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(40.273843, 49.693146),
            new google.maps.LatLng(40.51008, 50.005264)
         )
      },
      onPlaceSelected: (place) => {
         setValue('address', place.formatted_address as string)
         setCoordinates({
            lat: place.geometry?.location.lat() as number,
            lon: place.geometry?.location.lng() as number
         })
         Events.onFormChange()
         trigger()
      }
   })

   const sections: TSection[] = useMemo(
      () => [
         {
            title: t('main.data'),
            active: true
         }
      ],
      []
   )

   useEffect(() => {
      if (specialistResponse === 'CREATED') {
         navigate('/workers')
      }
   }, [specialistResponse])

   return (
      <AdminPanelContainer
         Header={
            <Header
               buttonsList={
                  <>
                     <Button theme="green" onClick={Events.saveButtonClickHandler}>
                        {t('save')}
                     </Button>
                  </>
               }
               title={t('create')}
               backButtonClickHandler={Events.backButtonClickHanlder}
            />
         }
         loading={loading}>
         <SubPagesBar sections={sections} />
         <Container>
            <FlexContainer gap="62px" align="flex-start">
               <Input
                  name="name"
                  type="text"
                  label={t('name')}
                  placeholder={t('enter.name')}
                  value={form.name}
                  onChange={Events.inputChangeHandler}
                  required={true}
                  error={t('required.field')}
                  isValid={isClicked ? Schema.name.condition : true}
               />
               <Input
                  name="secondName"
                  type="text"
                  label={t('lastName')}
                  placeholder={t('enter.lastName')}
                  value={form.secondName}
                  onChange={Events.inputChangeHandler}
                  required={true}
                  error={t('required.field')}
                  isValid={isClicked ? Schema.name.condition : true}
               />
               <Input
                  name="phone"
                  type="text"
                  label={t('phone')}
                  error={t('phone.length.is.incorrect')}
                  placeholder={t('enter.phone')}
                  isValid={isClicked ? Schema.phone.condition : true}
                  value={Utils.toPhoneNumber(form.phone)}
                  onChange={Events.inputChangeHandler}
               />
            </FlexContainer>

            <FlexContainer gap="62px" direction="row" align="flex-start">
               <Input
                  name="mainSpecialty"
                  type="text"
                  label={t('specialization')}
                  placeholder={t('enter.specialization')}
                  value={form.mainSpecialty}
                  onChange={Events.inputChangeHandler}
                  required={true}
                  error={t('required.field')}
                  isValid={isClicked ? Schema.name.condition : true}
               />
               {/* <FlexContainer width="318px" align="flex-start" gap="18px">
                  <Input
                     width="150px"
                     name="salary"
                     type="number"
                     label={t('salary')}
                     placeholder={t('enter.salary')}
                     value={form.salary + ''}
                     onChange={Events.inputChangeHandler}
                     required={true}
                     isValid={isClicked ? Schema.salary.condition : true}
                     error={t('required.field')}
                  />
                  <DropDownList
                     width="130px"
                     name="metric"
                     options={metricOptions}
                     label={t('payment.type')}
                     placeholder={t('choose.metric')}
                     value={metricOptions[0].name}
                     onChange={Events.inputChangeHandler}
                  />
               </FlexContainer> */}
               <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                     <>
                        <Input
                           name={'address'}
                           label={t('location')}
                           placeholder={t('enter.location')}
                           width="100%"
                           value={value}
                           isValid={!isClicked ? value.length >= 0 : value.length > 0}
                           error={t('required.field')}
                           cref={ref as any}
                           onChange={(e) =>
                              formUtils.onChangeText(
                                 (e.target as HTMLInputElement).value,
                                 onChange,
                                 'address',
                                 trigger
                              )
                           }
                        />
                     </>
                  )}
                  name="address"
               />
            </FlexContainer>

            <KeywordsInput
               width="90%"
               label={t('language')}
               placeholder={t('enter.language')}
               value={form.languages}
               onChange={Events.onKeywordsLanguageChangeHandler}
               isValid={isClicked ? form.languages.length > 0 : true}
               languageKeywords={true}
               error={t('required.field')}
            />

            <KeywordsInput
               width="90%"
               label={t('add.spec')}
               placeholder={t('enter.add.spec')}
               value={form.specialties}
               onChange={Events.onKeywordsSpecChangeHandler}
            />

            <KeywordsInput
               width="90%"
               label={t('meta.keywords')}
               placeholder={t('meta.keywords')}
               value={form.metaKeywords}
               onChange={Events.onKeywordsChangeHandler}
            />

            <Textarea
               name="metaDescription"
               width="100%"
               height="140px"
               label={t('meta.description')}
               onChange={Events.onChange}
               placeholder={t('meta.description')}
               margin={'24px'}>
               {form?.metaDescription}
            </Textarea>
         </Container>

         <Snackbar
            open={isAlertOpen}
            autoHideDuration={6000}
            onClose={() => toogleIsAlertOpen(false)}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
               {t(`${alertText ? alertText : 'added'}`)}
            </Alert>
         </Snackbar>
      </AdminPanelContainer>
   )
}

// Utils.textValidation(form.name)
//    ? t('required.field')
//    : t('only.letter')
// isValid={
//    isClicked && Utils.textValidation(form.mainSpecialty)
//       ? Schema.mainSpecialization.condition
//       : Utils.textValidation(form.mainSpecialty)
// }
